import { toast } from '@askable/ui/components/ui/sonner';
import { AskableSessionsIcon, Button } from 'ui';

import { SubmissionStatus, useAskableSessionsRoomCodeLazyQuery } from 'generated/graphql';
import { getClientAskableSessionAuthLink } from 'utils/url-utils';

import type { SessionsType } from 'generated/graphql';
import type React from 'react';

type Props = {
  userId: string;
  bookingId: string;
  teamId?: string;
  session?: SessionsType | null;
  submissionStatus?: SubmissionStatus | null;
};

export const BookingParticipantUnmodPlaybackLink = ({ bookingId, teamId, submissionStatus, userId }: Props) => {
  const [fetchRoomCode] = useAskableSessionsRoomCodeLazyQuery();

  const getRoomCode = async () => {
    const { data } = await fetchRoomCode({
      variables: {
        input: {
          _booking_id: bookingId,
          _user_id: userId,
        },
      },
    });
    return data?.searchAskableMeeting?.[0]?.code;
  };

  if (!submissionStatus || !bookingId) {
    return null;
  }

  const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();

    const roomCode = await getRoomCode();
    if (!roomCode) {
      toast.error('Session link could not be found');
      return;
    }

    const link = getClientAskableSessionAuthLink({ roomCode, playback: true, teamId });

    window.open(link, '_blank');
  };

  if (
    [
      SubmissionStatus.Completed,
      SubmissionStatus.Reported,
      SubmissionStatus.PendingCheck,
      SubmissionStatus.HelpRequested,
    ].includes(submissionStatus)
  ) {
    return (
      <Button size="sm" leftIcon={<AskableSessionsIcon />} variant="ghost" onClick={handleClick}>
        View session
      </Button>
    );
  }

  return null;
};
