import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { FormControl, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

interface Option {
  label: string;
  value: string;
}

interface CheckboxGroupFieldProps {
  isDisabled?: boolean;
  name: string;
  options: Option[];
  variant?: 'stacked' | 'inline';
}

const CheckboxGroupFieldComponent = ({ isDisabled, options, name, variant = 'stacked' }: CheckboxGroupFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <FormItem>
      <>
        <FormControl>
          <div className={cn('grid grid-rows-1 gap-3 sm:grid-cols-3', { 'flex flex-col': variant === 'stacked' })}>
            {options.map(option => (
              <div key={option.value} className="flex items-center gap-2">
                <Checkbox
                  value={option.value}
                  checked={Array.isArray(field.value) && field.value.includes(option.value)}
                  disabled={isDisabled}
                  id={`${name}_option_${option.value}`}
                  onCheckedChange={checked => {
                    const updatedValue = checked
                      ? [...(field.value || []), option.value]
                      : (field.value || []).filter((value: string) => value !== option.value);

                    field.onChange(updatedValue);
                  }}
                />
                <FormLabel
                  htmlFor={`${name}_option_${option.value}`}
                  className="flex-1 cursor-pointer leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {option.label}
                </FormLabel>
              </div>
            ))}
          </div>
        </FormControl>

        {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
      </>
    </FormItem>
  );
};

CheckboxGroupFieldComponent.displayName = 'CheckboxGroupField';

export const CheckboxGroupField = memo(CheckboxGroupFieldComponent);
