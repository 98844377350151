import { createContext, useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { BOOKING_STATUS } from 'lib/constants';

import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';

import { ResultsHeader } from './components/ResultsHeader';
import { RecordingContentBasic } from './containers/RecordingContentBasic';
import { ResultsNavigation } from './containers/ResultsNavigation';
// @todo replace with real data
import { dummyResults } from './dummyResults';

// @todo get types from API
export type BlockType = 'figma_prototype' | 'multiple_choice_question' | 'open_answer' | 'opinion_scale';
export type StatType = 'successful' | 'incomplete' | 'responses' | 'misclickRate' | 'averageDuration';
export type TaskSectionType = 'success' | 'incomplete';

export interface StatProps {
  subtitle?: string;
  type: StatType;
  value: number | string;
}

export interface ClicksProps {
  x: number;
  y: number;
}

export interface ScreenProps {
  clicks: ClicksProps[];
  duration?: number;
  height: number;
  id: string;
  image: string;
  misclickRate?: number;
  responses?: number;
  thumbnail: string;
  title: string;
  width: number;
}

export interface TaskSectionProps {
  duration: number;
  id: string;
  misclickRate: number;
  responses: number;
  screens: ScreenProps[];
  title: string;
  type: TaskSectionType;
}

interface TaskProps {
  id: string;
  responses: number;
  sections: TaskSectionProps[];
  stats: StatProps[];
  title: string;
  type: BlockType;
}

export interface ParticipantResponse {
  id: string;
  type: BlockType;
  question: string;
  answer: {
    id: string;
    value: string;
  };
}

export interface Recording {
  url: string;
  duration: number;
  screens: ScreenProps[];
}

export interface ParticipantProps {
  completed: number;
  duration: number;
  id: string;
  misclickRate: number;
  name: string;
  outcome: TaskSectionType;
  screensViewed: number;
  status: string;
  thumbnail: string;
  recording: Recording | null;
  responses: ParticipantResponse[];
  details: {
    age: number;
    location: string;
    gender: string;
  };
  screenerQuestions: {
    answer: string;
    eligible: boolean;
    id: string;
    question: string;
  }[];
  studyDetails: {
    completionDate: number;
    duration: number;
    deviceType: string;
    os: string;
  };
}

export interface ResultsProps {
  id: string;
  name: string;
  status: string;
  participants: ParticipantProps[];
  tasks: TaskProps[];
  completed: number;
  created: number;
}

type ResultsContextProps = {
  results: ResultsProps | null;
};

const ResultsContext = createContext<ResultsContextProps>({
  results: null,
});

export const useResultsContext = () => {
  return useContext(ResultsContext);
};

export const Results = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const { participantId, taskId } = params;

  const isLive = study.status !== BOOKING_STATUS.DRAFT && study.status !== BOOKING_STATUS.IN_REVIEW;
  const resultsContextValue = useMemo<ResultsContextProps>(() => ({ results: dummyResults }), []);

  // If we do have blocks but no taskId in the url, redirect to the first block
  if (!taskId && !!study?.config?.unmoderated?.task_blocks?.length) {
    const taskId = study.config?.unmoderated?.task_blocks?.[0]?._id;
    return <Navigate to={`/studies/${study._id}/results/task/${taskId}`} replace />;
  }

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: t('sections.studies.tabs.results'),
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <ResultsContext.Provider value={resultsContextValue}>
        <ResultsHeader hasSidebar={isLive} />
        {isLive ? (
          <>
            <ResultsNavigation active={participantId ? 'participants' : 'blocks'} showParticipants={false} />
            <main className="main">
              <Outlet />
            </main>
            <RecordingContentBasic />
          </>
        ) : (
          <main className="main">
            <div className="flex h-full flex-1 flex-row gap-4 overflow-auto rounded-xl bg-background">
              <EmptyState title={t('sections.studies.results.notLive')} />
            </div>
          </main>
        )}
      </ResultsContext.Provider>
    </>
  );
};
