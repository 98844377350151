import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useCallback, useId } from 'react';

import { useContainer } from './hooks';
import { TaskCardDraggableInner } from './task-card-draggable-inner';

import type { DragEndEvent } from '@dnd-kit/core';
import type { ComponentProps } from 'react';

/**
 * Task Card Draggable
 * - Requires a parent container with the class 'drag-container'
 * - Stores the position of the draggable card in localstorage
 *
 * Usage:
 *
 * <TaskCardDraggable
 *   id={id}
 *   title="Task"
 *   isCollapsed={isCollapsed}
 *   setIsCollapsed={setIsCollapsed}
 *   collapseLabel="Hide instructions"
 *   action={<ActionButton />}
 * >
 *   <>
 *    <TaskCardDraggableContent title="Some task" instructions="Content here" />
 *    other content...
 *   </>
 * </TaskCardDraggable>
 */

type Props = Omit<ComponentProps<typeof TaskCardDraggableInner>, 'position'>;

export const TaskCardDraggable = ({ isPositionSaved = true, ...props }: Props) => {
  const cardId = useId();
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }));
  const { cardPosition, saveCardPosition } = useContainer(cardId, isPositionSaved);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { delta } = event;
      const newPosition = {
        x: Number((cardPosition.x + delta.x).toFixed(0)),
        y: Number((cardPosition.y - delta.y).toFixed(0)),
      };

      saveCardPosition(newPosition);
    },
    [cardPosition, saveCardPosition],
  );

  return (
    <DndContext sensors={sensors} modifiers={[restrictToParentElement]} onDragEnd={onDragEnd}>
      <TaskCardDraggableInner {...props} id={cardId} position={cardPosition} />
    </DndContext>
  );
};

export { TaskCardDraggableContent } from './task-card-draggable-content';
