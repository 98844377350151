import { CancelIcon, CheckIcon, Text } from 'ui';

import type { FC } from 'react';

export const enum ParticipantAgreementStatus {
  Sent = 3,
  Viewed = 4,
  Declined = 5,
  Signed = 6,
}

type Props = {
  status: ParticipantAgreementStatus;
};

const TEXT_MAP: Record<ParticipantAgreementStatus, string> = {
  [ParticipantAgreementStatus.Sent]: 'Sent',
  [ParticipantAgreementStatus.Viewed]: 'Viewed',
  [ParticipantAgreementStatus.Declined]: 'Declined',
  [ParticipantAgreementStatus.Signed]: 'Signed',
};

export const TableAgreement: FC<Props> = ({ status }) => {
  const text = TEXT_MAP[status];

  if (!text) {
    return null;
  }

  return (
    <Text fontWeight="medium">
      {status === ParticipantAgreementStatus.Signed && <CheckIcon fontSize="md" color="green.500" mr="2" />}
      {status === ParticipantAgreementStatus.Declined && <CancelIcon color="red.500" fontSize="md" mr="2" />}
      {text}
    </Text>
  );
};
