import { AnchorButton } from '@askable/ui/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@askable/ui/components/ui/tabs';
import { MessageCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, Link } from 'react-router-dom';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { getPageTitle } from 'containers/Studies/utils/getPageTitle';
import { BOOKING_STATUS } from 'lib/constants';

import { InfoButton } from './components/Participants/InfoButton';

import type { recruitSchema } from 'containers/Studies/data/schemas/recruitSchema';
import type { z } from 'zod';

export type RecruitFormFields = z.infer<typeof recruitSchema>;

type SubSections = 'participants' | 'settings' | 'messages';

export const Recruit = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();

  const match = useMatch('/studies/:studyId/recruit/:section');
  const view = (match?.params?.section || 'settings') as SubSections;

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: t('sections.studies.tabs.recruit'),
            study: study.name || t('sections.studies.untitledStudy'),
            subSection: t(`sections.studies.recruit.sections.${view}`),
          })}
        </title>
      </Helmet>

      {study.status !== BOOKING_STATUS.DRAFT ? (
        <header className="recruit-header flex items-center justify-between gap-2 border-b-0.5 border-border bg-background px-3 py-2 md:px-4">
          <div>
            <Tabs defaultValue="settings" value={view}>
              <TabsList className="w-full">
                <TabsTrigger asChild value="participants">
                  <Link to={{ pathname: `/studies/${study._id}/recruit/participants` }} className="flex-1">
                    {t('sections.studies.recruit.sections.participants')}
                  </Link>
                </TabsTrigger>
                <TabsTrigger asChild value="settings">
                  <Link to={{ pathname: `/studies/${study._id}/recruit/settings` }} className="flex-1">
                    {t('sections.studies.recruit.sections.settings')}
                  </Link>
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>

          {view === 'participants' ? (
            <div className="flex gap-2">
              <AnchorButton variant="outline" asChild>
                <Link to={{ pathname: `/studies/${study._id}/recruit/messages` }}>
                  <MessageCircle className="h-4 w-4" />
                  {t('sections.studies.recruit.sections.messages')}
                </Link>
              </AnchorButton>
              <InfoButton />
            </div>
          ) : null}
        </header>
      ) : null}

      <Outlet />
    </>
  );
};
