import moment from 'moment';

import { stepperMenuUtils } from 'components/createBooking/stepperMenu/stepperMenuUtils';
import { BOOKING_TYPE } from 'lib/constants';

import type { Booking, BookingConfig, Maybe, OnlineTaskBookingConfig } from 'generated/graphql';

export function isBookingForProfessionals(booking?: Pick<Booking, 'config'> | null): boolean {
  return booking?.config?.participant_category === 2;
}

export const enum BookingTypes {
  IN_PERSON = 1,
  REMOTE = 2,
  ONLINE = 3,
  LONGITUDINAL_STUDY = 4,
  UNMODERATED = 5,
}

export function isModeratedBooking(type?: BookingTypes | null): boolean {
  return type === BookingTypes.IN_PERSON || type === BookingTypes.REMOTE;
}

export function isAutomatedBooking(config?: Pick<BookingConfig, 'options'> | null): boolean {
  return config?.options?.review_submission === false;
}

export function isBookingFull({
  bookingTotalParticipants,
  currentBookingTotalApplicants,
}: {
  bookingTotalParticipants: number;
  currentBookingTotalApplicants: number;
}) {
  return currentBookingTotalApplicants >= bookingTotalParticipants;
}

export const useIsNewPssBooking = (bookingCreatedAt?: Maybe<number>) => {
  return isNewPssBooking(bookingCreatedAt);
};

export const isNewPssBooking = (bookingCreatedAt?: Maybe<number>): boolean => {
  const bookingIsCreatedAfter4thMay =
    bookingCreatedAt && bookingCreatedAt > new Date('2023-05-03T14:00:00.000Z').valueOf();

  return !!bookingIsCreatedAfter4thMay;
};

export enum RESOLVED_BOOKING_TYPES {
  IN_PERSON = 'IN_PERSON',
  VIDEO_CALL = 'VIDEO_CALL',
  ONLINE_TASK = 'ONLINE_TASK',
  SURVEY = 'SURVEY',
  LONGITUDINAL_STUDY = 'LONGITUDINAL_STUDY',
  AI_MODERATED = 'AI_MODERATED',
  UNMODERATED = 'UNMODERATED',
}

export const getResolvedBookingType = (
  bookingType: (typeof BOOKING_TYPE)[keyof typeof BOOKING_TYPE] | number,
  onlineTaskType: OnlineTaskBookingConfig['type'],
): RESOLVED_BOOKING_TYPES => {
  switch (bookingType) {
    case BOOKING_TYPE.ONLINE: {
      if (onlineTaskType === 2) {
        return RESOLVED_BOOKING_TYPES.SURVEY;
      }
      if (onlineTaskType === 3) {
        return RESOLVED_BOOKING_TYPES.AI_MODERATED;
      }
      return RESOLVED_BOOKING_TYPES.ONLINE_TASK;
    }
    case BOOKING_TYPE.REMOTE:
      return RESOLVED_BOOKING_TYPES.VIDEO_CALL;
    case BOOKING_TYPE.IN_PERSON:
      return RESOLVED_BOOKING_TYPES.IN_PERSON;
    case BOOKING_TYPE.UNMODERATED:
      return RESOLVED_BOOKING_TYPES.UNMODERATED;
    case BOOKING_TYPE.LONGITUDINAL_STUDY:
    default:
      return RESOLVED_BOOKING_TYPES.LONGITUDINAL_STUDY;
  }
};

export const findNextRoute = (booking: any, currentRouteLabel: string, currentStep: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  let routes = stepperMenuUtils.projectSubSteps(booking);
  if (currentStep === 'project-setup') {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    routes = stepperMenuUtils.projectSubSteps(booking);
  } else if (currentStep === 'AI_MODERATED') {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    routes = stepperMenuUtils.aiModeratedSubSteps(booking);
    console.log('the rouytes', routes);
  }
  const currentRouteIndex = routes.findIndex(route => route.label === currentRouteLabel);
  return routes[currentRouteIndex + 1].link;
};

export const getDemographicAgeDefaultValue = () => {
  const minAge = moment().subtract(18, 'y').valueOf().toString();
  const maxAge = moment().subtract(199, 'y').valueOf().toString();
  return {
    minAge: {
      field: 'user.meta.identity.birthday.timestamp',
      value: minAge,
      operator: 'lt',
    },
    maxAge: {
      field: 'user.meta.identity.birthday.timestamp',
      value: maxAge,
      operator: 'gt',
    },
  };
};
