/**
 * Handles all the ui state for persistance
 */

import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type BookingParticipantFilterUIState = {
  segmentsOpen: boolean;
};

type BookingFilterUIActions = {
  toggleSegmentsPanel: () => void;
};

type State = BookingParticipantFilterUIState & BookingFilterUIActions;

export const useViewerUI = create<State>()(
  persist(
    devtools(
      immer(set => {
        return {
          segmentsOpen: true,
          toggleSegmentsPanel: () => {
            set(
              state => {
                state.segmentsOpen = !state.segmentsOpen;
              },
              false,
              'viewer.ui/segments-panel',
            );
          },
        };
      }),
      { name: 'ViewerUI' },
    ),
    { name: 'viewer.ui/segments-panel', storage: createJSONStorage(() => sessionStorage) },
  ),
);

/**
 * For performance reasons we should define selectors outside functional components
 */
export function segmentsIsOpenState(s: State): boolean {
  return s.segmentsOpen;
}
