import { formatSessionTime } from 'shared-utils';
import { GlobalIcon, Tooltip } from 'ui';

import type { IconProps } from 'ui';

type Props = {
  pptTimezone: string;
  displayName: string;
  sessionStart: number;
  sessionEnd: number;
} & IconProps;

export const BookingParticipantPptTimezoneIcon = ({
  displayName,
  pptTimezone,
  sessionEnd,
  sessionStart,
  ...iconProps
}: Props) => {
  const sessionTime = formatSessionTime({
    start: sessionStart,
    end: sessionEnd,
    timezone: pptTimezone,
  });

  return (
    <Tooltip label={`${displayName}'s timezone: ${sessionTime}`}>
      <GlobalIcon h="4" w="4" {...iconProps} />
    </Tooltip>
  );
};
