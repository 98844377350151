import { gql } from '@apollo/client';

export default gql`
  query FetchParticipantSessionById($_id: ID!) {
    findParticipantSessionById(_id: $_id) {
      _id
      _session_id
      cancel
      status
      no_show_request
      issue_request
      history {
        session_invitation_notification
        session_invitation_accepted
        early_confirmation_request
        session_start_reminder_no_action
        session_start_reminder_action_needed
        session_start_reminder_2_askable_incentive_message
        session_completed_feedback_request
        session_completed_feedback_submitted
        session_completed_client_feedback_request
        session_invitation_notification_followup
        session_quant_invitation_notification
        session_paid
      }
    }
  }
`;
