import { useRef } from 'react';

import { useConnectedClient } from 'context/ConnectedClientContext';

import { MessageAdd } from '../../../BookingMessagesContainer/components/MessageAdd';
import { MessagesList } from '../../../BookingMessagesContainer/components/MessagesList';

/**
 * Slide out panel containing the conversation with the selected user and a message reply field
 * @todo replace with BookingMessageContainer/components/PanelMessages when refactoring Participants page
 */

interface BookingParticipantMessagesPanelProps {
  bookingId: string;
  user: {
    _id: string;
    displayName: string;
  };
}

export const BookingParticipantMessagesPanel = ({ bookingId, user }: BookingParticipantMessagesPanelProps) => {
  const { details } = useConnectedClient();
  const panelRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom of list when adding a new note
  const onUpdated = () => {
    if (panelRef && panelRef.current) {
      panelRef.current?.scrollTo({ top: panelRef.current.scrollHeight });
    }
  };

  return (
    <div
      className="min-h-full w-full overflow-auto scroll-smooth text-foreground"
      style={{ height: 'inherit', '--messagePanelOffset': '116px' } as React.CSSProperties}
      ref={panelRef}
    >
      <MessagesList bookingId={bookingId} userId={user._id} fromUserId={details?.id!} onUpdated={onUpdated} />
      <div className="sticky bottom-0 bg-background px-4 py-3">
        <MessageAdd active bookingId={bookingId} userId={user._id} fromUserId={details?.id!} onUpdated={onUpdated} />
      </div>
    </div>
  );
};
