import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import intervalToDuration from 'date-fns/intervalToDuration';
import { useCallback, useEffect, useState } from 'react';
import {
  AlertErrorIcon,
  CancelIcon,
  CheckIcon,
  ClockFilledIcon,
  DraftIcon,
  HelpIcon,
  InProgressHalfIcon,
  InProgressIcon,
  InProgressNoneIcon,
  ReadyIcon,
} from 'ui';

import { SubmissionStatus } from 'generated/graphql';

import type { ReactNode } from 'react';

type Props = {
  paymentDue?: number | null;
  submissionStatus: SubmissionStatus;
};

const defaultProps = {
  alignSelf: 'flex-start',
  fontSize: 'md',
};
const statusData: { [key in SubmissionStatus]: { Icon: ReactNode; title: string } | null } = {
  [SubmissionStatus.Available]: {
    Icon: <DraftIcon {...defaultProps} />,
    title: 'Available',
  },
  [SubmissionStatus.Invited]: {
    Icon: <InProgressNoneIcon {...defaultProps} />,
    title: 'Invited',
  },
  [SubmissionStatus.InviteDeclined]: {
    Icon: <CancelIcon {...defaultProps} />,
    title: 'Declined',
  },
  [SubmissionStatus.Confirmed]: {
    Icon: <ReadyIcon {...defaultProps} />,
    title: 'Confirmed',
  },
  [SubmissionStatus.Completed]: {
    Icon: <CheckIcon {...defaultProps} />,
    title: 'Complete',
  },
  [SubmissionStatus.Waitlisted]: {
    Icon: <InProgressHalfIcon {...defaultProps} />,
    title: 'Waitlisted',
  },
  [SubmissionStatus.ParticipantCancelled]: {
    Icon: <CancelIcon {...defaultProps} />,
    title: 'Cancelled',
  },
  [SubmissionStatus.PendingTechCheck]: {
    Icon: <InProgressNoneIcon {...defaultProps} />,
    title: 'Pending tech check',
  },
  [SubmissionStatus.Reported]: {
    Icon: <AlertErrorIcon {...defaultProps} color="red.500" />,
    title: 'Reported',
  },
  [SubmissionStatus.InProgress]: {
    Icon: <InProgressIcon {...defaultProps} />,
    title: 'In progress',
  },
  [SubmissionStatus.HelpRequested]: {
    Icon: <HelpIcon {...defaultProps} color="yellow.500" />,
    title: 'Help requested',
  },
  [SubmissionStatus.PendingCheck]: {
    Icon: <ClockFilledIcon {...defaultProps} />,
    title: 'Complete',
  },
  [SubmissionStatus.TimeExpired]: {
    Icon: <CancelIcon {...defaultProps} />,
    title: 'Time expired',
  },
  [SubmissionStatus.Excluded]: null,
};

const getCounterTime = (targetDate: Date) => {
  const { minutes, hours } = intervalToDuration({
    start: new Date(),
    end: targetDate,
  });

  if ((hours ?? 0) >= 6) {
    // 2 hours
    return 7.2e6;
  }

  if ((hours ?? 0) >= 1) {
    // 30 minutes
    return 1.8e6;
  }

  if ((minutes ?? 0) >= 30) {
    // 15 minutes
    return 9e5;
  }

  // 1 minute
  return 6e4;
};

export const BookingParticipantStatus = ({ submissionStatus, paymentDue }: Props) => {
  const data = statusData[submissionStatus];

  const paymentDate = paymentDue ?? 0;
  const isPast = paymentDate < Date.now();
  const [duration, setDuration] = useState<string | null>(null);

  const updateDuration = useCallback(() => {
    if (submissionStatus !== SubmissionStatus.PendingCheck || !paymentDate || isPast) {
      if (duration) {
        setDuration(null);
      }
      return;
    }

    let timeout = 0;
    const updateDurationText = () => {
      if (isPast) {
        setDuration(null);
        return;
      }

      timeout = getCounterTime(new Date(paymentDate));
      const d = formatDistanceToNowStrict(paymentDate);

      setDuration(d);

      setTimeout(updateDurationText, timeout);
    };

    setTimeout(updateDurationText, timeout);
  }, [submissionStatus, paymentDate, isPast, duration]);

  useEffect(() => {
    updateDuration();
  }, [updateDuration]);

  if (!data) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center gap-1 font-semibold">
        {data.Icon}
        {data.title}
      </div>
      {duration ? (
        <div className="whitespace-nowrap pl-6 text-xs text-muted-foreground">{duration} to check</div>
      ) : null}
    </div>
  );
};
