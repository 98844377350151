/* eslint-disable max-lines */
import _ from 'lodash';
import momentTimezone from 'moment-timezone';

import { bookingUtils } from 'lib/booking';
import { bookingParticipantUtils } from 'lib/bookingParticipant';
import { bookingSubmissionUtils } from 'lib/bookingSubmission';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE } from 'lib/constants';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';

const cherryPickingUtils = {
  getSessionsLabel(booking: any, sessions: any) {
    const timezone = _.get(booking, 'config.timezone');
    const orderedSessions = utils.orderArrayByDate(sessions, 'start');
    return orderedSessions.map((item: any) => {
      return {
        value: item._id,
        label: `${momentTimezone(item.start).tz(timezone).format('ddd Do MMM h:mma')}`,
      };
    });
  },
  inviteApplicantsColumns(booking: any, industries: any) {
    const dataHeader = [];

    // Checkbox
    if (!bookingUtils.isBookingAutomated(booking)) {
      dataHeader.push({
        id: 'checkbox',
        header: 'checkbox',
        fixed: true,
        alwaysVisible: true,
      });
    }

    // Status
    dataHeader.push({
      id: 'status',
      header: 'Status',
      fixed: true,
      alwaysVisible: true,
      filter: {
        databaseField: 'sessions.status',
        type: 'list',
        options: _.map(bookingParticipantUtils.getStatus(booking.type), (item: any) => {
          return { label: item.label, value: item.label };
        }),
      },
    });

    // Participant Name
    dataHeader.push({
      id: 'name',
      header: 'Participant Name',
      fixed: true,
      alwaysVisible: true,
      filter: {
        databaseField: 'user.meta.identity.firstname',
        type: 'string',
      },
    });

    // Allocated Session
    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      dataHeader.push({
        id: 'session',
        header: 'Allocated Session',
        fixed: true,
        alwaysVisible: true,
      });

      // Eligibility
      dataHeader.push({
        id: 'eligibility',
        header: 'Eligible',
        filter: {
          databaseField: 'eligibility',
          type: 'number',
          options: _.range(10, 101, 10),
          percentage: true,
        },
      });

      if (bookingUtils.isRemote(booking)) {
        // Remote testing tech check?
        dataHeader.push({
          id: 'setup_check',
          header: 'Remote testing tech check?',
          helpType: 'help_remote_setup_check',
          filter: {
            databaseField: 'user.settings.remote.setup_check',
            type: 'list',
            options: bookingUtils.remoteSetupCheck(),
          },
        });
      }

      // Application time
      dataHeader.push({
        id: 'created',
        header: 'Applied',
        filter: {
          databaseField: 'created',
          type: 'number',
          disabled: true,
        },
      });
    } else if (!bookingUtils.isBookingAutomated(booking)) {
      // It should check whether the booking is automated or cherry picking
      dataHeader.push({
        id: 'actions',
        header: 'Actions',
        fixed: true,
        alwaysVisible: true,
      });

      // Eligibility
      dataHeader.push({
        id: 'eligibility',
        header: 'Eligible',
        filter: {
          databaseField: 'eligibility',
          type: 'number',
          options: _.range(10, 101, 10),
          percentage: true,
        },
      });
    } else {
      // It should check whether the booking is automated or cherry picking
      dataHeader.push({
        id: 'actions',
        header: 'Actions',
        fixed: true,
        alwaysVisible: true,
      });
    }

    if (
      _.get(booking, 'config.participant_agreement.type') === BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT
    ) {
      // Custom agreement
      dataHeader.push({
        id: 'agreement_status',
        header: 'Agreement',
        filter: {
          databaseField: 'agreement.status',
          type: 'list',
          options: bookingUtils.agreementStatus(),
        },
      });
    }

    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      // Application time
      dataHeader.push({
        id: 'created',
        header: 'Applied',
        filter: {
          databaseField: 'created',
          type: 'string',
          disabled: true,
        },
      });
    }

    // Age
    dataHeader.push({
      id: 'age',
      header: 'Age',
      filter: {
        databaseField: 'user.meta.identity.birthday.timestamp',
        type: 'number',
        options: bookingUtils.getAgeRange(booking),
      },
    });

    // Gender
    dataHeader.push({
      id: 'gender',
      header: 'Gender',
      filter: {
        databaseField: 'user.meta.identity.gender',
        type: 'list',
        options: bookingUtils.filterGenderTypes(),
      },
    });

    // Voice Sample
    dataHeader.push({
      id: 'feedback_sample',
      header: 'Voice Sample',
    });

    // Marital Status
    if (_.get(booking, 'config.criteria.meta_family_status') !== null) {
      dataHeader.push({
        id: 'marital_status',
        header: 'Marital Status',
        filter: {
          databaseField: 'user.meta.family.status',
          type: 'list',
          options: _.map(userUtils.maritalStatus(), (item: any) => {
            return { label: item.label, value: item.id };
          }),
        },
      });
    }

    // Education Level
    if (_.get(booking, 'config.criteria.meta_education') !== null) {
      dataHeader.push({
        id: 'education_level',
        header: 'Education Level',
        filter: {
          databaseField: 'user.meta.education',
          type: 'list',
          options: _.map(userUtils.educationLevels(), (item: any) => {
            return { label: item.label, value: item.id };
          }),
        },
      });
    }

    // Employment Status
    if (_.get(booking, 'config.criteria.meta_work_status') !== null) {
      dataHeader.push({
        id: 'employment',
        header: 'Employment',
        filter: {
          databaseField: 'user.meta.work.status',
          type: 'list',
          options: _.map(userUtils.employmentStatus(), (item: any) => {
            return { label: item.label, value: item.id };
          }),
        },
      });
    }

    // Professionals Fields
    if (bookingUtils.isBookingForProfessionals(booking)) {
      // Employment Type
      dataHeader.push({
        id: 'executive_level',
        header: 'Executive Level',
        filter: {
          databaseField: 'user.meta.work.employment_type',
          type: 'list',
          options: _.map(userUtils.workEmploymentType(), (item: any) => {
            return { label: item.label, value: item.value };
          }),
        },
      });

      // Industry / Sub-Industry
      dataHeader.push({
        id: 'industry_details',
        header: 'Industry',
        filter: {
          databaseField: 'user.meta.work._industry_id',
          type: 'list',
          options: _.map(industries, (item: any) => {
            return { label: item.name, value: item._id };
          }),
        },
      });

      // Job Title
      dataHeader.push({
        id: 'job_title',
        header: 'Job Title',
        filter: {
          databaseField: 'user.meta.work.job_title',
          type: 'string',
        },
      });
    }

    // English level
    if (_.get(booking, 'config.criteria.meta_identity_languages_english_speak') !== null) {
      dataHeader.push({
        id: 'english_level',
        header: 'English Level',
        filter: {
          databaseField: 'user.meta.identity.languages.english.speak',
          type: 'list',
          options: _.map(bookingUtils.englishProficiencyTypes(), (item: any) => {
            return { label: item.label, value: item.label.toLowerCase() };
          }),
        },
      });
    }

    dataHeader.push({
      id: 'location',
      header: bookingUtils.isInContextResearch(booking)
        ? utils.getLabelFromArray(
            bookingUtils.bookingInContextResearchLocationTypes(),
            _.get(booking, 'config.in_context.location_type'),
            'header_description',
          )
        : 'Location',
      filter: {
        databaseField: 'user.location.city',
        type: 'string',
      },
    });

    // Display the travel time
    if (bookingUtils.isInContextResearch(booking)) {
      dataHeader.push({
        id: 'travel_time',
        header: 'Travel Time',
        filter: {
          databaseField: 'in_context.travel_time',
          type: 'number',
        },
      });
    }

    // Get dynamic questions
    if (booking.config.question) {
      booking.config.question.forEach((question: any) => {
        dataHeader.push({
          id: question._id,
          header: question.title,
          className: 'column-dynamicColumn',
        });
      });
    }

    if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
      // Available Times
      dataHeader.push({
        id: 'available_times',
        header: 'What are your preferred times?',
        filter: {
          databaseField: 'sessions._session_id',
          type: 'list',
          options: cherryPickingUtils.getSessionsLabel(booking, booking.session),
        },
      });
    } else if (bookingUtils.isOnlineTask(booking)) {
      // Duration Times
      dataHeader.push({
        id: 'duration',
        header: 'Duration',
        // filter: {
        //     type: 'number',
        //     options: utils.getRangeNumbers(0, _.get(booking, 'config.session.time_limit', 120)),
        // }
      });

      if (bookingUtils.onlineTaskHasMultipleVariations(booking)) {
        dataHeader.push({
          id: 'link_variation',
          header: 'Study link variation',
        });
      }
    }

    return dataHeader;
  },
  getAllocatedSession(booking: any, submission: any, status: any) {
    if (status === 'Available' || status.toLowerCase().includes('cancelled') || status === 'Invite declined') return '';
    const timezone = _.get(booking, 'config.timezone');

    const sessionToRender = cherryPickingUtils.getSessionToRender(submission, submission.sessions);
    const startSession = sessionToRender.session.start;
    const endSession = sessionToRender.session.end;
    return {
      session: sessionToRender.session,
      label: `${momentTimezone(startSession).tz(timezone).format('h:mm')}-${momentTimezone(endSession).tz(timezone).format('h:mma')} ${momentTimezone(
        startSession,
      )
        .tz(timezone)
        .format('ddd Do MMM')}`,
      startSession: momentTimezone(startSession).tz(timezone).valueOf(),
    };
  },
  getSessionToRender(submission: any, participantSession: any) {
    let sessionToRender;
    const confirmedSession = participantSession.find((session: any) => session.status === 1 && session.cancel === 0);
    const waitlistedSession = participantSession.find((session: any) => session.status === 2 && session.cancel === 0);
    const helpRequestedSession = participantSession.find(
      (session: any) =>
        (session.status === 4 || session.status === 5) && session.cancel === 0 && submission.help_requested,
    );
    const invitedSession = participantSession.find(
      (session: any) => session.status === 4 && session.cancel === 0 && !submission.help_requested,
    );
    const inProgressSession = participantSession.find(
      (session: any) => session.status === 5 && session.cancel === 0 && !submission.help_requested,
    );

    if (helpRequestedSession) sessionToRender = { ...helpRequestedSession, help_requested: submission.help_requested };
    if (invitedSession) sessionToRender = { ...invitedSession, help_requested: submission.help_requested };
    if (inProgressSession) sessionToRender = { ...inProgressSession, help_requested: submission.help_requested };
    if (waitlistedSession) sessionToRender = { ...waitlistedSession, help_requested: submission.help_requested };
    if (confirmedSession) sessionToRender = { ...confirmedSession, help_requested: submission.help_requested };
    return sessionToRender;
  },
  getParticipantStatus(booking: any, submission: any, bookingType: any) {
    // Function to define what's the user status
    const { sessions } = submission;
    const timezone = _.get(booking, 'config.timezone');

    // Check whether is a quant booking or not
    if (bookingType !== 3 && bookingType !== 4) {
      if (sessions.length === 0) return 'Available';

      // Search for a confirmed session that hasn't been started yet
      if (
        sessions.find(
          (item: any) =>
            item.status === 1 && item.cancel === 0 && momentTimezone(item.session.start).tz(timezone).isAfter(),
        )
      ) {
        return 'Confirmed';
      }

      // Search for a confirmed session that hasn't been finished yet
      if (
        sessions.find(
          (item: any) =>
            item.status === 1 &&
            item.cancel === 0 &&
            momentTimezone(item.session.start).tz(timezone).isBefore() &&
            momentTimezone(item.session.end).tz(timezone).isAfter(),
        )
      ) {
        return 'Happening now';
      }

      // Search for a confirmed session that has been completed already
      if (
        sessions.find(
          (item: any) =>
            item.status === 1 && item.cancel === 0 && momentTimezone(item.session.end).tz(timezone).isBefore(),
        )
      ) {
        return 'Completed';
      }

      // Search for a waitlisted session
      if (sessions.find((item: any) => item.status === 2 && item.cancel === 0)) return 'Waitlisted';

      // Search for an invited session from Askable Live and participant didnt execute the tech check
      if (
        bookingUtils.isRemote(booking) &&
        bookingUtils.isAskableLive(booking) &&
        sessions.find((item: any) => item.status === 4 && item.cancel === 0) &&
        !_.get(submission.user, 'settings.remote.setup_check')
      ) {
        return 'Pending tech check';
      }

      // Search for an invited session
      if (sessions.find((item: any) => item.status === 4 && item.cancel === 0)) return 'Invited';
      // Search for an invited session that has been declined
      if (sessions.find((item: any) => item.status === 4 && item.cancel === 3)) return 'Invite declined';
      // Search for an available session
      if (sessions.find((item: any) => item.status === 3 && item.cancel === 0)) return 'Available';

      // Search for cancelled by admin sessions
      if (sessions.find((item: any) => item.cancel === 1)) return 'Cancelled by admin';

      // Search for cancelled by client sessions
      if (sessions.find((item: any) => item.cancel === 2)) return 'Cancelled by client';

      // Search for cancelled by participant sessions
      if (sessions.find((item: any) => item.cancel === 3)) return 'Cancelled by participant';

      // Search for cancelled by no show sessions
      if (sessions.find((item: any) => item.cancel === 4)) return 'Cancelled by no show';

      // Search for auto cancelled sessions
      if (sessions.find((item: any) => item.cancel === 5)) return 'Auto cancelled';

      // Search for cancelled by reschedule sessions
      if (sessions.find((item: any) => item.cancel === 6)) return 'Cancelled by reschedule';

      // Search for cancelled by issues sessions
      if (sessions.find((item: any) => item.cancel === 7)) return 'Cancelled by issues';
    } else {
      // Search for participants that have completed their task but haven't got paid yet
      if (
        sessions.find(
          (item: any) =>
            item.status === 1 &&
            item.cancel === 0 &&
            !_.get(item, 'history.session_paid') &&
            !_.get(submission, 'transaction.suspended'),
        )
      ) {
        return 'Completed - Check submission';
      }

      // Search for participants that have completed their task and have got paid already
      if (sessions.find((item: any) => item.status === 1 && item.cancel === 0 && _.get(item, 'history.session_paid'))) {
        return 'Completed - Paid';
      }

      // Search for participants that have completed their task but have their payment suspended
      if (
        sessions.find(
          (item: any) => item.status === 1 && item.cancel === 0 && _.get(submission, 'transaction.suspended'),
        )
      ) {
        return 'Completed - Paused';
      }

      // Search for a waitlisted sessions
      if (sessions.find((item: any) => item.status === 2 && item.cancel === 0)) return 'Waitlisted';

      // Search for an available sessions
      if (sessions.find((item: any) => item.status === 3 && item.cancel === 0)) return 'Available';

      // Search for help requested
      if (
        sessions.find(
          (item: any) => (item.status === 4 || item.status === 5) && item.cancel === 0 && submission.help_requested,
        )
      ) {
        return 'Help requested';
      }

      // Search for invited sessions
      if (sessions.find((item: any) => item.status === 4 && item.cancel === 0)) return 'Invited';

      // Search for in progress sessions
      if (sessions.find((item: any) => item.status === 5 && item.cancel === 0)) return 'In progress';

      // Search for cancelled by admin sessions

      if (sessions.find((item: any) => item.cancel === 1)) return 'Cancelled by admin';

      // Search for cancelled by client sessions

      if (sessions.find((item: any) => item.cancel === 2)) return 'Cancelled by client';

      // Search for cancelled by participant sessions

      if (sessions.find((item: any) => item.cancel === 3)) return 'Cancelled by participant';

      // Search for cancelled by no show sessions

      if (sessions.find((item: any) => item.cancel === 4)) return 'Cancelled by no show';

      // Search for auto cancelled sessions

      if (sessions.find((item: any) => item.cancel === 5)) return 'Auto cancelled';

      // Search for cancelled by reschedule sessions

      if (sessions.find((item: any) => item.cancel === 6)) return 'Cancelled by reschedule';

      // Search for cancelled by issues sessions

      if (sessions.find((item: any) => item.cancel === 7)) return 'Cancelled by issues';

      // Search for cancelled sessions
      if (sessions.find((item: any) => item.cancel > 0)) return 'Cancelled';
    }

    return '';
  },
  getCurrentStatus(booking: any, submission: any, bookingType: any) {
    // Function to define what's the user status
    const { sessions } = submission;
    const timezone = _.get(booking, 'config.timezone');

    // Check whether is a quant booking or not
    if (bookingType === 1 || bookingType === 2) {
      if (sessions.length === 0) return null;

      // Search for a confirmed session that hasn't been started yet
      const confirmedSession = sessions.find(
        (item: any) =>
          item.status === 1 && item.cancel === 0 && momentTimezone(item.session.start).tz(timezone).isAfter(),
      );
      if (confirmedSession) return confirmedSession;

      // Search for a confirmed session that hasn't been finished yet
      const happeningNowSession = sessions.find(
        (item: any) =>
          item.status === 1 &&
          item.cancel === 0 &&
          momentTimezone(item.session.start).tz(timezone).isBefore() &&
          momentTimezone(item.session.end).tz(timezone).isAfter(),
      );
      if (happeningNowSession) return happeningNowSession;

      // Search for a confirmed session that has been completed already
      const completedSession = sessions.find(
        (item: any) =>
          item.status === 1 && item.cancel === 0 && momentTimezone(item.session.end).tz(timezone).isBefore(),
      );
      if (completedSession) return completedSession;

      // Search for a waitlisted session
      const waitlistedSession = sessions.find((item: any) => item.status === 2 && item.cancel === 0);
      if (waitlistedSession) return waitlistedSession;

      // Search for an invited session
      const invitedSession = sessions.find((item: any) => item.status === 4 && item.cancel === 0);
      if (invitedSession) return invitedSession;

      // Other statuses
      return null;
    }

    // Search for participants that have completed their task but haven't got paid yet
    const checkSubmissionSession = sessions.find(
      (item: any) =>
        item.status === 1 &&
        item.cancel === 0 &&
        !_.get(item, 'history.session_paid') &&
        !_.get(submission, 'transaction.suspended'),
    );
    if (checkSubmissionSession) return checkSubmissionSession;

    // Search for participants that have completed their task and have got paid already
    const paidSession = sessions.find(
      (item: any) => item.status === 1 && item.cancel === 0 && _.get(item, 'history.session_paid'),
    );
    if (paidSession) return paidSession;

    // Search for participants that have completed their task but have their payment suspended
    const pausedSession = sessions.find(
      (item: any) => item.status === 1 && item.cancel === 0 && _.get(submission, 'transaction.suspended'),
    );
    if (pausedSession) return pausedSession;

    // Search for a waitlisted session
    const waitlistedSession = sessions.find((item: any) => item.status === 2 && item.cancel === 0);
    if (waitlistedSession) return waitlistedSession;

    // Search for an invited session
    const invitedSession = sessions.find((item: any) => item.status === 4 && item.cancel === 0);
    if (invitedSession) return invitedSession;

    // Search for in progress sessions
    const inProgressSession = sessions.find((item: any) => item.status === 5 && item.cancel === 0);
    if (inProgressSession) return inProgressSession;

    // Other statuses
    return null;
  },
  getSubmissionData(booking: any, submission: any, field: any, question: any, bookingType: any, industries: any) {
    let dataToReturn = '';

    switch (field) {
      case 'status':
        dataToReturn = cherryPickingUtils.getParticipantStatus(booking, submission, bookingType);
        break;
      case 'name':
        if (!_.has(submission.user, 'meta.identity.firstname')) return '(Deleted user)';
        dataToReturn = `${_.get(submission.user, 'meta.identity.firstname')} ${_.get(submission.user, 'meta.identity.lastname')}`;
        break;
      case 'session': {
        const participantStatus = cherryPickingUtils.getParticipantStatus(booking, submission, bookingType);
        // @ts-expect-error ts-migrate(2322) FIXME: Type '"" | { session: any; label: string; startSes... Remove this comment to see the full error message
        dataToReturn = cherryPickingUtils.getAllocatedSession(booking, submission, participantStatus);
        break;
      }
      case 'eligibility':
        dataToReturn = _.get(submission, 'eligibility');
        break;
      case 'setup_check':
        dataToReturn = _.get(submission.user, 'settings.remote.setup_check');
        break;
      case 'agreement_status':
        dataToReturn = _.get(submission, 'agreement.status');
        break;
      case 'created':
        dataToReturn = _.get(submission, 'created');
        break;
      case 'age':
        dataToReturn = userUtils.getAge(submission.user);
        break;
      case 'feedback_sample':
        dataToReturn = _.get(submission.user, 'meta.feedback_sample.recording_url');
        break;
      case 'gender':
        dataToReturn = utils.capitalize(_.get(submission.user, 'meta.identity.gender'));
        break;
      case 'marital_status':
        dataToReturn = userUtils.getMaritalStatus(submission.user)!;
        break;
      case 'education_level':
        dataToReturn = userUtils.getEducationLevels(submission.user)!;
        break;
      case 'executive_level':
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        dataToReturn = _.get(
          _.find(
            userUtils.workEmploymentType(),
            (employmentType: any) =>
              parseInt(employmentType.value, 10) === _.get(submission, 'user.meta.work.employment_type'),
          ),
          'label',
        );
        break;
      case 'industry_details': {
        const userIndustry = _.find(
          industries,
          (industry: any) => industry._id === _.get(submission, 'user.meta.work._industry_id'),
        );
        let subindustry = '';
        dataToReturn = _.get(userIndustry, 'name');
        if (_.get(submission, 'user.meta.work._subindustry_id') && _.has(userIndustry, 'subcategories')) {
          subindustry = _.find(
            userIndustry.subcategories,
            (sub: any) => sub._id === _.get(submission, 'user.meta.work._subindustry_id'),
          );
          dataToReturn += ` / ${_.get(subindustry, 'name')}`;
        }
        break;
      }
      case 'job_title':
        dataToReturn = _.get(submission, 'user.meta.work.job_title');
        break;
      case 'employment':
        dataToReturn = userUtils.getEmploymentStatus(submission.user)!;
        break;
      case 'english_level':
        dataToReturn = userUtils.getEnglishLevel(submission.user);
        break;
      case 'location':
        // It should check whether or not the booking is considered to be In-Context
        //  In Context -> Gets the location the user has put it in
        //  Normal     -> Gets the user's city and postal code
        if (_.get(submission, 'in_context.location.latitude')) {
          const query = [
            _.get(submission, 'in_context.location.name', ''),
            _.get(submission, 'in_context.location.level', ''),
            _.get(submission, 'in_context.location.street1', ''),
            _.get(submission, 'in_context.location.street2', ''),
            _.get(submission, 'in_context.location.city', ''),
            _.get(submission, 'in_context.location.state', ''),
          ];
          dataToReturn = _.filter(query, (part: any) => part && part.length > 0).join(' ');
        } else {
          if (!_.get(submission.user, 'location.city')) return '';
          dataToReturn = `${_.get(submission.user, 'location.city')} ${_.get(submission.user, 'location.postal_code') || ''}`;
        }
        break;
      case 'available_times': {
        const bookingTimezone = _.get(booking, 'config.timezone') || utils.getCurrentTimezone();
        const orderedSessions = utils
          .orderArrayByField(submission.sessions, 'session', 'asc', 'start')
          .map((participant: any) => cherryPickingUtils.getAvailableTimes(participant));
        const validSessions = _.map(orderedSessions, (item: any) => {
          if (momentTimezone(item.session.start).tz(bookingTimezone).diff(momentTimezone(), 'minutes') > 0) return item;
        }).filter(Boolean);

        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'string'.
        dataToReturn = validSessions;
        break;
      }
      case 'duration':
        // Get the time in minutes
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | ""' is not assignable to type 'stri... Remove this comment to see the full error message
        dataToReturn = bookingParticipantUtils.getDurationTimeToCompleteQuantTask(_.get(submission, 'sessions[0]'));
        break;
      case 'link_variation':
        dataToReturn = bookingParticipantUtils.getLinkVariationDetails(
          booking,
          _.get(submission, 'sessions[0]._online_task_link_id'),
        );
        break;
      case 'travel_time':
        // Get the travel time in minutes in case the booking is considered to be In-Context
        dataToReturn = _.get(submission, 'in_context.travel_time');
        break;
      default:
        // Dynamic field finding
        dataToReturn = bookingSubmissionUtils.getAnswersForDynamicQuestions(submission.data, question);
        break;
    }

    return dataToReturn;
  },
  getAvailableTimes(participant: any) {
    return {
      _id: participant._id,
      session: participant.session,
      cancel: participant.cancel,
      status: participant.status,
    };
  },
  getFilteredSubmissionsData(bookingSubmissions: any) {
    // It should only restrict people that hasn't submitted their attendance on face-to-face and remote bookings
    return (
      _.chain(bookingSubmissions)
        .map((submission: any) => ({
          ...submission,
          sessions: submission.sessions.filter((item: any) => item.session),
        }))
        // .filter(submission => submission.user.blacklist === false && submission.sessions.length > 0)
        // .filter(submission => submission.user.blacklist === false)
        .value()
    );
  },
  getApplicantsData(booking: any, bookingSubmissions: any, industries: any) {
    // Add some default filters
    const filteredSubmissions = cherryPickingUtils.getFilteredSubmissionsData(bookingSubmissions);
    const submissionsDataArray: any = [];

    filteredSubmissions.map((submission: any) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 5.
      const participantStatus = cherryPickingUtils.getSubmissionData(booking, submission, 'status', null, booking.type);

      // Hide available blacklisted participants
      if (
        submission.user.blacklist === true &&
        (participantStatus === 'Available' || participantStatus.toLowerCase().includes('cancelled'))
      ) {
        return null;
      }

      if (_.find(submissionsDataArray, (item: any) => item._id === submission._id)) return null;

      let dynamicColumn = [];
      if (booking.config.question) {
        dynamicColumn = booking.config.question.map((question: any) => {
          return {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 4.
            [question._id]: cherryPickingUtils.getSubmissionData(booking, submission, question._id, question),
          };
        });
      }

      const mainArray = {
        _id: submission._id,
        checkbox: '',
        status: participantStatus,
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        name: cherryPickingUtils.getSubmissionData(booking, submission, 'name'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        session: cherryPickingUtils.getSubmissionData(booking, submission, 'session'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        eligibility: cherryPickingUtils.getSubmissionData(booking, submission, 'eligibility'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        age: cherryPickingUtils.getSubmissionData(booking, submission, 'age'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        gender: cherryPickingUtils.getSubmissionData(booking, submission, 'gender'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        setup_check: cherryPickingUtils.getSubmissionData(booking, submission, 'setup_check'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        agreement_status: cherryPickingUtils.getSubmissionData(booking, submission, 'agreement_status'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        created: cherryPickingUtils.getSubmissionData(booking, submission, 'created'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        feedback_sample: cherryPickingUtils.getSubmissionData(booking, submission, 'feedback_sample'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        location: cherryPickingUtils.getSubmissionData(booking, submission, 'location'),
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        travel_time: cherryPickingUtils.getSubmissionData(booking, submission, 'travel_time'),
        sessions: submission.sessions,
        user: submission.user,
        in_context_location: _.get(submission, 'in_context.location'),
        status_updated: _.get(submission, 'status_updated'),
        transaction: _.get(submission, 'transaction'),
        feedback: _.get(submission, 'feedback'),
        blacklist: _.get(submission, 'user.blacklist'),
        actions: '',
      };
      // Merge the dynamic questions to the main Array
      dynamicColumn.forEach((cell: any) => {
        Object.assign(mainArray, cell);
      });

      // Render demographic filters dynamically
      if (
        _.get(booking, 'config.criteria.meta_work_status') !== null ||
        bookingUtils.isBookingForProfessionals(booking)
      ) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { employment: cherryPickingUtils.getSubmissionData(booking, submission, 'employment') });
      }
      if (bookingUtils.isBookingForProfessionals(booking)) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { executive_level: cherryPickingUtils.getSubmissionData(booking, submission, 'executive_level') });
        Object.assign(mainArray, {
          industry_details: cherryPickingUtils.getSubmissionData(
            booking,
            submission,
            'industry_details',
            null,
            null,
            industries,
          ),
        });
        Object.assign(mainArray, {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
          job_title: cherryPickingUtils.getSubmissionData(booking, submission, 'job_title'),
        });
      }
      if (_.get(booking, 'config.criteria.meta_identity_languages_english_speak') !== null) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { english_level: cherryPickingUtils.getSubmissionData(booking, submission, 'english_level') });
      }
      if (_.get(booking, 'config.criteria.meta_family_status') !== null) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { marital_status: cherryPickingUtils.getSubmissionData(booking, submission, 'marital_status') });
      }
      if (_.get(booking, 'config.criteria.meta_education') !== null) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { education_level: cherryPickingUtils.getSubmissionData(booking, submission, 'education_level') });
      }

      if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { available_times: cherryPickingUtils.getSubmissionData(booking, submission, 'available_times') });
      } else if (bookingUtils.isOnlineTask(booking)) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { duration: cherryPickingUtils.getSubmissionData(booking, submission, 'duration') });
        // prettier-ignore
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 3.
        Object.assign(mainArray, { link_variation: cherryPickingUtils.getSubmissionData(booking, submission, 'link_variation') });
      }

      submissionsDataArray.push(mainArray);
      return null;
    });

    return submissionsDataArray;
  },
  sortTableByStatus(tableData: any, sortedBy: any) {
    // Sort by Status Rule
    //  1 - Confirmed 2 - Invited 3 - Waitlisted 4 - Available 5 - Cancelled
    const confirmedSessions = tableData.filter(
      (item: any) =>
        item.status === 'Confirmed' || item.status.includes('Completed') || item.status === 'Happening now',
    );
    const waitlistedSessions = tableData.filter((item: any) => item.status === 'Waitlisted');
    const helpRequestedSessions = tableData.filter((item: any) => item.status === 'Help requested');
    const invitedSessions = tableData.filter((item: any) => item.status === 'Invited' || item.status === 'In progress');
    const pendingTechCheckSessions = tableData.filter((item: any) => item.status === 'Pending tech check');
    const availableSessions = tableData.filter((item: any) => item.status === 'Available');
    const cancelledSessions = tableData.filter((item: any) => item.status.toLowerCase().includes('cancelled'));

    // Merge all arrays together and return them
    const mergedArray = confirmedSessions.concat(
      waitlistedSessions,
      helpRequestedSessions,
      invitedSessions,
      pendingTechCheckSessions,
      availableSessions,
      cancelledSessions,
    );
    if (_.get(sortedBy, 'order') === 'asc') mergedArray.reverse();
    return mergedArray;
  },
  sortTableByStartSession(data: any, sortBy: any, sortOrder: any) {
    const allocatedSessionsData = utils.orderArrayByField(
      data.filter((item: any) => item.session !== ''),
      sortBy,
      sortOrder,
      'startSession',
    );
    const nonAllocatedSessionsData = data.filter((item: any) => item.session === '');
    return allocatedSessionsData.concat(nonAllocatedSessionsData);
  },
  sortDataBasedOnDefaultCriteria(booking: any, dataArray: any, sorting: any) {
    // If it's the first render, it should order the data by the following order:
    //  1) Status
    //  2) Registered time
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      // const completedSessions = utils.orderArrayByField(dataArray.filter(item => item.status === 'Completed'), 'created', 'desc');
      const completedCheckSubmissionSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Completed - Check submission'),
        'created',
        'desc',
      );
      const completedPausedSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Completed - Paused'),
        'created',
        'desc',
      );
      const completedPaidSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Completed - Paid'),
        'created',
        'desc',
      );
      const helpRequestedSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Help requested'),
        'created',
        'desc',
      );
      const inProgressSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'In progress'),
        'created',
        'desc',
      );
      const invitedSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Invited'),
        'eligibility',
        'desc',
      );
      const waitlistedSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Waitlisted'),
        'eligibility',
        'desc',
      );
      const availableSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Available'),
        'eligibility',
        'desc',
      );
      const cancelledSessions = utils.orderArrayByField(
        dataArray.filter((item: any) => item.status.toLowerCase().includes('cancelled')),
        'eligibility',
        'desc',
      );
      const restOfSessions = utils.orderArrayByField(
        dataArray.filter(({ status }: any) => {
          return (
            status !== 'Help requested' &&
            status !== 'In progress' &&
            status !== 'Completed - Check submission' &&
            status !== 'Completed - Paused' &&
            status !== 'Completed - Paid' &&
            status !== 'Invited' &&
            status !== 'Waitlisted' &&
            status !== 'Available' &&
            !status.toLowerCase().includes('cancelled')
          );
        }),
        'created',
        'desc',
      );

      if (_.get(sorting, '[0].sortingMethod') === -1) {
        return restOfSessions.concat(
          cancelledSessions,
          availableSessions,
          waitlistedSessions,
          invitedSessions,
          inProgressSessions,
          helpRequestedSessions,
          completedPaidSessions,
          completedPausedSessions,
          completedCheckSubmissionSessions,
        );
      }
      return completedCheckSubmissionSessions.concat(
        completedPausedSessions,
        completedPaidSessions,
        helpRequestedSessions,
        inProgressSessions,
        invitedSessions,
        waitlistedSessions,
        availableSessions,
        cancelledSessions,
        restOfSessions,
      );
    }
    const completeSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Completed'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const happeningSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Happening now'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const confirmedSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Confirmed'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const waitlistedSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Waitlisted'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const invitedSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Invited'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const pendingTechCheckSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Pending tech check'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const inviteDeclinedSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Invite declined'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const availableSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status === 'Available'),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );
    const cancelledSessions = cherryPickingUtils.sortTableByStartSession(
      utils.orderArrayByField(
        dataArray.filter((item: any) => item.status.toLowerCase().includes('cancelled')),
        'eligibility',
        'desc',
      ),
      'session',
      'asc',
    );

    // Merge all arrays together and return them
    if (_.get(sorting, '[0].sortingMethod') === -1) {
      return cancelledSessions.concat(
        availableSessions,
        inviteDeclinedSessions,
        pendingTechCheckSessions,
        invitedSessions,
        waitlistedSessions,
        confirmedSessions,
        happeningSessions,
        completeSessions,
      );
    }
    return completeSessions.concat(
      happeningSessions,
      confirmedSessions,
      waitlistedSessions,
      invitedSessions,
      pendingTechCheckSessions,
      inviteDeclinedSessions,
      availableSessions,
      cancelledSessions,
    );
  },
  defaultFiltersAndSorting(
    booking: any,
    data = [],
    filters = [],
    sorting = [],
    hiddenParticipants = [],
    shortListedParticipants = [],
    showShortlistedParticipants = false,
    showHiddenParticipants = false,
  ) {
    let dataToReturn = data;
    if (_.size(filters)) {
      const filterByStatus = _.find(filters, (item: any) => item.filterField === 'status');
      if (filterByStatus) {
        const statuses = _.map(filterByStatus.filterValues, (item: any) => item.value);
        if (statuses.includes('Cancelled')) {
          statuses.push(
            ...[
              'Cancelled by admin',
              'Cancelled by client',
              'Cancelled by participant',
              'Cancelled by no show',
              'Cancelled by reschedule',
              'Cancelled by issues',
            ],
          );
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
        dataToReturn = _.filter(dataToReturn, (item: any) => {
          return filterByStatus.filterMethod === 'is none of'
            ? !statuses.includes(item.status)
            : statuses.includes(item.status);
        });
      }
    }

    const sortBy = _.get(sorting, '[0].sortingField');
    let sortOrder = _.get(sorting, '[0].sortingMethod') === -1 ? 'desc' : 'asc';
    if (sortBy === 'age' || sortBy === 'created') {
      sortOrder = _.get(sorting, '[0].sortingMethod') === -1 ? 'asc' : 'desc';
    }

    if (_.size(sorting) === 0 || sortBy === 'status') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      dataToReturn = cherryPickingUtils.sortDataBasedOnDefaultCriteria(booking, dataToReturn, sorting);
    } else if (sortBy === 'session') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      dataToReturn = cherryPickingUtils.sortTableByStartSession(dataToReturn, sortBy, sortOrder);
    } else if (utils.isObjectId(sortBy)) {
      // Check if its a dynamic fieldId
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      dataToReturn = utils.orderArrayByField(dataToReturn, sortBy, sortOrder, 'label');
    } else {
      // Sort by its content only
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      dataToReturn = utils.orderArrayByField(dataToReturn, sortBy, sortOrder);
    }

    if (_.size(hiddenParticipants) === 0 && _.size(shortListedParticipants) === 0) return dataToReturn;

    if (showShortlistedParticipants && _.size(shortListedParticipants) > 0) {
      // prettier-ignore
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      dataToReturn = _.filter(dataToReturn, (item: any) => shortListedParticipants.indexOf(item._id) >= 0);
    } else if (_.size(hiddenParticipants) > 0) {
      if (showHiddenParticipants) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
        dataToReturn = _.filter(dataToReturn, (item: any) => hiddenParticipants.indexOf(item._id) >= 0);
      }

      if (!showHiddenParticipants) {
        // prettier-ignore
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
        dataToReturn = _.filter(dataToReturn, (item: any) => hiddenParticipants.indexOf(item._id) < 0);
      }
    }
    return dataToReturn;
  },
  isPercentageFilter(tableColumns: any, id: any) {
    return (
      _.get(
        tableColumns.find((item: any) => item.id === id),
        'filter.percentage',
      ) || false
    );
  },
  isUpToFilter(tableColumns: any, id: any) {
    return (
      _.get(
        tableColumns.find((item: any) => item.id === id),
        'filter.upTo',
      ) || false
    );
  },
  getFilterType(booking: any, tableColumns: any, fieldId: any) {
    // Function to get the type of the filter
    // The type can be defined statically on the header array or dynamically when the user is trying to filter a screening question
    if (utils.isObjectId(fieldId)) {
      const question = booking.config.question.find((item: any) => item._id === fieldId);
      if (_.get(question, 'config.type') === 1) return 'list';
      return 'string';
    }

    const configFilter = tableColumns.find((item: any) => item.id === fieldId);
    return _.get(configFilter, 'filter.type');
  },
  getRespectiveColor(status: any, bookingType: any) {
    switch (status) {
      case 'completed':
        if (bookingType === 3 || bookingType === 4) return '#d8ffcc';
        return '#e3e3e3';
      case 'invited':
      case 'pending tech check':
      case 'in progress':
      case 'help requested':
        return '#fef8cd';
      case 'cancelled':
      case 'cancelled by admin':
      case 'cancelled by client':
      case 'cancelled by participant':
      case 'cancelled by no show':
      case 'cancelled by reschedule':
      case 'cancelled by issues':
      case 'auto cancelled':
        return '#fbe4e4';
      case 'happening now':
      case 'confirmed':
      case 'completed - paid':
      case 'completed - check submission':
        return '#d8ffcc';
      case 'completed - paused':
        return '#C9E5C0';
      case 'waitlisted':
        return '#fef5b4';
      default:
        return '#ffffff';
    }
  },
  getListOfIgnoredSortedFields() {
    return [{ id: 'actions' }, { id: 'session' }, { id: 'checkbox' }];
  },
  getFilterMethods() {
    return [
      { label: 'is any of', value: 'is any of', filterType: 'list' },
      { label: 'is none of', value: 'is none of', filterType: 'list' },
      { label: 'contains', value: 'contains', filterType: 'string' },
      { label: 'does not contains', value: 'does not contains', filterType: 'string' },
      { label: 'is equal to', value: 'is equal to', filterType: 'number' },
      { label: 'is not equal to', value: 'is not equal to', filterType: 'number' },
      { label: 'is greater than', value: 'is greater than', filterType: 'number' },
      { label: 'is less than', value: 'is less than', filterType: 'number' },
      { label: 'is greater or equal to', value: 'is greater or equal to', filterType: 'number' },
      { label: 'is less or equal to', value: 'is less or equal to', filterType: 'number' },
    ];
  },
  getSortingMethods() {
    return [
      { label: 'A to Z', value: 1, sortingType: 'list' },
      { label: 'Z to A', value: -1, sortingType: 'list' },
      { label: 'A to Z', value: 1, sortingType: 'string' },
      { label: 'Z to A', value: -1, sortingType: 'string' },
      { label: 'Lowest to highest', value: 1, sortingType: 'number' },
      { label: 'Highest to lowest', value: -1, sortingType: 'number' },
    ];
  },
};

export { cherryPickingUtils };
