import { ChevronLeft } from 'lucide-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertInfoIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Tooltip,
} from 'ui';

import { SidePanelLoading } from './components/SidePanelLoading';

import type { Maybe } from 'generated/graphql';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import type { FlexProps } from 'ui';

type Props = FlexProps & {
  title?: string;
  footer?: ReactNode;
  loading?: boolean;
  support?: string | ReactNode;
  error?: Maybe<{
    title?: string;
    message: string;
  }>;
  onBackClick?: (() => void) | null;
  onCancelClick?: (() => void) | null;
  cancelButton?: ReactNode;
  isBulkInvite?: boolean;
};

export const BookingSidePanelStepperContainer: FC<PropsWithChildren<Props>> = ({
  onBackClick,
  loading,
  onCancelClick,
  error,
  cancelButton,
  title,
  support,
  footer,
  children,
  isBulkInvite,
  ...props
}) => {
  if (loading) {
    return <SidePanelLoading />;
  }

  const handleBackClick = () => {
    if (!onBackClick) {
      return;
    }

    onBackClick();
  };

  const supportNode = (() => {
    if (!support) {
      return null;
    }
    return typeof support === 'string' ? (
      <Text>
        {support}&nbsp;
        {isBulkInvite && (
          <Tooltip
            placement="top-start"
            w="248px"
            label="The suggested times feature allows you to review and make adjustments before sending."
          >
            <AlertInfoIcon />
          </Tooltip>
        )}
      </Text>
    ) : (
      support
    );
  })();

  return (
    <Flex h="full" justifyContent="space-between" alignItems="space-between" flexDir="column" w="full" {...props}>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="space-between"
        py="3"
        px="6"
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Button
          size="sm"
          onClick={handleBackClick}
          visibility={onBackClick ? 'visible' : 'hidden'}
          leftIcon={<ChevronLeft className="h-4 w-4" />}
          variant="ghost"
        >
          Back
        </Button>
        {cancelButton ??
          (onCancelClick && (
            <Button size="sm" variant="ghost" onClick={onCancelClick}>
              Cancel
            </Button>
          ))}
      </Flex>

      <Flex flexDirection="column" px="6" pt="10" h="100%" overflow="scroll">
        {title && (
          <Heading mb="4" size="md">
            {title}
          </Heading>
        )}
        {error && (
          <Alert status="error" mb="4" display="flex" flexShrink="0">
            <AlertIcon />
            <Box>
              {error.title && <AlertTitle>{error.title}</AlertTitle>}
              <AlertDescription>{error.message}</AlertDescription>
            </Box>
          </Alert>
        )}
        {supportNode}
        <Box mt="10">{children}</Box>
      </Flex>

      {footer && (
        <Flex p="6" w="full" borderTop="1px" borderColor="gray.200">
          {footer}
        </Flex>
      )}
    </Flex>
  );
};
