import { memo } from 'react';
import { Skeleton, VStack } from 'ui';

export const SidePanelLoading = memo(() => {
  return (
    <VStack w="full" p="6" pt="28" alignItems="flex-start" spacing={6}>
      <Skeleton height="6" w="full" />
      <Skeleton height="6" w="80%" />
      <Skeleton height="6" w="full" />
      <Skeleton height="6" w="85%" />
      <Skeleton height="6" w="40%" />
    </VStack>
  );
});
