import { cn } from '@askable/ui/lib/utils';

import { Click, Message, Smile } from './icons';

import type { ReactNode } from 'react';

interface InfoAlertProps {
  description?: string;
  title?: string;
  type: 'instructions' | 'feedback' | 'view-only' | 'participants';
  centerAlign?: boolean;
  children?: ReactNode;
}

const IconWrapper = ({ children }: { children: ReactNode }) => <div className="scale-x-100 transform">{children}</div>;

export const InfoAlert = ({
  description,
  title,
  type = 'instructions',
  centerAlign = false,
  children,
}: InfoAlertProps) => {
  return (
    <div
      className={cn('flex w-fit gap-2 text-pretty rounded-lg border-0 bg-background-subtle p-4', {
        'bg-info-foreground': type === 'view-only',
      })}
    >
      <div className={cn('flex gap-4', { 'items-center': centerAlign })}>
        {type === 'instructions' ? (
          <IconWrapper>
            <Click size={40} />
          </IconWrapper>
        ) : null}

        {type === 'feedback' ? (
          <IconWrapper>
            <Message size={40} />
          </IconWrapper>
        ) : null}

        {type === 'participants' ? (
          <IconWrapper>
            <Smile size={40} />
          </IconWrapper>
        ) : null}

        <div className="flex flex-col gap-1">
          {title && <div className="font-semibold">{title}</div>}
          {description && <div className="text-pretty text-sm">{description}</div>}
          {children && children}
        </div>
      </div>
    </div>
  );
};
