import { Button } from '@askable/ui/components/ui/button';
import { ChevronDown, ChevronUp, X } from 'lucide-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { utils } from 'lib/utils';

import { MessageAdd } from './MessageAdd';
import { MessagesList } from './MessagesList';

import type { User } from '../data/BookingSubmissionsWithMessages.query';

/**
 * Slide out panel containing the conversation with the selected user and a message reply field
 */

interface BasicUser {
  _id: string;
  displayName: string;
}

interface PanelMessagesProps {
  bookingId: string;
  fromUserId: string;
  previousNextMessages?: { previous: string | null; next: string | null };
  user: User | BasicUser;
  onClose: () => void;
  onNavigateMessages?: (userId: string | null) => void;
}

const panelWidth = window.innerWidth < 400 ? '96%' : '25rem';

export const PanelMessages = ({
  bookingId,
  fromUserId,
  user,
  previousNextMessages,
  onClose,
  onNavigateMessages,
}: PanelMessagesProps) => {
  const { t } = useTranslation();
  const panelRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom of list when adding a new note
  const onUpdated = () => {
    if (panelRef && panelRef.current) {
      panelRef.current?.scrollTo({ top: panelRef.current.scrollHeight });
    }
  };

  return (
    <div
      className="panel-messages opacity-1 absolute right-0 top-0 h-full overflow-hidden transition-all duration-150 ease-in-out
        starting:opacity-50"
      style={
        {
          width: user?._id ? panelWidth : 0,
          '--messagePanelOffset': '141px',
        } as React.CSSProperties
      }
    >
      <div
        className="fixed h-full overflow-auto scroll-smooth border-l border-border bg-background text-foreground shadow-md dark:bg-accent"
        style={{
          width: panelWidth,
          height: 'var(--mainContainerHeight)',
          minHeight: 'var(--mainContainerHeight)',
        }}
        ref={panelRef}
      >
        {user ? (
          <div className="sticky top-0 z-10 flex w-full justify-between gap-2 border-b border-border bg-background/90 p-2 backdrop-blur-sm sm:p-3">
            <div className="flex flex-col">
              <h3 className="font-semibold">
                {user.displayName}{' '}
                {'blacklist' in user && user.blacklist ? `(${t('global.blacklisted').toLocaleLowerCase()})` : null}
              </h3>

              {'email' in user ? (
                <div className="flex flex-col text-xs text-foreground-subtle">
                  <a
                    href={`mailto:${user.email}`}
                    className="break-all text-inherit hover:text-foreground hover:underline"
                  >
                    {user.email}
                  </a>
                  <span>{utils.formatPhoneNumber(user.contact?.phone?.mobile)}</span>
                </div>
              ) : null}
            </div>

            <div className="flex gap-1">
              {previousNextMessages && onNavigateMessages ? (
                <>
                  <Button
                    aria-label={t('global.next')}
                    disabled={!previousNextMessages.next}
                    onClick={() => onNavigateMessages(previousNextMessages.next)}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronDown className="h-4 w-4" />
                  </Button>

                  <Button
                    aria-label={t('global.previous')}
                    disabled={!previousNextMessages.previous}
                    onClick={() => onNavigateMessages(previousNextMessages.previous)}
                    size="icon"
                    variant="outline"
                  >
                    <ChevronUp className="h-4 w-4" />
                  </Button>
                </>
              ) : null}

              <Button variant="ghost" size="icon" onClick={onClose}>
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ) : null}

        {user?._id ? (
          <>
            <MessagesList bookingId={bookingId} userId={user._id} fromUserId={fromUserId} onUpdated={onUpdated} />
            <div className="sticky bottom-0 bg-background px-4 py-3">
              <MessageAdd
                active
                bookingId={bookingId}
                userId={user._id}
                fromUserId={fromUserId}
                onUpdated={onUpdated}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
