import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from 'ui';
import { useMutation } from 'urql';

import { SaveSegmentMutation } from '../data/SaveSegment.mutation';
import { useBookingParticipantState } from '../state/booking-participant-state';
import { mapUIFiltersToSegmentFiltersInput } from '../utils/booking-participant-utils';

import type { Segment } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  isOpen: boolean;
  bookingId: string;
  onClose: () => void;
  onSaveComplete: (segment?: Segment) => void;
};

type FormValues = { segmentName: string };

export const SaveSegmentModal: FC<Props> = ({ isOpen, onClose, onSaveComplete, bookingId }) => {
  const { handleSubmit, register } = useForm<FormValues>();

  const [{ fetching: loading }, saveSegment] = useMutation(SaveSegmentMutation);

  const onSubmit = async (fv: FormValues) => {
    const { segmentInput, filters, condition } = useBookingParticipantState.getState();

    try {
      const result = await saveSegment({
        booking_id: bookingId,
        segment_input: {
          method: condition?.value!,
          name: fv.segmentName,
          tableConfig: segmentInput.tableConfig,
          filters: mapUIFiltersToSegmentFiltersInput(filters) ?? [],
          sort: segmentInput.sort,
        },
      });
      if (result.error) {
        throw result.error;
      }

      onSaveComplete(result.data?.saveSegment as Segment);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Create new segment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input autoFocus {...register('segmentName', { required: true })} />
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" isLoading={loading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
};
