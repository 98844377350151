import { CircleCheck, CircleX, List, Text } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { BookingRecruitQuestion } from 'generated/graphql';

interface SummaryItemScreenerQuestionProps {
  question: BookingRecruitQuestion;
}

export const SummaryItemScreenerQuestion = ({ question }: SummaryItemScreenerQuestionProps) => {
  const { t } = useTranslation();

  return (
    <li className="flex flex-col gap-2 overflow-hidden rounded-lg border border-border bg-background p-4">
      <div className="flex items-center gap-2 text-xs text-foreground-subtle">
        {question.type === 'multiple_choice' ? (
          <>
            <List className="h-3 w-3" />
            {t('sections.studies.recruit.options.multiple_choice')}
          </>
        ) : (
          <>
            <Text className="h-3 w-3" />
            {t('sections.studies.recruit.options.short_answer')}
          </>
        )}
      </div>
      <div className="font-semibold">{question.title}</div>
      {question.description ? <div className="text-sm text-foreground-subtle">{question.description}</div> : null}

      {question.options && question.options.length > 0 ? (
        <ul className="flex flex-col gap-2">
          {question.options.map(option => (
            <li key={option._id} className="flex items-center gap-2 text-sm">
              {option.is_qualified ? (
                <CircleCheck className="h-4 w-4 text-success" />
              ) : (
                <CircleX className="h-4 w-4 text-destructive" />
              )}
              {option.label}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};
