import { Button } from '@askable/ui/components/ui/button';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@askable/ui/components/ui/table';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from 'containers/Studies/Results/components/Section';

import { ResponseTableRow } from './ResponseTableRow';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';

interface IndividualResponsesProps {
  isLoading?: boolean;
  perPage: number;
  results: BookingTaskResultPartial[];
  totalCount: number;
  onLoadMore?: () => void;
}

export const IndividualResponses = ({
  isLoading,
  perPage,
  results,
  totalCount,
  onLoadMore,
}: IndividualResponsesProps) => {
  const { t } = useTranslation();
  const moreCount = totalCount - results.length;
  const lastRowRef = useRef<HTMLTableRowElement>(null);

  // Scroll to the last row when new results are loaded
  useEffect(() => {
    if (results.length > perPage && lastRowRef.current) {
      lastRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [results.length, perPage]);

  return (
    <Section
      description={t('sections.studies.results.individualResponsesDescription')}
      id="individual-responses"
      title={t('sections.studies.results.individualResponsesTitle')}
    >
      <Table>
        <TableHeader>
          <TableRow className="whitespace-nowrap">
            <TableHead className="min-w-40">{t('sections.studies.results.participants', { count: 1 })}</TableHead>
            <TableHead>{t('sections.studies.results.outcome')}</TableHead>
            <TableHead>{t('sections.studies.results.duration')}</TableHead>
            <TableHead>{t('sections.studies.results.misclickRate')}</TableHead>
            <TableHead>{t('sections.studies.results.screensViewed')}</TableHead>
            <TableHead>{t('sections.studies.results.completionDate')}</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {results.map((result, index) => (
            <ResponseTableRow
              key={result._id}
              result={result}
              ref={index === results.length - 1 ? lastRowRef : undefined}
            />
          ))}
        </TableBody>
      </Table>

      {moreCount ? (
        <Button variant="ghost" className="w-full" onClick={onLoadMore} isLoading={isLoading}>
          {t('global.plusMore', { count: moreCount })}
        </Button>
      ) : null}
    </Section>
  );
};
