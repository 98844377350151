/* eslint-disable max-lines */
import isPast from 'date-fns/isPast';
import keyBy from 'lodash/keyBy';
import { ChevronRight } from 'lucide-react';
import { sort, listify } from 'radash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatSessionTime, pluralize } from 'shared-utils';
import { Box, Button, Flex, Stack, Text, useDisclosure } from 'ui';
import { useMutation, useQuery } from 'urql';

import { useBookingContainerConfig } from 'containers/Booking/BookingContainer';
import { BulkInviteDocument, BulkInviteModerateDataDocument, SubmissionStatus } from 'generated/graphql';
import { BOOKING_SESSION_STATUS } from 'lib/constants';
import { isBookingFull } from 'utils/booking-utils';

import { BookingFullModal } from '../BookingFullModal';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';
import { UserTimes } from './components/TimeSlots';
import { SELECTED_STATUS } from './utils/booking-side-panel-utils';

import type {
  BookingSession,
  BulkInviteModerateDataQuery,
  BulkInviteModerateDataQueryVariables,
  BulkInviteMutationResult,
  BulkInviteMutationVariables,
  Maybe,
  Submission,
} from 'generated/graphql';
import type { FC } from 'react';

function formatStatusText(status?: SubmissionStatus | null): string {
  switch (status) {
    case SubmissionStatus.ParticipantCancelled:
      return 'Cancelled';
    case SubmissionStatus.PendingCheck:
      return 'Pending check';
    case SubmissionStatus.HelpRequested:
      return 'Help requested';
    default:
      return `Already ${status}`;
  }
}

type Props = {
  submissionIds: string[];
  onClose: () => void;
  bookingId: string;
  onComplete: () => void;
};

type Stepper = 'users' | 'user-time';

const STEPPER_CONFIG: { [key in Stepper]: { previous: Maybe<Stepper>; next: Maybe<Stepper> } } = {
  users: {
    previous: null,
    next: 'user-time',
  },
  'user-time': {
    previous: 'users',
    next: 'user-time',
  },
};

type IAutoAllocate = {
  bookingSessions: BookingSession[];
  submissions: Submission[];
};

const CANCELLED_STATUS = [BOOKING_SESSION_STATUS.CANCELLED_BY_ADMIN, BOOKING_SESSION_STATUS.CANCELLED_BY_CLIENT];

export function autoAllocateTimes({ bookingSessions, submissions }: IAutoAllocate): Record<string, string> {
  // We want the ones with no sessions to be last meaning they get what they get
  const filtered = submissions.filter(a => a.status === SubmissionStatus.Available);
  const keyedSessions = sort(filtered, a => a.preferred_sessions?.length ?? 99);

  return bookingSessions.reduce<Record<string, string>>((acc, curr) => {
    if (CANCELLED_STATUS.includes(curr.status ?? 0)) {
      return acc;
    }

    if ((curr.start && isPast(curr.start)) || curr.participants?.some(a => a?.status !== 3)) {
      return acc;
    }

    const session = keyedSessions.find(a => a.preferred_sessions?.some(b => b?._id === curr._id));

    if (session?._id) {
      acc[session._id] = curr._id!;

      keyedSessions.splice(keyedSessions.indexOf(session), 1);
      return acc;
    }

    const next = keyedSessions.shift()?._id;

    if (next) {
      acc[next!] = curr._id!;
    }

    return acc;
  }, {});
}

export const BookingParticipantBulkInviteModerateSidePanel: FC<Props> = ({
  submissionIds,
  onClose,
  onComplete,
  bookingId,
}) => {
  const { isOpen, onOpen, onClose: onBookingFullModalClose } = useDisclosure();
  const [currentStep, setCurrentStep] = useState<{ step: Stepper; id: string | null }>({
    id: null,
    step: 'users',
  });

  const { sessionType } = useBookingContainerConfig();

  const stepper = STEPPER_CONFIG[currentStep.step];

  const { handleSubmit, control, getValues, setError, watch, formState, reset } = useForm<Record<string, string>>();

  const [{ fetching: loading }, bulkInvite] = useMutation<BulkInviteMutationResult, BulkInviteMutationVariables>(
    BulkInviteDocument,
  );

  const [{ data, fetching }] = useQuery<BulkInviteModerateDataQuery, BulkInviteModerateDataQueryVariables>({
    query: BulkInviteModerateDataDocument,
    variables: {
      bookingId,
      first: submissionIds.length,
      filter: {
        submissionIds,
        _booking_id: bookingId,
      },
    },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    /**
     * If the user removes a person we need to remove the time that was allocated to them
     * Because a item can not be added and removed at the same time, the lengths of submissionIds and allValue keys
     * will always differ when checking a submission checkbox. This is a optimization
     */
    const allValues = getValues();

    if (submissionIds.length === Object.keys(allValues).length) {
      return;
    }

    const update = submissionIds.reduce<Record<string, string>>((acc, subId) => {
      if (allValues[subId]) {
        return {
          ...acc,
          [subId]: allValues[subId],
        };
      }

      return acc;
    }, {});

    setCurrentStep({
      id: null,
      step: 'users',
    });

    reset(update);
  }, [submissionIds]);

  const onSubmit = async (fv: Record<string, string>) => {
    if (!Object.keys(fv).length) {
      setError('no_session', {
        type: 'required',
        message: 'At least one session needs to be allocated before invites can be sent',
      });

      return;
    }

    if (
      isBookingFull({
        bookingTotalParticipants: data?.bookingByID?.total_participants || 0,
        currentBookingTotalApplicants: data?.currentTotalApplicants?.totalCount || 0,
      })
    ) {
      onOpen();
      return;
    }

    try {
      const { error } = await bulkInvite({
        booking_id: bookingId,
        input: {
          submissions: listify(fv, (k, v) => ({ id: k, sessionOrTaskId: v })),
        },
      });
      if (error) {
        throw error;
      }

      onComplete();
    } catch (e) {
      console.log(e);
    }
  };

  const handleBackClick = () => {
    if (stepper.previous === null) {
      return;
    }

    setCurrentStep({ step: stepper.previous, id: null });
  };

  const onButtonClick = (id: string) => () => {
    setCurrentStep({
      id,
      step: 'user-time',
    });
  };

  const handleAutoAllocateClick = () => {
    const results = autoAllocateTimes({
      bookingSessions: data?.bookingByID?.session as BookingSession[],
      submissions: data?.bookingSubmissionConnection?.nodes as Submission[],
    });

    reset(results);
  };

  const keyedBookingSessions = useMemo(() => {
    return keyBy(data?.bookingByID?.session, b => b?._id!);
  }, [data]);

  const selected = data?.bookingSubmissionConnection?.nodes?.find(a => a?._id === currentStep.id) ?? null;

  const watchedFields = watch();

  const calculatedBookingSessions = useMemo(() => {
    /**
     * This calculates selected sessions to display in each slot
     */
    const j = listify(watchedFields, (key, value) => ({ sessionId: value, submissionId: key }));
    return data?.bookingByID?.session?.map(session => {
      const filtered = j.filter(q => q.sessionId === session?._id && q.submissionId !== currentStep.id);

      if (filtered.length) {
        const added = filtered.map(a => ({ status: SELECTED_STATUS, id: a.submissionId }));

        return {
          ...session,
          participants: [...(session?.participants ?? []), ...added],
        };
      }

      return session;
    });
  }, [watchedFields, data, currentStep]);

  const displayName = `${selected?.applicant?.firstname} ${selected?.applicant.lastname}`;

  const title = (() => {
    if (currentStep.step === 'users') {
      const inviteCount = data?.bookingSubmissionConnection?.nodes?.filter(
        a => a?.status === SubmissionStatus.Available,
      ).length;
      return `Invite ${pluralize('people', inviteCount, true)}`;
    }

    return displayName;
  })();

  const sortedSubmissions = useMemo(() => {
    return data?.bookingSubmissionConnection?.nodes?.sort(a => {
      if (a?.status === SubmissionStatus.Available) {
        return -1;
      }

      return 1;
    });
  }, [data]);

  const renderFooter = (() => {
    const values = getValues();
    switch (currentStep.step) {
      case 'users':
        return (
          <Button w="full" type="submit" size="lg" colorScheme="blue" isLoading={loading}>
            Send
          </Button>
        );
      case 'user-time':
        return (
          Object.keys(values).length !== 0 && (
            <Button
              w="full"
              type="button"
              onClick={e => [e.preventDefault(), handleBackClick()]}
              size="lg"
              colorScheme="gray"
            >
              Done
            </Button>
          )
        );
      default:
        return null;
    }
  })();

  return (
    <BookingSidePanelStepperContainer
      loading={fetching}
      onSubmit={handleSubmit(onSubmit)}
      onBackClick={stepper.previous !== null ? handleBackClick : null}
      title={title!}
      support={
        currentStep.step === 'users'
          ? 'Allocate individually or let us find the most suitable times based on preferred times and availability'
          : ''
      }
      as="form"
      error={
        formState.errors.no_session
          ? { title: 'No sessions selected', message: formState.errors.no_session.message! }
          : null
      }
      w="full"
      onCancelClick={currentStep.step === 'users' ? onClose : null}
      footer={renderFooter}
      isBulkInvite
    >
      {currentStep.step === 'users' && (
        <>
          <Button mb="6" onClick={handleAutoAllocateClick}>
            Populate suggested times
          </Button>
          <Stack pb="4">
            {sortedSubmissions?.map(node => {
              const selectedSession = keyedBookingSessions[getValues(node?._id!)] ?? null;
              const isDisabled = node?.status !== SubmissionStatus.Available;

              return (
                <Box
                  key={node?._id}
                  onClick={onButtonClick(node?._id!)}
                  as="button"
                  display="flex"
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="center"
                  disabled={isDisabled}
                  w="full"
                  p="2"
                  _disabled={{ pointerEvents: 'none' }}
                  px="4"
                  rounded="md"
                  _hover={{ bg: 'gray.50' }}
                >
                  <Flex alignItems="flex-start" flexDirection="column">
                    <Text fontWeight="semibold" mb="1">
                      {`${node?.applicant.firstname} ${node?.applicant.lastname}`}
                    </Text>
                    {isDisabled && (
                      <Text fontWeight="semibold" fontSize="sm">
                        {formatStatusText(node?.status)}
                      </Text>
                    )}
                    {!isDisabled && (
                      <Text color={selectedSession ? 'inherit' : 'gray.500'}>
                        {selectedSession
                          ? formatSessionTime({
                              start: selectedSession.start!,
                              end: selectedSession.end!,
                            })
                          : 'No time selected'}
                      </Text>
                    )}
                  </Flex>
                  {!isDisabled && <ChevronRight className="h-4 w-4" />}
                </Box>
              );
            })}
          </Stack>
        </>
      )}

      {currentStep.step === 'user-time' && (
        <UserTimes
          sessionType={sessionType}
          title={selected?.applicant.firstname!}
          pptTimezone={selected?.applicant?.timezone!}
          name={currentStep.id!}
          control={control}
          preferredSessions={selected?.preferred_sessions as BookingSession[]}
          bookingSessions={calculatedBookingSessions as BookingSession[]}
        />
      )}
      <BookingFullModal bookingId={bookingId} isOpen={isOpen} onClose={onBookingFullModalClose} isModeratedBooking />
    </BookingSidePanelStepperContainer>
  );
};
