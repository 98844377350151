import { memo } from 'react';
import {
  Flex,
  List,
  ListItem,
  ListIcon,
  Text,
  Tooltip,
  Popover,
  PopoverBody,
  PopoverTrigger,
  PopoverContent,
  HStack,
  CheckIcon,
  CancelIcon,
  Box,
} from 'ui';

import { bookingParticipantSelectors, useBookingParticipantState } from '../../../state/booking-participant-state';

import type { Data } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  isFull?: boolean;
  answers: Data[];
  multiSelection: boolean;
};

type QuestionHeaderProps = {
  title?: string | null;
};

export const QuestionHeader: FC<QuestionHeaderProps> = memo(({ title }) => {
  return (
    <Tooltip label={title} openDelay={300}>
      <Text noOfLines={3}>{title!}</Text>
    </Tooltip>
  );
});

type MultiChoiceAnswerProps = {
  isFull?: boolean;
  answers?: Data[];
};

const MultiChoiceAnswer: FC<MultiChoiceAnswerProps> = memo(({ answers, isFull }) => {
  const applicantInfoPanelEnabled = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);

  if (isFull) {
    return (
      <Flex wrap="wrap">
        {answers?.map(v => {
          return (
            <Flex key={v._answer_id} alignItems="center" position="relative" mr="3" mb="0.5">
              <Box
                position="absolute"
                top={1}
                mt="px"
                as={v?.option?.screen_in ? CheckIcon : CancelIcon}
                color={v?.option?.screen_in ? 'green.500' : 'red.500'}
                fontSize="xs"
              />
              <Text ml="4">{v.option?.label}</Text>
            </Flex>
          );
        })}
      </Flex>
    );
  }

  const totalAnswers = answers?.length;
  const maxAnswers = 7;

  const trigger = (
    <HStack spacing={1}>
      {answers?.slice(0, maxAnswers)?.map(answer => {
        return answer?.option?.screen_in ? (
          <CheckIcon color="green.500" key={answer._answer_id} />
        ) : (
          <CancelIcon color="red.500" key={answer._answer_id} />
        );
      })}
      {totalAnswers! > maxAnswers && <Text ml="2">+{totalAnswers! - maxAnswers}</Text>}
    </HStack>
  );

  if (applicantInfoPanelEnabled?.panel === 'details') {
    return trigger;
  }

  return (
    <Popover placement="top" isLazy trigger="hover" openDelay={200}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent flexShrink={1} flexGrow={1} w="auto" maxWidth={64} boxShadow="lg">
        <PopoverBody w="auto" p="3">
          <List spacing={1}>
            {answers?.map(v => {
              return (
                <ListItem position="relative" key={v?._answer_id} lineHeight={5}>
                  <ListIcon
                    fontSize="xs"
                    top="1"
                    position="absolute"
                    as={v?.option?.screen_in ? CheckIcon : CancelIcon}
                    color={v?.option?.screen_in ? 'green.500' : 'red.500'}
                  />
                  <Text ml="5">{v.option?.label}</Text>
                </ListItem>
              );
            })}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

export const QuestionAnswerCell: FC<Props> = memo(({ answers, multiSelection, isFull }) => {
  if (multiSelection) {
    return <MultiChoiceAnswer isFull={isFull} answers={answers} />;
  }

  if (answers[0]?.value) {
    return (
      <Tooltip label={answers?.[0].value} openDelay={200} placement="top-start">
        <Text noOfLines={1}>{answers?.[0].value}</Text>
      </Tooltip>
    );
  }

  const singleValue = answers[0]?.option?.label;

  if (!answers[0]?.option?.screen_in) {
    return (
      <Flex alignItems="center">
        <CancelIcon color="red.500" fontSize={isFull ? 'xs' : 'md'} />
        <Text ml="1" noOfLines={1}>
          {singleValue ?? 'No answer given'}
        </Text>
      </Flex>
    );
  }

  return <Text noOfLines={1}>{singleValue}</Text>;
});
