/* eslint-disable max-lines */
import { toast } from '@askable/ui/components/ui/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { ExternalLink } from 'lucide-react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useIntercom } from 'react-use-intercom';
import { useMutation } from 'urql';

import { Button } from 'components/common';
import ModalCompleteOrder from 'components/createBooking/components/modalCompleteOrder';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { analytics } from 'lib/analytics';
import { bookingUtils } from 'lib/booking';
import { BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE } from 'lib/constants';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { globalVariables } from 'lib/globalVariables';
import { location } from 'lib/location';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';

import { CompleteOrderBooking } from '../data/CompleteOrderBooking.mutation';

import iPhoneFrame from './assets/iphoneFrame.png';

import type { Booking } from 'generated/graphql';

import './styles/confirmBookingStyles.scss';

type Props = {
  booking: Booking;
  bookingSteps: Booking['steps'];
  team: any;
  history: any;
  updateLastStep: any;
  renderRightContent: any;
  renderRightAppPreview: any;
  updateBooking: any;
  createSession: any;
  deleteSession: any;
};
function ConfirmBooking(props: Props) {
  const intercom = useIntercom();
  const clientDetails = useConnectedClient();
  const [booking, setBooking] = useState<Booking>(_.get(props, 'booking'));
  const [bookingSteps, setBookingSteps] = useState<Booking['steps']>(_.get(props, 'bookingSteps'));

  const [openCompleteOrderModal, setOpenCompleteOrderModal] = useState(false);
  const [iframePath, setIframePath] = useState('');

  const taxesForCountry = teamUtils.getTax();
  const currency = teamUtils.getCurrency();

  // Number of credits needed per participant
  const creditsPerParticipant = bookingUtils.getCreditsPerParticipant(booking);

  // Credits to buy is equals to the number of participants needed minus the number of credits the team currently has
  const creditsToBeUsed = Math.ceil((_.get(booking, 'total_participants') || 0) * creditsPerParticipant);
  const bookingPrice = _.get(booking, 'total_amount.bookingPrice') || 0;
  const creditsRemaining = teamUtils.getCredits(_.get(props, 'team') || _.get(booking, 'team'));
  const creditsToBuy = creditsToBeUsed - creditsRemaining;

  useEffect(() => {
    props.updateLastStep?.({
      step: 'Review & Submit',
      subStep: `/booking-setup/${booking._id}/review-submit/confirm-booking`,
      stepId: 'review_submit_confirm_booking',
    });
  }, []);

  useEffect(() => {
    props.renderRightContent?.(null);
    props.renderRightAppPreview?.(null);
    setBooking(props.booking);
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(props.bookingSteps);
  }, [props.bookingSteps]);

  const [, completeOrderBooking] = useMutation(CompleteOrderBooking);

  const onClickFinish = async () => {
    if (bookingUtils.isAskablePlus(booking)) {
      try {
        const completedBooking = await completeOrderBooking({
          booking_id: booking._id,
        });

        if (completedBooking.error) {
          throw completedBooking.error;
        }
        const isBYO = _.get(booking, 'config.recruitment.byo') || false;
        const isAskableLive = _.get(booking, 'config.remote.askable_live') || false;

        clientDetails.actions.updateClientDetails({
          ...clientDetails.details,
          team: completedBooking.data?.completeOrderBooking?.team,
        });

        // Track Event Analytics
        analytics.track('booking_completed', {
          page: window.location.href,
          action: 'Project Completed',
          bookingId: _.get(booking, '_id'),
          bookingName: _.get(booking, 'name'),
          totalParticipants: _.get(booking, 'total_participants'),
          bookingType: _.get(bookingUtils.getBookingTypeSettings(booking), 'label'),
          isBYO,
          isAskableLive,
        });

        props.history.replace({
          pathname: `/bookings/${_.get(booking, '_id')}/participants/all`,
          state: { booking },
        });
      } catch (e: any) {
        toast.error(_.get(e, 'graphQLErrors[0].message') ? e.graphQLErrors[0].message : 'Error submitting study');
      }
    } else {
      setOpenCompleteOrderModal(true);
    }
  };

  const renderCardPrice = () => {
    return (
      <div className="priceCard">
        <p id="__totalCredits" className="totalCredits">
          {utils.numberWithCommas(creditsToBeUsed)} Credits
        </p>
        {creditsRemaining === 0 && (
          <p className="totalPrice">
            <span>
              {currency.symbol}
              {utils.formatMoney(bookingPrice, 2)} {currency.code}
            </span>
            {taxesForCountry?.tax_rate && taxesForCountry.tax_rate > 0 && (
              <span>
                <br /> + {_.get(taxesForCountry, 'tax_label')}
              </span>
            )}
          </p>
        )}
        {creditsRemaining > 0 && renderCreditsBalanceContainer()}
        {creditsRemaining > 0 && creditsToBuy > 0 && renderCreditsToBuyContainer()}
        <p className="totalInfo">• Price includes participant incentives</p>
        <p className="totalInfo">• Pay by invoice or credit card</p>
      </div>
    );
  };

  const renderCreditsBalanceContainer = () => {
    return (
      <div className="creditsBalanceContainer">
        <span>Your credit balance</span>
        <h3>
          {utils.numberWithCommas(creditsRemaining)} credit{creditsRemaining > 1 ? 's' : ''}
        </h3>
      </div>
    );
  };

  const renderCreditsToBuyContainer = () => {
    return (
      <div className="creditsToBuyContainer">
        <p>
          {creditsRemaining > 0 ? 'Top up with' : 'Price for'} {utils.numberWithCommas(creditsToBuy)} credit
          {creditsToBuy > 1 ? 's' : ''}:
        </p>
        <h3>
          {`${currency.symbol}${utils.formatMoney(_.get(booking, 'total_amount.bookingPrice', 0), 2)} ${currency.code}`}
          {taxesForCountry?.tax_rate && taxesForCountry.tax_rate > 0 && (
            <span> + {_.get(taxesForCountry, 'tax_label')}</span>
          )}
        </h3>
      </div>
    );
  };

  const renderReviewCardItem = ({
    key,
    icon,
    iconClass,
    title,
    value,
    isMissing = false,
    isWarning = false,
    editLink = null,
    editLinkState = null,
    type = '',
    markdown = null,
  }: any) => {
    return (
      <div className="reviewItemContainer" key={title} data-testid={key}>
        <div className="reviewItemIconContainer">
          <img
            className={`reviewItemIcon ${isMissing ? 'missingField' : ''} ${isWarning ? 'warningField' : ''} ${iconClass}`}
            src={`/booking/icons/${icon}`}
            alt="reviewItemIcon"
          />
        </div>
        <div className="reviewItemContent">
          <p className={`reviewItemTitle ${isMissing ? 'missingField' : ''} ${isWarning ? 'warningField' : ''}`}>
            {title}
          </p>
          {type === 'link' && (
            <a id={key} className="reviewItemOption linkField" href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          )}
          {type !== 'link' && markdown && (
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; className: string; source: any; }... Remove this comment to see the full error message
            <ReactMarkdown id={key} className="reviewItemOption" source={value} />
          )}
          {type !== 'link' && !markdown && (
            <div id={key} className="reviewItemOption">
              {value}
            </div>
          )}
        </div>
        {editLink && (
          <div
            className="reviewItemContentEdit"
            onClick={() => {
              props.history.push({ pathname: editLink, linkState: editLinkState });
            }}
          >
            Edit
          </div>
        )}
      </div>
    );
  };

  // Uncomment this test after participants app 2 is live
  // const renderSeeOnPreview = (link) => {
  //     return (
  //         <div
  //             className="seeOnPreviewContainer"
  //             onClick={() => console.log(link)}
  //         >
  //             <span className="seeOnPreviewLink">See on preview</span>
  //             <span className="seeOnPreviewArrow">&rarr;</span>
  //         </div>
  //     );
  // };

  const renderTaskSessionDuration = () => {
    if (bookingUtils.isLongitudinal(booking)) {
      const workloadTime = _.get(booking, 'config.longitudinal_study.participant_workload.time');
      const workloadMeasure = _.get(booking, 'config.longitudinal_study.participant_workload.measure');
      const workloadFrequency = _.get(booking, 'config.longitudinal_study.participant_workload.frequency');
      const workloadMeasureLabel = bookingUtils.getMeasureLabel(workloadMeasure, workloadTime > 1);
      const workloadFrequencyLabel = bookingUtils.getFrequencyLabel(workloadFrequency);

      const periodTime = _.get(booking, 'config.longitudinal_study.period.time');
      const periodFrequency = _.get(booking, 'config.longitudinal_study.period.frequency');
      const periodFrequencyLabel = bookingUtils.getFrequencyLabel(periodFrequency, periodTime > 1);

      if (!workloadTime || !periodTime) {
        renderAddRequiredInfoLink('study_duration', `/booking-setup/${booking._id}/project-setup/study-duration`);
      }
      return (
        <span>
          {workloadTime} {workloadMeasureLabel} per {workloadFrequencyLabel} to be done over {periodTime}{' '}
          {periodFrequencyLabel}
        </span>
      );
    }
    const session = utils.getLabelFromArray(
      bookingUtils.sessionDurations(props?.booking?.type ?? null, _.get(props.booking, 'config.online_task.type')),
      _.get(props, 'booking.config.session.duration'),
    );
    return session;
  };

  const renderAddRequiredInfoLink = (key: any, linkToAddValue: any, missingLabel?: any, linkState = null) => {
    let linkText = 'Add required info';
    if (missingLabel) linkText = missingLabel;

    return (
      <a
        className="missingField"
        onClick={() => {
          props.history.push({ pathname: linkToAddValue, linkState });
        }}
      >
        {linkText}
      </a>
    );
  };

  const renderFieldValue = (
    key: any,
    value: any,
    linkToAddValue: any,
    missingLabel: string | null = null,
    linkState: any = null,
  ) => {
    if (value) return value;
    return renderAddRequiredInfoLink(key, linkToAddValue, missingLabel, linkState);
  };

  const renderOrganiserInfo = (organiser: any) => {
    const organiserInfo = `${_.get(organiser, 'meta.identity.firstname')} ${_.get(organiser, 'meta.identity.lastname')} ${
      _.get(organiser, '_id') === clientDetails.details?._id! ? '(you)' : ''
    }`;
    if (_.get(bookingSteps, 'additional_info_contacts') === 'error') {
      return (
        <span className="reviewItemMissing">
          <span>{organiserInfo}</span>
          <a
            className="missingField"
            onClick={() => {
              props.history.push({
                pathname: `/booking-setup/${booking._id}/additional-info/contacts`,
              });
            }}
          >
            Add phone number
          </a>
        </span>
      );
    }
    return organiserInfo;
  };

  const renderDemographicFilters = (totalFilters: any) => {
    return (
      <span className="reviewItemMissing">
        {totalFilters > 0 && <span>{`${totalFilters} filter${totalFilters > 1 ? 's' : ''} applied`}</span>}
        {totalFilters === 0 && (
          <a
            className="missingField"
            onClick={() => {
              props.history.push({
                pathname: `/booking-setup/${booking._id}/audience/demographic-filters`,
              });
            }}
          >
            Add demographic filters (recommended)
          </a>
        )}
      </span>
    );
  };

  const renderScreeningQuestions = (totalQuestions: any) => {
    return (
      <span className="reviewItemMissing">
        {totalQuestions > 0 && <span>{`${totalQuestions} screening question${totalQuestions > 1 ? 's' : ''}`}</span>}
        {totalQuestions === 0 && (
          <a
            className="missingField"
            onClick={() => {
              props.history.push({
                pathname: `/booking-setup/${booking._id}/audience/custom-screener`,
              });
            }}
          >
            Add screening questions (recommended)
          </a>
        )}
      </span>
    );
  };

  const renderBackupSessions = () => {
    const timezone = momentTimezone(Date.now()).tz(_.get(booking, 'config.timezone'));
    const activeSessions =
      _.filter(_.get(booking, 'session', []), item => (item?.start ?? 0) > parseInt(timezone.format('x'))) || [];
    const backupSessions = _.size(activeSessions) - (booking.total_participants ?? 0);
    let backupSessionTimes = '0 backup session times';
    if (backupSessions > 0) {
      backupSessionTimes = `${backupSessions} backup session${backupSessions > 1 ? 's' : ''}`;
    }
    const extraBackupSessions = bookingUtils.isFocusGroup(booking)
      ? 0
      : Math.floor((booking.total_participants ?? 2) / 2) || 0;
    let backupSessionsLabel = 'Add backup sessions (recommended)';
    if (backupSessions > 0) {
      backupSessionsLabel = `Add ${extraBackupSessions - backupSessions} more backup session${extraBackupSessions - backupSessions > 1 ? 's' : ''} (recommended)`;
    }
    if (_.get(bookingSteps, 'session_times_backup') === 'warning' || backupSessions === 0) {
      return (
        <span className="reviewItemMissing">
          {backupSessions > 0 && <span>{backupSessionTimes}</span>}
          <a
            className="missingField"
            onClick={() => {
              props.history.push({
                pathname: `/booking-setup/${booking._id}/session-times/calendar`,
              });
            }}
          >
            {backupSessionsLabel}
          </a>
        </span>
      );
    }
    return backupSessionTimes;
  };

  const renderInterviewLocation = () => {
    const locationData = props.booking?.config?.location;
    if (locationData?.street1) {
      return <span>{`${locationData?.street1}, ${locationData?.city}, ${locationData?.postal_code || ''}`}</span>;
    }
    if (locationData?.name) {
      let postal_code = locationData.postal_code;
      if (postal_code) {
        postal_code = `, ${postal_code}`;
      }
      return <span>{`${locationData?.name}${postal_code || ''}`}</span>;
    }
    return renderAddRequiredInfoLink('session_location', `/booking-setup/${booking._id}/audience/session-location`);
  };

  const renderParticipantsLocation = () => {
    const country = booking.config?.location?.country;
    const states = booking.config?.criteria?.locations?.states ?? [];
    const bounds = booking.config?.criteria?.locations?.bounds ?? [];
    const countries = booking.config?.criteria?.locations?.countries ?? [];

    const allLocations = [...states, ...bounds, ...countries];
    const countryData = _.find(countryCodeData, {
      region: country,
    }) as (typeof countryCodeData)[number];

    if (allLocations.length === 0 && !country) {
      return renderAddRequiredInfoLink(
        'participant_location',
        `/booking-setup/${booking._id}/audience/participant-locations`,
      );
    }

    if (allLocations.length === 0) {
      return <span>Anywhere in {typeof countryData === 'number' ? countryData : countryData?.name}</span>;
    }

    return (
      <ul className="ml-4 list-disc">
        {allLocations.map(l => (
          <li key={l?.formatted_address}>{l?.formatted_address}</li>
        ))}
      </ul>
    );
  };

  const renderIncentiveLevel = () => {
    return `${bookingUtils.isBookingForProfessionals(booking) ? 'Premium' : 'Standard'} incentive amount`;
  };

  const renderStartEndSessionTimes = () => {
    // If its 1 on 1 interview it should list the start and end day plus the number of slots created -> 9th - 12th (10 slots created)
    // If its focus group it should show the start and end time only -> 3pm - 4:30pm
    // 1 on 1 interview
    const orderedArray = utils.orderArrayByDate(booking.session, 'start');
    if (bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) {
      if (_.size(orderedArray) === 0) {
        let predefinedCompletedBy;
        if (bookingUtils.isOnlineTask(booking)) {
          predefinedCompletedBy = momentTimezone(momentTimezone().add(5, 'days')).endOf('day');
        } else if (bookingUtils.isLongitudinal(booking)) {
          const daysPeriod = bookingUtils.getLongitudinalStudyPeriod(booking) || 5;
          predefinedCompletedBy = momentTimezone(momentTimezone().add(daysPeriod, 'days')).endOf('day');
        }
        const defaultSession = {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          start: predefinedCompletedBy.valueOf(),
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          end: predefinedCompletedBy.valueOf(),
        };
        const completeByDate = momentTimezone(_.get(defaultSession, 'start')).tz(_.get(booking, 'config.timezone'));
        return `${completeByDate.format('dddd, Do MMMM')} (${moment(completeByDate).fromNow()})`;
      }
      const completeByDate = momentTimezone(_.get(orderedArray, '[0].start')).tz(_.get(booking, 'config.timezone'));
      return `${completeByDate.format('dddd, Do MMMM')} (${moment(completeByDate).fromNow()})`;
    }
    if (bookingUtils.isInPerson(booking) || bookingUtils.isRemote(booking)) {
      const bookingSessions = _.get(booking, 'session') || [];
      const objectToDisplay = utils.groupByDate(orderedArray, 'start', _.get(booking, 'config.timezone')) || [];
      const timezone = momentTimezone(Date.now()).tz(booking.config?.timezone ?? 'Australia/Sydney');
      const pastSessions = _.filter(bookingSessions, item => (item?.end ?? 0) < parseInt(timezone.format('x'), 10));
      let missingSessions = 0;
      switch (booking.config?.session?.type) {
        case 1: // 1 on 1 interview
          missingSessions = (booking?.total_participants ?? 0) - (_.size(bookingSessions) - _.size(pastSessions));
          break;
        case 2: // Focus Group
          missingSessions = 1 - (_.size(bookingSessions) - _.size(pastSessions));
          break;
        default:
          missingSessions = 0;
      }
      return (
        <>
          {_.map(objectToDisplay, (item: any, key: any) => {
            if (_.get(item, '[0]end') < momentTimezone(Date.now()).tz(_.get(booking, 'config.timezone'))) {
              return (
                <span key={key}>
                  <s>{`${key} - (${item.length} ${item.length > 1 ? 'sessions' : 'session'})`}</s>
                  <br />
                </span>
              );
            }
            return (
              <span key={key}>
                {`${key} - (${item.length} ${item.length > 1 ? 'sessions' : 'session'})`}
                <br />
              </span>
            );
          })}
          {missingSessions > 0 && (
            <a
              className="missingField"
              onClick={() => {
                props.history.push({
                  pathname: `/booking-setup/${booking._id}/session-times/calendar`,
                });
              }}
            >
              {_.size(pastSessions) > 0 &&
                `${_.size(pastSessions)} of your session slots ${_.size(pastSessions) > 1 ? 'are now' : 'is'} in the past, please move ${
                  _.size(pastSessions) > 1 ? 'them' : 'it'
                } or add some more`}
              {_.size(pastSessions) === 0 &&
                `Add ${missingSessions} more session slot${missingSessions > 1 ? 's' : ''}`}
            </a>
          )}
        </>
      );
    }
  };

  const renderDeviceFeaturesRequired = () => {
    const requiredDevices = _.get(booking, 'config.online_task.required_device');
    const requiresDesktop = _.includes(requiredDevices, 1);
    const requiresMobile = _.includes(requiredDevices, 2);

    const requiredFeatures = _.get(booking, 'config.online_task.required_features');
    const requiresCamera = _.includes(requiredFeatures, 1);
    const requiresMicrophone = _.includes(requiredFeatures, 2);

    if (requiresDesktop) {
      if (requiresCamera && !requiresMicrophone) return 'Desktop with camera required';
      if (!requiresCamera && requiresMicrophone) return 'Desktop with mic required';
      if (requiresCamera && requiresMicrophone) return 'Desktop with camera and mic required';
      return 'Desktop required';
    }
    if (requiresMobile) {
      if (requiresCamera && !requiresMicrophone) return 'Mobile with camera required';
      if (!requiresCamera && requiresMicrophone) return 'Mobile with mic required';
      if (requiresCamera && requiresMicrophone) return 'Mobile with camera and mic required';
      return 'Mobile required';
    }

    if (requiresCamera && !requiresMicrophone) return 'Camera required';
    if (!requiresCamera && requiresMicrophone) return 'Microphone required';
    if (requiresCamera && requiresMicrophone) return 'Camera and mic required';
    return 'No device is required';
  };

  const renderResearchTypeTool = () => {
    const sessionType = _.get(booking, 'config.session.type', 1);
    const remoteTool =
      utils.getLabelFromArray(bookingUtils.bookingRemoteVideoCallToolOptions(), _.get(booking, 'config.remote.tool')) ||
      '';
    const surveyTool =
      utils.getLabelFromArray(bookingUtils.bookingSurveyToolOptions(), _.get(booking, 'config.online_task.tool')) || '';
    const onlineTaskTool =
      utils.getLabelFromArray(bookingUtils.bookingOnlineTaskToolOptions(), _.get(booking, 'config.online_task.tool')) ||
      '';

    if (bookingUtils.isRemote(booking)) {
      return renderReviewCardItem({
        key: 'research_method',
        icon: 'cameraIcon.svg',
        iconClass: 'cameraIcon',
        title: 'Research method',
        value: `Video call, ${sessionType === 2 ? 'group' : 'individual'} sessions ${remoteTool !== 'Other' ? `via ${remoteTool.replace(' (Recommended)', '')}` : ''}`,
        isMissing: _.get(bookingSteps, 'project_setup_research_type') === 'error',
        editLink: `/booking-setup/${booking._id}/project-setup/research-type`,
      });
    }
    if (bookingUtils.isInPerson(booking)) {
      return renderReviewCardItem({
        key: 'research_method',
        icon: 'personIcon.svg',
        iconClass: 'personIcon',
        title: 'Research method',
        value: `In person, ${sessionType === 2 ? 'group' : 'individual'} interviews`,
        isMissing: _.get(bookingSteps, 'project_setup_research_type') === 'error',
        editLink: `/booking-setup/${booking._id}/project-setup/research-type`,
      });
    }
    if (bookingUtils.isLongitudinal(booking)) {
      return renderReviewCardItem({
        key: 'research_method',
        icon: 'notesIcon.svg',
        iconClass: 'notesIcon',
        title: 'Research method',
        value: 'Longitudinal study',
        isMissing: _.get(bookingSteps, 'project_setup_research_type') === 'error',
        editLink: `/booking-setup/${booking._id}/project-setup/research-type`,
      });
    }
    if (bookingUtils.isSurvey(booking)) {
      return renderReviewCardItem({
        key: 'research_method',
        icon: 'notesIcon.svg',
        iconClass: 'notesIcon',
        title: 'Research method',
        value: `Online survey ${surveyTool !== 'Other' ? `using ${surveyTool}` : ''}`,
        isMissing: _.get(bookingSteps, 'project_setup_research_type') === 'error',
        editLink: `/booking-setup/${booking._id}/project-setup/research-type`,
      });
    }
    if (bookingUtils.isOnlineTask(booking)) {
      return renderReviewCardItem({
        key: 'research_method',
        icon: 'topicIcon.svg',
        iconClass: 'topicIcon',
        title: 'Research method',
        value: bookingUtils.isAiModeration(booking)
          ? 'AI Moderated'
          : `Online task ${onlineTaskTool !== 'Other' ? `using ${onlineTaskTool}` : ''}`,
        isMissing: _.get(bookingSteps, 'project_setup_research_type') === 'error',
        editLink: `/booking-setup/${booking._id}/project-setup/research-type`,
      });
    }
  };

  const renderProjectSetupReviewCard = () => {
    let bookingDurationLabel = 'Session length';
    if (bookingUtils.isOnlineTask(booking)) bookingDurationLabel = 'Task duration';
    if (bookingUtils.isLongitudinal(booking)) bookingDurationLabel = 'Study duration';
    const doesHaveError = _.get(bookingSteps, 'project_setup') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Study Setup</p>
        {renderReviewCardItem({
          key: 'project_title',
          icon: 'tagIcon.svg',
          iconClass: 'tagIcon',
          title: 'Study title (Only visible to you)',
          value: renderFieldValue(
            'project_title',
            _.get(booking, 'name'),
            `/booking-setup/${booking._id}/project-setup/project-title`,
            'Add project title',
          ),
          isMissing: _.get(bookingSteps, 'project_setup_project_title') === 'error',
          editLink: `/booking-setup/${booking._id}/project-setup/project-title`,
        })}
        {!bookingUtils.isInPerson(booking) &&
          renderReviewCardItem({
            key: 'device_requirements',
            icon: 'desktopMobileIcon.svg',
            iconClass: 'desktopMobileIcon',
            title: 'Device requirements',
            value: renderDeviceFeaturesRequired(),
            isMissing: _.get(bookingSteps, 'project_setup_devices') === 'error',
            editLink: `/booking-setup/${booking._id}/project-setup/devices`,
          })}
        {renderResearchTypeTool()}
        {renderReviewCardItem({
          key: 'quota',
          icon: 'quotaIcon.svg',
          iconClass: 'quotaIcon',
          title: 'Quota',
          value: renderFieldValue(
            'quota',
            booking.total_participants
              ? `${booking.total_participants} participant${booking.total_participants > 1 ? 's' : ''}`
              : null,
            `/booking-setup/${booking._id}/project-setup/quota`,
          ),
          isMissing: _.get(bookingSteps, 'project_setup_quota') === 'error',
          editLink: `/booking-setup/${booking._id}/project-setup/quota`,
        })}
        {!bookingUtils.isAiModeration(booking) &&
          renderReviewCardItem({
            key: bookingDurationLabel.replaceAll(' ', '_').toLowerCase(),
            icon: 'calendarTimeIcon.svg',
            iconClass: 'calendarTimeIcon',
            title: bookingDurationLabel,
            value: renderTaskSessionDuration(),
            isMissing: _.get(bookingSteps, 'project_setup_session_duration') === 'error',
            editLink: `/booking-setup/${booking._id}/project-setup/${bookingUtils.isLongitudinal(booking) ? 'study' : 'session'}-duration`,
          })}
        {renderReviewCardItem({
          key: 'participant_selection',
          icon: 'addPersonIcon.svg',
          iconClass: 'addPersonIcon',
          title: 'Participant selection',
          value: `${bookingUtils.isBookingAutomated(booking) ? 'Automated' : 'Hand picking'} mode`,
          isMissing: _.get(bookingSteps, 'project_setup_participant_selection') === 'error',
          editLink: `/booking-setup/${booking._id}/project-setup/participant-selection`,
        })}
      </div>
    );
  };

  const renderAudienceReviewCard = () => {
    const allFilters = bookingUtils.demographicFilterOptions();
    let bookingTypeFilters = allFilters;
    if (!bookingUtils.isBookingForProfessionals(booking)) {
      bookingTypeFilters = _.filter(allFilters, (item: any) => !_.has(item, 'type'));
    }
    const filtersSaved = _.filter(bookingTypeFilters, (item: any) => {
      if (_.has(booking, `config.criteria[${item.id}]`) && _.get(booking, `config.criteria[${item.id}]`) !== null) {
        return item;
      }
    });
    const doesHaveError = _.get(bookingSteps, 'audience') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Audience</p>
        {renderReviewCardItem({
          key: 'panel',
          icon: 'askableIcon.svg',
          iconClass: 'askableIcon',
          title: 'Panel',
          value: `${bookingUtils.isBYO(booking) ? 'Recruit your own participants' : 'Askable panel'}`,
          isMissing: _.get(bookingSteps, 'audience_panel') === 'error',
          editLink: `/booking-setup/${booking._id}/audience/panel`,
        })}
        {renderReviewCardItem({
          key: 'incentive_level',
          icon: 'cashIcon.svg',
          iconClass: 'cashIcon',
          title: 'Incentive Level',
          value: renderIncentiveLevel(),
          isMissing: _.get(bookingSteps, 'audience_incentives') === 'error',
          editLink: `/booking-setup/${booking._id}/audience/incentives`,
        })}
        {!bookingUtils.isInPerson(booking) &&
          renderReviewCardItem({
            key: 'participant_location',
            icon: 'earthIcon.svg',
            iconClass: 'earthIcon',
            title: 'Participant location',
            value: renderParticipantsLocation(),
            isMissing: _.get(bookingSteps, 'audience_participant_locations') === 'error',
            editLink: `/booking-setup/${booking._id}/audience/participant-locations`,
          })}
        {_.size(filtersSaved) > 0 &&
          renderReviewCardItem({
            key: 'demographic_filters',
            icon: 'personListIcon.svg',
            iconClass: 'personListIcon',
            title: 'Demographic filters',
            value: renderDemographicFilters(_.size(filtersSaved)),
            isMissing: _.get(bookingSteps, 'audience_demographic_filters') === 'error',
            editLink: `/booking-setup/${booking._id}/audience/demographic-filters`,
          })}
        {renderReviewCardItem({
          key: 'screening_questions',
          icon: 'listIcon.svg',
          iconClass: 'listIcon',
          title: 'Custom screening questions',
          value: renderScreeningQuestions(_.size(_.get(booking, 'config.question', []))),
          isMissing: _.get(bookingSteps, 'audience_custom_screener') === 'error',
          editLink: `/booking-setup/${booking._id}/audience/custom-screener`,
        })}
      </div>
    );
  };

  const renderAiModeratedReviewCard = () => {
    const doesHaveError = _.get(bookingSteps, 'ai_moderated') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">AI Researcher Settings</p>
        {renderReviewCardItem({
          key: 'research_objectives',
          icon: 'researchObjectiveIcon.svg',
          title: 'Research objectives',
          value: renderFieldValue(
            'ai_moderated_research_objective',
            _.get(booking, 'config.ai_moderated.prompt.overview'),
            `/booking-setup/${booking._id}/ai-moderated/research-objective`,
            'Add AI research objectives',
          ), // Remove this test after participants app 2 is live
          isMissing:
            _.get(bookingSteps, 'ai_moderated_research_objective') === 'error' &&
            !_.get(booking, 'config.ai_moderated.prompt.overview'),
          editLink: `/booking-setup/${booking._id}/ai-moderated/research-objective`,
        })}
        {renderReviewCardItem({
          key: 'helpful_context',
          icon: 'helpfulContextIcon.svg',
          title: 'Helpful context',
          value: renderFieldValue(
            'ai_moderated_helpful_context',
            _.get(booking, 'config.ai_moderated.prompt.context'),
            `/booking-setup/${booking._id}/ai-moderated/research-objective`,
            'Add AI Helpful context',
          ), // Remove this test after participants app 2 is live
          isMissing:
            _.get(bookingSteps, 'ai_moderated_research_objective') === 'error' &&
            !_.get(booking, 'config.ai_moderated.prompt.context'),
          editLink: `/booking-setup/${booking._id}/ai-moderated/research-objective`,
        })}
        {renderReviewCardItem({
          key: 'welcome_title',
          icon: 'welcomeTitleIcon.svg',
          title: 'Welcome title',
          value: renderFieldValue(
            'ai_moderated_helpful_context',
            _.get(booking, 'config.ai_moderated.information.welcome_title'),
            `/booking-setup/${booking._id}/ai-moderated/welcome-end-screen`,
            'Add welcome title',
          ), // Remove this test after participants app 2 is live
          isMissing:
            _.get(bookingSteps, 'ai_moderated_welcome_end_screen_title') === 'error' &&
            !_.get(booking, 'config.ai_moderated.information.welcome_title'),
          editLink: `/booking-setup/${booking._id}/ai-moderated/welcome-end-screen`,
        })}
        {renderReviewCardItem({
          key: 'welcome_description',
          icon: 'tripleParagraphIcon.svg',
          title: 'Welcome description',
          value: renderFieldValue(
            'ai_moderated_description',
            _.get(booking, 'config.ai_moderated.information.welcome_body'),
            `/booking-setup/${booking._id}/ai-moderated/welcome-end-screen`,
            'Add welcome description',
          ), // Remove this test after participants app 2 is live
          isMissing:
            _.get(bookingSteps, 'ai_moderated_welcome_end_screen_description') === 'error' &&
            !_.get(booking, 'config.ai_moderated.information.welcome_body'),
          editLink: `/booking-setup/${booking._id}/ai-moderated/welcome-end-screen`,
        })}
      </div>
    );
  };

  const renderAdditionalInfoReviewCard = () => {
    const organiser = _.get(booking.config, 'contact.UserOrganiser');
    const facilitator = _.get(booking.config, 'contact.UserFacilitator');

    const inContextResearch = bookingUtils.isInContextResearch(booking);
    const inContextType = _.find(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      bookingUtils.bookingInContextResearchTypes(booking),
      (item: any) => item.value === _.get(booking, 'config.in_context.location_type') > 0,
    );
    const locationType = _.find(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      bookingUtils.bookingInContextResearchLocationTypes(booking),
      (item: any) => item.value === _.get(booking, 'config.in_context.location_type'),
    );
    const travelTimeLimit = _.find(
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      bookingUtils.bookingInContextResearchTravelTimeTypes(booking),
      (item: any) => item.value === _.get(booking, 'config.in_context.travel_time_limit'),
    );

    const doesHaveError = _.get(bookingSteps, 'additional_info') === 'error';

    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Additional Info</p>
        {bookingUtils.isRemote(booking) &&
          renderReviewCardItem({
            key: 'your_timezone',
            icon: 'clockIcon.svg',
            iconClass: 'clockIcon',
            title: 'Your timezone',
            value: location.getTimezoneText(booking.config?.timezone),
            isMissing: _.get(bookingSteps, 'additional_info_your_timezone') === 'error',
            editLink: `/booking-setup/${booking._id}/additional-info/your-timezone`,
          })}
        {bookingUtils.isInPerson(booking) &&
          renderReviewCardItem({
            key: 'research_location',
            icon: 'businessIcon.svg',
            iconClass: 'businessIcon',
            title: 'Research location',
            value: _.get(inContextType, 'label'),
            isMissing: false,
            editLink: `/booking-setup/${booking._id}/additional-info/session-location`,
          })}
        {bookingUtils.isInPerson(booking) && inContextResearch && (
          <>
            {renderReviewCardItem({
              key: 'location_type',
              icon: 'mapIcon.svg',
              iconClass: 'mapIcon',
              title: 'Session location',
              value: _.get(locationType, 'label'),
              isMissing: false,
              editLink: `/booking-setup/${booking._id}/additional-info/session-location`,
            })}
            {renderReviewCardItem({
              key: 'travel_time_limit',
              icon: 'clockIcon.svg',
              iconClass: 'clockIcon',
              title: 'Travel time',
              value: _.get(travelTimeLimit, 'label'),
              isMissing: false,
              editLink: `/booking-setup/${booking._id}/additional-info/session-location`,
            })}
            {renderReviewCardItem({
              key: 'session_location',
              icon: 'locationIcon.svg',
              iconClass: 'locationIcon',
              title: 'From',
              value: renderInterviewLocation(),
              isMissing: _.get(bookingSteps, 'additional_info_session_location') === 'error',
              editLink: `/booking-setup/${booking._id}/additional-info/session-location`,
            })}
          </>
        )}
        {bookingUtils.isInPerson(booking) &&
          !inContextResearch &&
          renderReviewCardItem({
            key: 'session_location',
            icon: 'locationIcon.svg',
            iconClass: 'locationIcon',
            title: 'Session location',
            value: renderInterviewLocation(),
            isMissing: _.get(bookingSteps, 'additional_info_session_location') === 'error',
            editLink: `/booking-setup/${booking._id}/additional-info/session-location`,
          })}
        {(bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) && (
          <>
            {renderReviewCardItem({
              key: 'closing_date',
              icon: 'calendarOkIcon.svg',
              iconClass: 'calendarOkIcon',
              title: 'Closing date',
              value: renderStartEndSessionTimes(),
              isMissing: _.get(bookingSteps, 'additional_info_closing_date') === 'error',
              editLink: `/booking-setup/${booking._id}/additional-info/closing-date`,
            })}
          </>
        )}
        {renderReviewCardItem({
          key: 'short_description',
          icon: 'shortDescriptionIcon.svg',
          iconClass: 'shortDescriptionIcon',
          title: <span>Short description of who you&apos;re targeting</span>,
          // value: renderSeeOnPreview('/link'), // Uncomment this test after participants app 2 is live
          value: renderFieldValue(
            'short_description',
            _.get(booking, 'config.information.short_description'),
            `/booking-setup/${booking._id}/additional-info/description`,
            'Add short description',
          ), // Remove this test after participants app 2 is live
          isMissing: _.get(bookingSteps, 'additional_info_short_description') === 'error',
          editLink: `/booking-setup/${booking._id}/additional-info/description`,
        })}
        {renderReviewCardItem({
          key: 'full_description',
          icon: 'descriptionIcon.svg',
          iconClass: 'descriptionIcon',
          title: 'Full description',
          // value: renderSeeOnPreview('/link'), // Uncomment this test after participants app 2 is live
          value: renderFieldValue(
            'full_description',
            _.get(booking, 'config.information.brief_summary'),
            `/booking-setup/${booking._id}/additional-info/description`,
            'Add full description',
            { fieldFocus: 'brief_summary' },
          ), // Remove this test after participants app 2 is live
          isMissing: _.get(bookingSteps, 'additional_info_description') === 'error',
          editLink: `/booking-setup/${booking._id}/additional-info/description`,
          editLinkState: { fieldFocus: 'brief_summary' },
          markdown: _.get(booking, 'config.information.brief_summary'),
        })}
        {!bookingUtils.isOnlineTask(booking) &&
          _.get(booking, 'config.information.special_instructions') &&
          renderReviewCardItem({
            key: 'additional_instructions',
            icon: 'listOkIcon.svg',
            iconClass: 'listOkIcon',
            title: 'Info for confirmed participants',
            // value: renderSeeOnPreview('/link'), // Uncomment this test after participants app 2 is live
            value: _.get(booking, 'config.information.special_instructions'), // Remove this test after participants app 2 is live
            isMissing: _.get(bookingSteps, 'additional_info_additional_instructions') === 'error',
            editLink: `/booking-setup/${booking._id}/additional-info/additional-instructions`,
            markdown: _.get(booking, 'config.information.special_instructions'),
          })}
        {renderReviewCardItem({
          key: 'project_organiser',
          icon: 'personEmailIcon.svg',
          iconClass: 'personEmailIcon',
          title: 'Study organiser',
          value: renderOrganiserInfo(organiser),
          isMissing: _.get(bookingSteps, 'additional_info_contacts') === 'error',
          editLink: `/booking-setup/${booking._id}/additional-info/contacts`,
        })}
        {!(bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) &&
          renderReviewCardItem({
            key: 'facilitator',
            icon: 'personPhoneIcon.svg',
            iconClass: 'personPhoneIcon',
            title: 'Facilitator / Interviewer',
            value: `${_.get(facilitator, 'meta.identity.firstname')} ${_.get(facilitator, 'meta.identity.lastname')} ${
              _.get(facilitator, '_id') === clientDetails.details?._id! ? '(you)' : ''
            }`,
            editLink: `/booking-setup/${booking._id}/additional-info/contacts`,
          })}
        {renderReviewCardItem({
          key: 'legal_privacy',
          icon: 'textBoxCheckOutline.svg',
          iconClass: 'clockIcon',
          title: 'Legal & privacy',
          value: renderFieldValue(
            'participant_agreement',
            (() => {
              if (_.get(bookingSteps, 'additional_info_legal_privacy') === 'error') return null;
              switch (_.get(booking, 'config.participant_agreement.type')) {
                case BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.ASKABLE_TERMS:
                  return 'Use Askable’s standard terms';
                case BOOKING_CONFIG_PARTICIPANT_AGREEMENT_TYPE.CUSTOM_AGREEMENT:
                  return 'Use a custom agreement';
                default:
                  return null;
              }
            })(),
            `/booking-setup/${booking._id}/additional-info/legal-privacy`,
            'Add recipient email(s)',
          ),
          isMissing: _.get(bookingSteps, 'additional_info_legal_privacy') === 'error',
          editLink: `/booking-setup/${booking._id}/additional-info/legal-privacy`,
        })}
      </div>
    );
  };

  const renderSessionTimesReviewCard = () => {
    const doesHaveError = _.get(bookingSteps, 'session_times') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">Session Times</p>
        {renderReviewCardItem({
          key: 'session_dates',
          icon: 'calendarDatesIcon.svg',
          iconClass: 'calendarDatesIcon',
          title: 'Session Dates',
          value: renderStartEndSessionTimes(),
          isMissing: _.get(bookingSteps, 'session_times_calendar') === 'error',
          editLink: `/booking-setup/${booking._id}/session-times/calendar`,
        })}
        {renderReviewCardItem({
          key: 'backup_sessions',
          icon: 'calendarReloadIcon.svg',
          iconClass: 'calendarReloadIcon',
          title: 'Backup Sessions',
          value: renderBackupSessions(),
          isWarning: _.get(bookingSteps, 'session_times_backup') === 'warning',
          editLink: `/booking-setup/${booking._id}/session-times/calendar`,
        })}
      </div>
    );
  };

  const renderLinkToTaskReviewCard = () => {
    const hasLink = _.size(_.get(booking, 'config.online_task.links')) > 0;
    let linksToDisplay = _.get(booking, 'config.online_task.links');
    if (bookingUtils.isBookingAutomated(booking)) {
      linksToDisplay = _.slice(linksToDisplay, 0, 1);
    }
    const doesHaveError = _.get(bookingSteps, 'link_to_task') === 'error';
    return (
      <div
        aria-invalid={doesHaveError ? 'true' : 'false'}
        className={`reviewCard ${doesHaveError ? 'missingFields' : ''}`}
      >
        <p className="cardTitle">{`Link to ${bookingUtils.isSurvey(booking) ? 'Survey' : 'Task'}`}</p>
        {hasLink &&
          _.map(linksToDisplay, (link: any) => {
            return renderReviewCardItem({
              key: link.name.replaceAll(' ', '_').toLowerCase(),
              icon: 'linkToTaskIcon.svg',
              iconClass: 'linkToTaskIcon',
              title: link.name,
              value: link.url,
              isMissing: _.get(bookingSteps, 'link_to_task_online_task') === 'error',
              type: 'link',
              editLink: `/booking-setup/${booking._id}/link-to-task/${bookingUtils.isSurvey(booking) ? 'survey' : 'online-task'}`,
            });
          })}
        {!hasLink &&
          renderReviewCardItem({
            key: `link_to_${bookingUtils.isSurvey(booking) ? 'survey' : 'task'}`,
            icon: 'linkToTaskIcon.svg',
            iconClass: 'linkToTaskIcon',
            title: `URL to ${bookingUtils.isSurvey(booking) ? 'survey' : 'task'}`,
            value: renderFieldValue(
              `link_to_${bookingUtils.isSurvey(booking) ? 'survey' : 'task'}`,
              null,
              `/booking-setup/${booking._id}/link-to-task/${bookingUtils.isSurvey(booking) ? 'survey' : 'online-task'}`,
              `Add link to ${bookingUtils.isSurvey(booking) ? 'survey' : 'online task'}`,
            ),
            isMissing: _.get(bookingSteps, 'link_to_task') === 'error',
            editLink: `/booking-setup/${booking._id}/link-to-task/${bookingUtils.isSurvey(booking) ? 'survey' : 'online-task'}`,
          })}
      </div>
    );
  };

  let allowedCreditsDoesNotMatch = false;
  let disableButton = false;
  if (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) {
    disableButton =
      _.get(bookingSteps, 'project_setup') === 'error' ||
      _.get(bookingSteps, 'audience') === 'error' ||
      _.get(bookingSteps, 'additional_info') === 'error' ||
      _.get(bookingSteps, 'session_times') === 'error';
  } else if (bookingUtils.isAiModeration(booking)) {
    disableButton =
      _.get(bookingSteps, 'ai_moderated') === 'error' ||
      _.get(bookingSteps, 'ai_moderated_research_objective') === 'error' ||
      _.get(bookingSteps, 'ai_moderated_welcome_end_screen') === 'error';
  } else if (bookingUtils.isOnlineTask(booking)) {
    disableButton =
      _.get(bookingSteps, 'project_setup') === 'error' ||
      _.get(bookingSteps, 'audience') === 'error' ||
      _.get(bookingSteps, 'additional_info') === 'error' ||
      _.get(bookingSteps, 'link_to_task') === 'error';
  } else if (bookingUtils.isLongitudinal(booking)) {
    disableButton =
      _.get(bookingSteps, 'project_setup') === 'error' ||
      _.get(bookingSteps, 'audience') === 'error' ||
      _.get(bookingSteps, 'additional_info') === 'error';
  }

  if (bookingUtils.isAskablePlus(booking) && _.get(booking, 'config.project.allowed_credits')) {
    if (creditsToBeUsed !== _.get(booking, 'config.project.allowed_credits')) {
      allowedCreditsDoesNotMatch = true;
      disableButton = true;
    }
  }

  const getIFrameSource = () => {
    return `${globalVariables.getEnvironmentVariables().participantsAppURI}/booking/preview/${booking._id}${iframePath}`;
  };

  return (
    <div className="createBookingContent reviewSubmitPage">
      <div className="reviewSubmitContainer">
        <div className="reviewSubmitContent">
          <h1 id="__pageTitle" className="title">
            Study Summary
          </h1>
          {renderCardPrice()}
          {renderProjectSetupReviewCard()}
          {renderAudienceReviewCard()}
          {bookingUtils.isOnlineTask(booking) && !bookingUtils.isAiModeration(booking) && renderLinkToTaskReviewCard()}
          {bookingUtils.isAiModeration(booking) && renderAiModeratedReviewCard()}
          {renderAdditionalInfoReviewCard()}
          {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && renderSessionTimesReviewCard()}
          <div className="checkoutButtonContainer">
            <Button
              label={bookingUtils.isAskablePlus(booking) ? 'Submit Study' : 'Go to checkout'}
              className="button checkoutButton"
              onClick={onClickFinish}
              disabled={disableButton || false}
            />
          </div>

          {allowedCreditsDoesNotMatch ? (
            <div className="reviewSubmitAdditionalInfoContainer">
              <div className="additionalInfoContainer">
                <img className="additionalInfoIconImage" src="/icons/helpIconOutlined.svg" alt="illustration" />
                <div className="additionalInfoContent">
                  <p className="additionalInfoTitle">Why can’t I check out?</p>
                  <p className="additionalInfoText">
                    It seems the final credit amount doesn’t match the allocated credit amount. Send us a message and
                    we’ll see what we can do to help.
                  </p>
                  <span
                    className="additionalInfoLink"
                    onClick={() => {
                      intercom.showNewMessages(
                        `Hi! I can't check out my study because the final credit amount doesn’t match the allocated credit amount.\n\nStudy: ${_.get(
                          booking,
                          '_id',
                        )})\n\nComments: `,
                      );
                    }}
                  >
                    Chat to us <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="reviewSubmitAdditionalInfoContainer">
              <div className="additionalInfoContainer">
                <img className="additionalInfoIconImage" src="/icons/helpIconOutlined.svg" alt="illustration" />
                <div className="additionalInfoContent">
                  <p className="additionalInfoTitle">What happens next?</p>
                  <p className="additionalInfoText">
                    After checkout, we&apos;ll review your submission within 2 business hours. You&apos;ll get an email
                    when it&apos;s approved and recruitment will begin.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Remove this block  after participants app 2 is live */}
      <div className="appPreviewContainer">
        <div className="headerContainer">
          <h3>This is what participants will see:</h3>
          <div className="links">
            <a
              onClick={() => {
                setIframePath('');
              }}
              className={`${iframePath === '' ? 'selected' : ''}`}
            >
              {bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)
                ? 'Opportunity Information'
                : 'Session Information'}
            </a>
            {booking.config?.question && booking.config?.question.length > 0 && (
              <a
                onClick={() => {
                  setIframePath('/apply/screener');
                }}
                className={`${iframePath === '/apply/screener' ? 'selected' : ''}`}
              >
                Screener
              </a>
            )}
            <a
              onClick={() => {
                setIframePath('?registered');
              }}
              className={`${iframePath === '?registered' ? 'selected' : ''}`}
            >
              {bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)
                ? 'Opportunity in progress'
                : 'Session Confirmation'}
            </a>
            {}
            <a
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
              href={getIFrameSource(booking)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLink className="h-4 w-4" />
            </a>
          </div>
        </div>
        <div className="app-content-container">
          <div className="phone-container" style={{ backgroundImage: `url(${iPhoneFrame})` }}>
            <div className="screen">
              <iframe
                title={`Study ${booking.name}`}
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                src={getIFrameSource(booking)}
                className="iframeClass"
              />
            </div>
          </div>
          <div className="disclaimer">
            <p>* Actual amount may vary</p>
          </div>
        </div>
      </div>
      <ModalCompleteOrder
        booking={booking}
        open={openCompleteOrderModal}
        onClose={() => setOpenCompleteOrderModal(false)}
        updateBooking={props.updateBooking}
        createSession={props.createSession}
        deleteSession={props.deleteSession}
      />
    </div>
  );
}

export default deprecatedWithRouter(ConfirmBooking);
