import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { UsersByTeamId } from 'containers/Studies/Recruit/data/UsersByTeamId.query';
import { useConnectedClient } from 'context/ConnectedClientContext';

import { SelectField } from './Fields/SelectField';

import type { User } from 'containers/Studies/Recruit/data/UsersByTeamId.query';

interface Option {
  label: string;
  value: string;
}

export const PrimaryContact = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();

  const teamId = details?.ConnectedTeam?._id;

  const [usersQuery] = useQuery({
    query: UsersByTeamId,
    pause: !teamId,
    variables: { id: teamId },
  });

  const options = useMemo(() => {
    const formatUserName = (user: User): string => {
      const firstName = user.User?.meta?.identity?.firstname ?? '';
      const lastName = user.User?.meta?.identity?.lastname ?? '';
      return `${firstName} ${lastName}`.trim() || t('global.unknownUser');
    };

    return (
      usersQuery.data?.teamById?.users?.map(
        (user: User | null): Option =>
          !user
            ? {
                label: t('global.unknownUser'),
                value: '',
              }
            : {
                label: formatUserName(user),
                value: user._id ?? '',
              },
      ) || []
    );
  }, [t, usersQuery.data?.teamById?.users]);

  if (usersQuery.error) {
    return (
      <div className="flex items-center justify-between gap-2 text-sm" role="alert">
        <div className="font-medium">{t('sections.studies.recruit.fields.primaryContactTitle')}</div>
        <div className="text-sm text-destructive" role="alert">
          {t('sections.studies.recruit.fields.primaryContactError')}
        </div>
      </div>
    );
  }

  return (
    <SelectField
      name="_primary_contact_id"
      label={t('sections.studies.recruit.fields.primaryContactTitle')}
      description={t('sections.studies.recruit.fields.primaryContactDescription')}
      infoPopover={{
        title: t('sections.studies.recruit.fields.primaryContactCalloutTitle'),
        description: t('sections.studies.recruit.fields.primaryContactCalloutDescription'),
      }}
      options={options}
      variant="inline"
      isDisabled={isDisabled || usersQuery.fetching}
    />
  );
};
