import { sort } from 'radash';
import { Fragment } from 'react';
import { RadioCard, RadioGroup, useRadioGroup, VStack } from 'ui';

import { utils } from 'lib/utils';

import { SessionTimeAlertComponent, SessionTimeSlot } from '../../BookingParticipantInviteTimeSlot';

import type { BookingSessionWithOtherTimes } from '../../BookingParticipantInviteTimeSlot';
import type { BookingContainerConfig } from 'containers/Booking/BookingContainer';
import type { FC } from 'react';

type PreferredTimeOptionsProps = {
  times?: BookingSessionWithOtherTimes[];
  onChange: () => void;
  value: string;
  name: string;
  sessionType?: BookingContainerConfig['sessionType'];
  pptTimezone: string;
  displayName: string;
};

export const TimeOptions: FC<PreferredTimeOptionsProps> = ({
  times,
  sessionType,
  onChange,
  name,
  pptTimezone,
  value,
  displayName,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  });

  const isPptInDifferentTimezone = pptTimezone !== utils.getCurrentTimezone();

  return (
    <RadioGroup {...getRootProps}>
      <VStack w="full">
        {sort(times ?? [], a => a.start!).map(({ start, _id, end, confirmedCount, invitedCount, selectedCount }) => {
          const radio = getRadioProps({ value: _id });

          return (
            <Fragment key={_id}>
              <RadioCard w="full" borderWidth="0" {...radio}>
                <SessionTimeSlot
                  selectedCount={selectedCount}
                  confirmedCount={confirmedCount}
                  invitedCount={invitedCount}
                  sessionStart={start!}
                  sessionEnd={end!}
                  isSelected={_id === value}
                  pptTimezone={pptTimezone}
                  isPptInDifferentTimezone={isPptInDifferentTimezone}
                  displayName={displayName}
                />
              </RadioCard>

              {value === _id && sessionType === 'individual' && (
                <SessionTimeAlertComponent
                  sessionStart={start!}
                  invitedCount={invitedCount}
                  confirmedCount={confirmedCount}
                  displayName={displayName}
                />
              )}
            </Fragment>
          );
        })}
      </VStack>
    </RadioGroup>
  );
};
