import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { Label } from '@askable/ui/components/ui/label';
import { toast } from '@askable/ui/components/ui/sonner';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import LocationAutocomplete from 'components/common/LocationAutocomplete/view';
import { BookingRecruitLocationType, type Location } from 'generated/graphql';
import { location } from 'lib/location';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

interface LocationSearchProps {
  isDisabled?: boolean;
}

/**
 * Location Hierarchy:
 *
 * Country
 *   └── State
 *        └── Bounds
 *
 * When adding a location, all contained locations are removed:
 * - Adding Country removes its States and Bounds
 * - Adding State removes its Bounds
 * - Adding Bounds removes containing Country/State
 */
const getLocationType = (location: Location): RecruitFormFields['locations'][0]['type'] => {
  if (location.google_location_types?.includes('administrative_area_level_1')) {
    return BookingRecruitLocationType.State;
  }

  if (location.google_location_types?.includes('country')) {
    return BookingRecruitLocationType.Country;
  }

  return BookingRecruitLocationType.Bounds;
};

const shouldRemoveLocation = (
  existingLocation: RecruitFormFields['locations'][0],
  newLocation: RecruitFormFields['locations'][0],
) => {
  if (newLocation.type === 'country') {
    return existingLocation.location.country === newLocation.location.country;
  }

  if (newLocation.type === 'state') {
    if (existingLocation.type === 'bounds') {
      return (
        existingLocation.location.state === newLocation.location.state &&
        existingLocation.location.country === newLocation.location.country
      );
    }

    if (existingLocation.type === 'country') {
      return existingLocation.location.country === newLocation.location.country;
    }
  }

  if (newLocation.type === 'bounds') {
    if (existingLocation.type === 'country') {
      return existingLocation.location.country === newLocation.location.country;
    }
    if (existingLocation.type === 'state') {
      return (
        existingLocation.location.state === newLocation.location.state &&
        existingLocation.location.country === newLocation.location.country
      );
    }
  }

  return false;
};

export const LocationSearch = ({ isDisabled }: LocationSearchProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'locations',
  });

  const formValues = useWatch({
    control,
    name: 'locations',
  });

  const onLocationAdded = async (value: Location) => {
    // TODO: loading state?
    try {
      await location.getTimezoneFromCoordinates(value.latitude, value.longitude);
      if (formValues.some(location => location.location.formatted_address === value.formatted_address)) {
        return;
      }

      const newLocation = {
        type: getLocationType(value),
        location: value as RecruitFormFields['locations'][0]['location'],
      };

      const filteredLocations = formValues.filter(
        existingLocation => !shouldRemoveLocation(existingLocation, newLocation),
      );

      field.onChange([...filteredLocations, newLocation]);
    } catch (err) {
      toast.error(t('sections.errorBoundary.timezoneFetchFailed'));
      console.error('Timezone fetch failed', err);
    }
  };

  const handleRemove = (formatted_address: Location['formatted_address']) => {
    field.onChange(formValues.filter(location => location.location.formatted_address !== formatted_address));
  };

  return (
    <FormItem className="flex w-full flex-col gap-2">
      <Label htmlFor={`input_recruit_location_autocomplete`}>
        {t('sections.studies.recruit.fields.locationsTitle')}
      </Label>

      <FormControl>
        <LocationAutocomplete
          id="recruit"
          canChangeCountry
          clearValue
          isDisabled={isDisabled}
          isMultiSelect={true}
          selectedOptions={formValues.map(location => location.location.formatted_address)}
          placeholder={`${t('sections.studies.recruit.fields.locationsPlaceholder')}...`}
          type="(regions)"
          onNewPlace={onLocationAdded}
          onRemoveOption={handleRemove}
        />
      </FormControl>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
    </FormItem>
  );
};
