import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const ParticipantsSegments = graphql(`
  query ParticipantsSegments($id: ID!) {
    segmentsByBookingId(bookingId: $id) {
      _id
      name
      method
      sort
      tableConfig {
        columns {
          visible
          column
        }
      }
      filters {
        _id
        field
        method
        values {
          label
          value_string
          value_numeric
        }
      }
    }
  }
`);

export type ParticipantsSegmentsType = NonNullable<
  NonNullable<ResultOf<typeof ParticipantsSegments>>['segmentsByBookingId']
>;
export type ParticipantsSegmentType = NonNullable<ParticipantsSegmentsType[number]>;
