import { AnchorButton } from '@askable/ui/components/ui/button';
import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { cn } from '@askable/ui/lib/utils';
import { Link, useLocation } from 'react-router-dom';

import type { SystemBlockType, TaskBlockType } from 'generated/graphql';

export interface NavItem {
  _id: string;
  title: string;
  type: SystemBlockType | TaskBlockType;
}

interface PreviewNavProps {
  navItems: NavItem[];
  activeBlockId: string | null;
}

export const PreviewNav = ({ navItems, activeBlockId }: PreviewNavProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const updatedSearchParams = new URLSearchParams(searchParams);

  return (
    <nav className="flex flex-col gap-2 bg-background p-3">
      {navItems.map(item => {
        const isSystemBlock = ['welcome', 'thank_you'].includes(item?.type);
        const isActive = isSystemBlock ? activeBlockId === item.type : activeBlockId === item._id;

        return (
          <AnchorButton
            key={item?._id}
            className={cn(
              'aspect-square h-auto rounded-[14px] border-0.5 border-border p-1',
              `hover:border-input hover:bg-background focus:border-input focus:bg-background active:border-primary active:bg-background
              has-[button:active]:border-input`,
              {
                [`border-primary bg-background ring-1 ring-primary hover:border-primary focus:border-primary
                has-[button:active]:border-primary`]: isActive,
              },
            )}
            variant="ghost"
            asChild
          >
            <Link
              to={{
                search: (() => {
                  updatedSearchParams.set('task_id', item._id ?? item.type);
                  return updatedSearchParams.toString();
                })(),
              }}
              title={item.title}
            >
              <IconBlock type={item?.type || 'welcome'} />
            </Link>
          </AnchorButton>
        );
      })}
    </nav>
  );
};
