import { useIntercom } from 'react-use-intercom';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from 'ui';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const BookingParticipantMigrationErrorModal = ({ isOpen, onClose }: Props) => {
  const intercom = useIntercom();

  return (
    <Modal isOpen={isOpen} onClose={() => null} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>This booking was created before the new Participant screen</ModalHeader>
        <ModalBody>
          <Text>If you need access to the details of this booking, get in touch.</Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={onClose}>
            Back
          </Button>
          <Button colorScheme="blue" onClick={intercom.showMessages}>
            Chat with us
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
