import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingTaskResult = graphql(`
  query BookingTaskResult($filter: BookingTaskResultFilterInput!) {
    bookingTaskResult(filter: $filter) {
      _id
      status
      _user_id
      task_ended
      task_started
      task_loaded
      user {
        _id
        displayName
        picture
      }
      video {
        _id
        code
        rooms {
          _id
          startedAt
          composition {
            status
            duration
            filesize
            progress {
              percentage_done
              seconds_remaining
            }
          }
        }
      }
      previousResultId
      nextResultId
    }
  }
`);

export type BookingTaskResultPartial = NonNullable<
  NonNullable<ResultOf<typeof BookingTaskResult>['bookingTaskResult']>
>;
