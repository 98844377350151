import { Button } from '@askable/ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { X } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { BulkMessageDataDocument } from 'generated/graphql';

import { BatchMessageAdd } from '../../../BookingMessagesContainer/components/BatchMessageAdd';

import type { BulkMessageDataQuery, BulkMessageDataQueryVariables } from 'generated/graphql';

/**
 * Bulk message panel for participant view
 * @todo replace with BookingMessageContainer/components/PanelBatchMessages when refactoring Participants page
 */

interface BookingParticipantBulkMessageSidePanelProps {
  bookingId: string;
  submissionIds: string[];
  onClose: () => void;
  onComplete: () => void;
}

const showNamesCount = 3;

const ParticipantsList = ({ participants }: { participants: string[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {participants.slice(0, showNamesCount).join(', ')}
      {participants.length > showNamesCount ? (
        <>
          ,{' '}
          <Tooltip>
            <TooltipTrigger className="underline">
              {t('global.plusMore', { count: participants.length - showNamesCount })}
            </TooltipTrigger>
            <TooltipContent className="w-[14rem]" side="bottom">
              {participants.slice(showNamesCount).join(', ')}
            </TooltipContent>
          </Tooltip>
        </>
      ) : null}
    </>
  );
};

export const BookingParticipantBulkMessageSidePanel = ({
  bookingId,
  submissionIds,
  onClose,
  onComplete,
}: BookingParticipantBulkMessageSidePanelProps) => {
  const { t } = useTranslation();
  const [{ data }] = useQuery<BulkMessageDataQuery, BulkMessageDataQueryVariables>({
    query: BulkMessageDataDocument,
    variables: {
      first: submissionIds.length,
      filter: {
        submissionIds,
        _booking_id: bookingId,
      },
    },
    requestPolicy: 'cache-first',
  });

  const participants = useMemo(
    () => data?.bookingSubmissionConnection?.nodes?.map(submission => submission?.applicant!) || [],
    [data],
  );

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col px-4 py-2">
        <div className="flex w-full items-center justify-between gap-2">
          <h3 className="text-xl font-semibold">
            {t('sections.messages.messagePeople', { count: submissionIds.length })}
          </h3>

          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>

        {participants.length > 0 ? (
          <div className="text-sm text-foreground-subtle">
            <ParticipantsList participants={participants.map(p => p.firstname!)} />
          </div>
        ) : null}
      </div>

      <BatchMessageAdd userIds={participants.map(p => p._id!)} bookingId={bookingId} onClose={onComplete} />
    </div>
  );
};
