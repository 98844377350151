import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Label } from '@askable/ui/components/ui/label';
import isEqual from 'react-fast-compare';
import {
  PopoverTrigger,
  Button,
  SortIcon,
  PopoverContent,
  PopoverBody,
  VStack,
  Flex,
  Popover,
  Text,
  ReactSelect,
} from 'ui';

import { SegmentSortValue } from 'generated/graphql';

import { useBookingParticipantState, bookingParticipantSelectors } from '../../state/booking-participant-state';
import { DEFAULT_SORT } from '../../utils/booking-participant-utils';

import type { FilterOption, SingleChangeValue } from '../../utils/booking-participant-utils';

type Props = {
  isBookingModerated: boolean;
};

const OPTIONS: { [key in Exclude<SegmentSortValue, 'status'>]: FilterOption<SegmentSortValue> } = {
  [SegmentSortValue.Session]: {
    value: SegmentSortValue.Session,
    label: 'Allocated Session',
  },
  [SegmentSortValue.Eligibility]: {
    value: SegmentSortValue.Eligibility,
    label: 'Eligibility',
  },
  [SegmentSortValue.Age]: {
    value: SegmentSortValue.Age,
    label: 'Age',
  },
} as const;

const SORT_OPTIONS: {
  moderated: FilterOption<SegmentSortValue>[];
  shared: FilterOption<SegmentSortValue>[];
} = {
  moderated: [OPTIONS[SegmentSortValue.Session]],
  shared: [OPTIONS[SegmentSortValue.Eligibility], OPTIONS[SegmentSortValue.Age]],
};

export const ParticipantTableActionSortPopover = ({ isBookingModerated }: Props) => {
  const updateSort = useBookingParticipantState(bookingParticipantSelectors.updateSort);
  const sortValue = useBookingParticipantState(bookingParticipantSelectors.sort);
  const defaultSort = isBookingModerated ? DEFAULT_SORT.moderated : DEFAULT_SORT.unmoderated;
  const sort = sortValue ?? defaultSort;

  const options = [...(isBookingModerated ? SORT_OPTIONS.moderated : []), ...SORT_OPTIONS.shared];

  // sort array value is ordered by priority. (smaller index is higher priority). status is currently always set as hightest priority
  const onGroupByChange = (isChecked: boolean) => {
    if (isChecked) {
      updateSort([SegmentSortValue.Status, sort?.[0] ?? defaultSort[1]]);
      return;
    }
    updateSort([sort?.[1] ?? defaultSort[1]]);
  };

  const onSortByChange = (value: SingleChangeValue<SegmentSortValue>) => {
    const selectedValue = value?.value;
    if (!selectedValue) {
      return;
    }
    if (sort?.includes(SegmentSortValue.Status)) {
      updateSort([SegmentSortValue.Status, selectedValue]);
      return;
    }
    updateSort([selectedValue]);
  };

  const sortBy = (() => {
    // sort by value is set to the second value in the sort array when group by status is checked
    if (sort?.length === 2) {
      return sort[1];
    }
    // when group by value is not set, it's been set as the first value in the sort array
    return sort?.[0];
  })();

  // When group by or sort by is set, the button is highlighted
  const colorScheme = isEqual(defaultSort, sort) ? 'gray' : 'yellow';

  return (
    <Popover placement="bottom-start" isLazy returnFocusOnClose={false} id="sort-button-participants-screen">
      <PopoverTrigger>
        <Button size="sm" leftIcon={<SortIcon />} colorScheme={colorScheme}>
          Sort
        </Button>
      </PopoverTrigger>
      <PopoverContent boxShadow="xl">
        <PopoverBody px="6" py="5">
          <VStack alignItems="flex-start" spacing="6">
            <div className="flex items-center gap-2">
              <Checkbox
                id="group-by-status"
                checked={sort.includes(SegmentSortValue.Status)}
                onCheckedChange={(checked: boolean) => {
                  onGroupByChange(checked);
                }}
              />
              <Label htmlFor="group-by-status"> Group by Status</Label>
            </div>

            <Flex direction="column" w="full">
              <Text mb="2">Sort by</Text>
              {
                // prettier-ignore
                // @ts-expect-error FIXME - ReactSelect exposes wrong type definitions
                <ReactSelect<SegmentSortValue, false> options={options} value={OPTIONS[sortBy]} onChange={onSortByChange} />
              }
            </Flex>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
