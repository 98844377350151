import { toast } from '@askable/ui/components/ui/sonner';
import { template } from 'radash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatSessionTime } from 'shared-utils';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, FormLabel, Textarea } from 'ui';
import { useMutation, useQuery } from 'urql';

import { useBookingContainerConfig } from 'containers/Booking/BookingContainer';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { InvitePanelSubmissionByIdDocument, SubmissionRescheduleDocument } from 'generated/graphql';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';
import { UserTimes } from './components/TimeSlots';

import type {
  BookingSession,
  InvitePanelSubmissionByIdQuery,
  InvitePanelSubmissionByIdQueryVariables,
  Maybe,
  SubmissionRescheduleMutation,
  SubmissionRescheduleMutationVariables,
} from 'generated/graphql';
import type { GraphQLError } from 'graphql';
import type { FC } from 'react';

type FormState = {
  selectedTimeSlot: string; // Id of the session
  message: string;
};

type Props = {
  bookingId: string;
  title: string;
  onClose: () => void;
  pptTimezone: string;
  submissionId: string;
};

type Stepper = 'time' | 'message';

const STEPPER_CONFIG: { [key in Stepper]: { previous: Maybe<Stepper>; next: Maybe<Stepper> } } = {
  time: {
    previous: null,
    next: 'message',
  },
  message: {
    previous: 'time',
    next: null,
  },
};

export const BookingParticipantReschedulePanel: FC<Props> = ({
  bookingId,
  submissionId,
  title,
  pptTimezone,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState<Stepper>('time');
  const { details } = useConnectedClient();
  const { control, trigger, formState, handleSubmit, register, getValues, setValue } = useForm<FormState>();
  const { sessionType } = useBookingContainerConfig();

  const stepper = STEPPER_CONFIG[currentStep];

  const [{ fetching: loading }, reschedule] = useMutation<
    SubmissionRescheduleMutation,
    SubmissionRescheduleMutationVariables
  >(SubmissionRescheduleDocument);

  // Get participant selected sessions
  const [{ data: bookingSubmission, fetching: loadingSubmission }] = useQuery<
    InvitePanelSubmissionByIdQuery,
    InvitePanelSubmissionByIdQueryVariables
  >({
    query: InvitePanelSubmissionByIdDocument,
    requestPolicy: 'network-only',
    variables: {
      bookingId,
      isModerated: true,
      _id: submissionId,
    },
  });

  const currentSession = bookingSubmission?.bookingSubmissionById?.session;

  const supportText = (() => {
    if (stepper.next === null) {
      const bookingSession = bookingSubmission?.bookingSubmissionById?.booking?.session?.find(
        a => a?._id === getValues('selectedTimeSlot'),
      );
      const sessionFormat = formatSessionTime({
        start: bookingSession?.start!,
        end: bookingSession?.end!,
      });
      return `Scheduling new session for ${sessionFormat}`;
    }

    if (!currentSession) {
      return '';
    }

    return `${title} is currently ${bookingSubmission.bookingSubmissionById?.status} for a session on ${formatSessionTime(
      {
        start: currentSession.session?.start!,
        end: currentSession.session?.end!,
      },
    )}`;
  })();

  const onSubmit = async (fv: FormState) => {
    try {
      const { error } = await reschedule({
        input: {
          _submission_id: submissionId,
          newSessionId: fv.selectedTimeSlot,
          message: fv.message,
        },
      });
      if (error) {
        throw error;
      }

      toast.success('Session rescheduled');

      onClose();
    } catch (e) {
      const GQLError = e as GraphQLError;
      toast.error(GQLError?.message ?? 'Error rescheduling participant');
    }
  };

  const handleNextClick = async () => {
    if (stepper.next === null) {
      handleSubmit(onSubmit)();
      return;
    }

    const result = await trigger();
    if (!result) {
      return;
    }

    const bookingSession = bookingSubmission?.bookingSubmissionById?.booking?.session?.find(
      a => a?._id === getValues('selectedTimeSlot'),
    );

    const message = template(
      'Hi {{displayName}}, the session you were invited to on {{oldTime}} has been rescheduled. The new session has been scheduled for {{newTime}}. Please confirm this new session time.\nThanks,\n{{clientName}}',
      {
        displayName: title,
        oldTime: formatSessionTime({
          start: currentSession?.session?.start!,
          end: currentSession?.session?.end!,
          timezone: pptTimezone,
        }),
        newTime: formatSessionTime({
          start: bookingSession?.start!,
          end: bookingSession?.end!,
          timezone: pptTimezone,
        }),
        clientName: details?.name?.firstname ?? '',
      },
    );

    setValue('message', message);

    setCurrentStep(stepper.next);
  };

  const footer = useMemo(() => {
    return (
      <Button w="full" size="lg" colorScheme="blue" isLoading={loading} onClick={handleNextClick}>
        {stepper.next === null ? 'Submit' : 'Next'}
      </Button>
    );
  }, [stepper, currentSession, title, loading]);

  const handleBackClick = () => {
    if (stepper.previous === null) {
      return;
    }

    setCurrentStep(stepper.previous);
  };

  return (
    <BookingSidePanelStepperContainer
      loading={loadingSubmission}
      onBackClick={stepper.previous !== null ? handleBackClick : null}
      support={supportText}
      onSubmit={handleSubmit(onSubmit)}
      title="Reschedule session"
      as="form"
      onCancelClick={onClose}
      footer={footer}
    >
      {currentStep === 'time' && (
        <Flex direction="column">
          {formState.errors.selectedTimeSlot && (
            <Alert status="error" mb="10">
              <AlertIcon />
              <Box>
                <AlertTitle>No session selected</AlertTitle>
                <AlertDescription>A session needs to be selected before invites can be sent</AlertDescription>
              </Box>
            </Alert>
          )}

          {!loadingSubmission && (
            <UserTimes
              name="selectedTimeSlot"
              sessionType={sessionType}
              title={title}
              pptTimezone={pptTimezone}
              exclude={[currentSession?.session?._id ?? '']}
              preferredSessions={bookingSubmission?.bookingSubmissionById?.preferred_sessions as BookingSession[]}
              bookingSessions={bookingSubmission?.bookingSubmissionById?.booking?.session as BookingSession[]}
              control={control}
            />
          )}
        </Flex>
      )}

      {currentStep === 'message' && (
        <Box>
          <FormLabel htmlFor="reschedule-message">Message</FormLabel>
          <Textarea autoFocus rows={9} {...register('message', { required: true })} id="reschedule-message" />
        </Box>
      )}
    </BookingSidePanelStepperContainer>
  );
};
