import { Button } from '@askable/ui/components/ui/button';
import { Copy, Plus } from 'lucide-react';
import { useState } from 'react';
import { useFormContext, useWatch, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoPopover } from 'containers/Studies/components/InfoPopover';
import { SortableList } from 'containers/Studies/components/SortableList';
import { getObjectId } from 'containers/Studies/utils/utils';
import { BookingRecruitQuestionType } from 'generated/graphql';

import { ScreenerQuestionItem } from './ScreenerQuestions/ScreenerQuestionItem';
import { ScreenerQuestionsCopy } from './ScreenerQuestions/ScreenerQuestionsCopy';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export type ScreenerQuestion = NonNullable<RecruitFormFields['screener_questions']>[number];
export type ScreenerQuestionOption = NonNullable<NonNullable<ScreenerQuestion>['options']>[0];

export const SectionScreenerQuestions = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const { field } = useController({ name: 'screener_questions' });
  const formValues = useWatch({
    control,
    name: 'screener_questions',
  });

  const [isScreenerQuestionsCopyOpen, setIsScreenerQuestionsCopyOpen] = useState(false);

  const addScreenerQuestion = () => {
    const newQuestion: ScreenerQuestion = {
      _id: getObjectId(),
      title: '',
      description: null,
      type: BookingRecruitQuestionType.ShortAnswer,
      options: [],
    };

    field.onChange([...field.value, newQuestion]);
  };

  const addScreenerQuestions = (questions: ScreenerQuestion[]) => {
    field.onChange([...field.value, ...questions]);
    setIsScreenerQuestionsCopyOpen(false);
  };

  const handleCopy = (id: string) => {
    if (!formValues) {
      return;
    }

    const questionIndex = formValues.findIndex((q: ScreenerQuestion) => q._id === id);

    const question = formValues[questionIndex];

    const newQuestion: ScreenerQuestion = {
      ...question,
      _id: getObjectId(),
    };

    // Insert new question below the current question
    const updatedList = formValues.slice();
    updatedList.splice(questionIndex + 1, 0, newQuestion);

    field.onChange(updatedList);
  };

  const handleRemove = (id: string) => {
    const updatedList = field.value.filter((question: ScreenerQuestion) => question._id !== id);
    field.onChange(updatedList);
  };

  const handleSortChange = (updatedList: ScreenerQuestion[]) => {
    field.onChange(updatedList);
  };

  const closeScreenerQuestionsCopy = () => {
    setIsScreenerQuestionsCopyOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6" id="section-screener-questions">
        <div className="flex flex-wrap items-center justify-between gap-2">
          <h3 className="text-md flex items-center font-semibold">
            {t('sections.studies.recruit.fields.screenerQuestionsTitle')}

            <InfoPopover
              title={t('sections.studies.recruit.fields.screenerQuestionsCalloutTitle')}
              description={t('sections.studies.recruit.fields.screenerQuestionsCalloutDescription')}
            />
          </h3>

          <Button variant="outline" onClick={() => setIsScreenerQuestionsCopyOpen(true)} disabled={isDisabled}>
            <Copy className="h-4 w-4" /> {t('sections.studies.recruit.copyFromOtherStudy')}
          </Button>
        </div>

        {field.value.length > 0 ? (
          <SortableList
            onChange={handleSortChange}
            containerClass="gap-2"
            items={field.value}
            renderItem={(question, index) => (
              <ScreenerQuestionItem
                key={question._id}
                index={index}
                isDisabled={isDisabled}
                isMovable={field.value.length > 1}
                onCopy={handleCopy}
                onRemove={handleRemove}
              />
            )}
          />
        ) : null}

        <Button variant="secondary" size="lg" className="w-full" onClick={addScreenerQuestion} disabled={isDisabled}>
          <Plus className="h-4 w-4" /> {t('sections.studies.recruit.fields.screenerQuestionsCta')}
        </Button>
      </div>

      <ScreenerQuestionsCopy
        isOpen={isScreenerQuestionsCopyOpen}
        onAddQuestions={addScreenerQuestions}
        onClose={closeScreenerQuestionsCopy}
      />
    </>
  );
};
