import { Button } from '@askable/ui/components/ui/button';
import { useTranslation } from 'react-i18next';

export const NoMessages = ({ onRetry }: { onRetry?: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col items-center gap-2 p-4 text-center text-sm text-foreground-subtle">
      <svg width="100" fill="none" viewBox="0 0 168 143" clipRule="evenodd" fillRule="evenodd" className="m-auto">
        <path
          fill="#F5F5F5"
          d="M160 40a7 7 0 1 1 0 14h-40a7 7 0 1 1 0 14h22a7 7 0 1 1 0 14h-10.17c-4.88 0-8.83 3.13-8.83 7 0 2.58 2 4.91 6 7a7 7 0 1 1 0 14H46a7 7 0 1 1 0-14H7a7 7 0 1 1 0-14h40a7 7 0 1 0 0-14H22a7 7 0 1 1 0-14h40a7 7 0 1 1 0-14h98Zm0 28a7 7 0 1 1 0 14 7 7 0 0 1 0-14Z"
        />
        <path
          fill="#F5F5F5"
          d="M36.35 118h69.94c.39 0 .78-.05 1.15-.12.37.07.76.12 1.15.12h39.97a5.37 5.37 0 0 0 5.38-5.4 5.41 5.41 0 0 0-5.38-5.4h-4.62a5.37 5.37 0 0 1-5.38-5.4 5.41 5.41 0 0 1 5.38-5.4h14.6a5.37 5.37 0 0 0 5.39-5.4 5.41 5.41 0 0 0-5.38-5.4h-16.91a5.37 5.37 0 0 0 5.38-5.4 5.41 5.41 0 0 0-5.38-5.4H92.45a5.37 5.37 0 0 0 5.38-5.4 5.41 5.41 0 0 0-5.38-5.4h-43.8a5.37 5.37 0 0 0-5.38 5.4 5.41 5.41 0 0 0 5.38 5.4H17.9a5.37 5.37 0 0 0-5.38 5.4 5.41 5.41 0 0 0 5.38 5.4h19.21A5.37 5.37 0 0 1 42.5 91a5.41 5.41 0 0 1-5.38 5.4H6.38A5.37 5.37 0 0 0 1 101.8a5.4 5.4 0 0 0 5.38 5.4h29.97a5.37 5.37 0 0 0-5.38 5.4 5.4 5.4 0 0 0 5.38 5.4Zm125.27 0a5.37 5.37 0 0 0 5.38-5.4 5.4 5.4 0 0 0-5.38-5.4 5.37 5.37 0 0 0-5.38 5.4 5.41 5.41 0 0 0 5.38 5.4Z"
        />
        <path
          fill="#fff"
          d="M73.12 93a11.45 11.45 0 0 0 2.82 9.33 12 12 0 0 0 9.06 4.04 12.24 12.24 0 0 0 9.06-4.04A11.64 11.64 0 0 0 96.88 93H126v26.07a2.9 2.9 0 0 1-.88 2.07c-.56.55-1.32.86-2.12.86H47c-.8 0-1.56-.3-2.12-.86a2.9 2.9 0 0 1-.88-2.07V93h29.12Z"
        />
        <path
          fill="#fff"
          d="M98 93a13 13 0 1 1-25.98-.7H44l9.56-28.26A3 3 0 0 1 56.4 62h57.2a3 3 0 0 1 2.84 2.04L126 92.3H97.98l.02.69V93Z"
        />
        <path
          fill="#F5F5F5"
          d="M96.1 93.95C96.1 99.5 91.13 105 85 105s-11.1-5.5-11.1-11.05c0-.17 0-1.35.02-1.53H53l8.16-19.84A2.55 2.55 0 0 1 63.6 71h42.82c1.1 0 2.08.63 2.43 1.58l8.16 19.84H96.09l.02 1.53h-.01Z"
        />
        <path
          fill="#777"
          d="m45.25 92.51-1.18-.4-.07.2v.2h1.25Zm79.5 0H126v-.2l-.07-.2-1.18.4Zm-9.5-28.07-1.18.4 1.18-.4Zm-1.65-1.19V62v1.25Zm-58.86 1.19 1.18.4-1.18-.4ZM58 90.75a1.25 1.25 0 0 0 0 2.5v-2.5Zm66 2.5a1.25 1.25 0 0 0 0-2.5v2.5Zm-73.43-2.5a1.25 1.25 0 0 0 0 2.5v-2.5Zm2.43 2.5a1.25 1.25 0 1 0 0-2.5v2.5Zm-9-.74V119h2.5V92.51H44ZM44 119a3 3 0 0 0 3 3v-2.5a.5.5 0 0 1-.5-.5H44Zm3 3h76v-2.5H47v2.5Zm76 0a3 3 0 0 0 3-3h-2.5a.5.5 0 0 1-.5.5v2.5Zm3-3V92.51h-2.5V119h2.5Zm-.07-26.9-9.5-28.06-2.36.8 9.5 28.07 2.36-.8Zm-9.5-28.06a3 3 0 0 0-1.08-1.47l-1.47 2.02a.5.5 0 0 1 .19.25l2.36-.8Zm-1.08-1.47a3 3 0 0 0-1.74-.57l-.02 2.5c.1 0 .21.03.3.1l1.46-2.03ZM113.6 62H56.4v2.5h57.2V62Zm-57.2 0a3 3 0 0 0-2.84 2.04l2.36.8a.5.5 0 0 1 .48-.34V62Zm-2.84 2.04-9.5 28.07 2.37.8 9.5-28.07-2.37-.8ZM58 93.25h12.75v-2.5H58v2.5Zm12.75 0c.32 0 .47.06.51.08l.04.03c.01.02.04.08.05.2.02.13.02.26.02.44h2.5c0-.3.02-1.12-.4-1.87-.5-.9-1.45-1.38-2.72-1.38v2.5Zm.62.75c0 7.35 6.09 13.25 13.53 13.25v-2.5A10.9 10.9 0 0 1 73.87 94h-2.5Zm13.53 13.25c7.44 0 13.52-5.9 13.52-13.25h-2.5c0 5.91-4.9 10.75-11.02 10.75v2.5ZM98.42 94c0-.18 0-.3.02-.43a.6.6 0 0 1 .05-.2c0-.02 0-.02.04-.04.05-.02.2-.08.52-.08v-2.5c-1.27 0-2.22.48-2.73 1.38-.42.74-.4 1.58-.4 1.87h2.5Zm.63-.75H124v-2.5H99.05v2.5Zm-48.48 0H53v-2.5h-2.43v2.5Z"
        />
        <path
          stroke="#BBB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
          d="M110.1 39.3 99 51.76M84.1 35v16.76M58 39.3l11.1 12.46"
        />
      </svg>
      {t('sections.messages.noMessages')}

      {onRetry ? (
        <Button onClick={onRetry} variant="primary">
          {t('global.refresh')}
        </Button>
      ) : null}
    </div>
  );
};
