import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SummaryInfoMessage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col gap-1 text-pretty rounded-md border-0.5 border-border text-sm text-foreground-subtle transition-all
        focus-within:border-input hover:border-input"
    >
      <button
        aria-expanded={isOpen}
        className="flex items-center justify-between gap-2 px-4 py-2 text-start font-medium"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        {t('sections.studies.recruit.summaryCalloutTitle')}
        <ChevronDown className={`h-4 w-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen ? <div className="px-4 py-2">{t('sections.studies.recruit.summaryCalloutDescription')}</div> : null}
    </div>
  );
};
