import { useFeatureFlags } from 'feature-flags';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Navigate, useParams, useLocation } from 'react-router-dom';
import Header from 'src/components/common/Header/view';
import { useFigmaAuth } from 'src/hooks/useFigmaAuth';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { z } from 'zod';

import { PaymentOverDueBanner } from 'components/PaymentOverdueBanner/PaymentOverdueBanner';
import { HeaderAds } from 'components/common';
import { mapBookingToFormFields } from 'containers/Studies/Recruit/utils/mapBookingToFormFields';
import { StudiesHeader } from 'containers/Studies/components/StudiesHeader';
import { Booking } from 'containers/Studies/data/Booking.query';
import { blockSchema, taskBlockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { recruitFormSchema } from 'containers/Studies/data/schemas/recruitSchema';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { BOOKING_STATUS } from 'lib/constants';

import { EmptyState } from './components/EmptyState';
import { SkeletonLoader } from './components/SkeletonLoader';

import type { Booking as TBooking } from 'generated/graphql';
import type { ReactNode, Dispatch, SetStateAction } from 'react';
import type { FigmaAuthStatus } from 'src/hooks/useFigmaAuth';

import './Studies.css';

type ActiveSection = 'nav' | 'editor' | 'preview' | 'main' | 'segments';

interface StudyContextProps {
  isBuildDisabled: boolean;
  isSaving: boolean;
  study: TBooking;
  studyId: string;
  setActiveSection: Dispatch<SetStateAction<ActiveSection>>;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  figmaAuth: {
    authenticate: (cb?: { onTimeout?: () => void; onSuccess?: () => void }) => void;
    status: FigmaAuthStatus;
    checkStatus: ReturnType<typeof useFigmaAuth>['checkStatus'];
  };
  /**
   * Global validation state based on database data and defined validation triggers for
   * the different parts of the study creation process
   */
  isValid: {
    build: boolean;
    recruit: boolean;
  };
  /**
   * Local state to surpress validation errors while the user is creating a new block
   */
  newBlockId: string | null;
  setNewBlockId: Dispatch<SetStateAction<null | string>>;
  /**
   * Local state to enable recruit validation errors once the user tried to submit the study
   */
  hasSubmitted: boolean;
  setHasSubmitted: Dispatch<SetStateAction<boolean>>;
}

const StudyContext = createContext<StudyContextProps>({} as any);

export const useStudyContext = () => {
  return useContext(StudyContext);
};

// Copied from `components/App/view.tsx`
// TODO: ideally we just render this in one place as the root router layout instead of multiple times
// manually, that way uplifting the component also becomes less involved
function Layout({ children }: { children: ReactNode }) {
  const { details: clientDetails } = useConnectedClient();

  return (
    <div className="flex h-full flex-col">
      {!clientDetails?.type?.researcher ? <PaymentOverDueBanner teamId={clientDetails?.ConnectedTeam?._id!} /> : null}
      <HeaderAds />
      <Header />

      {children}
    </div>
  );
}

const useStudyNavigation = () => {
  const location = useLocation();
  const segments = location.pathname.split('/').filter(Boolean);
  const currentSection = segments[2]; // 'build', 'recruit', 'results', etc.
  const currentSubSection = segments[3]; // 'settings', 'task', 'participant', etc.

  return { currentSection, currentSubSection };
};

export const StudiesContainer = () => {
  const { studyId } = useParams();
  const { t } = useTranslation();
  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  invariant(studyId, 'Study ID is required');

  const { currentSection, currentSubSection } = useStudyNavigation();

  // TODO derive this from URL state
  const [activeSection, setActiveSection] = useState<ActiveSection>('nav');
  const [isSaving, setIsSaving] = useState(false);
  const [newBlockId, setNewBlockId] = useState<null | string>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const [bookingQuery] = useQuery({ query: Booking, variables: { id: studyId }, pause: !UNMODERATED_STUDY });

  const figmaAuth = useFigmaAuth();
  const isLoading = bookingQuery.fetching;

  const value: StudyContextProps = useMemo(() => {
    const study = bookingQuery.data?.unmoderatedBooking as TBooking;

    // Validation
    const blockValidationSchema = z.array(blockSchema);
    const taskBlockValidationSchema = z.array(taskBlockSchema);

    const blocks = study?.config?.unmoderated?.task_blocks || [];
    const isValidBuild =
      study?.status === BOOKING_STATUS.DRAFT
        ? blocks.length > 0 &&
          blockValidationSchema.safeParse([
            study?.config?.unmoderated?.welcome_block,
            study?.config?.unmoderated?.thank_you_block,
          ]).success &&
          taskBlockValidationSchema.safeParse(blocks).success
        : true;

    const recruitForm = study ? mapBookingToFormFields(study) : undefined;
    const isValidRecruit =
      study?.status === BOOKING_STATUS.DRAFT && recruitForm ? recruitFormSchema.safeParse(recruitForm).success : true;

    return {
      isBuildDisabled: study?.status !== BOOKING_STATUS.DRAFT,
      isSaving,
      isValid: {
        build: isValidBuild,
        recruit: isValidRecruit,
      },
      newBlockId,
      hasSubmitted,
      study,
      studyId,
      figmaAuth: {
        checkStatus: figmaAuth.checkStatus,
        authenticate: ({ onTimeout, onSuccess } = {}) => {
          figmaAuth.openAuthPopup({
            polling: {
              onTimeout,
              onAuthenticated: onSuccess,
            },
          });
        },
        status: figmaAuth.status,
      },
      setActiveSection,
      setIsSaving,
      setNewBlockId,
      setHasSubmitted,
    };
  }, [bookingQuery.data?.unmoderatedBooking, isSaving, newBlockId, hasSubmitted, studyId, figmaAuth]);

  // Set the active section from the URL hash to navigate to it on mobile
  useEffect(() => {
    setActiveSection((location.hash ? location.hash.replace('#', '') : 'nav') as ActiveSection);
  }, [location.hash]);

  if (!UNMODERATED_STUDY) {
    return <Navigate to="/not-found" />;
  }

  if (isLoading) {
    return (
      <Layout>
        <SkeletonLoader section={currentSection} />
      </Layout>
    );
  }

  if (!value.study) {
    return (
      <Layout>
        <EmptyState title={t('sections.studies.studyNotFound')} />
      </Layout>
    );
  }

  // TODO(ASK-9471): move preview into sessions app
  if (currentSection === 'preview') {
    return <Outlet />;
  }

  return (
    <StudyContext.Provider value={value}>
      <Layout>
        <StudiesHeader />
        <div
          className={`studies-container ${currentSection}-container h-full min-h-0 w-full flex-auto bg-secondary text-foreground
          show-${activeSection}${currentSubSection ? ` sub-section-${currentSubSection}` : ''}`}
        >
          <Outlet />
        </div>
      </Layout>
    </StudyContext.Provider>
  );
};
