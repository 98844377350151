import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const BookingsByTeamId = graphql(`
  query FetchBookingsByTeamId(
    $id: ID
    $status: [Int]
    $sortBy: String
    $order: Int
    $excludeDemoBookings: Boolean
    $excludeBookingsWithNoQuestions: Boolean
    $excludeAskablePlusBookings: Boolean
  ) {
    bookingsByTeam(
      _team_id: $id
      status: $status
      sortBy: $sortBy
      order: $order
      excludeDemoBookings: $excludeDemoBookings
      excludeBookingsWithNoQuestions: $excludeBookingsWithNoQuestions
      excludeAskablePlusBookings: $excludeAskablePlusBookings
    ) {
      _id
      name
      created
      type
      config {
        demo
        question {
          _id
          title
          description
          config {
            display_participant
            multiple_selection
            none_of_the_above
            type
          }
          options {
            _id
            label
            screen_in
          }
        }
        online_task {
          type
        }
      }
    }
  }
`);

export type BookingsByTeamId = NonNullable<NonNullable<ResultOf<typeof BookingsByTeamId>>['bookingsByTeam']>;
export type Booking = NonNullable<NonNullable<BookingsByTeamId>[number]>;
export type BookingQuestion = NonNullable<NonNullable<NonNullable<Booking['config']>['question']>[number]>;
