import { toast } from '@askable/ui/components/ui/sonner';
import { formatInTimeZone } from 'date-fns-tz';
import { template } from 'radash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatSessionTime } from 'shared-utils';
import { Textarea, Button, FormControl, FormLabel, FormErrorMessage } from 'ui';
import { useMutation } from 'urql';

import { useBooking } from 'containers/Booking/BookingContainer';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { CancelInviteDocument, SubmissionStatus } from 'generated/graphql';
import { handleRequest } from 'network/handleRequest';

import { useBookingParticipantInviteWaitlistedModalCaller } from '../BookingParticipantInviteWaitlistedModal';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';

import type { OpenInviteWaitlistedModalFunc } from '../BookingParticipantInviteWaitlistedModal';
import type {
  CancelInviteMutationResult,
  CancelInviteMutationVariables,
  SidePanelSubmissionQuery,
} from 'generated/graphql';

type Props = {
  submissionId?: string | null;
  session: NonNullable<SidePanelSubmissionQuery['bookingSubmissionById']>['session'];
  pptDisplayname: string;
  onClose: () => void;
  pptTimezone: string;
  bookingId: string;
  openInviteWaitlistedModal: OpenInviteWaitlistedModalFunc;
  submissionStatus?: SubmissionStatus | null;
  isModeratedBooking: boolean;
};

type FormValues = {
  note: string;
};

type CopyConfigType = { title: string; sessionValue: string };

const copyConfig: { [SubmissionStatus.PendingCheck]: CopyConfigType; common: CopyConfigType } = {
  [SubmissionStatus.PendingCheck]: {
    title: 'Cancel Submission',
    sessionValue: 'submission',
  },
  common: {
    title: 'Cancel invite',
    sessionValue: 'invitation',
  },
};

export const BookingParticipantCancel = ({
  session,
  submissionId,
  onClose,
  pptTimezone,
  pptDisplayname,
  bookingId,
  openInviteWaitlistedModal,
  submissionStatus,
  isModeratedBooking,
}: Props) => {
  const pptName = pptDisplayname || 'there';
  const booking = useBooking();

  const { canInviteWaitlisted, waitlistedSubmissionsWithSameSession } =
    useBookingParticipantInviteWaitlistedModalCaller({
      bookingId,
      reviewSubmission: !!booking?.config?.options?.review_submission,
      sessionId: session?._session_id,
      submissionStatus,
      isModeratedBooking,
    });

  const { handleSubmit, register, reset, setValue, formState } = useForm<FormValues>();

  const { details } = useConnectedClient();

  const copy =
    copyConfig[submissionStatus === SubmissionStatus.PendingCheck ? SubmissionStatus.PendingCheck : 'common'];

  useEffect(() => {
    const sessionTimeOrSurveyName = (() => {
      if (isModeratedBooking) {
        if (!session?.session?.start) {
          return booking?.config?.information?.short_description || '';
        }
        const startDayText = formatInTimeZone(session.session.start, pptTimezone, 'EEEE dd');
        const startTimeText = formatInTimeZone(session.session.start, pptTimezone, 'h:mm aaa');
        return `${startDayText} at ${startTimeText}`;
      }
      return booking?.config?.information?.short_description || '';
    })();

    if (!sessionTimeOrSurveyName) {
      return;
    }

    const senderText = details?.name?.firstname || 'the team';
    setValue(
      'note',
      template(
        `Hi {{pptName}}, unfortunately we’ve had to cancel your {{sessionValue}} for {{sessionTimeOrSurveyName}}. Apologies for any inconvenience this may have caused.\n\n${senderText}`,
        {
          pptName,
          sessionTimeOrSurveyName,
          sessionValue: copy.sessionValue,
        },
      ),
    );
  }, [reset, setValue, pptName, session, pptTimezone, details, isModeratedBooking, booking?.name]);

  const [{ fetching: loading }, cancelInvite] = useMutation<CancelInviteMutationResult, CancelInviteMutationVariables>(
    CancelInviteDocument,
  );

  const onConfirmCancel = async (fv: FormValues) => {
    try {
      if (!submissionId || !fv.note) {
        throw Error();
      }
      const [, cancelInviteError] = await handleRequest(async () => {
        return cancelInvite({
          submissionId,
          message: fv.note,
        });
      });

      if (cancelInviteError) {
        toast.error('Error cancelling the invite');
        return;
      }

      toast.success('Invite cancelled');

      if (canInviteWaitlisted) {
        openInviteWaitlistedModal({
          sessionId: session?._session_id || '',
          sessionStartTime: session?.session?.start,
          submissionId,
          waitlistedSubmissionsWithSameSession,
        });
      }

      onClose();
    } catch {
      toast.error('Error cancelling the invite');
    }
  };

  const supportText = (() => {
    const baseText = `With ${pptName}`;
    if (!isModeratedBooking) {
      return baseText;
    }
    const dateText =
      session?.session?.start && session.session.end
        ? `on ${formatSessionTime({ start: session.session?.start, end: session.session?.end })}`
        : '';

    return `${baseText} ${dateText}`;
  })();

  return (
    <BookingSidePanelStepperContainer
      onSubmit={handleSubmit(onConfirmCancel)}
      // Only show "submission" when booking is unmoderated and status is pending check
      title={copy.title}
      as="form"
      support={supportText}
      onCancelClick={onClose}
      footer={
        <Button colorScheme="blue" size="lg" type="submit" w="full" mt="auto" isLoading={loading}>
          Confirm
        </Button>
      }
    >
      <FormControl isInvalid={!!formState.errors.note}>
        <FormLabel htmlFor="note" mb="2">
          Message
        </FormLabel>
        <Textarea id="note" rows={9} {...register('note', { required: 'Message is required' })} name="note" w="full" />
        <FormErrorMessage>{formState.errors.note?.message}</FormErrorMessage>
      </FormControl>
    </BookingSidePanelStepperContainer>
  );
};
