import { graphql } from 'src/graphql';

// Subscribe to new user messages
export const SubscribeListMessages = graphql(`
  subscription listMessagesSubscription($filter: MessageSearchInput!) {
    messagesSubscription(filter: $filter) {
      _from_user_id
      _id
      _to_user_id
      body
      created
      direction
      seen
      type
      Submission {
        _id
        status
        user {
          _id
          contact {
            phone {
              mobile
              country_code
            }
          }
          displayName
          email
        }
        session {
          session {
            _id
            end
            start
          }
        }
        status_updated
        created
      }
      UserFrom {
        meta {
          identity {
            firstname
          }
        }
      }
    }
  }
`);
