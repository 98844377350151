import { graphql } from 'src/graphql';

// Add a new message
export const CreateMessage = graphql(`
  mutation createMessage($message: MessagesInput!) {
    createMessage(message: $message) {
      _from_user_id
      _id
      _to_user_id
      body
      created
      direction
      seen
      type
      UserFrom {
        meta {
          identity {
            firstname
          }
        }
      }
    }
  }
`);
