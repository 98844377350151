import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { FormControl, FormLabel } from '@askable/ui/components/ui/form';
import { cn } from '@askable/ui/lib/utils';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';
import { GENDER_OPTIONS } from 'containers/Studies/data/schemas/recruitSchema';

interface GenderProps {
  isDisabled?: boolean;
}

type GenderOption = (typeof GENDER_OPTIONS)[number];

export const Gender = ({ isDisabled }: GenderProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'gender' });

  const options = GENDER_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.gender.${option}`),
    value: option,
  }));

  const handleGenderChange = (checked: boolean, option: string) => {
    const currentValue = field.value || [];
    let newValue = field.value;

    if (option === 'any_gender') {
      newValue = [option];
    } else {
      newValue = checked
        ? [...new Set([...currentValue, option])].filter(g => g !== 'any_gender')
        : currentValue.filter((g: GenderOption) => g !== option).length === 0
          ? ['any_gender']
          : currentValue.filter((g: GenderOption) => g !== option);
    }
    field.onChange(newValue.length ? newValue : []);
  };

  return (
    <div className="flex flex-col gap-3">
      <FieldLabel
        name="input_gender"
        label={t('sections.studies.recruit.fields.genderTitle')}
        infoPopover={{
          title: t('sections.studies.recruit.fields.genderCalloutTitle'),
          description: t('sections.studies.recruit.fields.genderCalloutDescription'),
          moreLink: 'https://help.askable.com/en/articles/4849442-how-to-manage-study-quotas',
        }}
      />

      <>
        <FormControl>
          <div className="flex flex-wrap gap-3">
            {options.map(option => (
              <FormLabel
                key={option.value}
                htmlFor={`gender_option_${option.value}`}
                className={cn(
                  `flex max-w-40 flex-1 cursor-pointer items-center gap-2 whitespace-nowrap rounded-md border border-border px-3 py-2
                  hover:border-input focus:border-input active:border-input`,
                  {
                    'border-primary ring-1 ring-primary hover:border-primary focus:border-primary active:border-primary':
                      Array.isArray(field.value) && field.value.includes(option.value),
                    'cursor-not-allowed opacity-50 hover:border-border focus:border-border active:border-border':
                      isDisabled,
                  },
                )}
              >
                <Checkbox
                  value={option.value}
                  checked={Array.isArray(field.value) && field.value.includes(option.value)}
                  disabled={isDisabled}
                  id={`gender_option_${option.value}`}
                  onCheckedChange={checked => handleGenderChange(checked as boolean, option.value)}
                />

                {option.label}
              </FormLabel>
            ))}
          </div>
        </FormControl>

        {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
      </>
    </div>
  );
};
