import { Button } from '@askable/ui/components/ui/button';
import { toast } from '@askable/ui/components/ui/sonner';
import { CircleHelp } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { SummaryInfoMessage } from 'containers/Studies/Recruit/components/Summary/SummaryInfoMessage';
import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { SummaryItemIndustries } from 'containers/Studies/Recruit/components/Summary/SummaryItemIndustries';
import { SummaryParticipantsDevice } from 'containers/Studies/Recruit/components/Summary/SummaryParticipantsDevice';
import { SummaryValidationMessage } from 'containers/Studies/Recruit/components/Summary/SummaryValidationMessage';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { UpdateUnmoderatedBooking } from 'containers/Studies/data/UpdateUnmoderatedBooking.mutation';
import { AGE_LIMIT } from 'containers/Studies/data/schemas/recruitSchema';
import { BOOKING_STATUS } from 'lib/constants';

export const RecruitSummary = () => {
  const { t } = useTranslation();
  const { study, isValid, hasSubmitted, setHasSubmitted } = useStudyContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [, updateBooking] = useMutation(UpdateUnmoderatedBooking);

  const showFields = useMemo(() => {
    return (
      study.recruit?.age_range?.[0] !== AGE_LIMIT.min ||
      study.recruit?.age_range?.[1] !== AGE_LIMIT.max ||
      !!study.recruit?.locations?.length ||
      !!study.recruit?.gender?.length ||
      !!study.recruit?.industry_and_occupation?.length ||
      !!study.recruit?.language_fluency?.length ||
      !!study.recruit?.education_level?.length ||
      !!study.recruit?.marital_status?.length ||
      !!study.recruit?.occupational_status?.length ||
      !!study.recruit?.business_ownership?.length ||
      !!study.recruit?.screener_questions?.length
    );
  }, [study.recruit]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);

      // 1. set local `hasSubmitted: true`
      setHasSubmitted(true);

      // 2. check global validation state, if any errors -> bail
      if (!isValid.build || !isValid.recruit) {
        // TODO: should we show an error toast here?
        return;
      }

      // 3. update booking status (this will)
      const { error } = await updateBooking({ input: { _id: study._id, status: 3 } });
      if (error) {
        throw new Error(error.graphQLErrors?.[0]?.message || t('sections.errorBoundary.default'));
      }

      toast.success(t('sections.studies.recruit.submitted'));
    } catch (err: unknown) {
      toast.error(err instanceof Error ? err.message : t('sections.errorBoundary.default'));
    } finally {
      setIsSubmitting(false);
    }
  }, [isValid.build, isValid.recruit, setHasSubmitted, study._id, t, updateBooking]);

  return (
    <aside className="fixed bottom-0 w-full min-w-[20rem] sm:static sm:bottom-auto lg:pr-4">
      <div className="sticky top-0 flex max-h-[calc(100vh_-_5rem)] flex-col gap-5 bg-background p-4 lg:rounded-xl lg:p-6">
        <SummaryParticipantsDevice participants={study.recruit?.participants ?? 0} device={study.recruit?.device!} />

        {showFields ? (
          <>
            <div className="hidden font-semibold sm:visible">{t('sections.studies.recruit.audience')}</div>

            <ul className="flex h-0 flex-col gap-2 overflow-auto text-balance text-sm sm:h-auto">
              {study.recruit?.locations?.length ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.locationsTitle')}
                  type="locations"
                  options={study.recruit?.locations.map(location => location.location?.formatted_address!)}
                  variant="stacked"
                />
              ) : null}

              {study.recruit?.age_range &&
              (study.recruit?.age_range[0] !== AGE_LIMIT.min || study.recruit?.age_range?.[1] !== AGE_LIMIT.max) ? (
                <SummaryItem
                  label={t('sections.studies.recruit.fields.ageRangeTitle')}
                  type="age_range"
                  options={study.recruit?.age_range}
                  variant="dash-between"
                />
              ) : null}

              <SummaryItem
                label={t('sections.studies.recruit.fields.genderTitle')}
                type="gender"
                options={
                  study.recruit?.gender && study.recruit.gender.length > 0
                    ? study.recruit?.gender.map(val => t(`sections.studies.recruit.options.gender.${val}`))
                    : [t('sections.studies.recruit.options.gender.any_gender')]
                }
              />

              <SummaryItemIndustries options={study.recruit?.industry_and_occupation ?? []} />

              <SummaryItem
                label={t('sections.studies.recruit.fields.languageFluencyTitle')}
                type="language_fluency"
                options={
                  study.recruit?.language_fluency
                    ? study.recruit?.language_fluency.map(val =>
                        t(`sections.studies.recruit.options.language_fluency.${val}`),
                      )
                    : null
                }
              />

              <SummaryItem
                label={t('sections.studies.recruit.fields.educationLevelTitle')}
                type="education_level"
                options={
                  study.recruit?.education_level?.length
                    ? study.recruit?.education_level.map(val =>
                        t(`sections.studies.recruit.options.education_level.${val}`),
                      )
                    : null
                }
              />

              <SummaryItem
                label={t('sections.studies.recruit.fields.maritalStatusTitle')}
                type="marital_status"
                options={
                  study.recruit?.marital_status?.length
                    ? study.recruit?.marital_status.map(val =>
                        t(`sections.studies.recruit.options.marital_status.${val}`),
                      )
                    : null
                }
              />

              <SummaryItem
                label={t('sections.studies.recruit.fields.occupationalStatusTitle')}
                type="occupational_status"
                options={
                  study.recruit?.occupational_status?.length
                    ? study.recruit?.occupational_status.map(val =>
                        t(`sections.studies.recruit.options.occupational_status.${val}`),
                      )
                    : null
                }
              />

              <SummaryItem
                label={t('sections.studies.recruit.fields.businessOwnershipTitle')}
                type="business_ownership"
                options={
                  study.recruit?.business_ownership?.length
                    ? study.recruit?.business_ownership.map(val =>
                        t(`sections.studies.recruit.options.business_ownership.${val}`),
                      )
                    : null
                }
              />

              {study.recruit?.screener_questions?.length ? (
                <li className="flex gap-2">
                  <CircleHelp className="h-4 w-4 text-foreground-subtle" />
                  <div className="flex flex-col gap-1">
                    <div className="text-xs text-foreground-subtle">
                      {t('sections.studies.recruit.fields.screenerQuestionsTitle')}
                    </div>
                    {study.recruit?.screener_questions.length}{' '}
                    {t('sections.studies.recruit.screenerQuestions', {
                      count: study.recruit?.screener_questions.length,
                    }).toLocaleLowerCase()}
                  </div>
                </li>
              ) : null}
            </ul>
          </>
        ) : null}

        {/* Only show validation and submit button in draft mode */}
        {study.status === BOOKING_STATUS.DRAFT ? (
          <>
            {hasSubmitted ? <SummaryValidationMessage /> : null}
            <Button
              className="w-full"
              isLoading={isSubmitting}
              size="lg"
              type="submit"
              variant="primary"
              onClick={handleSubmit}
            >
              {t('sections.studies.recruit.submitStudy')}
            </Button>
          </>
        ) : null}

        <SummaryInfoMessage />
      </div>
    </aside>
  );
};
