import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent, DialogTitle } from '@askable/ui/components/ui/dialog';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { LoadingState } from 'containers/Studies/Results/components/LoadingState';
import { BookingTaskResultStatus } from 'generated/graphql';

import { Stat } from '../components/Stat';
import { BookingTaskResult } from '../data/BookingTaskResult.query';

import { RoomPlayer } from './RoomPlayer';

type Direction = 'previous' | 'next';

export const RecordingContentBasic = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [navigatingDirection, setNavigatingDirection] = useState<Direction>('next');

  const resultId = searchParams.get('result_id');

  const [taskResultQuery] = useQuery({
    query: BookingTaskResult,
    variables: { filter: { _id: resultId! } },
    pause: !resultId,
  });

  const title =
    taskResultQuery.data?.bookingTaskResult?.user?.displayName ?? taskResultQuery.data?.bookingTaskResult?.user?._id;
  const status = taskResultQuery.data?.bookingTaskResult?.status === 'completed' ? 'successful' : 'incomplete';
  const previousResultId = taskResultQuery.data?.bookingTaskResult?.previousResultId ?? null;
  const nextResultId = taskResultQuery.data?.bookingTaskResult?.nextResultId ?? null;

  const video = taskResultQuery.data?.bookingTaskResult?.video;
  const room = video?.rooms?.sort((a, b) => (b?.startedAt || 0) - (a?.startedAt || 0))?.at(0);

  const resultStatus = taskResultQuery.data?.bookingTaskResult?.status as BookingTaskResultStatus;
  const canHaveVideo =
    resultStatus && [BookingTaskResultStatus.Completed, BookingTaskResultStatus.Skipped].includes(resultStatus);

  // @todo: get duration from the result data field. Value should match the video duration
  const duration = useMemo(() => {
    const end = taskResultQuery.data?.bookingTaskResult?.task_ended;
    const start = taskResultQuery.data?.bookingTaskResult?.task_started;
    if (!end || !start) {
      return null;
    }
    return Math.max(0, (end - start) / 1000);
  }, [taskResultQuery.data?.bookingTaskResult?.task_ended, taskResultQuery.data?.bookingTaskResult?.task_started]);

  useEffect(() => {
    setIsOpen(searchParams.has('result_id') || searchParams.has('path_id') || searchParams.has('screen_id'));
  }, [searchParams]);

  const handleClose = () => {
    setIsOpen(false);

    // Clean up URL params when closing the dialog
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('result_id');
    newSearchParams.delete('path_id');
    newSearchParams.delete('screen_id');
    newSearchParams.delete('tab');
    setSearchParams(newSearchParams);
  };

  // Load the next result with the same path and screen
  const handleNavigation = (direction: Direction, nextId: string | null) => {
    if (!nextId) {
      return;
    }

    setNavigatingDirection(direction);
    setSearchParams({ result_id: nextId });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <DialogContent
        onEscapeKeyDown={event => event.preventDefault()}
        onOpenAutoFocus={event => event.preventDefault()}
        className="bottom-[unset] top-6 flex min-h-80 translate-y-0 flex-col gap-0 border-0 p-0 data-[state=open]:slide-in-from-top-1
          sm:max-h-[calc(100%_-3rem)] sm:max-w-[calc(100%_-2rem)]"
      >
        <div className="grid grid-cols-1 items-center justify-between gap-2 border-b border-border p-4 sm:grid-cols-3">
          <header className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <DialogTitle className="min-w-40 text-lg font-semibold leading-tight">
                {title || t('global.unknown')}
              </DialogTitle>

              <nav className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  title={t('global.previous')}
                  size="icon"
                  disabled={!previousResultId}
                  isLoading={navigatingDirection === 'previous' && taskResultQuery.fetching}
                  onClick={() => handleNavigation('previous', previousResultId)}
                >
                  <ChevronLeft className="h-4 w-4" />
                  <span className="sr-only">{t('global.previous')}</span>
                </Button>
                <Button
                  variant="ghost"
                  title={t('global.next')}
                  size="icon"
                  disabled={!nextResultId}
                  isLoading={navigatingDirection === 'next' && taskResultQuery.fetching}
                  onClick={() => handleNavigation('next', nextResultId)}
                >
                  <ChevronRight className="h-4 w-4" />
                  <span className="sr-only">{t('global.next')}</span>
                </Button>
              </nav>
            </div>

            <div className="flex gap-3 text-sm text-foreground-subtle">
              <Stat type={status} value={t(`sections.studies.results.${status}`)} />
              {duration ? <Stat type="averageDuration" value={duration} /> : null}
            </div>
          </header>

          <div />
        </div>

        <div className="flex flex-col gap-4 p-4 md:flex-row">
          <div className="aspect-video max-h-[75vh] w-full">
            {!canHaveVideo ? (
              <EmptyState title={t('sections.studies.results.video.notCompleted')} />
            ) : room?.composition?.status === 'processing' ? (
              <LoadingState title={t('sections.studies.results.video.processing')} />
            ) : room?.composition?.status !== 'completed' ? (
              <EmptyState title={t('sections.studies.results.video.notFound')} />
            ) : room?._id && video?.code ? (
              <RoomPlayer id={room?._id} code={video?.code} title={title} />
            ) : (
              <EmptyState title={t('sections.studies.results.video.notFound')} />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
