import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { shallow } from 'zustand/shallow';

import { BookingSubmissionConnectionDocument, SegmentSortValue } from 'generated/graphql';

import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';

import type { BookingColumnCustomFilterConfig } from '../utils/booking-participant-utils';
import type {
  BookingSubmissionConnectionQuery,
  BookingSubmissionConnectionQueryVariables,
  SegmentFilterInput,
} from 'generated/graphql';

type Props = {
  bookingId: string;
  isCustomNDA: boolean;
  columnConfig: BookingColumnCustomFilterConfig;
};

export type TableRowSubmission = NonNullable<
  NonNullable<BookingSubmissionConnectionQuery['bookingSubmissionConnection']>['nodes']
>[number];

export function useBookingParticipantsTableData({ bookingId, isCustomNDA, columnConfig }: Props) {
  const segmentInput = useBookingParticipantState(bookingParticipantSelectors.segmentInput, shallow);
  const [currentPage, setCurrentPage] = useBookingParticipantState(
    bookingParticipantSelectors.paginationCurrentPage,
    shallow,
  );
  const [totalCount, setTotalCount] = useState(0);
  const perPageCount = 50;

  const [cursors, setCursors] = useState<Pick<BookingSubmissionConnectionQueryVariables, 'before' | 'after'>>({
    after: null,
    before: null,
  });

  const [{ fetching, data: participantSubmissions, error }, reExecuteQuery] = useQuery<
    BookingSubmissionConnectionQuery,
    BookingSubmissionConnectionQueryVariables
  >({
    pause: !bookingId,
    query: BookingSubmissionConnectionDocument,
    requestPolicy: 'cache-and-network',
    variables: {
      ...(currentPage === 1 ? { after: null, before: null } : cursors),
      first: perPageCount,
      includeAgreement: isCustomNDA,
      includeEmploymentType: columnConfig.employmentType,
      includeFamilyStatus: columnConfig.familyStatus,
      includeEnglishLevel: columnConfig.englishLevel,
      includeIndustry: columnConfig.industry,
      includeWorkType: columnConfig.workStatus,
      filter: {
        condition: segmentInput.method,
        _booking_id: bookingId,
        filters: segmentInput.filters as SegmentFilterInput[],
        sort: [
          ...(segmentInput?.sort || []),
          ...(segmentInput?.sort?.includes(SegmentSortValue.Eligibility) ? [] : [SegmentSortValue.Eligibility]),
        ],
      },
    },
  });

  const handleForwardClick = () => {
    setCursors({
      before: null,
      after: participantSubmissions?.bookingSubmissionConnection?.pageInfo.endCursor,
    });
  };

  const handleBackClick = () => {
    setCursors({
      before: participantSubmissions?.bookingSubmissionConnection?.pageInfo.startCursor,
      after: null,
    });
  };

  useEffect(() => {
    if (typeof participantSubmissions?.bookingSubmissionConnection?.totalCount !== 'number') {
      return;
    }

    setTotalCount(s => {
      if (s === participantSubmissions.bookingSubmissionConnection?.totalCount) {
        return s;
      }

      return participantSubmissions.bookingSubmissionConnection?.totalCount!;
    });
  }, [participantSubmissions?.bookingSubmissionConnection?.totalCount]);

  const refreshData = () => {
    return reExecuteQuery({ requestPolicy: 'cache-and-network' });
  };

  return {
    onForwardClick: handleForwardClick,
    onBackClick: handleBackClick,
    fetching,
    perPageCount,
    currentPage,
    setCurrentPage,
    refreshData,
    count: totalCount,
    submissions: participantSubmissions?.bookingSubmissionConnection?.nodes ?? [],
    pageInfo: participantSubmissions?.bookingSubmissionConnection?.pageInfo,
    isMigrationError: error?.graphQLErrors.some(e => e.message === 'booking not migrated'),
  };
}
