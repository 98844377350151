import { graphql } from 'src/graphql';

export const Booking = graphql(`
  query Booking($id: ObjectID!) {
    unmoderatedBooking(_id: $id) {
      _id
      created
      updated
      name
      type
      status
      total_participants
      config {
        options {
          review_submission
        }
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }

            ... on TaskBlockMultipleChoiceQuestion {
              choices
            }
          }
        }
      }
      recruit {
        participants
        device
        locations {
          type
          location {
            name
            city
            state
            country
            latitude
            longitude
            level
            timezone
            postal_code
            formatted_address
            google_location_types
            region
            google_location {
              place_id
              name
              address_components {
                long_name
                short_name
                types
              }
              geometry {
                latitude_ne
                longitude_ne
                latitude_sw
                longitude_sw
                width
                height
                radius_min
                radius_max
              }
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
              map_polygon_id
            }
            currency_code
            currency_symbol
          }
        }
        age_range
        gender
        industry_and_occupation {
          _id
          name
          subcategories {
            _id
            name
          }
        }
        language_fluency
        education_level
        marital_status
        occupational_status
        business_ownership
        screener_questions {
          _id
          type
          title
          description
          is_multiple_selection
          options {
            _id
            label
            is_qualified
          }
        }
        participant_selection
        participants_excluded_months
        participant_source
        custom_terms {
          recipients
          _template_id
        }
      }
      contact {
        _primary_contact_id
      }
      listing_information {
        title
        description
      }
    }
  }
`);
