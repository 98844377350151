import { Button } from '@askable/ui/components/ui/button';
import { Figma } from '@askable/ui/components/unmod/icons';
import { PermissionsList } from '@askable/ui/components/unmod/permissions-list';
import { TaskCard, TaskCardHeader } from '@askable/ui/components/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableContent } from '@askable/ui/components/unmod/task-card-draggable';
import { cn } from '@askable/ui/lib/utils';
import { ArrowRight } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useTaskNavigation } from 'containers/Studies/Preview/hooks/useTaskNavigation';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';
import { SystemBlockType, TaskBlockType } from 'generated/graphql';

import type {
  SystemBlock,
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  UnmoderatedBookingConfig,
} from 'generated/graphql';

const welcomeBlock = {
  _id: 'welcome',
  instructions: '',
  title: '',
  type: 'welcome',
} as SystemBlock;

interface TaskContainerProps {
  studyConfig: UnmoderatedBookingConfig;
}

const getActiveTask = (config: UnmoderatedBookingConfig, activeBlockId: string | null) => {
  if (activeBlockId === 'welcome') {
    return config.welcome_block as SystemBlock;
  }

  if (activeBlockId === 'thank_you') {
    return config.thank_you_block as SystemBlock;
  }

  return (
    (config.task_blocks.find(task => task?._id === activeBlockId) as
      | TaskBlockFigmaPrototype
      | TaskBlockMultipleChoiceQuestion) ?? welcomeBlock
  );
};

export const TaskContainer = ({ studyConfig }: TaskContainerProps) => {
  const { t } = useTranslation();
  const { activeBlockId, handleProgress } = useTaskNavigation(studyConfig);
  const activeBlock = getActiveTask(studyConfig, activeBlockId);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' && studyConfig.task_blocks.some(task => task?.is_recording_enabled)) ?? false;

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      'figma_prototype' in activeBlock && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCollapsed(false);
    }
  };

  const id = '_id' in activeBlock ? activeBlock._id : activeBlock.type;

  return (
    <div
      className={cn('h-full w-full p-4 lg:p-6', {
        'p-0 lg:p-0': activeBlock.type === 'figma_prototype',
      })}
    >
      {match(activeBlock.type)
        .with(SystemBlockType.Welcome, SystemBlockType.ThankYou, () => (
          <TaskCard
            id={id}
            action={
              <Button
                className="flex-1 rounded-xl md:flex-none"
                variant="primary"
                size="xl"
                onClick={activeBlock.type === SystemBlockType.Welcome ? () => handleProgress('next') : undefined}
              >
                {/* TODO: better translation naming! */}
                {activeBlock.type === SystemBlockType.Welcome
                  ? t('sections.studies.prototypeCalloutCta')
                  : t('sections.studies.backToAskable')}
                <ArrowRight className="hidden md:inline-flex" />
              </Button>
            }
          >
            <TaskCardHeader instructions={activeBlock.instructions} title={activeBlock.title} type={activeBlock.type} />
            {isRecordingEnabled ? (
              <div className="px-4 md:px-8">
                <PermissionsList
                  description={t('sections.studies.permissions.description')}
                  permissions={[
                    { key: 'camera', description: t('sections.studies.permissions.camera') },
                    { key: 'microphone', description: t('sections.studies.permissions.microphone') },
                  ]}
                />
              </div>
            ) : null}
          </TaskCard>
        ))
        .with(TaskBlockType.FigmaPrototype, () => (
          // TODO (ASK-9471): this preview is currently not accurate, it does not render the introduction state and jumps straight
          // into the task instead. Imo not worth fixing this here, and instead start moving the preview back to sessions
          <>
            {'figma_prototype' in activeBlock && !activeBlock.figma_prototype?.file_id ? (
              <div className="drag-container relative h-full w-full @container">
                <div className="h-full w-full bg-prototype p-6">
                  <div className="flex h-full w-full items-center justify-center">
                    <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                      <Figma size={48} />
                      {t('sections.studies.previewNoPrototype')}
                    </div>
                  </div>
                  <TaskCardDraggable
                    collapseLabel={t('sections.studies.hideInstructions')}
                    id={activeBlockId}
                    isDisabled
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    title={t('sections.studies.tasks', { count: 1 })}
                    action={
                      <Button
                        className="flex-1 rounded-xl md:flex-none"
                        variant="primary"
                        size="xl"
                        onClick={() => handleProgress('next')}
                      >
                        {t('sections.studies.endTask')}
                      </Button>
                    }
                  >
                    <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                  </TaskCardDraggable>
                </div>
              </div>
            ) : null}

            {'figma_prototype' in activeBlock && activeBlock.figma_prototype?.file_id ? (
              <FigmaPreview
                key={activeBlockId}
                previewMode
                activeBlockId={activeBlockId}
                fileId={activeBlock.figma_prototype.file_id}
                startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
                onSelectedNode={handleSelectedNode}
              >
                <TaskCardDraggable
                  id={activeBlockId}
                  isCollapsed={isCollapsed}
                  isDraggable={!isGoalScreen}
                  setIsCollapsed={setIsCollapsed}
                  title={t('sections.studies.tasks', { count: 1 })}
                  collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                  action={
                    <Button
                      className="flex-1 rounded-xl md:flex-none"
                      variant="primary"
                      size="xl"
                      onClick={() => handleProgress('next')}
                    >
                      {isGoalScreen ? t('global.continue') : t('sections.studies.endTask')}
                    </Button>
                  }
                >
                  <TaskCardDraggableContent
                    status={isGoalScreen ? 'success' : undefined}
                    title={isGoalScreen ? t('sections.studies.taskSuccessTitle') : activeBlock.title}
                    instructions={
                      isGoalScreen ? t('sections.studies.taskSuccessDescription') : activeBlock.instructions
                    }
                  />
                </TaskCardDraggable>
              </FigmaPreview>
            ) : null}
          </>
        ))
        .with(TaskBlockType.MultipleChoiceQuestion, () => {
          // TODO: Not implemented yet
          return null;
        })
        .exhaustive()}
    </div>
  );
};
