/* eslint-disable max-lines */
import { Button } from '@askable/ui/components/ui/button';
import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Label } from '@askable/ui/components/ui/label';
import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useSubscription } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';

import { BookingMessageItem } from './components/BookingMessageItem';
import { Header } from './components/Header';
import { NoMessages } from './components/NoMessages';
import { PanelBatchMessages } from './components/PanelBatchMessages';
import { PanelMessages } from './components/PanelMessages';
import { BookingSubmissionsWithMessages } from './data/BookingSubmissionsWithMessages.query';
import { SubscribeListMessages } from './data/ListMessages.subscription';

import type { Submission, Submissions, User } from './data/BookingSubmissionsWithMessages.query';
import type { SupportedSubmissionStatus } from './types/submissionStatus';

// For pagination. Default: 50 submissions per page
const PER_PAGE = 50;

const BookingMessagesContainer = () => {
  const { t } = useTranslation();
  const params = useParams<{ bookingId: string; studyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { details } = useConnectedClient();

  const [activeStatuses, setActiveStatusess] = useState<SupportedSubmissionStatus[]>([]);
  const [activeUsers, setActiveUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(
    (searchParams.get('page') ?? '') ? parseInt(searchParams.get('page') ?? '1') : 1,
  );
  const [submissionData, setSubmissionData] = useState<Submissions | undefined>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [{ data, fetching, stale }, refetch] = useQuery({
    query: BookingSubmissionsWithMessages,

    requestPolicy: 'network-only',
    variables: {
      bookingId: params.studyId ?? params.bookingId!,
      limit: PER_PAGE,
      page: currentPage,
      pause: !params.bookingId && !params.studyId,
      status: activeStatuses,
    },
  });

  // Subscribe for new messages
  useSubscription(
    {
      query: SubscribeListMessages,
      pause: fetching,
      variables: {
        filter: {
          _booking_id: params.studyId ?? params.bookingId!,
        },
      },
    },
    (_previous, response) => {
      if (response.messagesSubscription) {
        // Incoming message
        const newMessage = response.messagesSubscription;

        const messageIndex =
          submissionData?.nodes.findIndex(
            submission =>
              newMessage._from_user_id === submission?.user?._id || newMessage._to_user_id === submission?.user?._id,
          ) ?? -1;

        if (messageIndex !== -1) {
          // Update existing conversation
          const updatedMessage = {
            _id: newMessage._id,
            _from_user_id: newMessage._from_user_id,
            _to_user_id: newMessage._to_user_id,
            body: newMessage.body,
            created: newMessage.created,
            direction: newMessage.direction,
            seen: newMessage.seen,
            type: newMessage.type,
            UserFrom: newMessage.UserFrom,
          };

          setSubmissionData(prevData => {
            if (!prevData) {
              return prevData;
            }

            return {
              ...prevData,
              nodes: prevData.nodes.map((submission, index) => {
                return index === messageIndex
                  ? ({ ...submission, Messages: [updatedMessage] } as Submission)
                  : submission;
              }),
            };
          });
        } else {
          // Refetch to load the new conversation
          refetch();
        }
      }

      return response;
    },
  );

  // Update message data when new data is fetched
  useEffect(() => {
    if (data?.bookingSubmissionsWithMessages) {
      setSubmissionData(prevData => {
        const newData = data.bookingSubmissionsWithMessages;

        if (!prevData || !newData) {
          return newData ?? undefined;
        }

        // Merge the new submissions with the existing ones
        const updatedSubmissions = newData.nodes.map(
          submission => prevData.nodes.find(m => m?._id === submission?._id) || submission,
        );

        return {
          nodes: updatedSubmissions,
          currentPage: newData.currentPage,
          totalCount: newData.totalCount,
          totalPages: newData.totalPages,
        };
      });
    }
  }, [data]);

  // If batch message is open, update list of users when selecting/deselecting rows
  useEffect(() => {
    if (activeUsers.length > 0) {
      showBatchMessage();
    }
  }, [selectedRows]);

  // Reset page to 1 and refetch messages
  const refetchMessages = () => {
    setActiveStatusess([]);
    setSearchParams({ page: '1' });
    setCurrentPage(1);
    refetch();
  };

  // Fetch messages for the selected page
  const handlePageChange = (page = 1) => {
    refetch({
      _booking_id: params.bookingId!,
      limit: PER_PAGE,
      page,
    });

    setCurrentPage(page);
    setSearchParams({ page: String(page) });
    setActiveUsers([]);
  };

  // Open the message panel for a single user
  const openMessagePanel = (userId: string) => {
    const targetMessage = submissionData?.nodes.find(m => m?.user?._id === userId);

    if (targetMessage) {
      setActiveUsers(
        prevData => (prevData.findIndex(u => u?._id === userId) !== -1 ? [] : [targetMessage?.user]) as User[],
      );

      // Hack to mark the message as seen
      if (targetMessage?.Messages && targetMessage.Messages[0]) {
        targetMessage.Messages[0].seen = 1;
      }
    }
  };

  // Open the message panel for multiple users
  const showBatchMessage = () => {
    if (selectedRows.length === 1) {
      openMessagePanel(selectedRows[0]);
    } else {
      // Open the batch message panel
      // @ts-expect-error fix the null issues with GraphQL types
      const users: User[] = submissionData?.nodes
        .filter(m => selectedRows.includes(m?.user?._id ?? ''))
        ?.map(m => m?.user);
      setActiveUsers(users);
    }
  };

  // Filter users by submission status
  const handleFilterChange = (filter: SupportedSubmissionStatus | null) => {
    if (filter === null) {
      setActiveStatusess([]);
    } else {
      setActiveStatusess(prevData => {
        return prevData.includes(filter) ? prevData.filter(f => f !== filter) : [...prevData, filter];
      });
    }

    setSelectedRows([]);
  };

  // Select a message row
  const selectRow = (userId: string) => {
    setSelectedRows(prevData => {
      const updatedRows = prevData.includes(userId) ? prevData.filter(id => id !== userId) : [...prevData, userId];
      return updatedRows.filter((id): id is string => id !== undefined);
    });
  };

  // Select all message rows
  const selectAllRows = () => {
    setSelectedRows(prevData => {
      if (prevData.length > 0) {
        return [];
      }

      return (
        submissionData?.nodes.map(submission => submission?.user?._id).filter((id): id is string => id !== undefined) ||
        []
      );
    });
  };

  const onNavigateMessages = (userId: string | null) => {
    if (!userId) {
      return;
    }

    const user = submissionData?.nodes.find(m => m?.user?._id === userId)?.user;
    if (user) {
      setActiveUsers([user]);
    }
  };

  // Get the previous and next message for the active user to navigate between messages
  const getPreviousNextMessage = (userId?: string) => {
    if (!userId) {
      return { previous: null, next: null };
    }

    const activeMessageIndex = submissionData?.nodes.findIndex(m => m?.user?._id === userId) ?? 0;

    return {
      previous: submissionData?.nodes[activeMessageIndex - 1]?.user?._id ?? null,
      next: submissionData?.nodes[activeMessageIndex + 1]?.user?._id ?? null,
    };
  };

  return (
    <div className="relative h-full overflow-auto dark:bg-background">
      <Header
        activeStatuses={activeStatuses}
        bookingId={params.bookingId ?? ''}
        currentPage={currentPage}
        fetching={fetching || stale}
        perPage={PER_PAGE}
        totalCount={submissionData?.totalCount ?? 0}
        totalPages={submissionData?.totalPages ?? 1}
        onFilterChange={handleFilterChange}
        onPageChange={handlePageChange}
        onRefresh={refetch}
      />

      <div className="flex flex-1">
        <main className="flex w-full flex-col gap-4 overflow-auto py-4 text-foreground">
          {fetching && !submissionData ? (
            <div className="flex max-w-5xl flex-col gap-1 px-4">
              <Skeleton className="h-10 w-2/5" />
              <Skeleton className="h-10 w-2/5" />
              <Skeleton className="h-10 w-1/5" />
            </div>
          ) : null}

          {submissionData?.nodes && submissionData.nodes.length === 0 ? <NoMessages onRetry={refetchMessages} /> : null}

          {submissionData?.nodes && submissionData.nodes.length > 0 ? (
            <>
              <div className="flex items-center gap-2 px-4">
                <Checkbox
                  id="select-all"
                  onCheckedChange={selectAllRows}
                  checked={
                    selectedRows.length > 0 && selectedRows.length < submissionData.nodes.length
                      ? 'indeterminate'
                      : selectedRows.length === submissionData.nodes.length
                  }
                />
                {selectedRows.length > 0 ? (
                  <Button variant="primary" className="-my-2" onClick={showBatchMessage}>
                    {t('sections.messages.messagePeople', { count: selectedRows.length })}
                  </Button>
                ) : (
                  <Label htmlFor="select-all" className="text-xs text-foreground-subtle">
                    {t('global.selectAll')}
                  </Label>
                )}
              </div>

              <ol className="flex flex-col divide-y divide-border">
                {submissionData.nodes.map(submission => (
                  <BookingMessageItem
                    clientId={details?._id ?? ''}
                    isActive={!!(activeUsers && activeUsers[0]?._id === submission?.user?._id)}
                    key={
                      submission?.Messages
                        ? submission.Messages?.[submission.Messages.length - 1]?._id
                        : submission?._id
                    }
                    selectedRows={selectedRows}
                    submission={submission!}
                    openMessagePanel={openMessagePanel}
                    selectRow={selectRow}
                  />
                ))}
              </ol>
            </>
          ) : null}
        </main>

        <PanelMessages
          key={activeUsers[0]?._id}
          bookingId={params?.bookingId ?? ''}
          fromUserId={details?._id ?? ''}
          user={activeUsers.length === 1 ? activeUsers[0] : null}
          previousNextMessages={getPreviousNextMessage(activeUsers[0]?._id)}
          onClose={() => setActiveUsers([])}
          onNavigateMessages={onNavigateMessages}
        />

        <PanelBatchMessages
          bookingId={params?.bookingId ?? ''}
          users={activeUsers.length > 1 ? activeUsers : []}
          onClose={() => {
            setActiveUsers([]);
            refetchMessages();
          }}
        />
      </div>
    </div>
  );
};

export default BookingMessagesContainer;
