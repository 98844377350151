import differenceInMinutes from 'date-fns/differenceInMinutes';
import { formatSessionTime, pluralize } from 'shared-utils';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, ClockIcon, Flex, HStack, Text, TickIcon } from 'ui';

import { BookingParticipantPptTimezoneIcon } from './BookingParticipantPptTimezoneIcon';

import type { BookingSession } from 'generated/graphql';
import type { FC } from 'react';

export type BookingSessionWithOtherTimes = BookingSession & {
  // Selected count is for the bulk invite flow
  selectedCount: number;
  invitedCount: number;
  confirmedCount: number;
};

type PropsStatusLabelComponent = {
  inviteCount: number;
  selectedCount: number;
  confirmedCount: number;
};

export const SessionTimeSlotStatusLabel: FC<PropsStatusLabelComponent> = ({
  inviteCount,
  confirmedCount,
  selectedCount,
}) => {
  const label = (() => {
    if (confirmedCount > 0) {
      return `${pluralize('people', confirmedCount, true)} already confirmed`;
    }

    if (inviteCount > 0) {
      return `${pluralize('people', inviteCount, true)} already invited`;
    }

    if (selectedCount > 0) {
      return `${pluralize('people', selectedCount, true)} already selected`;
    }

    return '';
  })();

  if (!label) {
    return null;
  }

  return (
    <Text fontSize="sm" fontWeight="semibold" mt="1" aria-label={label}>
      {label}
    </Text>
  );
};

type PropsSessionTimeAlertComponent = {
  invitedCount: number;
  confirmedCount: number;
  sessionStart: number;
  displayName: string;
};

export const SessionTimeAlertComponent: FC<PropsSessionTimeAlertComponent> = ({
  confirmedCount,
  invitedCount,
  sessionStart,
  displayName,
}) => {
  const alertProperties = (() => {
    if (confirmedCount > 0) {
      return {
        title: 'Someone already confirmed',
        content:
          'Someone is already confirmed for this session. Any additional invitees will get added to the wait list.',
      };
    }

    if (invitedCount > 0) {
      return {
        title: 'Someone already invited',
        content:
          'The first person who accepts will be assigned the session. All other invitees will get added to the wait list.',
      };
    }

    // Check whether the session is starting soon
    const minutesToStartSession = differenceInMinutes(sessionStart, new Date());

    if (minutesToStartSession <= 60) {
      return {
        title: 'Session starting soon',
        content: `This session is starting very soon (in ${minutesToStartSession} minutes). ${displayName ?? 'The participant'} may not have enough notice to accept this invite.`,
      };
    }

    return null;
  })();

  if (!alertProperties) {
    return null;
  }

  return (
    <Alert status="info" w="full">
      <AlertIcon />
      <Box>
        <AlertTitle>{alertProperties.title}</AlertTitle>
        <AlertDescription>{alertProperties.content}</AlertDescription>
      </Box>
    </Alert>
  );
};

type PropsSessionTimeSlot = {
  sessionStart: number;
  sessionEnd: number;
  confirmedCount: number;
  selectedCount: number;
  invitedCount: number;
  pptTimezone: string;
  isSelected: boolean;
  isPptInDifferentTimezone: boolean;
  displayName: string;
};

export const SessionTimeSlot: FC<PropsSessionTimeSlot> = ({
  sessionStart,
  sessionEnd,
  confirmedCount,
  selectedCount,
  invitedCount,
  pptTimezone,
  isSelected,
  isPptInDifferentTimezone,
  displayName,
}) => {
  const sessionTime = formatSessionTime({ start: sessionStart!, end: sessionEnd! });

  return (
    <Flex>
      {isSelected ? <TickIcon mt="0.5" color="blue.500" /> : <ClockIcon mt="1" />}
      <Flex direction="column" ml="3">
        <HStack>
          <Text>{sessionTime}</Text>
          {isPptInDifferentTimezone && (
            <BookingParticipantPptTimezoneIcon
              displayName={displayName}
              sessionEnd={sessionEnd}
              sessionStart={sessionStart}
              pptTimezone={pptTimezone}
            />
          )}
        </HStack>
        <SessionTimeSlotStatusLabel
          selectedCount={selectedCount}
          confirmedCount={confirmedCount}
          inviteCount={invitedCount}
        />
      </Flex>
    </Flex>
  );
};
