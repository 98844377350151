import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Fragment, useCallback } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import type { ReactNode } from 'react';

interface BaseItem {
  _id: string;
}

interface SortableListProps<T extends BaseItem> {
  containerClass?: string;
  items: T[];
  isDragging?: (value: boolean) => void;
  onChange: (items: T[]) => void;
  renderItem: (item: T, index: number) => ReactNode;
}

export function SortableList<T extends BaseItem>({
  containerClass,
  items,
  isDragging,
  onChange,
  renderItem,
}: SortableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const activeIndex = items.findIndex(item => item._id === active.id);
        const overIndex = items.findIndex(item => item._id === over.id);
        onChange(arrayMove(items, activeIndex, overIndex));
      }

      isDragging?.(false);
    },
    [items, onChange, isDragging],
  );

  const ids = items.map(item => item._id);
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={() => {
        navigator?.vibrate?.(50);
        isDragging?.(true);
      }}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext items={ids}>
        <ul className={`${'relative z-10 flex w-full flex-col'} ${containerClass}`}>
          {items.map((item, index) => (
            <Fragment key={item._id}>{renderItem(item, index)}</Fragment>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
}
