import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
} from 'ui';

import { useDeleteSegmentMutation } from 'generated/graphql';

import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';

import type { Segment } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  isOpen: boolean;
  bookingId: string;
  segment: Segment;
  onClose: () => void;
};

export const DeleteSegmentModal: FC<Props> = ({ isOpen, onClose, bookingId }) => {
  const segment = useBookingParticipantState(bookingParticipantSelectors.segment);
  const navigate = useNavigate();
  const removeSegment = useBookingParticipantState(a => a.deleteSegment);
  const cancelRef = useRef(null);

  const [deleteSegment, { loading }] = useDeleteSegmentMutation();

  const handleConfirmDeleteSegment = async () => {
    try {
      const result = await deleteSegment({
        variables: {
          booking_id: bookingId,
          segment_id: segment?._id!,
        },
      });

      removeSegment(result.data?.deleteSegment!);

      onClose();

      navigate(`/bookings/${bookingId}/participants/all`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <Heading fontSize="2xl">Delete segment?</Heading>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you want to delete <strong>{segment?.name}</strong>?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button colorScheme="red" ml={3} onClick={handleConfirmDeleteSegment} isLoading={loading}>
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
