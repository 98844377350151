import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, useDisclosure } from 'ui';

import { SaveSegmentModal } from '../modals/SaveSegmentModal';
import { UpdateSegmentModal } from '../modals/UpdateSegmentModal';
import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';

import type { Segment } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  bookingId: string;
};

export const ParticipantSegmentActions: FC<Props> = ({ bookingId }) => {
  const navigate = useNavigate();

  const availableSegmentActions = useBookingParticipantState(bookingParticipantSelectors.availableSegmentActions);
  const addSegment = useBookingParticipantState(state => state.addSegment);
  const resetSegment = useBookingParticipantState(state => state.resetSegment);
  const updateSegment = useBookingParticipantState(state => state.updateSegment);
  const clearPersistedActions = useBookingParticipantState(a => a.clearPersistedActions);
  const { isOpen: saveSegmentIsOpen, onClose: closeSaveSegment, onOpen: openSaveSegment } = useDisclosure();
  const { isOpen: updateSegmentIsOpen, onClose: closeUpdateSegment, onOpen: openUpdateSegment } = useDisclosure();

  const handleSaveSegment = useCallback(
    (segment?: Segment) => {
      closeSaveSegment();

      addSegment(segment!);
      // Clear all stored filters, fields or sort values from storage to reset all view
      clearPersistedActions();

      navigate(`/bookings/${bookingId}/participants/${segment?._id}`);
    },
    [bookingId],
  );

  const handleUpdateSegment = useCallback(
    (segment?: Segment) => {
      closeUpdateSegment();

      updateSegment(segment!);
    },
    [bookingId],
  );

  if (!availableSegmentActions) {
    return null;
  }

  return (
    <div className="flex border-border md:border-l md:px-2">
      {availableSegmentActions === 'save' && (
        <Button size="sm" colorScheme="blue" onClick={openSaveSegment}>
          Save as segment...
        </Button>
      )}

      {availableSegmentActions === 'update' && (
        <ButtonGroup size="sm">
          <Button onClick={openSaveSegment}>Save new segment...</Button>
          <Button colorScheme="blue" onClick={openUpdateSegment}>
            Update
          </Button>
          <Button variant="ghost" onClick={resetSegment}>
            Reset
          </Button>
        </ButtonGroup>
      )}

      <SaveSegmentModal
        isOpen={saveSegmentIsOpen}
        onClose={closeSaveSegment}
        onSaveComplete={handleSaveSegment}
        bookingId={bookingId}
      />

      <UpdateSegmentModal
        onClose={closeUpdateSegment}
        isOpen={updateSegmentIsOpen}
        onUpdateComplete={handleUpdateSegment}
        bookingId={bookingId}
      />
    </div>
  );
};
