import { Button } from '@askable/ui/components/ui/button';
import { cn } from '@askable/ui/lib/utils';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { ChevronRight, EyeOff } from 'lucide-react';
import { useState, useRef, useEffect, memo, useLayoutEffect } from 'react';

import { useContainer } from './hooks';
import { TaskCardDraggableHeader } from './task-card-draggable-header';

import type { CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';

export const CONFIG = {
  CONTAINER: '.drag-container',
  COLLAPSED_SIZE: { h: 60, w: 36 }, // Size of the collapsed card
  COLLAPSED_Y: 84, // Distance from the bottom of the container when collapsed
  DRAGGABLE_MIN_WIDTH: 480,
  EDGE_OFFSET: 8,
  INITIAL_POSITION: { x: 8, y: 8 },
  SNAP_THRESHOLD: 24, // Snap to edge when within this distance
};

interface Props {
  id: string;
  title: string;
  children: ReactNode;
  isCollapsed?: boolean;
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>;
  collapseLabel?: string;
  isDisabled?: boolean;
  isDraggable?: boolean;
  isPositionSaved?: boolean;
  action?: ReactNode;
  position: {
    x: number;
    y: number;
  };
}

export const TaskCardDraggableInner = memo(function TaskCardDraggableInnerMemo({
  id,
  title,
  children,
  isDisabled,
  isCollapsed,
  setIsCollapsed,
  collapseLabel = 'Collapse',
  isDraggable: isDraggableProp,
  action,
  position,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { containerWidth } = useContainer(`card-${id}`, true);

  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [isFinishedDragging, setIsFinishedDragging] = useState(false);

  // Disable dragging on mobile
  const isDraggable = isDraggableProp && containerWidth >= CONFIG.DRAGGABLE_MIN_WIDTH;
  const disabled = isDisabled || !isDraggable || isCollapsed;
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({ id, disabled });

  const cardStyle: CSSProperties = {
    position: 'absolute',
    height: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.h}px` : contentHeight ? `${contentHeight}px` : 'auto',
    width: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.w}px` : undefined,
    left: isCollapsed ? 0 : `${position.x}px`,
    bottom: isCollapsed ? `${CONFIG.COLLAPSED_Y}px` : `${position.y}px`,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
  };

  // Recalculate height when card content changes in editor
  // NOTE: we run this intentionally on every render, because whenever children re-render the inner
  // content dimensions might change. this might backfire if it becomes too expensive
  useLayoutEffect(() => {
    if (contentRef.current && contentHeight !== contentRef.current.offsetHeight && !isCollapsed) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  });

  // When not dragging add an duration for the collapsible animation
  const finishedDraggingTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    clearTimeout(finishedDraggingTimer.current);
    if (isDragging) {
      setIsFinishedDragging(false);
    } else {
      finishedDraggingTimer.current = setTimeout(() => setIsFinishedDragging(true), 501);
    }
  }, [isDragging]);

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={cardStyle}
      className={cn('z-20 grid w-full max-w-[380px] origin-center overflow-hidden rounded-xl bg-background shadow', {
        'rounded-l-none focus-within:shadow-lg hover:shadow-lg': isCollapsed,
        'shadow-lg': isDragging,
        'duration-500 ease-anticipation motion-reduce:transition-none': isFinishedDragging,
      })}
    >
      <div
        ref={contentRef}
        className={cn(
          `col-start-1 col-end-2 row-start-1 row-end-2 flex w-full max-w-[380px] flex-col justify-between transition-all delay-200
          motion-reduce:transition-none`,
          {
            'overflow-hidden opacity-0 ': isCollapsed,
            'pt-4': !isDraggable,
          },
        )}
      >
        {isDraggable ? (
          <TaskCardDraggableHeader title={title} dragHandleProps={{ ...attributes, ...listeners }} />
        ) : null}

        <div className="flex max-h-[50vh] flex-1 flex-col gap-4 overflow-auto px-4 pb-4">{children}</div>

        <footer className="flex items-center justify-between gap-2 border-t-0.5 border-border px-4 pb-4 pt-4">
          {setIsCollapsed ? (
            <Button variant="ghost" className="gap-2 text-muted-foreground" onClick={() => setIsCollapsed(true)}>
              <EyeOff className="h-4 w-4" /> {collapseLabel}
            </Button>
          ) : (
            <div />
          )}

          {action}
        </footer>
      </div>

      {setIsCollapsed ? (
        <button
          // TODO: see how we can more easily bring translations into the ui library
          aria-label={isCollapsed ? 'Expand' : 'Collapse'}
          aria-expanded={!isCollapsed}
          className={cn(
            `pointer-events-none z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex -translate-x-2 flex-col items-center
            justify-center opacity-0 transition-all focus:outline-none focus:ring-2 focus:ring-primary`,
            {
              [`opacity-200 pointer-events-auto translate-x-0 cursor-pointer delay-300 hover:scale-110 focus:scale-110
              motion-reduce:transition-none`]: isCollapsed,
            },
          )}
          style={{ height: `${CONFIG.COLLAPSED_SIZE.h}px`, width: `${CONFIG.COLLAPSED_SIZE.w}px` }}
          onClick={() => setIsCollapsed(false)}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      ) : null}
    </div>
  );
});
