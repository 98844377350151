import { FormField, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { isMobileByWidth } from '@askable/ui/lib/utils';
import { ErrorMessage } from '@hookform/error-message';
import { ArrowRight } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';
import { FigmaFileDetails } from 'containers/Studies/BuildStudy/data/FigmaFileDetails.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InfoPopover } from 'containers/Studies/components/InfoPopover';

import { SelectScreenButton } from './SelectScreenButton';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

interface FigmaScreensFieldProps {
  isDisabled?: boolean;
}

export const FigmaScreensField = ({ isDisabled }: FigmaScreensFieldProps) => {
  const { t } = useTranslation();
  const { setActiveSection } = useStudyContext();
  const { control, formState, setValue, watch } = useFormContext<BlockFormFields>();
  const { toggleScreenSelection, setIsCardCollapsed } = useBlockContext();

  const fileId = watch('figma_prototype.file_id');
  const [figmaFileQuery] = useQuery({
    query: FigmaFileDetails,
    pause: !fileId,
    variables: { fileId: fileId! },
  });
  const figmaFile = figmaFileQuery.data?.figmaFile ?? null;

  if (!fileId) {
    return null;
  }

  return (
    <FormField
      control={control}
      name="figma_prototype"
      render={({ field }) => {
        const startFrame = figmaFile?.frames?.find(frame => frame.node_id === field.value.start_screen_id) || null;
        const goalFrame = figmaFile?.frames?.find(frame => frame.node_id === field.value.goal_screen_id) || null;

        const isStartMobile = isMobileByWidth(startFrame?.width);
        const isGoalMobile = isMobileByWidth(goalFrame ? goalFrame?.width : startFrame?.width);

        return (
          <>
            <Input type="hidden" name={field.name} />

            <FormItem>
              <div className="flex flex-col">
                <div className="relative flex items-center justify-between gap-2">
                  <FormLabel
                    hasErrorStyle={false}
                    className="flex w-fit items-center gap-1"
                    aria-describedby="prototype-screens-description"
                  >
                    {t('sections.studies.build.formFields.figmaPrototypeScreens.label')}
                    <InfoPopover
                      title={t('sections.studies.build.formFields.figmaPrototypeScreens.infoTitle')}
                      description={t('sections.studies.build.formFields.figmaPrototypeScreens.infoDescription')}
                      moreLink="https://help.askable.com"
                    />
                  </FormLabel>
                </div>
                <div className="text-pretty text-xs text-muted-foreground" id="prototype-screens-description">
                  {t('sections.studies.build.formFields.figmaPrototypeScreens.description')}
                </div>
              </div>

              <div className="flex items-center justify-evenly rounded-xl border-0.5 border-border bg-background-subtle p-3 text-sm">
                <SelectScreenButton
                  screenType="start"
                  isMobile={isStartMobile}
                  isDisabled={isDisabled}
                  frame={startFrame ?? null}
                  onClick={() => {
                    toggleScreenSelection({
                      mode: 'start',
                      onSave: nodeId => {
                        // Reset the goal screen whenever the start screen changes, cause we can't be sure that the
                        // new start screen is still connected to the previously selected goal screen
                        setValue('figma_prototype.start_screen_id', nodeId);
                        setValue('figma_prototype.goal_screen_id', '');
                      },
                    });
                  }}
                />
                <ArrowRight className="h-6 w-6 opacity-40" />
                <SelectScreenButton
                  screenType="goal"
                  isMobile={isGoalMobile}
                  isDisabled={isDisabled}
                  frame={goalFrame}
                  onClick={() => {
                    setIsCardCollapsed(true);
                    setActiveSection('preview');
                    toggleScreenSelection({
                      mode: 'goal',
                      onSave: nodeId => {
                        setValue('figma_prototype.goal_screen_id', nodeId);
                      },
                    });
                  }}
                />
              </div>

              <ErrorMessage
                // HACK: we're combining the errors of the two individual fields since we're handling them
                // in one field component,
                errors={{
                  figma_prototype:
                    'figma_prototype' in formState.errors
                      ? formState.errors?.figma_prototype?.start_screen_id ||
                        formState.errors?.figma_prototype?.goal_screen_id
                      : undefined,
                }}
                name={field.name}
                render={({ message }) => (
                  <FormErrorMessage message={message} prefix="sections.studies.formValidation" />
                )}
              />
            </FormItem>
          </>
        );
      }}
    />
  );
};
