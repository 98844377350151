import { Monitor, MonitorSmartphone, Smartphone } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { DEVICE_OPTIONS } from 'containers/Studies/data/schemas/recruitSchema';
import type { LucideIcon } from 'lucide-react';

type DeviceType = (typeof DEVICE_OPTIONS)[number];

interface SummaryParticipantsDeviceProps {
  participants: number;
  device: DeviceType;
}

const deviceIconByType: Record<DeviceType, LucideIcon> = {
  any_device: MonitorSmartphone,
  desktop: Monitor,
  mobile: Smartphone,
};

export const SummaryParticipantsDevice = ({ participants = 0, device }: SummaryParticipantsDeviceProps) => {
  const { t } = useTranslation();
  const Icon = deviceIconByType[device as DeviceType];

  return (
    <div className="grid grid-cols-2 divide-x-0.5 rounded-md border-0.5 border-border p-4 text-center">
      <div className="flex flex-col items-center gap-1 truncate text-sm">
        <div className="text-3xl font-semibold">{participants > 0 ? participants : 0}</div>
        {t('sections.studies.recruit.participants', { count: participants })}
      </div>

      {device ? (
        <div className="flex flex-col items-center gap-1 text-sm">
          <Icon className="h-9 w-9" />
          {t(`sections.studies.recruit.options.device.${device as DeviceType}`)}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-1 text-sm text-input">
          <MonitorSmartphone className="h-9 w-9" />
          {t('sections.studies.recruit.selectDevice')}
        </div>
      )}
    </div>
  );
};
