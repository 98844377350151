import { Input } from '@askable/ui/components/ui/input';
import { Table, TableBody, TableCell, TableRow, TableHeader, TableHead } from '@askable/ui/components/ui/table';
import { format, formatISO } from 'date-fns';
import { User, Video, ListTodo, MousePointerClick, Repeat2, MessageSquareText } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedSearch } from 'shared-utils/hooks';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { getResolvedBookingType } from 'utils/booking-utils';

import type { Booking } from '../../data/BookingsByTeamId.query';
import type { LucideIcon } from 'lucide-react';
import type { RESOLVED_BOOKING_TYPES } from 'utils/booking-utils';

type StudyType = keyof typeof RESOLVED_BOOKING_TYPES;

interface ScreenerQuestionsCopyStudiesProps {
  studies: Booking[];
  onSelectStudy: (study: Booking) => void;
}

export const ScreenerQuestionsCopyStudies = ({ studies, onSelectStudy }: ScreenerQuestionsCopyStudiesProps) => {
  const { t } = useTranslation();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { searchTerm: search, handleChange: setSearch } = useDebouncedSearch({
    defaultSearchTerm: '',
    debounceFunction: setDebouncedSearch,
  });

  const studyType: Record<StudyType, { label: string; Icon: LucideIcon }> = {
    IN_PERSON: {
      label: t('studyTypes.in_person'),
      Icon: User,
    },
    VIDEO_CALL: {
      label: t('studyTypes.video_call'),
      Icon: Video,
    },
    ONLINE_TASK: {
      label: t('studyTypes.online_task'),
      Icon: MousePointerClick,
    },
    SURVEY: {
      label: t('studyTypes.survey'),
      Icon: ListTodo,
    },
    LONGITUDINAL_STUDY: {
      label: t('studyTypes.longitudinal_study'),
      Icon: Repeat2,
    },
    AI_MODERATED: {
      label: t('studyTypes.ai_moderated'),
      Icon: MessageSquareText,
    },
    UNMODERATED: {
      label: t('studyTypes.unmoderated'),
      Icon: MousePointerClick,
    },
  };

  const filteredStudies =
    studies.filter(study => {
      const searchTerm = debouncedSearch.trim().toLowerCase();
      const studyName = study.name?.trim().toLowerCase() ?? '';
      return searchTerm ? studyName.includes(searchTerm) : true;
    }) ?? [];

  return (
    <>
      <div className="bg-background/90 px-4 pb-2 backdrop-blur-sm">
        <Input
          autoFocus
          className="w-full bg-background-subtle hover:bg-secondary focus:bg-secondary"
          id="filter-studies"
          placeholder={`${t('global.search')}...`}
          type="search"
          value={search}
          variant="borderless"
          onChange={e => setSearch(e.target.value)}
        />
      </div>

      {filteredStudies.length > 0 ? (
        <Table>
          <TableHeader className="[&_tr]:border-0">
            <TableRow>
              <TableHead className="h-0 min-w-40">
                <span className="sr-only">{t('sections.studies.recruit.screenerQuestionsCopy.study')}</span>
              </TableHead>
              <TableHead className="h-0">
                <span className="sr-only">{t('sections.studies.recruit.screenerQuestionsCopy.type')}</span>
              </TableHead>
              <TableHead className="h-0">
                <span className="sr-only">{t('sections.studies.recruit.screenerQuestionsCopy.created')}</span>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {filteredStudies.map((study: Booking) => {
              const type = getResolvedBookingType(study.type ?? 2, study.config?.online_task?.type);
              const { Icon, label } = studyType[type as StudyType];

              return (
                <TableRow
                  className="h-auto cursor-pointer hover:bg-secondary"
                  id={`result-${study._id}`}
                  key={study._id}
                  role="button"
                  tabIndex={0}
                  onClick={() => onSelectStudy(study)}
                  onKeyDown={e => {
                    const VALID_KEYS = new Set([' ', 'Enter']);
                    if (VALID_KEYS.has(e.key)) {
                      onSelectStudy(study);
                    }
                  }}
                >
                  <TableCell className="pl-4">{study.name}</TableCell>
                  <TableCell className="flex items-center gap-2">
                    <Icon className="h-4 w-4" />
                    {label}
                  </TableCell>
                  <TableCell>
                    {study.created ? (
                      <time dateTime={formatISO(study.created)}>{format(study.created, 'd MMM yyyy, h:mmaaa')}</time>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <EmptyState title={t('sections.studies.recruit.screenerQuestionsCopy.noStudies')} />
      )}
    </>
  );
};
