import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { format, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import ReactLinkify from 'react-linkify';

import type { ListMessage } from '../data/ListMessages.query';
import type { ReactNode } from 'react';

/**
 * Displays a single message in the MessageList
 */

interface MessageListItemProps {
  /* Sender ID */
  fromUserId: string;
  message: ListMessage;
  /* Add timestamp above message if true */
  firstInBlock: boolean;
  /* Round off message box corners if true */
  lastInBlock: boolean;
}

// Parse the message body to convert links to clickable links
const decorator = (href: string, text: string): ReactNode => (
  <a href={href} className="underline" target="_blank" rel="noreferrer">
    {text}
  </a>
);

export const MessageListItem = ({ fromUserId, message, firstInBlock, lastInBlock }: MessageListItemProps) => {
  const { t } = useTranslation();

  // Left align message from sender
  // Right align message from you or from Askable team (message.type = 2)
  return (
    <li
      key={message._id}
      className={cn('flex flex-col gap-1', {
        'items-end': message._from_user_id === fromUserId || message.type === 2,
      })}
    >
      {firstInBlock ? (
        <div className="flex gap-1 pt-2 text-xs">
          {message._from_user_id !== fromUserId && message.type !== 2
            ? message.UserFrom?.meta?.identity?.firstname
            : null}
          <Tooltip>
            <TooltipTrigger>
              <time dateTime={formatISO(message.created!)}>{format(message.created!, 'h:mmaaa')}</time>
            </TooltipTrigger>
            <TooltipContent>{format(message.created!, 'EEE d MMM yyyy, h:mmaaa')}</TooltipContent>
          </Tooltip>

          {message.seen && message._from_user_id === fromUserId ? (
            <Tooltip>
              <TooltipTrigger>
                <time dateTime={formatISO(message.seen)} className="text-xs">
                  {t('sections.messages.seen')}
                </time>
              </TooltipTrigger>
              <TooltipContent>
                {t('sections.messages.seenByParticipant')} {format(message.seen, 'EEE d MMM yyyy, h:mmaaa')}
              </TooltipContent>
            </Tooltip>
          ) : null}
        </div>
      ) : null}

      <div
        className={cn(' w-fit max-w-[80%] whitespace-pre-wrap bg-accent px-2 py-1 dark:bg-background', {
          'flex flex-col gap-1 rounded-md border border-border bg-background py-2 dark:bg-transparent':
            message.type === 2,
          'rounded-l-md bg-primary text-primary-foreground dark:bg-primary':
            message._from_user_id === fromUserId && message.type !== 2,
          'rounded-l-md': message._from_user_id === fromUserId,
          'rounded-r-md': message._from_user_id !== fromUserId,
          'rounded-tr-md': firstInBlock && message._from_user_id === fromUserId,
          'rounded-tl-md': firstInBlock && message._from_user_id !== fromUserId,
          'rounded-br-md': lastInBlock && message._from_user_id === fromUserId,
          'rounded-bl-md': lastInBlock && message._from_user_id !== fromUserId,
        })}
      >
        {message.type === 2 ? (
          <div className="flex items-center gap-1 text-sm font-semibold text-destructive">
            <svg width="18" height="18" fill="none" viewBox="0 0 48 48">
              <path fill="#fff" d="M12 7h24v27H12z" />
              <path
                fill="#dc3c4c"
                fillRule="evenodd"
                d="M24.026 1.5c14.703.025 18.933 4.253 18.909 18.906-.01 5.906-.708 10.113-2.497 13.033-3.894 8.205-12.553 12.125-16.487 13.061l.012-7.251c-14.705-.025-18.934-4.253-18.91-18.906C5.079 5.69 9.322 1.476 24.027 1.5Zm-.077 15.665a3.785 3.785 0 0 1-3.791-3.78 3.786 3.786 0 0 1 3.794-3.776 3.786 3.786 0 0 1 3.792 3.78c-.001 2.087-1.7 3.777-3.795 3.776Zm0 2.598c3.141.001 8.217.652 8.216 3.782-.001 3.13-3.393 6.925-8.221 6.923-4.828-.002-8.216-3.8-8.215-6.93.001-3.13 5.077-3.776 8.22-3.775Z"
                clipRule="evenodd"
              />
            </svg>
            Askable team
          </div>
        ) : null}
        <ReactLinkify componentDecorator={decorator}>{message.body}</ReactLinkify>
      </div>
    </li>
  );
};
