import { Box, useRadio, Text } from '@chakra-ui/react';

import type { BoxProps, UseRadioProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

type Props = UseRadioProps &
  BoxProps & {
    title?: string;
  };

const CHECKBOX_CONFIG: BoxProps = {
  cursor: 'pointer',
  borderWidth: '1px',
  borderRadius: 'md',
  _disabled: {
    pointerEvents: 'none',
    color: 'gray.500',
    cursor: 'not-allowed',
    _hover: {
      bg: 'transparent',
    },
  },
  _focus: {
    boxShadow: 'outline',
  },
  _checked: {
    outline: 'auto',
    outlineColor: 'blue.500',
  },
  _hover: {
    bg: 'gray.50',
  },
  p: '4',
  borderColor: 'gray.300',
};

export const RadioCard: FC<PropsWithChildren<Props>> = ({ children, title, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps({});
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full" position="relative">
      <input {...input} />
      <Box {...checkbox} {...CHECKBOX_CONFIG} {...props}>
        {title && (
          <Text mb="2" fontWeight="semibold">
            {title}
          </Text>
        )}
        {children}
      </Box>
    </Box>
  );
};
