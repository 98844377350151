import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useStudyContext } from '../StudiesContainer';
import { EmptyState } from '../components/EmptyState';
import { getPageTitle } from '../utils/getPageTitle';

import { BlockEditor } from './containers/BlockEditor';
import { BlockForm } from './containers/BlockForm';
import { BlockPreview } from './containers/BlockPreview';
import { BlocksSidebar } from './containers/BlocksSidebar';
import { useActiveBlockId } from './hooks/useActiveBlockId';

import type { Dispatch, SetStateAction } from 'react';

export type ScreenSelect = 'goal' | 'start';

type ScreenSelection = null | {
  mode: 'goal' | 'start';
  onSave: (nodeId: string) => void;
};

type BlockContextType = {
  screenSelection: ScreenSelection;
  setScreenSelection: Dispatch<SetStateAction<ScreenSelection>>;
  toggleScreenSelection: (value: ScreenSelection) => void;
  isCardCollapsed: boolean;
  setIsCardCollapsed: Dispatch<SetStateAction<boolean>>;
};

const BlockContext = createContext<BlockContextType>({
  screenSelection: null,
  setScreenSelection: () => false,
  toggleScreenSelection: () => false,
  isCardCollapsed: false,
  setIsCardCollapsed: () => false,
});

export const useBlockContext = () => {
  return useContext(BlockContext);
};

export const BuildStudy = () => {
  const { t } = useTranslation();
  const { study, newBlockId, setActiveSection, isBuildDisabled } = useStudyContext();
  const { activeBlockId, activeBlock } = useActiveBlockId();
  const [screenSelection, setScreenSelection] = useState<ScreenSelection>(null);
  const [isCardCollapsed, setIsCardCollapsed] = useState(false);

  const toggleScreenSelection = useCallback((newValue: ScreenSelection) => {
    setScreenSelection(state => (state?.mode === newValue?.mode ? null : newValue));
  }, []);

  useEffect(() => {
    if (activeBlockId) {
      setScreenSelection(null);
    }
  }, [activeBlockId]);

  // Whenever screen selection is toggled on, we want to switch the editors `activeSection` (for
  // mobile) and card collapsed state
  // TODO: we should see if we can refactor the whole state management around screen selection to
  // centralise/encapsulate the logic
  useEffect(() => {
    if (screenSelection) {
      setActiveSection('preview');
      setIsCardCollapsed(true);
    } else {
      setActiveSection('editor');
      setIsCardCollapsed(false);
    }
  }, [screenSelection, setActiveSection]);

  const blockContextValue = useMemo<BlockContextType>(
    () => ({
      screenSelection,
      setScreenSelection,
      toggleScreenSelection,
      setIsCardCollapsed,
      isCardCollapsed,
    }),
    [screenSelection, toggleScreenSelection, isCardCollapsed],
  );

  // Reset if we don't have a valid Figma file any more
  useEffect(() => {
    if (screenSelection?.mode && activeBlock && 'figma_prototype' in activeBlock && !activeBlock?.figma_prototype) {
      setActiveSection('editor');
      setIsCardCollapsed(false);
      setScreenSelection(null);
    }
  }, [screenSelection, activeBlock, setActiveSection, setIsCardCollapsed]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: t('sections.studies.tabs.build'),
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <BlocksSidebar />
      {activeBlock && activeBlockId ? (
        <BlockContext.Provider value={blockContextValue}>
          <BlockForm activeBlock={activeBlock} activeBlockId={activeBlockId} key={`${activeBlockId}--${newBlockId}`}>
            <BlockEditor activeBlock={activeBlock} isDisabled={isBuildDisabled} />
            <BlockPreview activeBlock={activeBlock} activeBlockId={activeBlockId} />
          </BlockForm>
        </BlockContext.Provider>
      ) : (
        <EmptyState title={t('sections.studies.taskNotFound')} />
      )}
    </>
  );
};
