import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import { IndividualResponses } from '../components/Task/IndividualResponses';
import { BookingTaskResults } from '../data/BookingTaskResults.query';

const PER_PAGE = 10;

export const TaskContent = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { study } = useStudyContext();

  const taskBlock = study.config?.unmoderated?.task_blocks?.find(block => block?._id === params.taskId);

  const [cursor, setCursor] = useState<string | null>(null);
  const [{ data, fetching }] = useQuery({
    query: BookingTaskResults,
    variables: {
      first: Number(searchParams.get('limit') ?? PER_PAGE),
      after: cursor ?? undefined,
      filter: {
        _booking_id: { eq: study._id },
        _task_id: { eq: params.taskId },
      },
    },
    pause: !params.taskId,
  });

  const loadMoreResults = async () => {
    if (data?.bookingTaskResults.pageInfo.hasNextPage && data?.bookingTaskResults.pageInfo.endCursor) {
      setCursor(data?.bookingTaskResults.pageInfo.endCursor);
    }
  };

  return (
    <div
      id={`task-block-${taskBlock?._id}`}
      className="flex h-full flex-col gap-10 rounded-xl bg-background p-3 lg:p-8"
    >
      {taskBlock && data ? (
        <>
          <TaskHeader title={taskBlock.title} type={taskBlock.type} responses={data.bookingTaskResults.totalCount} />
          {data.bookingTaskResults.totalCount ? (
            <IndividualResponses
              isLoading={fetching}
              perPage={PER_PAGE}
              results={data?.bookingTaskResults.nodes}
              totalCount={data.bookingTaskResults.totalCount}
              onLoadMore={loadMoreResults}
            />
          ) : (
            <EmptyState />
          )}
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};
