import isPast from 'date-fns/isPast';

import { BOOKING_SESSION_STATUS, SUBMISSION_SESSION_STATUS } from 'lib/constants';

import type { BookingSessionWithOtherTimes } from '../../BookingParticipantInviteTimeSlot';
import type { BookingSession, Maybe } from 'generated/graphql';

type ReturnType = {
  preferredTimes: BookingSessionWithOtherTimes[];
  otherTimes: BookingSessionWithOtherTimes[];
};

type Args = {
  preferredTimes: Pick<BookingSession, '_id'>[];
  bookingSessionTimes: BookingSession[];
  exclude?: Maybe<string>[]; // A list of session ids to exclude
};

// This is a placeholder status value that we use to drive the UI
export const SELECTED_STATUS = 99;

function getSessionCounts(ppts: BookingSession['participants']) {
  return (ppts ?? [])?.reduce(
    (acc, curr) => {
      if (curr?.status === SELECTED_STATUS) {
        acc.selectedCount += 1;
      }

      if (curr?.status === SUBMISSION_SESSION_STATUS.CONFIRMED) {
        acc.confirmedCount += 1;
      }

      if (curr?.status === SUBMISSION_SESSION_STATUS.INVITED) {
        acc.invitedCount += 1;
      }

      return acc;
    },
    {
      confirmedCount: 0,
      invitedCount: 0,
      selectedCount: 0,
    },
  );
}

export function organizeInviteData({ preferredTimes, bookingSessionTimes, exclude }: Args): ReturnType {
  return (bookingSessionTimes ?? []).reduce<ReturnType>(
    (acc, session) => {
      if (
        session.status === BOOKING_SESSION_STATUS.CANCELLED_BY_CLIENT ||
        session.status === BOOKING_SESSION_STATUS.CANCELLED_BY_ADMIN
      ) {
        return acc;
      }
      const hasConfirmedParticipants = session.participants?.some(a => a?.completed) ?? false;

      if (
        hasConfirmedParticipants ||
        (session.start && isPast(session.start)) ||
        (exclude ?? [])?.includes(session._id!)
      ) {
        return acc;
      }

      const isPreferred = preferredTimes.some(a => a._id === session._id);

      const counts = getSessionCounts(session?.participants);

      const payload = {
        ...session,
        ...counts,
      };

      if (isPreferred) {
        acc.preferredTimes.push(payload);
        return acc;
      }

      acc.otherTimes.push(payload);
      return acc;
    },
    {
      preferredTimes: [],
      otherTimes: [],
    },
  );
}
