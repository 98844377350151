import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogClose } from '@askable/ui/components/ui/dialog';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { CircleCheckBig } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';

import { EsignatureCreate } from '../data/EsignatureCreate.mutation';

interface CustomTermsUploadProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: ({ _id, name }: { _id: string; name: string }) => void;
}

export const CustomTermsUpload = ({ isOpen, onClose, onSuccess }: CustomTermsUploadProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const { details } = useConnectedClient();

  const [{ data, fetching, error }, createESignatureTemplate] = useMutation(EsignatureCreate);

  const handleAddAgreement = async () => {
    if (!name || name.trim().length < 3 || name.trim().length > 100) {
      return;
    }

    const response = await createESignatureTemplate({
      input: {
        _team_id: details?.team?.id,
        name,
        type: 1,
      },
    });

    if (response.error) {
      return;
    }

    setName('');
    onSuccess({
      _id: response.data?.createESignatureTemplate?._id ?? '',
      name: response.data?.createESignatureTemplate?.name ?? name,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          createESignatureTemplate({ input: {} }, { requestPolicy: 'network-only' });

          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogTitle className="text-lg">{t('sections.studies.recruit.fields.customTermsCta')}</DialogTitle>

        {data ? (
          <div className="flex gap-2">
            <CircleCheckBig className="mt-[3px] h-5 w-5 text-success" />
            <div className="flex flex-col gap-2">
              <div className="font-semibold">{t('sections.studies.recruit.fields.agreementSuccessTitle')}</div>
              <div className="text-sm">{t('sections.studies.recruit.fields.agreementSuccessDescription')}</div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              <Label htmlFor="custom_terms_agreement_name">
                {t('sections.studies.recruit.fields.agreementAddTitle')}
              </Label>

              <div className="text-pretty text-xs text-foreground-subtle">
                {t('sections.studies.recruit.fields.agreementDescription')}
              </div>

              <Input
                placeholder="Name your document"
                id="custom_terms_agreement_name"
                maxLength={100}
                onChange={e => setName(e.target.value)}
              />

              {error ? <div className="text-sm text-destructive">{error?.message}</div> : null}
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button variant="ghost">{t('global.cancel')}</Button>
              </DialogClose>
              <Button onClick={handleAddAgreement} variant="primary" isLoading={fetching}>
                {t('global.add')}
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
