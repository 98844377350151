type SessionsLogoProps = {
  height?: number;
  iconColor?: string;
};

export const SessionsLogo = ({ height = 22, iconColor = '#FF5065' }: SessionsLogoProps) => {
  return (
    <svg height={height} viewBox="0 0 448 86">
      <title>Askable Sessions</title>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M421.04 0c20.96.04 27 6.14 26.96 27.3-.01 8.54-1 14.62-3.56 18.83C438.9 58 426.54 63.65 420.94 65l.01-10.47C400 54.49 393.96 48.38 394 27.22 394.03 6.05 400.08-.04 421.04 0Zm-9.1 18.32a2.81 2.81 0 0 1 2.9-2.82c.47 0 .94.11 1.37.37l15.98 9.34a2.8 2.8 0 0 1-.1 4.87l-16 9.35a2.72 2.72 0 0 1-3.95-1.47c-.13-.32-.2-.67-.2-1.04v-18.6Z"
        clipRule="evenodd"
      />
      <g fill="currentColor">
        <path d="M0 57.81c0-18.8 13.6-28.49 26.07-28.49a21.25 21.25 0 0 1 15.92 6.2l1.54-4.88h15.43v54.31H44.28l-2.3-5.32a20.67 20.67 0 0 1-16.66 6.6C11.85 86.23 0 75.18 0 57.81Zm42.62 0c0-8.97-5.7-14.26-12.93-14.26-7.23 0-12.93 5.53-12.93 14.26s5.6 14.23 12.93 14.23 12.93-5.47 12.93-14.23Zm25.11 14.08 12.5-5.65a11.12 11.12 0 0 0 10.19 7.3c4.17 0 6.04-2.07 6.04-4.34s-1.87-4.16-6.57-5.98l-6.82-2.42a16.27 16.27 0 0 1-8.86-5.97 16.05 16.05 0 0 1-3.2-10.14c0-8.97 8.11-15.43 18.84-15.43 9.32 0 15.08 3.6 18.3 10.1l-11.7 6.5a6.36 6.36 0 0 0-6.02-4.73c-2.41 0-4.04 1.32-4.04 3.29s1.08 2.99 3.62 3.89l7.44 2.72c8.56 3.16 14.14 8.27 14.14 17.93 0 9.65-8.44 17.28-21.8 17.28-11.87 0-19.2-5.74-22.06-14.35Zm51.51-64.66h16.67v41.31l15.44-17.93h20.16l-23.12 24.87 24.57 29.47h-21.1l-16-20.32v20.32h-16.62V7.23Z" />
        <path d="M170.97 57.81c0-18.8 13.6-28.49 26.1-28.49a21.26 21.26 0 0 1 15.89 6.2l1.53-4.88h15.44v54.31h-14.69l-2.28-5.32a20.65 20.65 0 0 1-16.67 6.6c-13.48 0-25.32-11.05-25.32-28.42Zm42.65 0c0-8.97-5.7-14.26-12.93-14.26-7.24 0-12.93 5.53-12.93 14.26s5.57 14.23 12.93 14.23c7.35 0 12.93-5.47 12.93-14.23Zm43.61 20.93-1.86 5.97h-13.93V7h16.67v29.11a22.1 22.1 0 0 1 16.87-7.05c12.72 0 25.65 8.96 25.65 28.48 0 18.27-13.05 28.46-26.52 28.46a20.18 20.18 0 0 1-16.88-7.27v.01Zm26.62-20.93c0-8.6-5.57-14.14-13.02-14.14-7.45 0-13.05 5.41-13.05 14.14s5.7 14.11 12.93 14.11a13.27 13.27 0 0 0 9.69-4.2 13 13 0 0 0 3.45-9.9v-.01Zm23.36-50.58h16.67v77.73h-16.67V7.23Zm55.67 79.01c-16.66 0-28.18-11.4-28.18-28.46 0-17.07 11.42-28.49 28.06-28.49 19.3 0 28.07 13.28 28.07 26.64 0 2-.22 4.01-.67 5.98h-38.8a11.4 11.4 0 0 0 4.06 7.57 11.57 11.57 0 0 0 8.22 2.65 12.3 12.3 0 0 0 11.42-6.3l13.78 6.72c-4.8 8.7-13.48 13.69-25.96 13.69Zm-11.18-35.3h22.07a10.38 10.38 0 0 0-3.72-6.62 10.52 10.52 0 0 0-7.25-2.35 10.7 10.7 0 0 0-7.3 2.36c-2.05 1.65-3.4 4-3.8 6.6v.01Z" />
      </g>
    </svg>
  );
};
