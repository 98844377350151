import { Loader2, CircleCheckBig } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTime } from './DateTime';

interface Props {
  isSaving?: boolean;
  created?: number | null;
  updated?: number | null;
}

export const StudySavingIndicator = ({ isSaving, created, updated }: Props) => {
  const { t } = useTranslation();
  const date = updated ?? created;

  // HACK: the loader flashing for split seconds looks pretty broken, so we enforce a minimum
  // duration for it here
  const [isLoading, setIsLoading] = useState(isSaving);

  const timer = useRef<NodeJS.Timer>();
  useEffect(() => {
    if (isSaving) {
      setIsLoading(true);
    } else {
      const t = setTimeout(() => setIsLoading(false), 600);
      timer.current = t;
      return () => clearTimeout(t);
    }
  }, [isSaving]);

  const icon = isLoading ? (
    <Loader2 className="h-3.5 w-3.5 animate-spin opacity-50" aria-label={t('global.saving')} />
  ) : (
    <CircleCheckBig className="h-3.5 w-3.5 text-success" aria-label={t('sections.studies.saved')} />
  );

  if (date) {
    return <DateTime date={date}>{icon}</DateTime>;
  }

  return icon;
};
