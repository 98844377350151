import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { match } from 'ts-pattern';

import { BOOKING_STATUS } from 'lib/constants';

import { StudyName } from '../BuildStudy/components/StudyName';
import { useStudyContext } from '../StudiesContainer';

import { NavLink } from './NavLink';
import { PreviewLinkButton } from './PreviewLink';
import { StudyActions } from './StudyActions';
import { StudySavingIndicator } from './StudySavingIndicator';
import { StudyStatusIndicator } from './StudyStatusIndicator';

export const StudiesHeader = () => {
  const { t } = useTranslation();
  const { study, hasSubmitted, isSaving, isValid } = useStudyContext();
  const location = useLocation();
  const section = matchPath('/studies/:studyId/:section/*', location.pathname)?.params?.section;
  invariant(section, 'Section needs to be set!');

  const navItems = [
    {
      label: t('sections.studies.tabs.build'),
      to: 'build',
      key: 'build',
    },
    {
      label: t('sections.studies.tabs.recruit'),
      to: study.status === BOOKING_STATUS.DRAFT ? 'recruit/settings' : 'recruit/participants',
      key: 'recruit',
    },
    {
      label: t('sections.studies.tabs.results'),
      to: 'results',
      key: 'results',
    },
  ] as const;

  const isTabInvalid = (tab: (typeof navItems)[number]['key']) => {
    return match(tab)
      .with('build', () => tab !== section && isValid.build === false)
      .with('recruit', () => tab !== section && hasSubmitted && isValid.recruit === false)
      .otherwise(() => false);
  };

  return (
    <header className="studies-header">
      <div className="left-0 grid grid-cols-[1fr_auto] items-center gap-2 border-b-0.5 border-border bg-background px-2 py-3 lg:grid-cols-3">
        <StudyName id={study._id} name={study.name || ''} />

        <nav
          className="order-4 col-start-1 col-end-3 flex w-full gap-1 justify-self-center overflow-auto lg:order-2 lg:col-start-2 lg:col-end-3
            lg:w-fit"
        >
          {navItems.map(item => (
            <NavLink key={item.to} item={item} section={section} isInvalid={isTabInvalid(item.key)} />
          ))}
        </nav>

        <div className="order-3 flex items-center gap-3 justify-self-end pr-2">
          <StudyStatusIndicator study={study} />
          <StudySavingIndicator isSaving={isSaving} created={study.created} updated={study.updated} />

          <PreviewLinkButton />
          <StudyActions study={study} />
        </div>
      </div>
    </header>
  );
};
