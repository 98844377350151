import { useMemo, useState } from 'react';

import { utils } from 'lib/utils';

import type { Location, LocationCriteria } from 'generated/graphql';

/**
 * This hook is used to manage the location criteria for a booking.
 */
export const useLocationCriteria = (locations: LocationCriteria | null) => {
  const [locationCriteria, setLocationCriteria] = useState<LocationCriteria | null>(locations);

  const states = useMemo(
    () => utils.removeTypenames((locationCriteria?.states as Location[]) ?? []),
    [locationCriteria],
  );
  const bounds = useMemo(
    () => utils.removeTypenames((locationCriteria?.bounds as Location[]) ?? []),
    [locationCriteria],
  );
  const countries = useMemo(
    () => utils.removeTypenames((locationCriteria?.countries as Location[]) ?? []),
    [locationCriteria],
  );

  const allLocations = useMemo(() => [...states, ...bounds, ...countries], [states, bounds, countries]);

  const countryCodes = useMemo<string[]>(() => {
    const allCountryCodes = allLocations.map(country => country.country as string).filter(Boolean);
    return Array.from(new Set(allCountryCodes));
  }, [allLocations]);

  const isCountry = (value: Location) =>
    countries.find(country => country.formatted_address === value.formatted_address);
  const isState = (value: Location) => states.find(state => state.formatted_address === value.formatted_address);

  const addLocation = (value: Location) => {
    const isStateSelected = value.google_location_types?.includes('administrative_area_level_1');
    const isCountrySelected = value.google_location_types?.includes('country');

    // Tests if location has already been added
    const locationAdded = allLocations.filter(data => {
      return data?.formatted_address === value.formatted_address;
    });
    if (locationAdded.length > 0) {
      return null;
    }

    const newLocation: Location = {
      formatted_address: value.formatted_address,
      country: value.country,
      state: value.state,
      latitude: value.latitude,
      longitude: value.longitude,
      google_location: {
        viewport: value.google_location?.viewport || {},
        geometry: {
          latitude_ne: value?.google_location?.geometry?.latitude_ne,
          longitude_ne: value?.google_location?.geometry?.longitude_ne,
          latitude_sw: value?.google_location?.geometry?.latitude_sw,
          longitude_sw: value?.google_location?.geometry?.longitude_sw,
        },
      },
      google_location_types: value.google_location_types,
    };

    if (isCountrySelected) {
      // remove all states and bounds for this country
      const newStates = states.filter(data => data?.country !== value.country);
      const newBounds = bounds.filter(data => data?.country !== value.country);
      const newCountries = [...countries, newLocation];

      setLocationCriteria({ states: newStates, countries: newCountries, bounds: newBounds });
      return;
    }

    if (isStateSelected) {
      // remove countries and bounds for this state
      const newCountries = countries.filter(data => data?.country !== value.country);
      const newBounds = bounds.filter(data => data?.state !== value.state && data.state !== null);
      const newStates = [...states, newLocation];

      setLocationCriteria({ states: newStates, countries: newCountries, bounds: newBounds });
      return;
    }

    // remove countries and states for this bound
    const newCountries = countries.filter(data => data?.country !== value.country);
    const newStates = states.filter(data => data?.state !== value.state);
    bounds.push(newLocation);

    setLocationCriteria({ states: newStates, countries: newCountries, bounds });
  };

  const removeLocation = (value: Location) => {
    if (isState(value)) {
      removeState(value);
      return;
    }

    if (isCountry(value)) {
      removeCountry(value);
      return;
    }

    removeBound(value);
  };

  const removeState = (state: Location) => {
    setLocationCriteria({
      states: states.filter(s => s?.formatted_address !== state.formatted_address),
      countries,
      bounds,
    });
  };

  const removeCountry = (country: Location) => {
    setLocationCriteria({
      states,
      countries: countries.filter(c => c?.formatted_address !== country.formatted_address),
      bounds,
    });
  };

  const removeBound = (bound: Location) => {
    setLocationCriteria({
      states,
      countries,
      bounds: bounds.filter(b => b?.formatted_address !== bound.formatted_address),
    });
  };

  return {
    locationCriteria,
    states,
    bounds,
    countries,
    allLocations,
    countryCodes,
    addLocation,
    removeLocation,
    setLocations: setLocationCriteria,
    isState,
    isCountry,
  };
};
