import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { DualSlider } from '@askable/ui/components/ui/slider';
import { ErrorMessage } from '@hookform/error-message';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FieldLabel } from 'containers/Studies/components/Fields/FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface DualSliderFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isFocused?: boolean;
  label: string;
  max: number;
  min: number;
  name: string;
}

const DualSliderFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  isFocused,
  label,
  max,
  min = 1,
  name,
}: DualSliderFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  const [minValue, maxValue] = field.value;

  return (
    <FormItem>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <div className="flex items-center gap-2">
        <FormControl>
          <Input
            {...field}
            aria-describedby={description ? `description_${name}` : undefined}
            autoFocus={isFocused}
            className="text-md w-9 text-center lg:text-sm"
            disabled={isDisabled}
            id={`input_${name}`}
            inputMode="numeric"
            max={max}
            min={min}
            pattern="[0-9]*"
            value={field.value[0] ?? min}
            onChange={e => {
              const value = Number(e.target.value.replace(/[^0-9]/g, ''));
              if (isNaN(value)) {
                return;
              }
              const clampedValue = Math.min(Math.max(value, min), maxValue);
              field.onChange([clampedValue, maxValue]);
            }}
          />
        </FormControl>
        <FormControl>
          <DualSlider value={field.value} max={max} min={min} onValueChange={field.onChange} disabled={isDisabled} />
        </FormControl>
        <FormControl>
          <Input
            {...field}
            aria-describedby={description ? `description_${name}` : undefined}
            autoFocus={isFocused}
            className="text-md w-9 text-center lg:text-sm"
            inputMode="numeric"
            max={max}
            min={min}
            value={field.value[1] ?? max}
            disabled={isDisabled}
            onChange={e => field.onChange([minValue, Number(e.target.value)])}
            onBlur={e => {
              const value = Number(e.target.value);
              if (isNaN(value)) {
                return;
              }
              const clampedValue = Math.min(Math.max(value, min), max);
              field.onChange([minValue, clampedValue]);
            }}
          />
        </FormControl>
      </div>

      <ErrorMessage
        errors={error}
        name={name}
        render={() => {
          // Show both min and max errors
          const messages = Array.isArray(error) ? error.map(e => e.message) : [error?.message];

          return (
            <div className="flex flex-col gap-1">
              {messages.map((message, index) => (
                <FormErrorMessage key={index} message={message} prefix="sections.studies.formValidation" />
              ))}
            </div>
          );
        }}
      />
    </FormItem>
  );
};

DualSliderFieldComponent.displayName = 'DualSliderField';

export const DualSliderField = memo(DualSliderFieldComponent);
