import { v4 } from 'uuid';

/**
 * Accepts a value eg. 0.15
 * Returns a formatted percentage eg. 15%
 */
export const formatPercentage = (value: number | string): string => {
  const percentage = Number(value) * 100;
  return `${Number.isInteger(percentage) ? percentage : percentage.toFixed(1)}%`;
};

/**
 * Get a unique object ID for compatibility with MongoDB
 */
export const getObjectId = (): string => v4().replace(/-/g, '').slice(0, 24);
