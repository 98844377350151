import type { SubmissionStatus } from '../data/BookingSubmissionsWithMessages.query';

type Extends<T, U extends T> = U;

export const supportedSubmissionStatuses = [
  'available',
  'completed',
  'confirmed',
  'invite_declined',
  'invited',
  'participant_cancelled',
  'waitlisted',
] as const;

// Type safe way to make sure the statuses are actually a subset of the valid statuses
export type SupportedSubmissionStatus = Extends<SubmissionStatus, (typeof supportedSubmissionStatuses)[number]>;
