import { Check } from 'lucide-react';
import { Button, CopyIcon, SessionsLogo, Text, useClipboard, VStack } from 'ui';
import { useQuery } from 'urql';

import { AskableSessionsRoomCodeDocument } from 'generated/graphql';
import { getClientAskableSessionAuthLink, getParticipantAskableSessionAuthLink, getSessionUrl } from 'utils/url-utils';

import type { AskableSessionsRoomCodeQuery, AskableSessionsRoomCodeQueryVariables } from 'generated/graphql';

type RowProps = {
  title: string;
  url: string;
};

const Row = ({ title, url }: RowProps) => {
  const { onCopy, hasCopied } = useClipboard(url);

  return (
    <VStack alignItems="flex-start" w="full">
      <Text fontWeight="semibold" fontSize="md" color="chakra-body-text">
        {title}
      </Text>
      <Button
        colorScheme="blue"
        fontWeight="normal"
        variant="ghost"
        onClick={onCopy}
        alignSelf="baseline"
        paddingX="2"
        ml="-2"
      >
        {hasCopied ? <Check className="mr-4 h-4 w-4" /> : <CopyIcon mr={4} />}
        {hasCopied ? 'Copied' : 'Copy joining link'}
      </Button>
    </VStack>
  );
};

type Props = {
  bookingId: string;
  userId: string;
  confirmedSessionId: string;
};

export const BookingParticipantSessionDetails = ({ bookingId, userId, confirmedSessionId }: Props) => {
  const [{ data, fetching }] = useQuery<AskableSessionsRoomCodeQuery, AskableSessionsRoomCodeQueryVariables>({
    query: AskableSessionsRoomCodeDocument,
    variables: {
      input: {
        _booking_id: bookingId,
        _user_id: userId,
        _session_id: confirmedSessionId,
      },
    },
  });

  if (fetching) {
    return null;
  }

  const code = data?.searchAskableMeeting?.[0]?.code || '';

  return (
    <VStack px="6" mt="8" alignItems="flex-start" spacing="8">
      <VStack alignItems="flex-start" spacing="6" w="full">
        <Row url={getParticipantAskableSessionAuthLink(code)} title="Participants" />
        <Row url={getSessionUrl(code)} title="Observers" />
        <Row url={getClientAskableSessionAuthLink({ roomCode: code })} title="Facilitators" />
      </VStack>
      <VStack justifyContent="center">
        <Text fontWeight="semibold" color="gray.400">
          Video call tool
        </Text>
        <SessionsLogo height={20} />
      </VStack>
    </VStack>
  );
};
