import { Button } from '@askable/ui/components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogClose } from '@askable/ui/components/ui/dialog';
import { Tooltip, TooltipTrigger, TooltipContent } from '@askable/ui/components/ui/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SliderField } from 'containers/Studies/Recruit/components/Fields/SliderField';
import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { BOOKING_STATUS } from 'lib/constants';

// @todo remove this when updating is implemented
const showEditOption = false;

export const SummaryItemParticipants = () => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdateParticipants = async () => {
    alert('@todo: update participants');
  };

  const EditButton = ({ isDisabled }: { isDisabled?: boolean }) => (
    <Button variant="link" disabled={isDisabled} onClick={() => setIsOpen(true)}>
      {t('global.edit')}
    </Button>
  );

  return (
    <>
      <div className="flex justify-between gap-2">
        <SummaryItem
          label={t('sections.studies.recruit.fields.participantsTitle')}
          options={[study.recruit?.participants ?? 0]}
          type="participants"
        />

        {showEditOption ? (
          <>
            {study.status !== BOOKING_STATUS.ACTIVE ? (
              <Tooltip>
                <TooltipTrigger className="cursor-not-allowed">
                  <EditButton isDisabled={true} />
                </TooltipTrigger>
                <TooltipContent>{t('sections.studies.recruit.editingDisabled')}</TooltipContent>
              </Tooltip>
            ) : (
              <EditButton />
            )}
          </>
        ) : null}
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={open => {
          if (!open) {
            setIsOpen(false);
          }
        }}
      >
        <DialogContent>
          <DialogTitle className="text-lg">{t('sections.studies.recruit.participantQuota')}</DialogTitle>

          <SliderField
            name="participants"
            label={t('sections.studies.recruit.fields.participantsTitle')}
            min={1}
            max={50}
            infoPopover={{
              title: t('sections.studies.recruit.fields.participantsCalloutTitle'),
              description: t('sections.studies.recruit.fields.participantsCalloutDescription'),
              moreLink: 'https://help.askable.com/en/articles/9423123-how-do-i-change-my-study-quota',
            }}
          />

          <DialogFooter>
            <DialogClose asChild>
              <Button variant="ghost">{t('global.cancel')}</Button>
            </DialogClose>
            <Button onClick={handleUpdateParticipants} variant="primary">
              {t('global.save')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
