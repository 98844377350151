import { ChevronDown, ChevronUp } from 'lucide-react';
import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Button, Text, useDisclosure } from 'ui';

import { organizeInviteData } from '../utils/booking-side-panel-utils';

import { TimeOptions } from './TimeOptions';

import type { BookingSessionWithOtherTimes } from '../../BookingParticipantInviteTimeSlot';
import type { BookingContainerConfig } from 'containers/Booking/BookingContainer';
import type { BookingSession } from 'generated/graphql';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';

type PreferredOtherTimeSlotsProps = {
  name: string;
  sessionType: BookingContainerConfig['sessionType'];
  pptTimezone: string;
  control: Control<any, any>;
  times: BookingSessionWithOtherTimes[];
  displayName: string;
};

export const PreferredTimesSlots: FC<PreferredOtherTimeSlotsProps> = ({
  control,
  name,
  sessionType,
  pptTimezone,
  times,
  displayName,
}) => {
  return (
    <>
      <Text fontSize="md" fontWeight="semibold" mb={4}>
        Preferred times
      </Text>

      {times.length === 0 && <Text color="gray.500">No preferred times were provided.</Text>}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <TimeOptions
              sessionType={sessionType}
              times={times}
              displayName={displayName}
              pptTimezone={pptTimezone}
              onChange={field.onChange}
              value={field.value}
              name={field.name}
            />
          );
        }}
      />
    </>
  );
};

export const OtherAvailableTimeSlots: FC<PreferredOtherTimeSlotsProps & { hasPreferredTimes: boolean }> = ({
  control,
  name,
  pptTimezone,
  hasPreferredTimes,
  sessionType,
  times,
  displayName,
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: !hasPreferredTimes });
  return (
    <Box mt={10}>
      <Button
        rightIcon={isOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        onClick={onToggle}
        mb={4}
      >
        {isOpen ? 'Hide all other times' : 'Show all other times'}
      </Button>
      {isOpen &&
        (times.length >= 1 ? (
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TimeOptions
                  sessionType={sessionType}
                  pptTimezone={pptTimezone}
                  displayName={displayName}
                  times={times}
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                />
              );
            }}
          />
        ) : (
          <Text color="gray.500">No other times available.</Text>
        ))}
    </Box>
  );
};

type UserTimesProps = Omit<PreferredOtherTimeSlotsProps, 'times' | 'displayName'> & {
  title?: string;
  sessionType?: BookingContainerConfig['sessionType'];
  preferredSessions: BookingSession[];
  bookingSessions: BookingSession[];
  exclude?: string[];
};

export const UserTimes: FC<UserTimesProps> = memo(
  ({ name, sessionType = 'individual', exclude, bookingSessions, control, pptTimezone, preferredSessions, title }) => {
    const data = organizeInviteData({
      preferredTimes: preferredSessions ?? [],
      bookingSessionTimes: bookingSessions,
      exclude: exclude ?? [],
    });

    return (
      <>
        <PreferredTimesSlots
          sessionType={sessionType}
          name={name}
          displayName={title ?? ''}
          times={data.preferredTimes}
          pptTimezone={pptTimezone}
          control={control}
        />
        <OtherAvailableTimeSlots
          name={name}
          sessionType={sessionType}
          displayName={title ?? ''}
          hasPreferredTimes={data.preferredTimes.length > 0}
          times={data.otherTimes}
          pptTimezone={pptTimezone}
          control={control}
        />
      </>
    );
  },
);
