import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from 'ui';

const ModeratedBody = () => {
  return (
    <ModalBody>
      All sessions in this booking are confirmed or complete.
      <br />
      <br />
      To create new sessions in your calendar and invite more participants, increase your quota in booking settings.
    </ModalBody>
  );
};

const UnmoderatedBody = () => {
  return (
    <ModalBody>
      Your booking quota has been reached with participants who are either currently in progress or have already
      completed the task.
      <br />
      <br />
      To invite more participants, increase your quota in booking settings.
    </ModalBody>
  );
};

type Props = {
  bookingId: string;
  isOpen: boolean;
  onClose: () => void;
  isModeratedBooking: boolean;
};

export const BookingFullModal = ({ bookingId, isOpen, onClose, isModeratedBooking }: Props) => {
  const navigate = useNavigate();
  const onGoToSettings = () => {
    if (!bookingId) {
      return;
    }
    navigate(`/bookings/${bookingId}/settings`);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Study full</ModalHeader>
        <ModalCloseButton />
        {isModeratedBooking ? <ModeratedBody /> : <UnmoderatedBody />}
        <ModalFooter>
          <Button mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={onGoToSettings}>
            Go to settings
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
