import { graphql } from 'src/graphql';

export const UpdateBookingSubmission = graphql(`
  mutation UpdateBookingSubmission($input: UpdateBookingSubmissionInput!) {
    updateBookingSubmission(input: $input) {
      _id
      status
    }
  }
`);
