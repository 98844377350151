import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Fragment, useCallback } from 'react';
import { Flex } from 'ui';

import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import type { ReactNode } from 'react';

type BaseItem = {
  id: UniqueIdentifier;
};

interface PropsT<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T): ReactNode;
}

export function SortableList<T extends BaseItem>({ items, onChange, renderItem }: PropsT<T>) {
  const sensors = useSensors(useSensor(PointerSensor));

  const onDragEnd = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = items.findIndex(({ id }) => id === active.id);
        const overIndex = items.findIndex(({ id }) => id === over.id);
        onChange(arrayMove(items, activeIndex, overIndex));
      }
    },
    [items],
  );

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={items}>
        <Flex flexDir="column" role="application" h="full" w="full">
          {items.map(item => (
            <Fragment key={item.id}>{renderItem(item)}</Fragment>
          ))}
        </Flex>
      </SortableContext>
    </DndContext>
  );
}
