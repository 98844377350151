import { TaskCardFooter } from '@askable/ui/components/unmod/task-card/task-card-footer';
import { cn } from '@askable/ui/lib/utils';

import type { ReactNode } from 'react';

interface TaskCardProps {
  id: string;
  children: ReactNode;
  action: ReactNode;
  isExiting?: boolean;
  isFloating?: boolean;
  isInPreview?: boolean;
}

export const TaskCard = ({ id, action, children, isExiting, isFloating, isInPreview }: TaskCardProps) => {
  return (
    <div
      className={cn(
        'flex h-full w-full max-w-[45rem] flex-col justify-between gap-4 bg-background shadow md:gap-8 lg:rounded-xl',
        {
          'absolute left-4 top-4 z-10 h-[calc(100%_-_36px)] w-auto lg:left-6 lg:top-6 lg:h-[calc(100%_-_48px)]':
            isFloating,
          'left-0 top-0 h-full lg:left-0 lg:top-0 lg:h-full': isFloating && isInPreview,
          'origin-bottom-left animate-scale-down ease-anticipation': isExiting,
        },
      )}
      data-id={id}
    >
      <div className="flex flex-1 flex-col gap-3 overflow-auto">{children}</div>
      <footer className="flex flex-col gap-2 md:gap-6">
        <TaskCardFooter>{action}</TaskCardFooter>
      </footer>
    </div>
  );
};

export { TaskCardHeader } from './task-card-header';
