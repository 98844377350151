import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
} from 'ui';
import { useMutation } from 'urql';

import { UpdateSegmentDocument } from 'generated/graphql';

import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';
import { mapUIFiltersToSegmentFiltersInput } from '../utils/booking-participant-utils';

import type { Segment, UpdateSegmentMutation, UpdateSegmentMutationVariables } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  isOpen: boolean;
  bookingId: string;
  onClose: () => void;
  onUpdateComplete: (segment?: Segment) => void;
};

export const UpdateSegmentModal: FC<Props> = ({ isOpen, onClose, onUpdateComplete, bookingId }) => {
  const segment = useBookingParticipantState(bookingParticipantSelectors.segment);
  const cancelRef = useRef(null);

  const [{ fetching: loading }, updateSegment] = useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(
    UpdateSegmentDocument,
  );

  const handleSegmentUpdate = async () => {
    const { segmentInput, filters } = useBookingParticipantState.getState();

    try {
      const result = await updateSegment({
        input: {
          filters: mapUIFiltersToSegmentFiltersInput(filters),
          ...(segmentInput.method ? { method: segmentInput.method } : {}),
          tableConfig: segmentInput.tableConfig,
          booking_id: bookingId,
          segment_id: segment?._id!,
          sort: segmentInput.sort,
        },
      });
      if (result.error) {
        throw result.error;
      }

      onUpdateComplete(result.data?.updateSegment as Segment);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AlertDialog leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          <Heading fontSize="2xl">Update segment?</Heading>
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Are you sure you want to update <strong>{segment?.name}</strong>?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            data-testid="segment-update-confirm"
            colorScheme="blue"
            ml={3}
            onClick={handleSegmentUpdate}
            isLoading={loading}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
