import { useParams } from 'react-router-dom';

import { PageLayout } from 'components/Layout/PageLayout';
import CherryPickingRecruitment from 'components/manageBooking/recruitment/cherryPickingRecruitment';
import { useFetchBookingByIdQuery } from 'generated/graphql';

const BookingOverviewContainer = () => {
  const params = useParams<{ bookingId: string; studyId: string }>();
  const { data, loading } = useFetchBookingByIdQuery({
    variables: {
      id: params.studyId ?? params.bookingId,
    },
  });

  return (
    <PageLayout pt="0" loading={loading}>
      {!loading && data?.bookingByID ? (
        <CherryPickingRecruitment booking={data?.bookingByID} hiddenColumns={[]} contentContainerProps={{ p: '0!' }} />
      ) : null}
    </PageLayout>
  );
};

export default BookingOverviewContainer;
