import { Button } from '@askable/ui/components/ui/button';
import { Figma } from '@askable/ui/components/unmod/icons';
import { PermissionsList } from '@askable/ui/components/unmod/permissions-list';
import { TaskCard, TaskCardHeader } from '@askable/ui/components/unmod/task-card';
import { TaskCardDraggable, TaskCardDraggableContent } from '@askable/ui/components/unmod/task-card-draggable';
import { cn } from '@askable/ui/lib/utils';
import { ArrowRight } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';
import { SelectStartScreen } from 'containers/Studies/BuildStudy/components/Fields/FigmaScreensField/SelectStartScreen';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { FigmaPreview } from 'containers/Studies/components/FigmaPreview';
import { SystemBlockType, TaskBlockType } from 'generated/graphql';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { ReactNode } from 'react';

interface BlockPreviewProps {
  children?: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}

export const BlockPreview = ({ activeBlock, activeBlockId }: BlockPreviewProps) => {
  const { t } = useTranslation();
  const { screenSelection, setScreenSelection, isCardCollapsed, setIsCardCollapsed } = useBlockContext();
  const { study, setActiveSection } = useStudyContext();
  const [isGoalScreen, setIsGoalScreen] = useState(false);

  const isRecordingEnabled =
    (activeBlock.type === 'welcome' &&
      study.config?.unmoderated?.task_blocks?.some(task => task?.is_recording_enabled)) ??
    false;

  useEffect(() => {
    setScreenSelection(null);
    setIsCardCollapsed(false);
  }, [setScreenSelection, setIsCardCollapsed, activeBlockId]);

  const handleSelectedNode = (nodeId: string) => {
    const goalScreenReached =
      'figma_prototype' in activeBlock && nodeId === activeBlock.figma_prototype?.goal_screen_id;
    setIsGoalScreen(goalScreenReached);
    if (goalScreenReached) {
      setIsCardCollapsed(false);
    }
  };

  return (
    <>
      <aside
        className={cn('build-preview', {
          'touch-events-none absolute right-0 opacity-0': screenSelection?.mode === 'start',
        })}
        id="preview"
      >
        <div className="flex h-full flex-col overflow-auto bg-secondary p-3 pt-2 transition-transform lg:p-8">
          {match(activeBlock.type)
            .with(SystemBlockType.Welcome, SystemBlockType.ThankYou, () => (
              <TaskCard
                id={activeBlockId}
                action={
                  activeBlock.type === SystemBlockType.Welcome ? (
                    <Button variant="primary" disabled>
                      {t('sections.studies.getStarted')} <ArrowRight className="block h-4 w-4" />
                    </Button>
                  ) : (
                    <Button variant="primary" disabled>
                      {t('sections.studies.backToAskable')}
                    </Button>
                  )
                }
              >
                <TaskCardHeader
                  instructions={activeBlock.instructions}
                  subtitle={t('sections.studies.tasks', { count: 1 })}
                  title={activeBlock.title}
                  type={activeBlock.type}
                />
                {isRecordingEnabled ? (
                  <div className="px-4 md:px-8">
                    <PermissionsList
                      description={t('sections.studies.permissions.description')}
                      permissions={[
                        { key: 'camera', description: t('sections.studies.permissions.camera') },
                        {
                          key: 'microphone',
                          description: t('sections.studies.permissions.microphone'),
                        },
                      ]}
                    />
                  </div>
                ) : null}
              </TaskCard>
            ))
            .with(TaskBlockType.FigmaPrototype, () => (
              <>
                {'figma_prototype' in activeBlock && !activeBlock.figma_prototype?.file_id ? (
                  <div className="drag-container relative h-full w-full @container">
                    <div className="h-full w-full rounded-xl bg-prototype p-6">
                      <div className="flex h-full w-full items-center justify-center">
                        <div className="flex flex-col items-center gap-3 text-center font-semibold opacity-50">
                          <Figma size={48} />
                          {t('sections.studies.previewNoPrototype')}
                        </div>
                      </div>

                      <TaskCardDraggable
                        id={activeBlockId}
                        title={t('sections.studies.tasks', { count: 1 })}
                        isDisabled
                        isCollapsed={isCardCollapsed}
                        setIsCollapsed={setIsCardCollapsed}
                        collapseLabel={t('sections.studies.hideInstructions')}
                        isPositionSaved={false}
                        action={
                          <Button variant="primary" disabled>
                            {t('sections.studies.endTask')}
                          </Button>
                        }
                      >
                        <TaskCardDraggableContent instructions={activeBlock.instructions} title={activeBlock.title} />
                      </TaskCardDraggable>
                    </div>
                  </div>
                ) : null}

                {'figma_prototype' in activeBlock && activeBlock.figma_prototype?.file_id ? (
                  <FigmaPreview
                    activeBlockId={activeBlockId}
                    fileId={activeBlock.figma_prototype.file_id}
                    startScreenId={activeBlock.figma_prototype.start_screen_id ?? undefined}
                    embedParams={{ 'hide-ui': 'false' }}
                    submitGoalScreenId={nodeId => {
                      screenSelection?.onSave(nodeId);
                      setActiveSection('editor');
                    }}
                    showGoalScreenSelect={screenSelection?.mode === 'goal'}
                    cancelGoalScreenSelect={() => {
                      setScreenSelection(null);
                      setActiveSection('editor');
                    }}
                    onSelectedNode={handleSelectedNode}
                  >
                    <TaskCardDraggable
                      id={activeBlockId}
                      isDisabled
                      isDraggable={!isGoalScreen}
                      title={t('sections.studies.tasks', { count: 1 })}
                      isCollapsed={isCardCollapsed && screenSelection?.mode !== 'start'}
                      setIsCollapsed={setIsCardCollapsed}
                      collapseLabel={!isGoalScreen ? t('sections.studies.hideInstructions') : undefined}
                      isPositionSaved={false}
                      action={
                        <Button variant="primary" disabled>
                          {t('sections.studies.endTask')}
                        </Button>
                      }
                    >
                      <TaskCardDraggableContent
                        status={isGoalScreen ? 'success' : undefined}
                        instructions={
                          isGoalScreen ? t('sections.studies.taskSuccessDescription') : activeBlock.instructions
                        }
                        title={isGoalScreen ? t('sections.studies.taskSuccessTitle') : activeBlock.title}
                      />
                    </TaskCardDraggable>
                  </FigmaPreview>
                ) : null}
              </>
            ))
            .with(TaskBlockType.MultipleChoiceQuestion, () => {
              // TODO: not implemented yet
              return null;
            })
            .exhaustive()}
        </div>
      </aside>

      {/* TODO: render goal selection here in the same way, to decouple it from the preview screen */}
      {screenSelection?.mode === 'start' && 'figma_prototype' in activeBlock ? (
        <SelectStartScreen
          fileId={activeBlock.figma_prototype?.file_id}
          activeStartScreenId={activeBlock.figma_prototype?.start_screen_id ?? null}
          onChange={nodeId => screenSelection?.onSave(nodeId)}
        />
      ) : null}
    </>
  );
};
