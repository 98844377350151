import { toast } from '@askable/ui/components/ui/sonner';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from 'ui';
import { useMutation, useQuery } from 'urql';

import { InviteWaitlistedDocument, InviteWaitlistedModalDocument, SubmissionStatus } from 'generated/graphql';
import { handleRequest } from 'network/handleRequest';

import type {
  InviteWaitlistedModalQuery,
  InviteWaitlistedModalQueryVariables,
  InviteWaitlistedMutation,
  InviteWaitlistedMutationVariables,
  Maybe,
} from 'generated/graphql';

export type OpenInviteWaitlistedModalFunc = ({
  sessionId,
  sessionStartTime,
  submissionId,
  waitlistedSubmissionsWithSameSession,
}: {
  submissionId: string;
  sessionId?: Maybe<string>;
  sessionStartTime?: Maybe<number>;
  waitlistedSubmissionsWithSameSession: NonNullable<InviteWaitlistedModalQuery['bookingSubmissionConnection']>['nodes'];
}) => void;

type Props = {
  submissionId?: string;
  sessionId?: Maybe<string>;
  sessionStartTime?: Maybe<number>;
  isOpen: boolean;
  onClose: () => void;
  bookingId: string;
  waitlistedSubmissionsWithSameSession: NonNullable<InviteWaitlistedModalQuery['bookingSubmissionConnection']>['nodes'];
};

export const BookingParticipantInviteWaitistedModal = ({
  isOpen,
  sessionStartTime,
  onClose,
  bookingId,
  submissionId,
  sessionId,
  waitlistedSubmissionsWithSameSession,
}: Props) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, inviteWaitlisted] = useMutation<
    InviteWaitlistedMutation,
    InviteWaitlistedMutationVariables
  >(InviteWaitlistedDocument);

  const waitlistedPptNames =
    waitlistedSubmissionsWithSameSession?.reduce<string[]>((acc, node) => {
      if (node?.session?._session_id !== sessionId) {
        return acc;
      }
      return [...acc, `${node?.applicant.firstname} ${node?.applicant?.lastname}`];
    }, []) || [];

  const onSendInvite = async () => {
    if (!submissionId) {
      return;
    }
    const [, error] = await handleRequest(() =>
      inviteWaitlisted({
        bookingId,
        submissionId,
      }),
    );

    try {
      if (error) {
        toast.error('Failed to reinvite waitlisted');
        return;
      }

      toast.success(t('sections.bookingParticipantTable.inviteSent'));
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite waitlisted applicants</ModalHeader>
        <ModalCloseButton />
        <ModalBody color="gray.800">
          The following people are waitlisted{' '}
          {sessionStartTime ? `for this session on ${format(sessionStartTime, 'EEE d, haaa')}` : ''}
          . Would you like to re-invite them?
          <br />
          <br />
          {waitlistedPptNames?.map(name => <Text key={name}>{name}</Text>)}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} size="md" colorScheme="gray" isLoading={loading}>
            Cancel
          </Button>
          <Button onClick={onSendInvite} size="md" colorScheme="blue" ml={3} isLoading={loading}>
            Send invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const useBookingParticipantInviteWaitlistedModalDisclosure = () => {
  const [data, setData] = useState<{
    submissionId: string;
    sessionId?: Maybe<string>;
    sessionStartTime?: Maybe<number>;
    waitlistedSubmissionsWithSameSession: NonNullable<
      InviteWaitlistedModalQuery['bookingSubmissionConnection']
    >['nodes'];
  } | null>(null);
  const {
    onOpen: onInviteWaitlistedModalOpen,
    onClose: onInviteWaitlistedModalClose,
    isOpen: isInviteWaitlistedModalOpen,
  } = useDisclosure();

  const openInviteWaitlistedModal: OpenInviteWaitlistedModalFunc = ({
    submissionId: _submissionId,
    sessionId: _sessionId,
    sessionStartTime: _sessionStartTime,
    waitlistedSubmissionsWithSameSession: _waitlistedSubmissionsWithSameSession,
  }) => {
    setData({
      submissionId: _submissionId,
      sessionId: _sessionId,
      sessionStartTime: _sessionStartTime,
      waitlistedSubmissionsWithSameSession: _waitlistedSubmissionsWithSameSession,
    });
    onInviteWaitlistedModalOpen();
  };

  const closeInviteWaitlistedModal = () => {
    setData(null);
    onInviteWaitlistedModalClose();
  };

  return {
    ...data,
    openInviteWaitlistedModal,
    closeInviteWaitlistedModal,
    isInviteWaitlistedModalOpen,
  };
};

export const useBookingParticipantInviteWaitlistedModalCaller = ({
  bookingId,
  sessionId,
  pause,
  reviewSubmission,
  submissionStatus,
  isModeratedBooking,
}: {
  bookingId: string;
  reviewSubmission: boolean;
  sessionId?: string | null;
  pause?: boolean;
  submissionStatus?: SubmissionStatus | null;
  isModeratedBooking: boolean;
}) => {
  const [{ data }] = useQuery<InviteWaitlistedModalQuery, InviteWaitlistedModalQueryVariables>({
    pause,
    query: InviteWaitlistedModalDocument,
    requestPolicy: 'cache-and-network',
    variables: {
      bookingId,
    },
  });

  const waitlistedSubmissionsWithSameSession =
    (isModeratedBooking
      ? data?.bookingSubmissionConnection?.nodes?.filter(node => node?.session?._session_id === sessionId)
      : data?.bookingSubmissionConnection?.nodes) || [];

  const canInviteWaitlisted = (() => {
    // reviewSubmission false means that booking is automatic. Currently api only support invite waitlisted for automatic for unmoderated booking.
    const customCondition = isModeratedBooking
      ? submissionStatus === SubmissionStatus.Confirmed
      : !reviewSubmission && submissionStatus === SubmissionStatus.InProgress;

    return waitlistedSubmissionsWithSameSession.length > 0 && customCondition;
  })();

  return {
    canInviteWaitlisted,
    waitlistedSubmissionsWithSameSession,
  };
};
