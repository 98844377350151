import { toast } from '@askable/ui/components/ui/sonner';
import { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from 'ui';
import { useMutation } from 'urql';

import { MarkSubmissionCompleteDocument } from 'generated/graphql';

import type { MarkSubmissionCompleteMutation, MarkSubmissionCompleteMutationVariables } from 'generated/graphql';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  submissionId?: string;
  bookingId: string;
};
export const BookingSubmissionMarkCompleteAlert = ({ isOpen, onClose, submissionId }: Props) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [{ fetching }, markParticipantSessionAsComplete] = useMutation<
    MarkSubmissionCompleteMutation,
    MarkSubmissionCompleteMutationVariables
  >(MarkSubmissionCompleteDocument);

  const onMarkCompleteClick = async () => {
    if (!submissionId) return;

    try {
      const { error } = await markParticipantSessionAsComplete({ submission_id: submissionId });
      if (error) {
        throw error;
      }
      onClose();
    } catch {
      toast.error('Error marking session complete');
    }
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Mark as complete
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure? This will trigger participant payment. You can't undo this action afterwards.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onMarkCompleteClick} ml={2} isLoading={fetching}>
              Mark complete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const useBookingSubmissionMarkCompleteModal = (): {
  isOpen: boolean;
  onClose: () => void;
  onOpen: (submissionId: string) => void;
  submissionId?: string;
} => {
  const [submissionId, setSubmissionSessionId] = useState<string>();
  const { isOpen, onClose, onOpen: onOpenModal } = useDisclosure();

  useEffect(() => {
    if (!isOpen && submissionId) {
      setSubmissionSessionId(undefined);
    }
  }, [isOpen, setSubmissionSessionId, submissionId]);

  const onOpen = (_submissionId: string) => {
    setSubmissionSessionId(_submissionId);
    onOpenModal();
  };

  return {
    isOpen,
    onClose,
    onOpen,
    submissionId,
  };
};
