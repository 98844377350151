import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { mapBookingToFormFields } from 'containers/Studies/Recruit/utils/mapBookingToFormFields';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { recruitSchema } from 'containers/Studies/data/schemas/recruitSchema';
import { BOOKING_STATUS } from 'lib/constants';

import { RecruitForm } from './RecruitForm';
import { RecruitPreview } from './RecruitPreview';
import { RecruitSummary } from './RecruitSummary';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export const SettingsContent = () => {
  const { study } = useStudyContext();

  const form = useForm<RecruitFormFields>({
    mode: 'all',
    shouldUnregister: false,
    resolver: zodResolver(recruitSchema),
    defaultValues: mapBookingToFormFields(study),
  });

  return (
    <>
      <main className="main h-full overflow-auto lg:p-4">
        <FormProvider {...form}>
          {study.status === BOOKING_STATUS.DRAFT ? (
            <div className="mx-auto flex max-w-[63.5rem] flex-col gap-4 md:flex-row">
              <RecruitForm form={form} />
              <RecruitSummary />
            </div>
          ) : (
            <RecruitPreview form={form} />
          )}
        </FormProvider>
      </main>
    </>
  );
};
