import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingTaskResults = graphql(`
  query BookingTaskResults($first: Int, $filter: BookingTaskResultsFilterInput, $after: String, $before: String) {
    bookingTaskResults(first: $first, filter: $filter, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        _id
        _user_id
        status
        task_ended
        task_started
        task_loaded
        user {
          _id
          displayName
          picture
        }
        details {
          type

          ... on BookingTaskResultDetailsFigmaPrototype {
            path {
              _id
              node_id
              started
              ended
              clicks
              misclicks
            }
          }
        }
      }
    }
  }
`);

export type BookingTaskResultPartial = NonNullable<
  NonNullable<ResultOf<typeof BookingTaskResults>['bookingTaskResults']['nodes']>[number]
>;
