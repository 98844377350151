import { Spinner } from '@askable/ui/components/ui/spinner';

export const LoadingState = ({ title }: { title?: string }) => {
  return (
    <div className="flex h-full min-h-44 w-full flex-col items-center justify-center gap-2 text-sm text-foreground-subtle">
      <Spinner />
      {title}
    </div>
  );
};
