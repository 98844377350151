import { CheckIcon } from 'ui';

import type { FC } from 'react';

type Props = {
  check?: number | null;
};

export const ApplicantTechCheck: FC<Props> = ({ check }) => {
  return check ? <CheckIcon fontSize="md" color="green.500" /> : null;
};
