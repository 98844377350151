import { Button } from '@askable/ui/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { memo } from 'react';
import { Container, Flex, Spinner } from 'ui';

import type { FC, PropsWithChildren, ReactNode } from 'react';
import type { BoxProps, ContainerProps, HeadingProps } from 'ui';

type Props = ContainerProps & {
  heading?: ReactNode;
  bookingId?: string;
  loading?: boolean;
  pageHeadingProps?: HeadingProps;
  pageBreadcrumbWrapperProps?: BoxProps;
  onClose?: () => void;
};

export const PageLayout: FC<PropsWithChildren<Props>> = memo(
  ({ children, bookingId, heading, pageHeadingProps, pageBreadcrumbWrapperProps, loading, onClose, ...props }) => {
    return (
      <Container maxW="3xl" pt="6" mt="4" display="flex" flexDir="column" {...props}>
        <Flex flexGrow={0} flexShrink={1} flexBasis="auto" gap={2} alignItems="center" {...pageHeadingProps}>
          {onClose ? (
            <Button onClick={onClose} variant="ghost" aria-label="Close messages">
              <ArrowLeft className="h-4 w-4" />
            </Button>
          ) : null}
          {typeof heading === 'string' ? <h2 className="font-semibold">{heading}</h2> : heading}
        </Flex>

        {loading ? (
          <div className="flex h-full w-full items-center justify-center p-4">
            <Spinner />
          </div>
        ) : (
          children
        )}
      </Container>
    );
  },
);
