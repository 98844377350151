import { Button } from '@askable/ui/components/ui/button';
import { FormControl, FormItem } from '@askable/ui/components/ui/form';
import { MultiSelect } from '@askable/ui/components/ui/multi-select';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { Trash2 } from 'lucide-react';
import { memo, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

import type { IndustryListType } from '../data/IndustryList.query';

interface IndustryAndOccupationItemProps {
  index: number;
  items: IndustryListType;
  isDisabled?: boolean;
  selectedIndustries: string[];
  onRemove: (id: string) => void;
}

const IndustryAndOccupationItemComponent = ({
  index,
  items,
  isDisabled,
  selectedIndustries,
  onRemove,
}: IndustryAndOccupationItemProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name: `industry_and_occupation.${index}` });

  const occupations = useMemo(
    () =>
      items
        .find(item => item?._id === field.value?._id)
        ?.subcategories?.map(occupation => ({
          label: occupation?.name ?? '',
          value: occupation?._id ?? '',
        })) ?? [],
    [field.value?._id, items],
  );

  const availableIndustries = items
    .filter(item => !selectedIndustries.includes(item?._id ?? '') || item?._id === field.value?.industry)
    .map(item => ({
      label: item?.name,
      value: item?._id,
    }));

  const handleChangeIndustry = (value: string) => {
    if (value !== field.value?._id) {
      field.onChange({
        ...field.value,
        _id: value,
        name: items.find(item => item?._id === value)?.name,
        subcategories: [],
      });
    }
  };

  const handleChangeOccupations = (values: string[]) => {
    field.onChange({
      ...field.value,
      subcategories: values.map(_id => ({
        _id,
        name: occupations.find(occupation => occupation.value === _id)?.label,
      })),
    });
  };

  return (
    <FormItem>
      <div
        className="flex flex-col gap-2 opacity-100 transition-opacity starting:opacity-0 sm:flex-row"
        data-testid={field.value._id}
      >
        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_occupations_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.industryTitle')}
            </label>
            <Select value={field.value?._id} onValueChange={handleChangeIndustry} disabled={isDisabled}>
              <SelectTrigger>
                <SelectValue placeholder={t('sections.studies.recruit.fields.industryPlaceholder')} />
              </SelectTrigger>
              <SelectContent className="z-20">
                {availableIndustries.map(option => (
                  <SelectItem key={option.value} value={option.value ?? ''}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </FormControl>

        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_occupations_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.occupationsTitle')}
            </label>
            <MultiSelect
              disabled={isDisabled || !field.value.name}
              id={`input_occupations_${index}`}
              options={occupations}
              placeholder={
                field.value?.subcategories.length === 0
                  ? t('sections.studies.recruit.fields.occupationsPlaceholderEmpty')
                  : `${t('sections.studies.recruit.fields.occupationsPlaceholder')}...`
              }
              values={field.value?.subcategories.map(({ _id }: any) => _id)}
              onChange={handleChangeOccupations}
            />
          </div>
        </FormControl>

        <Button
          disabled={isDisabled}
          size="icon"
          title={t('global.remove')}
          variant="ghost"
          onClick={() => onRemove(field.value._id)}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
    </FormItem>
  );
};

IndustryAndOccupationItemComponent.displayName = 'IndustryAndOccupationItem';

export const IndustryAndOccupationItem = memo(IndustryAndOccupationItemComponent);
