import produce from 'immer';
import { ChevronDown } from 'lucide-react';
import { capitalize } from 'radash';
import { Fragment, useMemo } from 'react';
import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from 'ui';

import { SubmissionStatus } from 'generated/graphql';

import type {
  BookingParticipantSinglePanelType,
  BookingParticipantStateAndActions,
} from '../state/booking-participant-state';
import type React from 'react';

export type Props = {
  isModerated: boolean;
  isAutomated: boolean;
  handleSetPanelState: BookingParticipantStateAndActions['setPanelState'];
  submissionId: string;
  submissionStatus: SubmissionStatus;
  isAskableSessions: boolean;
  onMarkAsCompleteMenuItemClick: (submissionId: string) => void;
  isBookingComplete?: boolean;
};

export type AvailableModStatuses = Exclude<
  SubmissionStatus,
  | SubmissionStatus.InProgress
  | SubmissionStatus.PendingCheck
  | SubmissionStatus.HelpRequested
  | SubmissionStatus.TimeExpired
  | SubmissionStatus.PendingTechCheck
>;

export type AvailableUnmodStatuses = Exclude<
  SubmissionStatus,
  | SubmissionStatus.PendingTechCheck
  | SubmissionStatus.Confirmed
  | SubmissionStatus.InviteDeclined
  | SubmissionStatus.ParticipantCancelled
>;

type MenuItems = BookingParticipantSinglePanelType | 'mark-as-complete';

const availableMenuItemsOne: MenuItems[] = ['invite', 'message'];
const availableMenuItemsTwo: MenuItems[] = ['report-issue'];

/**
 * Completed booking menu items
 */
const completed = [{ id: 'complete', items: ['message'] as MenuItems[] }];
const completedMenuItems: Record<AvailableModStatuses | AvailableUnmodStatuses, { id: string; items: MenuItems[] }[]> =
  {
    available: completed,
    invited: completed,
    completed,
    confirmed: completed,
    waitlisted: completed,
    invite_declined: completed,
    participant_cancelled: completed,
    reported: completed,
    in_progress: completed,
    help_requested: completed,
    pending_check: completed,
    time_expired: completed,
    [SubmissionStatus.Excluded]: [],
  };

/**
 * Unmoderated menu items
 */
const automatedUnmoderatedMenuItems: Record<AvailableUnmodStatuses, { id: string; items: MenuItems[] }[]> = {
  available: [
    { id: 'available-one', items: ['message'] },
    { id: 'available-two', items: ['report-issue'] },
  ],
  invited: [
    { id: 'invited-one', items: ['message'] },
    { id: 'invited-two', items: ['cancel', 'report-issue'] },
  ],
  waitlisted: [
    { id: 'waitlisted-one', items: ['resend-invite', 'message'] },
    { id: 'waitlisted-two', items: ['cancel', 'report-issue'] },
  ],
  help_requested: [
    {
      id: 'help-requested-one',
      items: ['mark-as-complete', 'message'],
    },
    {
      id: 'help-requested-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  in_progress: [
    {
      id: 'in-progress-one',
      items: ['mark-as-complete', 'message'],
    },
    {
      id: 'in-progress-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  pending_check: [
    {
      id: 'pending-check-one',
      items: ['mark-as-complete', 'cancel', 'message'],
    },
    {
      id: 'pending-check-two',
      items: ['report-issue'],
    },
  ],
  completed: [
    { id: 'completed-one', items: ['message'] },
    { id: 'completed-two', items: ['report-issue'] },
  ],
  reported: [{ id: 'reported-one', items: ['message'] }],
  time_expired: [
    { id: 'time-expired-one', items: ['mark-as-complete', 'message'] },
    { id: 'time-expired-two', items: ['report-issue'] },
  ],
  [SubmissionStatus.Excluded]: [],
};

const handpickedUnmoderatedMenu = produce(automatedUnmoderatedMenuItems, draft => {
  draft.available[0].items.unshift('invite');

  draft.waitlisted[0].items = ['resend-invite', 'message'];

  draft.invited[0].items = ['resend-invite', 'mark-as-complete', 'message'];
  draft.invited[1].items = ['cancel', 'report-issue'];

  draft.time_expired[0].items = ['resend-invite', 'mark-as-complete', 'message'];
});

/**
 * Moderated menu items
 */
const moderatedMenuItems: Record<AvailableModStatuses, { id: string; items: MenuItems[] }[]> = {
  available: [
    { id: 'available-one', items: availableMenuItemsOne },
    { id: 'available-two', items: availableMenuItemsTwo },
  ],
  invited: [
    { id: 'invited-one', items: ['resend-invite', 'reschedule', 'message'] },
    { id: 'invited-two', items: ['cancel', 'report-issue'] },
  ],
  completed: [{ id: 'completed-2', items: ['report-issue'] }],
  confirmed: [
    {
      id: 'confirmed-one',
      items: ['session-joining-links', 'message', 'reschedule', 'mark-as-complete'],
    },
    {
      id: 'confirmed-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  waitlisted: [
    { id: 'waitlisted-one', items: ['reschedule', 'resend-invite', 'message'] },
    { id: 'waitlisted-two', items: ['cancel', 'report-issue'] },
  ],
  // InviteDeclined and ParticipantCancelled should behave like Available
  invite_declined: [
    { id: 'invite-declined-one', items: availableMenuItemsOne },
    { id: 'invite-declined-two', items: availableMenuItemsTwo },
  ],
  participant_cancelled: [
    { id: 'participant-cancelled-one', items: availableMenuItemsOne },
    { id: 'participant-cancelled-two', items: availableMenuItemsTwo },
  ],
  reported: [{ id: 'reported', items: ['invite', 'message'] }],
  [SubmissionStatus.Excluded]: [],
};

export const BookingParticipantMenu = ({
  submissionId,
  isAutomated,
  isModerated,
  isBookingComplete,
  submissionStatus,
  isAskableSessions,
  handleSetPanelState,
  onMarkAsCompleteMenuItemClick,
}: Props) => {
  const actions = useMemo(() => {
    if (isBookingComplete) {
      return completedMenuItems;
    }

    if (!isModerated) {
      if (isAutomated) {
        return automatedUnmoderatedMenuItems;
      }

      return handpickedUnmoderatedMenu;
    }

    return moderatedMenuItems;
  }, [isBookingComplete, isModerated, isAutomated]);

  const handleClick = (action: MenuItems) => (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    switch (action) {
      case 'mark-as-complete': {
        onMarkAsCompleteMenuItemClick(submissionId);
        return;
      }
      default:
        handleSetPanelState({
          type: 'single',
          panel: action,
          submissionId,
        });
    }
  };

  const value = actions?.[submissionStatus as AvailableModStatuses & AvailableUnmodStatuses];

  if (!value) {
    return null;
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <Menu isLazy>
      <MenuButton
        data-testid="menu-button"
        as={IconButton}
        icon={<ChevronDown className="h-4 w-4" />}
        aria-label="participants-menu"
        size="sm"
        onClick={handleButtonClick}
      />
      <MenuList boxShadow="lg">
        {value?.map((container, index) => {
          return (
            <Fragment key={container.id}>
              {container.items.map(action => {
                if (!isAskableSessions && action === 'session-joining-links') {
                  return null;
                }

                const itemTitle = capitalize(action.split('-').join(' '));

                return (
                  <MenuItem
                    data-testid={itemTitle}
                    key={`${submissionId}-${action}`}
                    py="2"
                    onClick={handleClick(action)}
                  >
                    {itemTitle}
                  </MenuItem>
                );
              })}
              {value?.[index + 1]?.id && <MenuDivider />}
            </Fragment>
          );
        })}
      </MenuList>
    </Menu>
  );
};
