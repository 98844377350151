import { Checkbox } from '@askable/ui/components/ui/checkbox';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { differenceInDays, differenceInSeconds, format, formatISO, formatDistanceToNowStrict, getYear } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Message, Submission, User } from '../data/BookingSubmissionsWithMessages.query';

/**
 * Displays the latest message for a user. Used in BookingMessagesContainer
 */

interface BookingMessageItemProps {
  clientId: string;
  isActive: boolean;
  selectedRows: string[];
  submission: Submission;
  selectRow: (userId: string) => void;
  openMessagePanel: (userId: string) => void;
}

const formatDate = (date: number, justNowLabel?: string) => {
  const now = new Date();
  const secondsDifference = differenceInSeconds(now, date);
  const daysDifference = differenceInDays(now, date);
  const currentYear = getYear(now);
  const dateYear = getYear(date);

  if (secondsDifference < 60) {
    return justNowLabel ?? 'Just now';
  }

  // Is last year
  if (dateYear < currentYear) {
    return format(date, 'd MMM yyyy');
  }

  // Older than a week
  if (daysDifference > 6) {
    return format(date, 'd MMM');
  }

  // Relative time eg. '4 hours ago'
  return formatDistanceToNowStrict(date, { addSuffix: true });
};

export const BookingMessageItem = ({
  clientId,
  isActive,
  selectedRows,
  submission,
  selectRow,
  openMessagePanel,
}: BookingMessageItemProps) => {
  const { t } = useTranslation();
  const [isSeen, setIsSeen] = useState(true);

  const user: User = submission.user;
  const message: Message = submission.Messages?.[submission.Messages.length - 1] ?? null;

  useEffect(() => setIsSeen(!!message?.seen), [message, isSeen]);

  return user ? (
    <li>
      <button
        type="button"
        onClick={() => {
          setIsSeen(true);
          openMessagePanel(user._id);
        }}
        title="View messages"
        className={cn(
          'flex w-full gap-2 bg-background px-4 py-2 text-start transition-all hover:bg-accent focus:bg-accent active:bg-secondary',
          {
            'bg-secondary': isActive,
          },
        )}
      >
        <Checkbox
          onCheckedChange={() => selectRow(user._id)}
          onClick={e => e.stopPropagation()}
          checked={selectedRows.includes(user._id)}
        />
        <div className="flex w-full flex-col gap-1 ">
          <div className="flex flex-wrap justify-between gap-x-2 text-sm text-foreground-subtle">
            <div className="flex flex-wrap items-center gap-x-3">
              {user.displayName}
              {submission.status ? (
                <div
                  className={cn('text-xs', {
                    'text-success': submission.status === 'confirmed' || submission.status === 'completed',
                    'text-warning': submission.status === 'invited' || submission.status === 'waitlisted',
                    'text-destructive': submission.status === 'participant_cancelled',
                  })}
                >
                  {submission.status === 'completed' ? '✔ ' : null}
                  {t(`submission.status.${submission.status}`)}{' '}
                  {submission.status === 'confirmed' && submission.session?.session?.start ? (
                    <time
                      dateTime={formatISO(submission.session?.session?.start)}
                      title={formatISO(submission.session?.session?.start)}
                    >
                      {format(submission.session.session?.start, 'd MMM, h:mmaaa')}
                    </time>
                  ) : null}
                  {submission.status === 'completed' && submission.status_updated ? (
                    <time dateTime={formatISO(submission.status_updated)} title={formatISO(submission.status_updated)}>
                      {format(submission.status_updated, 'd MMM, h:mmaaa')}
                    </time>
                  ) : null}
                </div>
              ) : null}
            </div>
            {message?.created ? (
              <Tooltip>
                <TooltipTrigger>
                  <time dateTime={formatISO(message.created)} className="text-xs">
                    {formatDate(message.created, t('sections.messages.justNow'))}
                  </time>
                </TooltipTrigger>
                <TooltipContent>{format(message.created, 'EEE d MMM yyyy, h:mmaaa')}</TooltipContent>
              </Tooltip>
            ) : null}
          </div>

          {message ? (
            <div className="flex gap-1 text-sm">
              <div
                className={cn('text-md line-clamp-1', {
                  'font-semibold': message.direction === 4 && !isSeen,
                })}
              >
                {clientId === message._from_user_id ? `${t('sections.messages.you')}: ` : null}
                {message.body}
              </div>
            </div>
          ) : null}
        </div>
      </button>
    </li>
  ) : null;
};
