import { cn } from '@askable/ui/lib/utils';

import type { ReactNode } from 'react';

/**
 * TODO: use ui library component instead
 */

interface TagProps {
  variant: 'success' | 'failure' | 'idle';
  children: ReactNode;
}

export const Tag = ({ children, variant }: TagProps) => {
  return (
    <div
      className={cn('w-fit rounded-lg px-2 py-1 text-sm font-medium leading-none text-primary', {
        'bg-pastel-green/30': variant === 'success',
        'bg-pastel-red/30': variant === 'failure',
        'bg-gray-100': variant === 'idle',
      })}
    >
      {children}
    </div>
  );
};
