import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/components/ui/tooltip';
import { secondsToTime } from '@askable/ui/lib/utils';
import { CheckCircle, CircleOff, MousePointerClick, Timer, UserCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { formatPercentage } from 'containers/Studies/utils/utils';

import type { StatType } from 'containers/Studies/Results/Results';

interface StatProps {
  isBlock?: boolean;
  subtitle?: string;
  type: StatType;
  value: string | number;
}

const iconByType = {
  successful: CheckCircle,
  incomplete: CircleOff,
  responses: UserCircle,
  misclickRate: MousePointerClick,
  averageDuration: Timer,
};

export const Stat = ({ isBlock = false, subtitle, type, value }: StatProps) => {
  const { t } = useTranslation();

  const Icon = iconByType[type];
  let localValue = value;

  if (['successful', 'incomplete', 'misclickRate'].includes(type) && typeof value === 'number') {
    localValue = formatPercentage(value);
  }

  if (type === 'averageDuration') {
    localValue = secondsToTime(Number(value), 'labels');
  }

  return isBlock ? (
    <Tooltip>
      <TooltipTrigger className="flex flex-col justify-center gap-2 rounded-2xl border-0.5 border-border p-4 text-sm leading-none">
        <div>{t(`sections.studies.results.${type}`)}</div>
        <div className="flex w-full flex-wrap items-center justify-between gap-x-2 gap-y-1">
          <div className="text-3xl font-semibold leading-none">{localValue}</div>{' '}
          {subtitle ? <div className="text-foreground-subtle">{subtitle}</div> : null}
        </div>
      </TooltipTrigger>
      <TooltipContent>{t(`sections.studies.results.${type}Description`)}</TooltipContent>
    </Tooltip>
  ) : (
    <Tooltip>
      <TooltipTrigger className="flex items-center gap-1">
        <Icon className="relative -top-[1px] h-4 w-4" /> {localValue}
      </TooltipTrigger>
      <TooltipContent>{t(`sections.studies.results.${type}`)}</TooltipContent>
    </Tooltip>
  );
};
