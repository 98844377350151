import { graphql } from 'src/graphql';

export const SaveSegmentMutation = graphql(`
  mutation saveSegment($booking_id: ID!, $segment_input: SegmentInput!) {
    saveSegment(booking_id: $booking_id, segment_input: $segment_input) {
      _id
      name
      sort
      filters {
        _id
        field
        method
        values {
          label
          value_string
          value_numeric
        }
      }
    }
  }
`);
