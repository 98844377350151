import { memo, useEffect, useMemo } from 'react';
import { pluralize } from 'shared-utils';
import {
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ColumnsIcon,
  FilterIcon,
  Tooltip,
  Box,
  Portal,
} from 'ui';

import { SortableList } from 'components/List/SortableList';
import { isModeratedBooking } from 'utils/booking-utils';

import { bookingParticipantSelectors, useBookingParticipantState } from '../../state/booking-participant-state';
import { BookingParticipantFilters } from '../BookingParticipantFilter';
import { DraggableFieldAction } from '../DraggableFieldAction';
import { ParticipantSegmentActions } from '../ParticipantSegmentActions';

import { ParticipantTableActionSortPopover } from './ParticipantTableActionSortPopover';

import type { TableRowSubmission } from '../../hooks/useBookingParticipantsTableData';
import type { BookingParticipantBulkPanelName } from '../../state/booking-participant-state';
import type { Column, RowModel } from '@tanstack/react-table';
import type { BookingContainerBookingQuery } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  booking?: BookingContainerBookingQuery['bookingByID'];
  clearRows: () => void;
  columns: Column<TableRowSubmission>[];
  getSelectedRows: () => RowModel<TableRowSubmission>;
  selectedRows: Record<string, boolean>;
  visibleColumns: Record<string, boolean>;
};

export const ParticipantTableActions: FC<Props> = memo(
  ({ selectedRows, booking, clearRows, getSelectedRows, columns, visibleColumns }) => {
    const selectRowCount = Object.keys(selectedRows).length;
    const hasHiddenFields = Object.values(visibleColumns).some(a => !a);

    const setPanelState = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
    const filterCount = useBookingParticipantState(bookingParticipantSelectors.filterCount);
    const updateColumnOrder = useBookingParticipantState(bookingParticipantSelectors.updateColumnOrder);
    const sidePanel = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);

    const handleNewOrder = (newOrder: Props['columns']) => {
      updateColumnOrder(newOrder.map(b => b.id));
    };

    const handleClearRows = () => {
      if (sidePanel !== null) {
        setPanelState(null);
      }

      clearRows();
    };

    const renderItem = (item: Props['columns'][0]) => {
      if (item.id === '_id') {
        return null;
      }

      return <DraggableFieldAction key={item.id} column={item} />;
    };

    const filterButtonText = useMemo(() => {
      if (filterCount === 1) {
        return '1 filter applied';
      }

      if (filterCount > 1) {
        return `${filterCount} filters applied`;
      }

      return 'Filter';
    }, [filterCount]);

    useEffect(() => {
      /**
       * We only want this to happen when its a bulk action.
       * Because a client could add and remove rows
       */
      if (sidePanel?.type !== 'bulk') {
        return;
      }

      const selectedIds = getSelectedRows().rows.map(row => row.original?._id!);

      setPanelState({
        type: 'bulk',
        panel: sidePanel?.panel,
        data: {
          submissionIds: selectedIds,
        },
      });
    }, [selectRowCount]);

    const handleBulkTypeClick = (bulkPanelName: BookingParticipantBulkPanelName) => () => {
      const selectedIds = getSelectedRows().rows.map(row => row.original?._id!);

      if (bulkPanelName === 'bulk-message' && selectedIds.length === 1) {
        setPanelState({
          type: 'single',
          panel: 'message',
          submissionId: selectedIds[0],
        });
      } else {
        setPanelState({
          type: 'bulk',
          panel: bulkPanelName,
          data: {
            submissionIds: selectedIds,
          },
        });
      }
    };

    return (
      <div className="flex flex-wrap items-center gap-2">
        {selectRowCount >= 1 ? (
          <>
            <Text fontSize="sm" color="gray.900">
              {selectRowCount} selected
            </Text>
            <Button
              size="sm"
              colorScheme="blue"
              onClick={handleBulkTypeClick('bulk-invite')}
              isDisabled={sidePanel?.panel === 'bulk-invite'}
            >
              Invite
            </Button>

            <Button
              size="sm"
              colorScheme="blue"
              onClick={handleBulkTypeClick('bulk-message')}
              isDisabled={sidePanel?.panel === 'bulk-message'}
            >
              Message
            </Button>

            <Button onClick={handleClearRows} size="sm" variant="ghost">
              Unselect all
            </Button>
          </>
        ) : (
          <>
            <Popover placement="bottom-start" returnFocusOnClose={false} id="filter-button-participants-screen">
              <Tooltip label="Filter table data" aria-label="filter-table-data" openDelay={1000}>
                <Box>
                  <PopoverTrigger>
                    <Button
                      data-testid="filter-button"
                      size="sm"
                      leftIcon={<FilterIcon />}
                      colorScheme={filterCount > 0 ? 'yellow' : 'gray'}
                    >
                      {filterButtonText}
                    </Button>
                  </PopoverTrigger>
                </Box>
              </Tooltip>
              <Portal>
                <PopoverContent flexShrink={1} flexGrow={1} w="auto" zIndex={11} maxWidth="100vw">
                  <PopoverBody w="auto" p="5">
                    <BookingParticipantFilters booking={booking} />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
            <Popover placement="bottom-start" isLazy returnFocusOnClose={false} id="fields-button-participants-screen">
              <PopoverTrigger>
                <Button
                  size="sm"
                  leftIcon={<ColumnsIcon />}
                  colorScheme={Object.values(visibleColumns).filter(a => !a).length >= 1 ? 'yellow' : 'gray'}
                >
                  {hasHiddenFields
                    ? `${Object.values(visibleColumns).filter(a => !a).length} ${pluralize('field', Object.values(visibleColumns).filter(a => !a).length)} hidden`
                    : 'Fields'}
                </Button>
              </PopoverTrigger>
              <PopoverContent overflow="hidden" boxShadow="xl" maxH="xl" width="sm" maxWidth="100vw">
                <PopoverBody overflow="scroll">
                  <SortableList onChange={handleNewOrder} items={columns} renderItem={renderItem} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <ParticipantTableActionSortPopover isBookingModerated={isModeratedBooking(booking?.type)} />
            <ParticipantSegmentActions bookingId={booking?._id!} />
          </>
        )}
      </div>
    );
  },
);
