import { graphql } from 'src/graphql';

// Update messages seen
export const MarkMultipleMessagesAsSeen = graphql(`
  mutation markMultipleMessagesAsSeen($_messages_id: [ID]!) {
    markMultipleMessagesAsSeen(_messages_id: $_messages_id) {
      _id
      seen
    }
  }
`);
