import { Check } from '@askable/ui/components/unmod/icons';
import { match } from 'ts-pattern';

interface Props {
  instructions: string;
  title: string;
  status?: 'success';
}

export const TaskCardDraggableContent = ({ status, instructions, title }: Props) => {
  return (
    <div className="flex flex-col gap-2 pt-4">
      {match(status)
        .with('success', () => <Check bgColor="success" />)
        .otherwise(() => null)}

      <div className="flex flex-col gap-1">
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="whitespace-pre-wrap text-sm">{instructions}</div>
      </div>
    </div>
  );
};
