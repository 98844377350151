import { Button } from '@askable/ui/components/ui/button';
import { ChevronDown, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { ReactNode } from 'react';

interface CollapsibleProps {
  children: ReactNode;
  isDefaultOpen?: boolean;
  name: keyof RecruitFormFields;
  title: string;
  onOpenChange?: (isOpen: boolean) => void;
}

const showSelectedCount = (selectedValues: unknown[], name: keyof RecruitFormFields): boolean => {
  if (!Array.isArray(selectedValues) || !selectedValues.length) {
    return false;
  }

  if (name === 'industry_and_occupation') {
    const valid = selectedValues.filter((item: any) => !!item.name);
    return !!valid.length;
  }

  return true;
};

export const Collapsible = ({ children, isDefaultOpen = false, name, title, onOpenChange }: CollapsibleProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<RecruitFormFields>();
  const selectedValues = useWatch({
    control,
    name,
  });

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  useEffect(() => {
    onOpenChange?.(isOpen);
  }, [isOpen, onOpenChange]);

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setValue(name, [], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <div
      className="flex flex-col gap-2 rounded-lg border border-border text-foreground transition-all focus-within:border-input
        hover:border-input"
      data-state={isOpen ? 'open' : 'closed'}
      role="region"
      aria-labelledby={`${name}_header`}
    >
      <button
        aria-expanded={isOpen}
        className="flex items-center justify-between gap-2 px-3 py-3 lg:px-4"
        id={`${name}_header`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-medium text-foreground">{title}</h3>
        <div className="flex items-center gap-2">
          {Array.isArray(selectedValues) && showSelectedCount(selectedValues, name) ? (
            <Button
              aria-label={t('global.remove')}
              className="h-6 bg-info-foreground px-2 hover:bg-info/10 focus:bg-info/10 active:bg-info/20"
              variant="secondary"
              onClick={handleClear}
            >
              {t('sections.studies.recruit.selectedCount', { count: selectedValues.length })}
              <X className="ml-1 h-3 w-3" />
            </Button>
          ) : null}

          <ChevronDown
            className={`h-4 w-4 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          />
        </div>
      </button>

      {isOpen ? (
        <div className="px-3 pb-4 lg:px-4" role="region" aria-labelledby={`${name}_header`}>
          {children}
        </div>
      ) : null}
    </div>
  );
};
