import { formatSessionTime } from 'shared-utils';
import { Text } from 'ui';

import { BookingParticipantPptTimezoneIcon } from '../../BookingParticipantPptTimezoneIcon';

type Props = {
  isPptInDifferentTimezone: boolean;
  sessionStart: number;
  sessionEnd: number;
  displayName: string;
  pptTimezone: string;
};

export const BookingParticipantAllocatedSession = ({
  displayName,
  isPptInDifferentTimezone,
  sessionEnd,
  sessionStart,
  pptTimezone,
}: Props) => {
  const formattedSessionTime = formatSessionTime({ start: sessionStart, end: sessionEnd });
  if (isPptInDifferentTimezone) {
    return (
      <Text lineHeight={5}>
        {formattedSessionTime}
        <BookingParticipantPptTimezoneIcon
          ml="2"
          displayName={displayName}
          pptTimezone={pptTimezone}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
      </Text>
    );
  }
  return <Text lineHeight={5}>{formattedSessionTime}</Text>;
};
