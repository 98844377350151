import { Skeleton } from '@askable/ui/components/ui/skeleton';
import { differenceInMinutes, format, isToday, isYesterday } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, useSubscription } from 'urql';

import { ListMessages } from '../data/ListMessages.query';
import { SubscribeListMessages } from '../data/ListMessages.subscription';
import { MarkMultipleMessagesAsSeen } from '../data/MarkMultipleMessagesAsSeen.mutation';

import { MessageListItem } from './MessageListItem';
import { NoMessages } from './NoMessages';

import type { ListMessage } from '../data/ListMessages.query';

/**
 * Displays the message history between participant and client. Used in MessagesPanel
 */

interface MessagesListProps {
  bookingId: string;
  userId: string;
  fromUserId: string;
  onUpdated: () => void;
}

interface ListItem {
  firstInBlock?: boolean;
  lastInBlock?: boolean;
  message?: ListMessage;
  title?: string;
  type: 'header' | 'message';
}

const getFormattedDate = (date: number) => {
  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';
  return format(date, 'd MMM');
};

const SHOW_TIMESTAMP_AFTER_MINUTES = 2;

export const MessagesList = ({ bookingId, userId, fromUserId, onUpdated }: MessagesListProps) => {
  const [messages, setMessages] = useState<ListMessage[]>([]);

  // Set the inital messages
  const [{ data, fetching, error }] = useQuery({
    query: ListMessages,
    requestPolicy: 'cache-and-network',
    variables: {
      search: {
        _booking_id: bookingId,
        _user_id: userId,
        hide_batch_messages: true,
      },
    },
  });

  const [, markMultipleMessagesAsSeen] = useMutation(MarkMultipleMessagesAsSeen);

  // Mark incoming messages as seen
  const onMessagesSeen = (newMessages: ListMessage[]) => {
    const unseenMessageIds = newMessages.filter(m => m?.direction === 4 && !m?.seen).map(m => m?._id!);
    if (unseenMessageIds.length > 0) {
      markMultipleMessagesAsSeen({ _messages_id: unseenMessageIds });
    }
  };

  // Subscribe for new messages
  useSubscription(
    {
      query: SubscribeListMessages,
      pause: fetching,
      variables: {
        filter: {
          _booking_id: bookingId,
          _user_id: userId,
        },
      },
    },
    (_previous, response) => {
      if (response.messagesSubscription) {
        // Add incoming message to the list
        const newMessage = response.messagesSubscription;

        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          onMessagesSeen([newMessage]);
          return updatedMessages;
        });
      }

      return response;
    },
  );

  // Set the initial messages
  useEffect(() => {
    if (data && data.messages) {
      setMessages(prevMessages => {
        // Merge existing messages with new messages from the query
        const newMessages = (data.messages as ListMessage[]).filter(
          newMessage => !prevMessages.some(m => m._id === newMessage?._id),
        );

        if (newMessages.length > 0) {
          const updatedMessages = [...prevMessages, ...newMessages].sort((a: any, b: any) => a.created - b.created);
          onMessagesSeen(newMessages);
          return updatedMessages;
        }

        return prevMessages;
      });
    }
  }, [data]);

  // Construct a flat list of messages with headers for each day
  const flatList = useMemo(() => {
    const sortedMessages = [...messages].sort((a: any, b: any) => a.created - b.created);
    let currentDate: string | null = null;

    return sortedMessages.reduce<ListItem[]>((list, message, index) => {
      const formattedDate = getFormattedDate(message?.created as number);

      let firstInBlock = true;
      let lastInBlock = true;

      if (formattedDate !== currentDate) {
        // Add a day divider eg. Today, Yesterday, 26 Jul
        list.push({ type: 'header', title: formattedDate });
        currentDate = formattedDate;
      } else {
        // firstInBlock = this message is a new user or less than 2 minutes from previous message
        // lastInBlock = next message is a new user or more than 2 minutes from this message
        // Controls the rounded corners and where the timestamps are placed
        if (sortedMessages[index - 1]) {
          firstInBlock =
            differenceInMinutes(
              new Date(sortedMessages[index].created!),
              new Date(sortedMessages[index - 1].created!),
            ) >= SHOW_TIMESTAMP_AFTER_MINUTES ||
            sortedMessages[index - 1]._from_user_id !== sortedMessages[index]._from_user_id;
        }

        if (sortedMessages[index + 1]) {
          lastInBlock =
            differenceInMinutes(
              new Date(sortedMessages[index + 1].created!),
              new Date(sortedMessages[index].created!),
            ) > SHOW_TIMESTAMP_AFTER_MINUTES ||
            sortedMessages[index + 1]._from_user_id !== sortedMessages[index]._from_user_id;
        }
      }

      list.push({
        firstInBlock,
        lastInBlock,
        message,
        type: 'message',
      });

      // Scroll list to the bottom after updating messages
      window.setTimeout(() => onUpdated(), 0);

      return list;
    }, []);
  }, [messages]);

  // @todo: use CSS Grid for layout so we don't need these magic numbers
  const minHeight = 'calc(var(--mainContainerHeight) - var(--messagePanelOffset))';

  return (
    <>
      {error ? (
        <div className="p-4 text-center text-sm text-destructive" style={{ minHeight }}>
          {error.message}
        </div>
      ) : null}

      {fetching ? (
        <div className="flex flex-col gap-1 p-4" style={{ minHeight }}>
          <Skeleton className="h-10 w-2/5" />
          <Skeleton className="h-10 w-2/5" />
          <Skeleton className="h-10 w-1/5" />
        </div>
      ) : null}

      {messages.length === 0 ? (
        <div style={{ minHeight }}>
          <NoMessages />
        </div>
      ) : null}

      {messages.length > 0 ? (
        <ol className="flex flex-col gap-1 px-4 py-2" style={{ minHeight, marginBottom: '-0.4rem' }}>
          {flatList.map(item => {
            return item.type === 'header' ? (
              <li
                key={`header-${item.title}`}
                className="grid grid-cols-[1fr_auto_1fr] place-items-center gap-4 pt-4 text-xs text-foreground-subtle"
              >
                <div className="w-full border-b border-border" />
                <div>{item.title}</div>
                <div className="w-full border-b border-border" />
              </li>
            ) : (
              <MessageListItem
                fromUserId={fromUserId}
                key={item.message?._id}
                message={item.message as ListMessage}
                firstInBlock={!!item.firstInBlock}
                lastInBlock={!!item.lastInBlock}
              />
            );
          })}
        </ol>
      ) : null}
    </>
  );
};
