import { Button } from '@askable/ui/components/ui/button';
import { FormControl, FormItem, FormLabel } from '@askable/ui/components/ui/form';
import { Input } from '@askable/ui/components/ui/input';
import { Label } from '@askable/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { Switch } from '@askable/ui/components/ui/switch';
import { Textarea } from '@askable/ui/components/ui/textarea';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Copy, GripVertical, Plus, Trash2 } from 'lucide-react';
import { memo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { SortableList } from 'containers/Studies/components/SortableList';
import { getObjectId } from 'containers/Studies/utils/utils';

import { ScreenerQuestionItemOption } from './ScreenerQuestionItemOption';

import type { ScreenerQuestionOption } from 'containers/Studies/Recruit/components/SectionScreenerQuestions';
import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { CSSProperties } from 'react';
import type { FieldError } from 'react-hook-form';

interface ScreenerQuestionItemProps {
  index: number;
  isDisabled?: boolean;
  isMovable?: boolean;
  onCopy: (id: string) => void;
  onRemove: (id: string) => void;
}

const ScreenerQuestionItemComponent = ({
  index,
  isDisabled,
  isMovable,
  onCopy,
  onRemove,
}: ScreenerQuestionItemProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({ name: `screener_questions.${index}`, control });

  const titleError = (error as FieldError & { title?: { message: string } })?.title;
  const optionsError = (error as FieldError & { options?: { message: string }[] })?.options;

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && !isDisabled ? field.value._id : '',
  });

  const [showDescription, setShowDescription] = useState(field.value.description ? true : false);

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const addOption = () => {
    field.onChange({
      ...field.value,
      type: 'multiple_choice',
      options: [...(field.value.options || []), { _id: getObjectId(), label: '', is_qualified: false }],
    });
  };

  const handleChangeOption = (optionIndex: number, label: string, is_qualified: boolean) => {
    const updatedList = (field.value.options ?? []).map((item: ScreenerQuestionOption, i: number) => {
      if (i === optionIndex) {
        return {
          ...item,
          label,
          is_qualified,
        };
      }

      return item;
    });

    field.onChange({ ...field.value, options: updatedList });
  };

  const handleRemoveOption = (optionIndex: number) => {
    const updatedList = (field.value.options ?? []).filter(
      (item: ScreenerQuestionOption, i: number) => i !== optionIndex,
    );
    field.onChange({ ...field.value, options: updatedList });
  };

  const handleOptionSortChange = (updatedList: ScreenerQuestionOption[]) => {
    field.onChange({ ...field.value, options: updatedList });
  };

  const updateOptionList = (type: string) => {
    const options =
      type === 'multiple_choice'
        ? [
            { _id: getObjectId(), label: '', is_qualified: true },
            { _id: getObjectId(), label: '', is_qualified: false },
          ]
        : [];

    field.onChange({ ...field.value, type, options });
  };

  return (
    <li
      ref={setNodeRef}
      style={dragStyle}
      className={cn(
        `flex flex-col gap-4 overflow-hidden rounded-lg border border-border bg-background opacity-100 transition-opacity
        starting:opacity-0`,
        { 'z-20 shadow-lg': isMovable && isDragging },
      )}
      data-testid={field.value._id}
    >
      <div
        className={cn('flex items-center justify-between gap-2 px-4 pt-2 aria-[disabled=true]:!cursor-not-allowed', {
          '!cursor-grab touch-none active:!cursor-grabbing': isMovable && !isDisabled,
        })}
        {...attributes}
        {...listeners}
      >
        <div>{isMovable ? <GripVertical className="h-4 w-4" /> : null}</div>

        <div className="flex items-center gap-2">
          <Button
            disabled={isDisabled}
            size="icon"
            title={t('global.copy')}
            variant="ghost"
            onClick={() => onCopy(field.value._id)}
          >
            <Copy className="h-4 w-4" />
          </Button>

          <Button
            disabled={isDisabled}
            size="icon"
            title={t('global.remove')}
            variant="ghost"
            onClick={() => onRemove(field.value._id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <FormItem className="flex flex-col gap-4 p-4 pt-0">
        <FormControl>
          <div className="flex flex-col gap-2 md:flex-row">
            <div className="flex flex-1 flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_title`}>
                {t('sections.studies.recruit.fields.questionTitle')}
              </FormLabel>

              <Input
                autoFocus
                disabled={isDisabled}
                name={`input_screener_question_${field.value._id}_title`}
                value={field.value?.title}
                onChange={e => field.onChange({ ...field.value, title: e.target.value })}
              />
            </div>

            <div className="flex w-[180px] flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_type`}>&nbsp;</FormLabel>

              <Select
                disabled={isDisabled}
                value={field.value?.type ?? 'short_answer'}
                onValueChange={value => {
                  field.onChange({ ...field.value, type: value });
                  updateOptionList(value);
                }}
              >
                <SelectTrigger id={`input_screener_question_${field.value._id}_type`}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="z-20">
                  <SelectItem value="short_answer">{t('sections.studies.recruit.options.short_answer')}</SelectItem>
                  <SelectItem value="multiple_choice">
                    {t('sections.studies.recruit.options.multiple_choice')}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </FormControl>

        {titleError ? <FormErrorMessage message={titleError.message} prefix="sections.studies.formValidation" /> : null}

        {showDescription ? (
          <FormControl>
            <div className="flex flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_description`}>
                {t('sections.studies.recruit.fields.descriptionTitle')}
              </FormLabel>

              <Textarea
                disabled={isDisabled}
                maxRows={3}
                name={`input_screener_question_${field.value._id}_description`}
                rows={1}
                value={field.value?.description ?? ''}
                onChange={e => field.onChange({ ...field.value, description: e.target.value })}
              />
            </div>
          </FormControl>
        ) : (
          <Button variant="link" className="px-0" onClick={() => setShowDescription(true)} disabled={isDisabled}>
            <Plus className="h-4 w-4" />
            {t('sections.studies.recruit.fields.descriptionCta')}
          </Button>
        )}

        {field.value?.type === 'multiple_choice' ? (
          <div className="-mx-2 flex flex-col gap-2 overflow-auto">
            <FormLabel
              htmlFor={`input_screener_question_${field.value._id}_options`}
              className="flex w-full items-center justify-between gap-2 px-2"
            >
              {t('sections.studies.recruit.fields.optionsTitle')}
              <div className="flex items-center gap-2 ">
                <Label
                  htmlFor={`input_screener_question_${field.value._id}_is_multiple_selection`}
                  className="font-normal"
                >
                  {t('sections.studies.recruit.fields.is_multiple_selectionTitle')}
                </Label>
                <Switch
                  checked={!!field.value?.is_multiple_selection}
                  disabled={isDisabled}
                  id={`input_screener_question_${field.value._id}_is_multiple_selection`}
                  size="small"
                  onCheckedChange={value => field.onChange({ ...field.value, is_multiple_selection: value })}
                />
              </div>
            </FormLabel>

            <div className="px-1">
              {field.value?.options?.length ? (
                <SortableList
                  onChange={handleOptionSortChange}
                  items={field.value?.options}
                  renderItem={(option, index) => (
                    <ScreenerQuestionItemOption
                      id={option._id}
                      index={index}
                      isDisabled={isDisabled}
                      isMovable={(field.value?.options ?? []).length > 1}
                      is_qualified={option.is_qualified}
                      key={index}
                      value={option.label}
                      onChangeValue={handleChangeOption}
                      onRemove={handleRemoveOption}
                    />
                  )}
                />
              ) : null}
            </div>

            <div className="px-2">
              <Button variant="outline" onClick={addOption}>
                <Plus className="h-4 w-4" />
                {t('sections.studies.recruit.fields.optionsCta')}
              </Button>
            </div>
          </div>
        ) : null}

        {optionsError ? (
          <FormErrorMessage
            message={optionsError.find(error => error?.message)?.message}
            prefix="sections.studies.formValidation"
          />
        ) : null}

        {error ? <FormErrorMessage message={error?.message} prefix="sections.studies.formValidation" /> : null}
      </FormItem>
    </li>
  );
};

ScreenerQuestionItemComponent.displayName = 'ScreenerQuestionItem';

export const ScreenerQuestionItem = memo(ScreenerQuestionItemComponent);
