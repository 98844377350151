import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import BookingParticipantsTableContainer from 'containers/Booking/containers/BookingParticipantsContainer/BookingParticipantTableContainer';
import { BookingParticipantDrawer } from 'containers/Booking/containers/BookingParticipantsContainer/components/BookingParticipantsSidePanel/BookingParticipantsSidePanel';
import {
  bookingParticipantSelectors,
  useBookingParticipantState,
} from 'containers/Booking/containers/BookingParticipantsContainer/state/booking-participant-state';
import { Segments } from 'containers/Studies/Recruit/components/Participants/Segments';
import { IndustryList } from 'containers/Studies/Recruit/data/IndustryList.query';
import { ParticipantsSegments } from 'containers/Studies/Recruit/data/ParticipantsSegments.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { BOOKING_STATUS } from 'lib/constants';

import type { IndustryListItemType } from 'containers/Studies/Recruit/data/IndustryList.query';

export const ParticipantsContent = () => {
  const { segmentId } = useParams();
  const { study } = useStudyContext();
  const activeSegmentId = segmentId ?? 'all';
  const setSegments = useBookingParticipantState(bookingParticipantSelectors.setSegments);

  const [{ data: industryData }] = useQuery({ query: IndustryList });

  const [{ data: segmentsData, fetching: fetchingSegments }] = useQuery({
    pause: !study._id,
    query: ParticipantsSegments,
    variables: { id: study._id },
  });

  useEffect(() => {
    if (!segmentsData?.segmentsByBookingId || !industryData?.industryList) {
      return;
    }

    // @todo fix any types (expects Segment[] and Booking)
    setSegments(
      segmentsData.segmentsByBookingId as any[],
      activeSegmentId,
      study as any,
      industryData?.industryList as IndustryListItemType[],
    );
  }, [segmentsData, industryData, activeSegmentId, setSegments, study]);

  if (study.status === BOOKING_STATUS.DRAFT) {
    return <Navigate to={`/studies/${study._id}/recruit/settings`} />;
  }

  return (
    <>
      <main className="main flex overflow-auto bg-background">
        <BookingParticipantsTableContainer booking={study} />
        <BookingParticipantDrawer bookingId={study._id} isModeratedBooking={false} />
      </main>

      <div className="page-nav bg-background">
        {segmentsData?.segmentsByBookingId && !fetchingSegments ? <Segments /> : null}
      </div>
    </>
  );
};
