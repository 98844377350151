import { Avatar, AvatarImage, AvatarFallback } from '@askable/ui/components/ui/avatar';
import { TableCell, TableRow } from '@askable/ui/components/ui/table';
import { secondsToTime } from '@askable/ui/lib/utils';
import { format, formatISO } from 'date-fns';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { BookingTaskResultStatus } from 'generated/graphql';

import { Tag } from '../Tag';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';

interface ResponseTableRowProps {
  result: BookingTaskResultPartial;
}

export const ResponseTableRow = forwardRef<HTMLTableRowElement, ResponseTableRowProps>(({ result }, ref) => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const duration =
    result.task_started && result.task_ended ? Math.abs(result.task_started - result.task_ended) / 1000 : null;
  const initials =
    result.user?.displayName
      ?.split(' ')
      .map(n => n[0])
      .join('') ?? 'U';

  const pathsLength = 'path' in result.details ? result.details?.path?.length : 0;
  const clicks = 'path' in result.details ? result.details?.path?.reduce((all, item) => all + item.clicks, 0) : 0;
  const misclicks = 'path' in result.details ? result.details?.path?.reduce((all, item) => all + item.misclicks, 0) : 0;

  return (
    <TableRow
      ref={ref}
      onClick={
        ['completed', 'skipped'].includes(result.status)
          ? () => {
              setSearchParams({ result_id: result._id, hash: 'recording' });
            }
          : undefined
      }
      key={result._id}
      className="h-auto cursor-pointer hover:bg-secondary"
      id={`result-${result._id}`}
    >
      <TableCell>
        {result.user?.picture && result.user?.displayName ? (
          <Avatar size="sm">
            <AvatarImage src={result.user?.picture} alt={result.user?.displayName} />
            <AvatarFallback variant="primary">{initials}</AvatarFallback>
          </Avatar>
        ) : null}

        {result.user?.displayName ?? result.user?._id}
      </TableCell>
      <TableCell>
        {match(result.status)
          .with(BookingTaskResultStatus.Completed, () => (
            <Tag variant="success">{t(`sections.studies.results.success`)}</Tag>
          ))
          .with(BookingTaskResultStatus.Skipped, () => (
            <Tag variant="failure">{t('sections.studies.results.incomplete')}</Tag>
          ))
          .with(BookingTaskResultStatus.Pending, () => (
            <Tag variant="idle">{t('sections.studies.results.pending')}</Tag>
          ))
          .with(BookingTaskResultStatus.Started, BookingTaskResultStatus.Loaded, () => (
            <Tag variant="idle">{t('sections.studies.results.started')}</Tag>
          ))
          .otherwise(() => null)}
      </TableCell>
      <TableCell>{result.task_ended && duration ? secondsToTime(duration) : <>-</>}</TableCell>
      <TableCell>{clicks > 0 ? `${Math.floor((misclicks / clicks) * 100)}%` : 'n/a'}</TableCell>
      <TableCell>{pathsLength}</TableCell>
      <TableCell>
        {result.task_ended ? (
          <time dateTime={formatISO(result.task_ended)}>{format(result.task_ended, 'd MMM yyyy, h:mmaaa')}</time>
        ) : (
          <>-</>
        )}
      </TableCell>
    </TableRow>
  );
});

ResponseTableRow.displayName = 'ResponseTableRow';
