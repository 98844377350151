import { Button } from '@askable/ui/components/ui/button';
import { Command, CommandGroup, CommandItem, CommandList } from '@askable/ui/components/ui/command';
import { Command as CommandPrimitive } from 'cmdk';
import { X } from 'lucide-react';
import { useRef, useState, useCallback, useMemo } from 'react';

import type { KeyboardEvent } from 'react';

/**
 * Source: https://craft.mxkaske.dev/post/fancy-multi-select
 */

type Option = Record<'value' | 'label', string>;

interface MultiSelectProps {
  id: string;
  disabled?: boolean;
  options: Option[];
  placeholder?: string;
  values: string[];
  onChange: (value: string[]) => void;
}

export function MultiSelect({ id, disabled, options, placeholder, values = [], onChange }: MultiSelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { selectedOptions, selectableOptions } = useMemo(
    () => ({
      selectedOptions: options.filter(option => values.includes(option.value)),
      selectableOptions: options.filter(option => !values.includes(option.value)),
    }),
    [options, values],
  );

  const handleUnselect = useCallback(
    (optionValue: string) => {
      const newValues = values.filter(v => v !== optionValue);
      onChange(newValues);
    },
    [values, onChange],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if ((e.key === 'Delete' || e.key === 'Backspace') && input.value === '') {
          onChange(values.slice(0, -1));
        }
        if (e.key === 'Escape') {
          input.blur();
          setOpen(false);
        }
      }
    },
    [values, onChange],
  );

  return (
    <Command onKeyDown={handleKeyDown} className="overflow-visible bg-transparent">
      <div
        className="group min-h-[2.1rem] rounded-md border border-input bg-background p-1 text-sm ring-primary focus-within:ring-1
          aria-[disabled=true]:opacity-50"
        aria-disabled={disabled}
      >
        <div className="flex flex-wrap gap-1">
          {selectedOptions.map(option => {
            return (
              <Button
                key={option.value}
                variant="ghost"
                className="flex max-w-xs items-center gap-1 rounded-sm bg-info-foreground pr-3 leading-none hover:bg-info/10 focus:bg-info/10
                  active:bg-info/20"
                title={option.label}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleUnselect(option.value);
                  }
                }}
                onMouseDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={() => handleUnselect(option.value)}
              >
                <div className="truncate">{option.label}</div>
                <X className="h-3 w-3" />
              </Button>
            );
          })}

          <CommandPrimitive.Input
            id={id}
            className="min-h-6 flex-1 bg-transparent px-1 outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed"
            disabled={disabled}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onValueChange={setInputValue}
            placeholder={placeholder}
            ref={inputRef}
            value={inputValue}
          />
        </div>
      </div>
      <div className="relative">
        <CommandList>
          {open && selectableOptions.length > 0 ? (
            <div
              data-state={open ? 'open' : 'closed'}
              className="starting:scale-97 absolute top-1 z-20 w-full scale-100 rounded-md border bg-popover text-popover-foreground opacity-100
                shadow-md outline-none transition-[opacity,transform] starting:opacity-0"
            >
              <CommandGroup className="h-full overflow-auto">
                {selectableOptions.map(option => {
                  return (
                    <CommandItem
                      key={option.value}
                      className={'cursor-pointer'}
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');
                        onChange([...values, option.value]);
                      }}
                    >
                      {option.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  );
}
