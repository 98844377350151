import { graphql } from 'src/graphql';

export const BatchMessage = graphql(`
  mutation createBatchMessages($_user_ids: [ID]!, $_booking_id: ID!, $message: String!) {
    createBatchMessages(_user_ids: $_user_ids, _booking_id: $_booking_id, message: $message) {
      _id
      type
      body
      seen
      created
      _to_user_id
      _from_user_id
      direction
    }
  }
`);
