import { toast } from '@askable/ui/components/ui/sonner';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pluralize } from 'shared-utils';
import { Button, useDisclosure } from 'ui';
import { useMutation, useQuery } from 'urql';

import { BulkInviteDocument, BulkInviteUnModerateDataDocument } from 'generated/graphql';
import { BookingTypes, isBookingFull } from 'utils/booking-utils';

import { BookingFullModal } from '../BookingFullModal';

import { AvailableLinks } from './BookingParticipantInvitePanel';
import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';

import type {
  BulkInviteMutationResult,
  BulkInviteMutationVariables,
  BulkInviteUnModerateDataQuery,
  BulkInviteUnModerateDataQueryVariables,
  LinkOnlineTaskBookingConfig,
} from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  submissionIds: string[];
  onClose: () => void;
  bookingId: string;
  onComplete: () => void;
};

export const BookingParticipantBulkInviteUnModerateSidePanel: FC<Props> = ({
  submissionIds,
  onClose,
  onComplete,
  bookingId,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose: onBookingFullModalClose } = useDisclosure();

  const { handleSubmit, setError, formState, control, clearErrors, setValue } =
    useForm<Record<'sessionOrTaskId', string>>();

  const [{ fetching: loading }, bulkInvite] = useMutation<BulkInviteMutationResult, BulkInviteMutationVariables>(
    BulkInviteDocument,
  );

  const [{ data, fetching }] = useQuery<BulkInviteUnModerateDataQuery, BulkInviteUnModerateDataQueryVariables>({
    query: BulkInviteUnModerateDataDocument,
    variables: {
      bookingId,
      submissionIds,
    },
    requestPolicy: 'cache-and-network',
  });

  const isLongitudinal = data?.bookingByID?.type === BookingTypes.LONGITUDINAL_STUDY;

  const invitableSubmissions = useMemo(() => data?.bookingSubmissionConnection?.nodes, [data]);

  useEffect(() => {
    if (fetching || isLongitudinal || !data?.bookingByID?.config?.online_task?.links?.[0]?._id) {
      return;
    }

    setValue('sessionOrTaskId', data?.bookingByID?.config?.online_task?.links[0]?._id);
  }, [fetching, data]);

  useEffect(() => {
    if (invitableSubmissions && invitableSubmissions.length > 0) {
      clearErrors('sessionOrTaskId');
    }
  }, [invitableSubmissions, clearErrors]);

  const onSubmit = async (fv: Record<string, string>) => {
    if (!invitableSubmissions?.length) {
      setError('sessionOrTaskId', {
        type: 'no-valid-participants',
        message: 'Only applicants who have the status Available can be invited',
      });
      return;
    }
    if (!fv.sessionOrTaskId && !isLongitudinal) {
      setError('sessionOrTaskId', {
        type: 'required',
        message: 'A link needs to be selected before invites can be sent',
      });

      return;
    }

    if (
      isBookingFull({
        bookingTotalParticipants: data?.bookingByID?.total_participants || 0,
        currentBookingTotalApplicants: data?.currentTotalApplicants?.totalCount || 0,
      })
    ) {
      onOpen();
      return;
    }

    try {
      const { error } = await bulkInvite({
        booking_id: bookingId,
        input: {
          submissions: invitableSubmissions.map(submission => ({
            id: submission?._id!,
            sessionOrTaskId: fv.sessionOrTaskId,
          })),
        },
      });
      if (error) {
        throw error;
      }
      toast.success(t('sections.bookingParticipantTable.inviteSent'));

      onComplete();
    } catch (e) {
      console.log(e);
    }
  };

  if (fetching) {
    return null;
  }

  return (
    <BookingSidePanelStepperContainer
      onSubmit={handleSubmit(onSubmit)}
      title={`Invite ${pluralize('people', invitableSubmissions?.length || 0, true)}`}
      as="form"
      error={
        formState.errors.sessionOrTaskId
          ? {
              title:
                formState.errors.sessionOrTaskId.type === 'no-valid-participants'
                  ? 'No available applicants selected'
                  : 'No link selected',
              message: formState.errors.sessionOrTaskId.message || '',
            }
          : null
      }
      w="full"
      onCancelClick={onClose}
      footer={
        <Button w="full" type="submit" size="lg" colorScheme="blue" isLoading={loading}>
          Send
        </Button>
      }
    >
      <Controller
        control={control}
        name="sessionOrTaskId"
        render={({ field }) => {
          return (
            <AvailableLinks
              title={
                isLongitudinal && data?.bookingByID?.config?.information?.short_description
                  ? data.bookingByID.config.information.short_description
                  : undefined
              }
              formName={field.name}
              value={field.value}
              onChange={e => {
                if (formState.errors.sessionOrTaskId) {
                  clearErrors('sessionOrTaskId');
                }
                field.onChange(e);
              }}
              links={data?.bookingByID?.config?.online_task?.links as LinkOnlineTaskBookingConfig[]}
            />
          );
        }}
      />

      <BookingFullModal
        isOpen={isOpen}
        onClose={onBookingFullModalClose}
        bookingId={bookingId}
        isModeratedBooking={false}
      />
    </BookingSidePanelStepperContainer>
  );
};
