import { TriangleAlert } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

export const SummaryValidationMessage = () => {
  const { t } = useTranslation();
  const { isValid, study } = useStudyContext();

  const message = useMemo(() => {
    if (!isValid.recruit && !isValid.build) {
      return t('sections.studies.formValidation.requiredFields.descriptionBuildAndRecruit');
    }
    if (!study.config?.unmoderated?.task_blocks?.length) {
      return t('sections.studies.formValidation.requiredFields.descriptionBuildNoBlocks');
    }
    if (!isValid.build) {
      return t('sections.studies.formValidation.requiredFields.descriptionBuild');
    }
    if (!isValid.recruit) {
      return t('sections.studies.formValidation.requiredFields.description');
    }
  }, []);

  if (!message) {
    return null;
  }

  return (
    <div
      role="alert"
      aria-live="polite"
      className="flex gap-2 rounded-md bg-destructive/10 p-3 text-sm text-foreground dark:border-0.5 dark:border-destructive"
    >
      <TriangleAlert className="h-5 w-5 text-destructive" />
      <div className=" flex flex-col gap-1 text-pretty">
        <div className="font-semibold">{t('sections.studies.formValidation.requiredFields.title')}</div>
        <p>{message}</p>
      </div>
    </div>
  );
};
