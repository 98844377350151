import { isWithinInterval, sub } from 'date-fns';
import { sort } from 'radash';
import { AskableSessionsIcon, Button } from 'ui';

import { SubmissionStatus, useAskableSessionsRoomCodeLazyQuery } from 'generated/graphql';
import { getClientAskableSessionAuthLink } from 'utils/url-utils';

import type { SessionsType } from 'generated/graphql';
import type React from 'react';

type Props = {
  userId: string;
  bookingId: string;
  teamId?: string;
  session?: SessionsType | null;
  submissionStatus?: SubmissionStatus | null;
};

export const BookingParticipantSessionAction = ({ bookingId, teamId, session, submissionStatus, userId }: Props) => {
  const [fetchRoomCode] = useAskableSessionsRoomCodeLazyQuery();

  const getRoomCode = async () => {
    if (!submissionStatus) {
      return;
    }
    const { data } = await fetchRoomCode({
      variables: {
        input: {
          _booking_id: bookingId,
          _user_id: userId,
        },
      },
    });
    return sort(data?.searchAskableMeeting || [], a => a?.created || 0, true)[0]?.code;
  };

  if (!submissionStatus || !bookingId) {
    return null;
  }

  const handleClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    const roomCode = (await getRoomCode()) ?? '';

    const link =
      submissionStatus === SubmissionStatus.Completed
        ? getClientAskableSessionAuthLink({ roomCode, playback: true, teamId })
        : getClientAskableSessionAuthLink({ roomCode });

    window.open(link, '_blank');
  };

  if (submissionStatus === SubmissionStatus.Completed) {
    return (
      <Button size="sm" leftIcon={<AskableSessionsIcon />} variant="ghost" onClick={handleClick}>
        View session
      </Button>
    );
  }

  if (!session?.session?.start || !session?.session?.end) {
    return null;
  }

  if (submissionStatus === SubmissionStatus.Confirmed) {
    const startsWithin15Mins = isWithinInterval(new Date(), {
      start: sub(session.session.start, { minutes: 15 }),
      end: new Date(session.session.end),
    });

    return (
      <Button
        colorScheme={startsWithin15Mins ? 'blue' : 'gray'}
        size="sm"
        data-testid="join-call"
        leftIcon={<AskableSessionsIcon fill={startsWithin15Mins ? 'white' : 'blue.700'} />}
        onClick={handleClick}
        marginX="auto"
      >
        Join call
      </Button>
    );
  }

  return null;
};
