import { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from 'ui';

import { useRenameSegmentMutation } from 'generated/graphql';

import { useBookingParticipantState } from '../state/booking-participant-state';

import type { Maybe, Segment } from 'generated/graphql';
import type { FC } from 'react';

type Props = {
  isOpen: boolean;
  bookingId: string;
  segment: Maybe<Segment>;
  onClose: () => void;
};

type FormValues = { segmentName: string };

export const RenameSegmentModal: FC<Props> = memo(({ isOpen, segment, bookingId, onClose }) => {
  const { handleSubmit, register, reset } = useForm<FormValues>();
  const [renameSegment, { loading }] = useRenameSegmentMutation();
  const updateSegmentName = useBookingParticipantState(state => state.updateSegmentName);

  const onSubmit = async (fv: FormValues) => {
    try {
      await renameSegment({
        variables: {
          input: {
            booking_id: bookingId,
            segment_id: segment?._id!,
            name: fv.segmentName,
          },
        },
      });

      updateSegmentName(fv.segmentName);

      reset();

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>Segment settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel htmlFor="segmentName">
              Name
              <Input id="segmentName" autoFocus {...register('segmentName', { required: true })} />
            </FormLabel>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" isLoading={loading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Modal>
  );
});
