import { Button } from '@askable/ui/components/ui/button';
import { DialogFooter, DialogTrigger } from '@askable/ui/components/ui/dialog';
import { cn } from '@askable/ui/lib/utils';
import { AlignLeft, CircleCheck, CircleX, List } from 'lucide-react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingRecruitQuestionType } from 'generated/graphql';

import type { BookingQuestion } from '../../data/BookingsByTeamId.query';
import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

type QuestionType = 1 | 2;
type ScreenerQuestion = NonNullable<RecruitFormFields['screener_questions']>[0];

interface Props {
  questions: BookingQuestion[];
  onAddQuestions: (questions: ScreenerQuestion[]) => void;
  onGoBack: () => void;
}

export const ScreenerQuestionsCopyStudy = ({ questions, onAddQuestions, onGoBack }: Props) => {
  const { t } = useTranslation();

  const [selectedQuestions, setSelectedQuestions] = useState<ScreenerQuestion[]>([]);

  const questionType = {
    1: {
      label: t('sections.studies.recruit.options.multiple_choice'),
      Icon: List,
    },
    2: {
      label: t('sections.studies.recruit.options.short_answer'),
      Icon: AlignLeft,
    },
  };

  useEffect(() => {
    setSelectedQuestions([]);
  }, [questions]);

  const handleSelectQuestion = useCallback(
    (question: BookingQuestion) => () => {
      setSelectedQuestions(questions => {
        if (questions.find(q => q._id === question._id)) {
          return questions.filter(q => q._id !== question._id);
        }

        // Convert to ScreenerQuestion schema because booking question schema sucks
        // Also nullable fields are super annoying
        const newQuestion: ScreenerQuestion = {
          _id: question._id ?? '',
          description: question.description ?? '',
          title: question.title ?? '',
          type:
            question.config?.type === 1
              ? BookingRecruitQuestionType.MultipleChoice
              : BookingRecruitQuestionType.ShortAnswer,
          is_multiple_selection: question.config?.multiple_selection ?? false,
          options: question.options?.map(o => ({
            _id: o?._id ?? '',
            label: o?.label ?? '',
            is_qualified: o?.screen_in ?? false,
          })),
        };

        return [...questions, newQuestion];
      });
    },
    [],
  );

  return (
    <div className="flex flex-1 flex-col px-4">
      <ul className="flex flex-1 flex-col gap-2 py-1">
        {questions.map((question: BookingQuestion) => {
          const { Icon, label } = questionType[question.config?.type as QuestionType];

          return (
            <li key={question._id}>
              <button
                className={cn(
                  `flex w-full flex-col gap-2 rounded-md border-0.5 border-border p-4 text-start ring-1 ring-transparent hover:border-input
                  focus:border-input`,
                  {
                    'border-primary ring-primary focus:border-primary': selectedQuestions.find(
                      q => q._id === question._id,
                    ),
                  },
                )}
                role="checkbox"
                aria-checked={Boolean(selectedQuestions.find(q => q._id === question._id))}
                onClick={handleSelectQuestion(question)}
                onKeyDown={e => {
                  const VALID_KEYS = new Set([' ', 'Enter']);
                  if (VALID_KEYS.has(e.key)) {
                    e.preventDefault();
                    handleSelectQuestion(question)();
                  }
                }}
              >
                <div className="flex items-center gap-2 text-sm text-foreground-subtle">
                  <Icon className="h-3 w-3" />
                  {label}
                </div>
                <div className="font-semibold">{question.title}</div>

                {question.description ? <div>{question.description}</div> : null}

                {question.options?.length ? (
                  <ol>
                    {question.options.map(option => (
                      <li key={option?._id} className="flex items-center gap-2">
                        {option?.screen_in ? (
                          <CircleCheck className="h-4 w-4 text-success" />
                        ) : (
                          <CircleX className="h-4 w-4 text-destructive" />
                        )}
                        {option?.label}
                      </li>
                    ))}
                  </ol>
                ) : null}
              </button>
            </li>
          );
        })}
      </ul>

      <DialogFooter className="sticky bottom-0 z-10 flex bg-background/90 py-4 backdrop-blur-sm">
        <Button variant="ghost" className="w-full sm:w-fit" onClick={onGoBack}>
          {t('global.back')}
        </Button>
        <DialogTrigger asChild>
          <Button variant="primary" className="w-full sm:w-fit" onClick={() => onAddQuestions(selectedQuestions)}>
            {t('sections.studies.recruit.screenerQuestionsCopy.addCta')} ({selectedQuestions.length})
          </Button>
        </DialogTrigger>
      </DialogFooter>
    </div>
  );
};
