import { graphql } from 'src/graphql';

export const EsignatureCreate = graphql(`
  mutation createESignatureTemplate($input: ESignatureTemplateInput!) {
    createESignatureTemplate(template: $input) {
      _id
      global
      active
      _team_id
      _organisation_id
      provider {
        name
        reference
      }
      name
      description
      recipients
      created
      updated
    }
  }
`);
