import { DropdownMenuItem, DropdownMenuLabel } from '@askable/ui/components/ui/dropdown-menu';
import { IconBlock } from '@askable/ui/components/unmod/icon-block';
import { cn } from '@askable/ui/lib/utils';
import { useTranslation } from 'react-i18next';

import { TaskBlockType } from 'generated/graphql';

interface AddBlockListProps {
  onSelect: (type: TaskBlockType) => void;
}

const blockOptions = [
  { label: 'tasks' },
  { type: TaskBlockType.FigmaPrototype, isDisabled: false },
  { label: 'questions' },
  { type: TaskBlockType.MultipleChoiceQuestion, isDisabled: true },
  { type: 'open_answer', isDisabled: true }, // TODO: add type to BE schema
  { type: 'opinion_scale', isDisabled: true }, // TODO: add type BE to schema
] as const;

export const AddBlockList = ({ onSelect }: AddBlockListProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DropdownMenuLabel className="px-2 text-lg font-semibold text-primary lg:hidden">
        {t('sections.studies.build.blocks.add.title')}
      </DropdownMenuLabel>

      {blockOptions.map(block => {
        if ('label' in block) {
          return (
            <DropdownMenuLabel key={`label-${block.label}`} className="px-2 text-sm font-medium text-muted-foreground">
              {t(`global.${block.label}`)}
            </DropdownMenuLabel>
          );
        }

        return (
          <DropdownMenuItem
            key={block.type}
            className={cn('items-start p-3', { 'opacity-50': block.isDisabled })}
            disabled={block.isDisabled}
            onSelect={block.isDisabled ? undefined : () => onSelect(block.type)}
          >
            <IconBlock type={block.type} />

            <div className="flex w-full flex-col gap-1 text-left lg:max-w-xs">
              <div className="flex items-center justify-between gap-2">
                <div className="font-semibold">{t(`sections.studies.build.blocks.${block.type}.title`)}</div>
                {block.isDisabled ? (
                  <div className="whitespace-nowrap rounded-sm bg-foreground px-1 py-0 text-xs leading-5 text-background">
                    {t('sections.studies.comingSoon')}
                  </div>
                ) : null}
              </div>
              <div className="text-muted-foreground lg:max-w-[34ch]">
                {t(`sections.studies.build.blocks.${block.type}.description`)}
              </div>
            </div>
          </DropdownMenuItem>
        );
      })}
    </>
  );
};
