import { Button } from '@askable/ui/components/ui/button';
import { Input } from '@askable/ui/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/components/ui/select';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CircleCheck, CircleX, GripVertical, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { CSSProperties } from 'react';

interface ScreenerQuestionItemOptionProps {
  id: string;
  index: number;
  isDisabled?: boolean;
  isMovable?: boolean;
  is_qualified: boolean;
  value: string;
  onChangeValue: (index: number, label: string, is_qualified: boolean) => void;
  onRemove: (index: number) => void;
}

export const ScreenerQuestionItemOption = ({
  id,
  index,
  isDisabled,
  isMovable,
  is_qualified,
  value,
  onChangeValue,
  onRemove,
}: ScreenerQuestionItemOptionProps) => {
  const { t } = useTranslation();
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && !isDisabled ? id : '',
  });

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={dragStyle}
      className={cn(
        `flex flex-col items-center gap-2 rounded-md bg-background p-1 opacity-100 transition-opacity starting:opacity-0
        md:flex-row`,
        {
          'z-20 shadow-lg': isDragging,
        },
      )}
    >
      <div
        className="z-10 -mr-10 !cursor-grab touch-none p-2 active:!cursor-grabbing aria-[disabled=true]:!cursor-default
          aria-[disabled=true]:text-input"
        {...attributes}
        {...listeners}
        aria-disabled={!isMovable || isDisabled}
      >
        <GripVertical className="h-4 w-4" />
      </div>

      <div className="flex flex-1 flex-col gap-1">
        <label htmlFor={`input_screener_question_${index}_option_value`} className="sr-only">
          {t('sections.studies.recruit.answer')} {index + 1}
        </label>
        <Input
          value={value}
          name={`input_screener_question_${index}_option_value`}
          disabled={isDisabled}
          onChange={e => onChangeValue(index, e.target.value, is_qualified)}
          placeholder={`${t('sections.studies.recruit.answer')} ${index + 1}`}
          variant="indented"
        />
      </div>

      <div className="flex w-[140px] flex-col gap-1">
        <label htmlFor={`input_screener_question_${index}_option_qualified`} className="sr-only"></label>
        <Select
          value={is_qualified ? 'qualify' : 'disqualify'}
          onValueChange={q => onChangeValue(index, value, q === 'qualify')}
          disabled={isDisabled}
        >
          <SelectTrigger id={`input_screener_question_${index}_option_qualified`}>
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="z-20">
            <SelectItem value="qualify">
              <div className="flex items-center gap-2">
                <CircleCheck className="h-4 w-4 text-success" />
                {t('sections.studies.recruit.options.qualify')}
              </div>
            </SelectItem>
            <SelectItem value="disqualify">
              <div className="flex items-center gap-2">
                <CircleX className="h-4 w-4 text-destructive" />
                {t('sections.studies.recruit.options.disqualify')}
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </div>

      <Button
        variant="ghost"
        size="icon"
        title={t('global.remove')}
        disabled={isDisabled || !isMovable}
        onClick={() => onRemove(index)}
      >
        <Trash2 className="h-4 w-4" />
      </Button>
    </div>
  );
};
