import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, DragIcon, Flex, Switch, Text } from 'ui';

import type { Column } from '@tanstack/react-table';
import type { CSSProperties, FC } from 'react';

type Props = {
  column: Column<any>;
};

export const DraggableFieldAction: FC<Props> = ({ column }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: column.id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Flex
      bg="white"
      zIndex={isDragging ? 'banner' : 'auto'}
      boxShadow={isDragging ? 'lg' : 'none'}
      justifyContent="space-between"
      _hover={{ bg: 'gray.100' }}
      alignItems="flex-start"
      style={style}
      px="2"
      py="2"
      rounded="md"
      ref={setNodeRef}
    >
      <Flex w="full" alignItems="start">
        <Box
          mt="1"
          {...attributes}
          {...listeners}
          cursor={isDragging ? 'grabbing' : 'grab'}
          as={DragIcon}
          color="gray.400"
          _focus={{ outline: 'none' }}
        />
        <Text mx="2" noOfLines={2} overflowWrap="break-word">
          {typeof column.columnDef.header === 'string' ? column.columnDef.header : column.id}
        </Text>
      </Flex>
      <Switch mt="1" size="sm" isChecked={column.getIsVisible()} onChange={column.getToggleVisibilityHandler()} />
    </Flex>
  );
};
