import { toast } from '@askable/ui/components/ui/sonner';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  LinkIcon,
  RadioCard,
  RadioGroup,
  Text,
  VStack,
  useDisclosure,
  useRadioGroup,
} from 'ui';
import { useMutation, useQuery } from 'urql';

import { useBookingContainerConfig } from 'containers/Booking/BookingContainer';
import { UpdateBookingSubmission } from 'containers/Studies/Recruit/data/UpdateBookingSubmission.mutation';
import { SubmissionInviteDocument, InvitePanelSubmissionByIdDocument, SubmissionStatus } from 'generated/graphql';
import { BookingTypes, isBookingFull } from 'utils/booking-utils';

import { BookingFullModal } from '../BookingFullModal';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';
import { UserTimes } from './components/TimeSlots';

import type {
  BookingSession,
  SubmissionInviteMutation,
  LinkOnlineTaskBookingConfig,
  InvitePanelSubmissionByIdQuery,
  SubmissionInviteMutationVariables,
  InvitePanelSubmissionByIdQueryVariables,
} from 'generated/graphql';
import type { FC } from 'react';

type FormState = {
  sessionOrTaskId: string;
};

type Props = {
  bookingId: string;
  userId: string;
  applicantName: string;
  onClose: () => void;
  pptTimezone: string;
  submissionId: string;
  isModeratedBooking: boolean;
};

export const BookingParticipantInvitePanel: FC<Props> = ({
  bookingId,
  submissionId,
  isModeratedBooking,
  pptTimezone,
  applicantName,
  onClose,
}) => {
  const { t } = useTranslation();
  const { sessionType } = useBookingContainerConfig();
  const { control, formState, handleSubmit, setValue } = useForm<FormState>();
  const [isInviting, setIsInviting] = useState(false);

  const [, invite] = useMutation<SubmissionInviteMutation, SubmissionInviteMutationVariables>(SubmissionInviteDocument);

  // New unmoderated studies don't have sessions, so we can't use the existing invite mutation
  // TODO: create dedicated mutation for this
  const [, updateBookingSubmission] = useMutation(UpdateBookingSubmission);

  // Get participant selected sessions
  const [{ fetching: fetchingSubmission, data }] = useQuery<
    InvitePanelSubmissionByIdQuery,
    InvitePanelSubmissionByIdQueryVariables
  >({
    query: InvitePanelSubmissionByIdDocument,
    requestPolicy: 'network-only',
    variables: {
      isModerated: isModeratedBooking,
      bookingId,
      _id: submissionId,
    },
  });

  useEffect(() => {
    if (fetchingSubmission || !data?.bookingSubmissionById?.booking?.config?.online_task?.links?.[0]?._id) {
      return;
    }

    setValue('sessionOrTaskId', data?.bookingSubmissionById.booking.config.online_task.links[0]._id);
  }, [fetchingSubmission]);

  const isLongitudinal = data?.bookingSubmissionById?.booking?.type === BookingTypes.LONGITUDINAL_STUDY;
  const isUnmoderated = data?.bookingSubmissionById?.booking?.type === BookingTypes.UNMODERATED;

  useEffect(() => {
    if (!isLongitudinal || !data?.bookingSubmissionById?.booking?.session?.[0]?._id) {
      return;
    }
    setValue('sessionOrTaskId', data?.bookingSubmissionById?.booking?.session?.[0]?._id);
  }, [data]);

  const { isOpen, onOpen, onClose: onBookingFullModalClose } = useDisclosure();

  const onSubmit = async (fv: FormState) => {
    try {
      setIsInviting(true);

      if (
        isBookingFull({
          bookingTotalParticipants: data?.bookingSubmissionById?.booking?.total_participants || 0,
          currentBookingTotalApplicants: data?.currentTotalApplicants?.totalCount || 0,
        })
      ) {
        onOpen();
        return;
      }

      if (isUnmoderated) {
        const updateRes = await updateBookingSubmission({
          input: { _id: submissionId!, status: SubmissionStatus.Invited },
        });
        if (updateRes.error) {
          throw updateRes.error;
        }
        toast.success(t('sections.bookingParticipantTable.inviteSent'));
        onClose();
        return;
      }

      const inviteRes = await invite({
        input: {
          sessionOrTaskId: fv.sessionOrTaskId,
          _submission_id: submissionId,
        },
      });
      if (inviteRes.error) {
        throw inviteRes.error;
      }
      toast.success(t('sections.bookingParticipantTable.inviteSent'));
      onClose();
    } catch {
      toast.error('Error inviting user to session');
    } finally {
      setIsInviting(false);
    }
  };

  return (
    <BookingSidePanelStepperContainer
      loading={fetchingSubmission}
      onSubmit={handleSubmit(onSubmit)}
      title={`Invite ${applicantName}`}
      as="form"
      onCancelClick={onClose}
      footer={
        <Button w="full" size="lg" colorScheme="blue" type="submit" isLoading={isInviting}>
          Send
        </Button>
      }
    >
      <Flex direction="column">
        {isUnmoderated ? (
          <>
            <Text color="gray.500">
              Send an invite for the participant to be able to start the study. Their results will appear in the Results
              tab once they have done so.
            </Text>
          </>
        ) : (
          <>
            {formState.errors.sessionOrTaskId && (
              <Alert status="error" mb="10">
                <AlertIcon />
                <Box>
                  <AlertTitle>No session selected</AlertTitle>
                  <AlertDescription>A session needs to be selected before an invite can be sent</AlertDescription>
                </Box>
              </Alert>
            )}
            {!fetchingSubmission && isModeratedBooking && (
              <UserTimes
                sessionType={sessionType}
                title={applicantName}
                name="sessionOrTaskId"
                pptTimezone={pptTimezone}
                preferredSessions={data?.bookingSubmissionById?.preferred_sessions as BookingSession[]}
                bookingSessions={data?.bookingSubmissionById?.booking?.session as BookingSession[]}
                control={control}
              />
            )}

            {!fetchingSubmission && !isModeratedBooking && (
              <Controller
                control={control}
                name="sessionOrTaskId"
                render={({ field }) => {
                  return (
                    <AvailableLinks
                      title={
                        isLongitudinal && data.bookingSubmissionById?.booking?.config?.information?.short_description
                          ? data.bookingSubmissionById.booking.config.information.short_description
                          : undefined
                      }
                      formName={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      links={
                        data?.bookingSubmissionById?.booking?.config?.online_task
                          ?.links as LinkOnlineTaskBookingConfig[]
                      }
                    />
                  );
                }}
              />
            )}
          </>
        )}
      </Flex>
      <BookingFullModal
        bookingId={bookingId}
        isOpen={isOpen}
        onClose={onBookingFullModalClose}
        isModeratedBooking={isModeratedBooking}
      />
    </BookingSidePanelStepperContainer>
  );
};

type AvailableLinksProps = {
  value: string;
  formName: 'sessionOrTaskId';
  onChange: (nextValue: string) => void;
  links?: Pick<LinkOnlineTaskBookingConfig, '_id' | 'name' | 'url'>[];
  title?: string;
};

export const AvailableLinks: FC<AvailableLinksProps> = ({ links, formName, value, onChange, title }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: formName,
    value,
    onChange,
    defaultValue: value,
  });

  return (
    <RadioGroup {...getRootProps}>
      <VStack w="full" alignItems="flex-start">
        <Text fontWeight="semibold" mb="1">
          {title ?? 'Select task'}
        </Text>
        {(links ?? []).map(({ _id, name }, index) => {
          const radio = getRadioProps({ value: _id });

          return (
            <RadioCard key={_id} w="full" borderWidth="0" {...radio}>
              <LinkIcon mr="3" />
              {`Version ${index + 1}: ${name}`}
            </RadioCard>
          );
        })}
      </VStack>
    </RadioGroup>
  );
};
