/* eslint-disable max-lines */
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { Button } from '@askable/ui/components/ui/button';
import { Label } from '@askable/ui/components/ui/label';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@askable/ui/components/ui/sheet';
import { Textarea } from '@askable/ui/components/ui/textarea';
import _ from 'lodash';
import { Clock, Send } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useState, useEffect, Fragment } from 'react';
import Linkify from 'react-linkify';
import { useIntercom } from 'react-use-intercom';

import { LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import createMessageMutation from 'data/mutations/messages/createMessage';
import markMultipleMessagesAsSeenMutation from 'data/mutations/messages/markMultipleMessagesAsSeen';
import fetchMessages from 'data/queries/messages/fetchMessages';
import fetchUserById from 'data/queries/user/fetchUserById';
import messagesSubscription from 'data/subscriptions/messages/messagesSubscription';
import { bookingUtils } from 'lib/booking';
import { bookingParticipantUtils } from 'lib/bookingParticipant';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';

import MessageDrawerQuery from './messageDrawerQuery';
import ScheduleMessage from './scheduleMessage';

import './styles/messageDrawerStyles.scss';

function MessageDrawer(props: any) {
  const intercom = useIntercom();

  const timezone = _.get(props, 'booking.config.timezone') || utils.getCurrentTimezone();
  const isGuest = userUtils.isGuestUser(_.get(props.userData, 'userByID'));
  const [messageToSend, setMessageToSend] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [loadingUI, setLoadingUI] = useState(false);
  const { details } = useConnectedClient();

  useEffect(() => {
    // Close intercom
    if (props.open) {
      intercom.hide();
    }

    setIsScrolled(false);
    if (props.submission_id) {
      // Get all messages that aren't marked as Seen and mark them as seen
      const updatedSubmission = _.find(
        props.messagesToDisplay,
        (submissions: any) => submissions._id === props.submission_id,
      );
      if (_.size(_.get(updatedSubmission, 'Messages')) > 0 && !props.hideSendMessage) {
        markMessagesAsSeen(_.get(updatedSubmission, 'Messages'));
      }
    }
    scrollToBottom();
    focusOnMessageToSendComponent();
  }, [props]);

  const onClickSendMessage = async (submission: any) => {
    setLoadingUI(true);
    const defaultArgs = {
      type: 1,
      direction: 3,
      body: messageToSend,
      sms: false,
    };

    // Check whether the messages are related to a booking
    if (_.get(props, 'booking._id')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_to_user_id' does not exist on type '{ t... Remove this comment to see the full error message
      defaultArgs._to_user_id = _.get(submission, '_user_id');
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type '{ type:... Remove this comment to see the full error message
      defaultArgs.context = {
        _booking_id: _.get(props, 'booking._id'),
        _booking_participant_id: _.get(submission, 'sessionToRender._id'),
      };
      // It should send sms to booking related messages
      defaultArgs.sms = _.get(props, 'sendSms', true);
    } else if (_.get(props, '_live_demo_room_id')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type '{ type:... Remove this comment to see the full error message
      defaultArgs.context = {
        _live_demo_room_id: props._live_demo_room_id,
      };
    }

    setMessageToSend('');
    await props.createMessage({ ...defaultArgs });
    setLoadingUI(false);
  };

  const getScheduledMessages = (submission: any, booking: any) => {
    // Method that returns an array of scheduled messages
    const { sessionToRender } = submission;
    // If there's no session assign to that participant, it shouldn't return any scheduled message
    if (!sessionToRender) return [];

    const { session } = sessionToRender;
    const arrayScheduledMessages = [];
    const historyTypes = bookingParticipantUtils.historyTypes(submission.user, submission, booking);

    // Messages on confirmed sessions
    if (
      sessionToRender.status === 1 &&
      sessionToRender.cancel === 0 &&
      (bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking))
    ) {
      // EARLY_CONFIRMATION - 72 hours before session start
      if (
        !_.get(sessionToRender, 'history.early_confirmation_request') &&
        _.get(sessionToRender, 'user_confirm') < _.get(session, 'start') - 72 * 60 * 60 * 1000
      ) {
        const isInPast = utils.isInPast(
          momentTimezone(_.get(session, 'start')).tz(timezone).subtract(72, 'h').valueOf(),
          timezone,
        );
        if (!isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(historyTypes, history => history.key === 'early_confirmation_request').sms_template,
            time: momentTimezone(_.get(session, 'start')).tz(timezone).subtract(72, 'h').valueOf(),
          });
        }
      }

      // SESSION_START_REMINDER - 24 hours before session start
      // No action needed when user signed up 36 hours or less before the session start
      const hoursSignupBeforeStart = momentTimezone(_.get(session, 'start')).diff(sessionToRender.created, 'h');
      if (!_.get(sessionToRender, 'history.session_start_reminder_no_action') && hoursSignupBeforeStart <= 36) {
        const isInPast = utils.isInPast(
          momentTimezone(_.get(session, 'start')).tz(timezone).subtract(24, 'h').valueOf(),
          timezone,
        );
        if (!isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(historyTypes, (history: any) => history.key === 'session_start_reminder_no_action')
              .sms_template,
            time: momentTimezone(_.get(session, 'start')).tz(timezone).subtract(24, 'h').valueOf(),
          });
        }
      }

      // SESSION_START_REMINDER_ACTION_NEEDED - 24 hours before job starts
      if (!_.get(sessionToRender, 'history.session_start_reminder_action_needed') && hoursSignupBeforeStart > 36) {
        const isInPast = utils.isInPast(
          momentTimezone(_.get(session, 'start')).tz(timezone).subtract(24, 'h').valueOf(),
          timezone,
        );
        if (!isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(historyTypes, (history: any) => history.key === 'session_start_reminder_action_needed')
              .sms_template,
            time: momentTimezone(_.get(session, 'start')).tz(timezone).subtract(24, 'h').valueOf(),
          });
        }
      }

      // SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_FACE_TO_FACE && SESSION_START_REMINDER_2_ASKABLE_INCENTIVE_REMOTE
      // 2.5 hours before session starts
      if (!_.get(sessionToRender, 'history.session_start_reminder_2_askable_incentive_message')) {
        const isInPast = utils.isInPast(
          momentTimezone(_.get(session, 'start')).tz(timezone).subtract(2.5, 'h').valueOf(),
          timezone,
        );
        if (!isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(
              historyTypes,
              (history: any) => history.key === 'session_start_reminder_2_askable_incentive_message',
            ).sms_template,
            time: momentTimezone(_.get(session, 'start')).tz(timezone).subtract(2.5, 'h').valueOf(),
          });
        }
      }

      // SESSION_COMPLETED_FEEDBACK_REQUEST
      // 15 minutes after the session ends
      if (
        !_.get(sessionToRender, 'history.session_completed_feedback_request') &&
        !_.get(sessionToRender, 'history.session_completed_feedback_followup_request') &&
        !_.get(sessionToRender, 'no_show_request') &&
        !_.get(sessionToRender, 'issue_request') &&
        sessionToRender.completed !== true
      ) {
        const isInPast = utils.isInPast(
          momentTimezone(_.get(session, 'end')).tz(timezone).add(15, 'm').valueOf(),
          timezone,
        );
        if (!isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(historyTypes, (history: any) => history.key === 'session_completed_feedback_request')
              .sms_template,
            time: momentTimezone(_.get(session, 'end')).tz(timezone).add(15, 'm').valueOf(),
          });
        }
      }
    }

    // Messages on invited sessions
    // 4 hours after invitation
    if (sessionToRender.status === 4 && sessionToRender.cancel === 0) {
      // SESSION_INVITATION_FOLLOWUP
      if (
        !_.get(sessionToRender, 'history.session_invitation_notification_followup') &&
        _.get(sessionToRender, 'history.session_invitation_notification')
      ) {
        const timeToCompare = momentTimezone(_.get(sessionToRender, 'history.session_invitation_notification')).add(
          4,
          'h',
        );
        const isInPast = utils.isInPast(
          momentTimezone(_.get(sessionToRender, 'history.session_invitation_notification'))
            .tz(timezone)
            .add(4, 'h')
            .valueOf(),
          timezone,
        );
        // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'Moment' a... Remove this comment to see the full error message
        if (timeToCompare > new Date().valueOf() && !isInPast) {
          arrayScheduledMessages.push({
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            message: _.find(historyTypes, (history: any) => history.key === 'session_invitation_notification_followup')
              .sms_template,
            time: momentTimezone(_.get(sessionToRender, 'history.session_invitation_notification'))
              .tz(timezone)
              .add(4, 'h')
              .valueOf(),
          });
        }
      }
    }

    return arrayScheduledMessages;
  };

  const getSessionToRender = (bookingParticipant: any) => {
    const confirmedSession = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 1 && participant.cancel === 0,
    );
    const waitlistedSession = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 2 && participant.cancel === 0,
    );
    const invitedSession = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 4 && participant.cancel === 0,
    );
    const inProgressSession = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 5 && participant.cancel === 0,
    );
    const confirmedSessionCancelled = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 1 && participant.cancel > 0,
    );
    const invitationDeclinedSessions = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 4 && participant.cancel > 0,
    );
    const availableSessions = _.find(
      bookingParticipant,
      (participant: any) => participant.status === 3 && participant.cancel === 0,
    );

    if (confirmedSession) return confirmedSession;
    if (waitlistedSession) return waitlistedSession;
    if (invitedSession) return invitedSession;
    if (inProgressSession) return inProgressSession;
    if (confirmedSessionCancelled) return confirmedSessionCancelled;
    if (invitationDeclinedSessions) return invitationDeclinedSessions;
    if (availableSessions) return availableSessions;
    return null;
  };

  const getLastMessage = (sessionToRender: any, messages: any, user: any) => {
    // If we have no messages received, it should render the last history step
    // It should filter messages by type 1 (In-app Messages) and type 3 (Batch Messages)
    const inAppMessages = _.filter(messages, (item: any) => item.type === 1 || item.type === 3);
    const systemMessages = _.filter(messages, (item: any) => item.type === 2);
    let lastInAppMessage;
    let lastSystemMessage;
    let lastStatusUpdate = 0;
    let lastOrderedHistory = 0;
    // Get the last in-app message
    if (_.size(inAppMessages) > 0) {
      lastInAppMessage = _.maxBy(inAppMessages, 'created');
    }
    // Get the last system message
    if (_.size(systemMessages) > 0) {
      lastSystemMessage = _.maxBy(systemMessages, 'created');
    }
    // Get the last status update
    if (_.size(_.get(sessionToRender, 'history')) > 0) {
      if (
        _.get(sessionToRender, 'history.session_invitation_notification') ||
        _.get(sessionToRender, 'history.session_invitation_notification_followup')
      ) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dictionary<any>' is not assignable to type '... Remove this comment to see the full error message
        lastOrderedHistory = utils.orderObjectByValues(sessionToRender.history, true);
        lastStatusUpdate = Object.values(lastOrderedHistory)[0];
      }
    }

    const lastCreatedInAppMessage = _.get(lastInAppMessage, 'created') || 0;
    const lastCreatedSystemMessage = _.get(lastSystemMessage, 'created') || 0;

    // Define InApp Message as the last message
    if (lastCreatedInAppMessage > lastCreatedSystemMessage && lastCreatedInAppMessage > lastStatusUpdate) {
      return {
        message: _.get(lastInAppMessage, 'body'),
        date: _.get(lastInAppMessage, 'created'),
        seen: _.get(lastInAppMessage, 'seen'),
        direction: _.get(lastInAppMessage, 'direction'),
      };
    }

    // Define System Message as the last message
    if (lastCreatedSystemMessage > lastCreatedInAppMessage && lastCreatedSystemMessage > lastStatusUpdate) {
      return {
        message: `[System Message] ${_.get(lastSystemMessage, 'body')}`,
        date: _.get(lastSystemMessage, 'created'),
        seen: true,
      };
    }

    // Define Status Update as the last message
    if (lastStatusUpdate > lastCreatedInAppMessage && lastStatusUpdate > lastCreatedSystemMessage) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      if (_.size(lastOrderedHistory) > 0) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        const getHistoryStatusUpdate = bookingParticipantUtils.getHistoryStatusUpdate(
          Object.keys(lastOrderedHistory)[0],
          user,
        );
        return {
          message: `[Status Update] ${_.get(getHistoryStatusUpdate, 'label')}`,
          date: Object.values(lastOrderedHistory)[0],
          seen: true,
        };
      }
    }
    return {
      message: '',
      date: '',
      seen: true,
    };
  };

  const sortMessagesToDisplay = (messages: any) => {
    return utils.orderArrayByField(messages, 'lastMessage', 'desc', 'date');
  };

  const scrollToBottom = () => {
    const messageTextBoxComponent = document.getElementById('anchorPoint');
    if (messageTextBoxComponent) {
      setTimeout(() => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0-1 arguments, but got 2.
        messageTextBoxComponent.scrollIntoView(true, {
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        toggleIsScrolled();
      }, 100);
    }
  };

  const focusOnMessageToSendComponent = () => {
    const messageToSendComponent = document.getElementById('messageToSend');
    if (messageToSendComponent) messageToSendComponent.focus();
  };

  const toggleIsScrolled = () => {
    setTimeout(() => setIsScrolled(true), 100);
  };

  const componentDecorator = (href: any, text: any, key: any) => {
    return (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const mergeMessagesWithHistory = (submission: any) => {
    const mergedArray: any = [];
    const history = _.get(submission, 'sessionToRender.history');
    _.each(submission.Messages, (message: any) => mergedArray.push(message));
    if (history) {
      const filteredHistory = utils.orderObjectByValues(history);
      _.each(filteredHistory, (value: any, key: any) => {
        const historyUpdate = bookingParticipantUtils.getHistoryStatusUpdate(
          key,
          _.get(submission, 'user'),
          submission,
        );
        if (historyUpdate) {
          mergedArray.push({
            type: 0,
            body: _.get(historyUpdate, 'label'),
            created: value,
            originalValue: { key, value },
            extraInfo: _.get(historyUpdate, 'extraInfo'),
          });
        }
      });
    }

    return mergedArray;
  };

  const markMessagesAsSeen = (messages: any) => {
    // Filter messages
    // * Haven't been seen yet
    // * Are in-app or batch out messages
    // * Are messages coming from participants (We don't want to mark our own messages as seen)
    const filteredUnseenMessages = _.filter(messages, (message: any) => {
      if (!message.seen && (message.type === 1 || message.type === 3) && message.direction === 4) return message;
    });
    if (_.size(filteredUnseenMessages) > 0) {
      props.markMultipleMessagesAsSeen(_.map(filteredUnseenMessages, (message: any) => message._id));
    }
  };

  const renderStatusUpdate = (message: any) => {
    return (
      <div key={`${message.originalValue.key}_${message.originalValue.value}`} className="statusUpdate">
        <p className="body">[Status Update] {message.body}</p>
        {message.extraInfo && <p className="extraInfoContainer">&apos;{message.extraInfo}&apos;</p>}
        <p className="date">{momentTimezone(message.created).tz(timezone).format('h:mm A')}</p>
      </div>
    );
  };

  const renderSystemMessage = (message: any) => {
    return (
      <div key={message._id} className="systemMessage">
        <img alt="Askable logo Icon" src="/askable-logo-icon.svg" />
        <div className="contentMessage">
          <p className="body">[System Message] {message.body}</p>
          <p className="date">{momentTimezone(message.created).tz(timezone).format('h:mm A')}</p>
        </div>
      </div>
    );
  };

  const renderTextMessage = (message: any, submission: any) => {
    // Check whether the participant selected received or sent the message
    const isSentByParticipant = _.get(submission, '_user_id')
      ? submission._user_id === message._from_user_id
      : details?._id !== message._from_user_id;

    // @TODO is this coming from a different user that we want to fetch or is it the
    return (
      <div key={message._id} className={`messageBox ${isSentByParticipant ? 'received' : 'sent'}`}>
        <p className="user">{message?.UserFrom?.meta?.identity?.firstname}</p>
        <p className="body">{message.body}</p>
        <div className="additionalInfo">
          <p className="time">{momentTimezone(message.created).tz(timezone).format('h:mm A')}</p>
          {message.seen && !isSentByParticipant && <p>Seen</p>}
        </div>
      </div>
    );
  };

  const renderMessages = (messages: any, submission: any) => {
    return _.map(messages, (message: any) => {
      // Check whether or not it is a Status Update
      if (!props.hideAutomatedMessages && message.type === 0) return renderStatusUpdate(message);

      // Check whether or not it is a System Message
      if (!props.hideAutomatedMessages && message.type === 2) return renderSystemMessage(message);

      // If it's not either a System Message or a Status Update then render the proper message
      return renderTextMessage(message, submission);
    });
  };

  const renderScheduledMessages = (updatedSubmission: any) => {
    let scheduledMessages = getScheduledMessages(updatedSubmission, props.booking);
    if (_.size(scheduledMessages) === 0) return null;
    scheduledMessages = utils.orderArrayByDate(scheduledMessages, 'time');

    return (
      <div className="scheduledMessagesContainer">
        <div className="scheduledHeader">
          <Clock color="#666" className="h-4 w-4" />
          <p>SCHEDULED AUTOMATED MESSAGES ({_.size(scheduledMessages)})</p>
        </div>
        <div className="scheduleContent">
          {_.map(scheduledMessages, ({ message, time }: any, index: any) => {
            return (
              <ScheduleMessage
                booking={props.booking}
                message={message}
                scheduledTime={time}
                onScrollToBottom={scrollToBottom}
                key={`scheduleMessage-${index}`}
              />
            );
          })}
          <div className="emptyComponent">.</div>
        </div>
      </div>
    );
  };

  const renderMessageTextBox = (updatedSubmission: any) => {
    if (props.hideSendMessage || isGuest) return null;
    return (
      <div id="messageTextBox" className="messageTextBox relative border-t border-border bg-background pt-2">
        <div className="" onClick={focusOnMessageToSendComponent}>
          <Label htmlFor="messageToSend" className="p-4">
            Write a message
          </Label>
          <Textarea
            disableAutoResizing
            id="messageToSend"
            placeholder={props.hintMessage || 'Your message here...'}
            value={messageToSend}
            onChange={(e: any) => setMessageToSend(e.target.value)}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            rows={6}
            maxRows={6}
            autoFocus
            variant="borderless"
            className="bg-transparent px-4 pt-2"
          />
        </div>
        <div className="actionsContainer">
          <Button
            variant={props.inlineSendButton ? 'ghost' : 'primary'}
            onClick={(event: any) => {
              if (_.size(messageToSend) <= 0 || loadingUI) {
                return null;
              }
              event.preventDefault();
              onClickSendMessage(updatedSubmission);
            }}
          >
            {props.inlineSendButton ? <Send className="h-5 w-5" /> : 'Send'}
          </Button>
        </div>
      </div>
    );
  };

  const renderMessagesContent = (messagesToDisplay: any) => {
    const updatedSubmission = _.find(messagesToDisplay, (submissions: any) => submissions._id === props.submission_id);

    if (!updatedSubmission) return null;

    // Merge messages and history to transform it into a proper timeline
    const mergedMessagesWithHistory = mergeMessagesWithHistory(updatedSubmission);

    // Order messages by creation date
    const orderedMergedArray = utils.orderArrayByDate(mergedMessagesWithHistory, 'created');

    // Group messages by date
    const groupedMessagesByDate = utils.groupByDates(orderedMergedArray, 'created');

    return (
      <Fragment>
        {!props.hideUserContainer && (
          <SheetHeader className="messageHeader px-4 py-2">
            <SheetTitle className="nameContainer">
              {_.get(updatedSubmission, 'user.meta.identity.firstname')}{' '}
              {_.get(updatedSubmission, 'user.meta.identity.lastname')}
            </SheetTitle>
            <div className="contactDetailsContainer">
              <p>{utils.formatPhoneNumber(_.get(updatedSubmission, 'user.contact.phone.mobile'))}</p>
              <a href={`mailto:${_.get(updatedSubmission, 'user.email')}`}>{_.get(updatedSubmission, 'user.email')}</a>
            </div>
          </SheetHeader>
        )}
        <div
          className={`messagesContentContainer ${isScrolled ? 'isScrolled' : ''} ${isGuest ? 'isGuest' : ''} ${
            props.messagesContentContainerClass ? props.messagesContentContainer : ''
          }`}
        >
          {_.size(groupedMessagesByDate) > 0 && (
            <Fragment>
              {_.map(groupedMessagesByDate, (date: any) => {
                return (
                  <div className="dateContainer" key={date.day}>
                    <p className="dateTitle">{date.day}</p>
                    <Linkify componentDecorator={componentDecorator}>
                      {renderMessages(date.group, updatedSubmission)}
                    </Linkify>
                  </div>
                );
              })}
              {!props.hideAutomatedMessages && renderScheduledMessages(updatedSubmission)}
              <div id="anchorPoint">Last message</div>
            </Fragment>
          )}
        </div>
        {props.showReplyNoteOptions && !props.hideSendMessage && (
          <div className="replyNotesContainer flex flex-col gap-2">
            <strong className="px-6 text-sm text-muted-foreground">Reply</strong>
            {renderMessageTextBox(updatedSubmission)}
          </div>
        )}
        {!props.showReplyNoteOptions && renderMessageTextBox(updatedSubmission)}
      </Fragment>
    );
  };

  const renderNormal = (messagesToDisplay: any) => {
    if (props.renderAsDrawer) {
      return (
        <Sheet open={props.open} modal={false} onOpenChange={props.onOpenChange}>
          <SheetContent className="messageDrawer" onInteractOutside={e => e.preventDefault()}>
            {renderMessagesContent(messagesToDisplay)}
          </SheetContent>
        </Sheet>
      );
    }
    return (
      <div className={`messageDrawer ${props.hideUserContainer ? 'ptop0' : ''}`}>
        {renderMessagesContent(messagesToDisplay)}
      </div>
    );
  };

  const renderWithQuery = () => {
    if (props.userData.loading) return <LoadingOverlay />;
    return (
      <Query
        query={fetchMessages}
        variables={{
          search: {
            _booking_id: _.get(props, 'booking._id'),
            _user_id: _.get(props, 'user._id'),
            _live_demo_room_id: _.get(props, '_live_demo_room_id'), // Argument passed for askable Live demos
            hide_batch_messages: true,
          },
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, subscribeToMore }: any) => {
          if (loading || error) {
            if (!props.renderAsDrawer) return null;
            return (
              <Sheet open={props.open} onOpenChange={props.onOpenChange}>
                <SheetContent>
                  <LoadingOverlay style={{ opacity: 0.8 }} />
                </SheetContent>
              </Sheet>
            );
          }

          const sessionToRender = getSessionToRender(props.bookingParticipant);
          const lastMessage = getLastMessage(sessionToRender, data.messages, props.user);

          let messagesToDisplay = [
            {
              _id: _.get(props, 'submission_id'),
              _user_id: _.get(props, 'user._id'),
              user: _.get(props, 'user'),
              BookingParticipant: _.get(props, 'bookingParticipant'),
              ParticipantFeedback: _.get(props, 'participantFeedback'),
              Messages: data.messages,
              lastMessage,
              sessionToRender,
            },
          ];

          messagesToDisplay = sortMessagesToDisplay(messagesToDisplay);

          // Mark messages as seen
          if (!props.hideSendMessage) markMessagesAsSeen(_.get(messagesToDisplay, '[0].Messages'));

          return (
            <MessageDrawerQuery
              scrollToBottom={scrollToBottom}
              subscribeToRealTimeData={() => {
                subscribeToMore({
                  document: messagesSubscription,
                  variables: {
                    filter: {
                      _booking_id: _.get(props, 'booking._id'),
                      _user_id: _.get(props, 'user._id'),
                      _live_demo_room_id: _.get(props, '_live_demo_room_id'), // Argument passed for askable Live demos
                    },
                  },
                  updateQuery: (prev: any, { subscriptionData }: any) => {
                    if (!subscriptionData.data) return prev;

                    const newMessageItem = subscriptionData.data.messagesSubscription;
                    scrollToBottom();
                    return { ...prev, messages: [newMessageItem, ...prev.messages] };
                  },
                });
              }}
            >
              {renderNormal(messagesToDisplay)}
            </MessageDrawerQuery>
          );
        }}
      </Query>
    );
  };

  return (
    <Fragment>
      {props.messagesToDisplay && renderNormal(props.messagesToDisplay)}
      {!props.messagesToDisplay && renderWithQuery()}
    </Fragment>
  );
}

const createMessageContainer = graphql(createMessageMutation, {
  props: ({ mutate }) => ({
    createMessage: (message: any) =>
      mutate?.({
        variables: { message },
      }),
  }),
});

const markMultipleMessagesAsSeenContainer = graphql(markMultipleMessagesAsSeenMutation, {
  props: ({ mutate }) => ({
    markMultipleMessagesAsSeen: (_messages_id: any) =>
      mutate?.({
        variables: { _messages_id },
      }),
  }),
});

const userDetailsContainer = graphql(fetchUserById, {
  name: 'userData',
  options: () => ({
    fetchPolicy: 'no-cache',
  }),
});

export default _.flowRight(
  userDetailsContainer,
  createMessageContainer,
  markMultipleMessagesAsSeenContainer,
)(MessageDrawer);
