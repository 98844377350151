import { Button } from '@askable/ui/components/ui/button';
import { Survey } from '@askable/ui/components/unmod/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

const CURRENCY_SYMBOL = '$';

const RewardDurationSection = () => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 text-sm">
      <div className="flex flex-col gap-1">
        <span>{t('sections.studies.recruit.reward')}</span>
        <div className="flex h-7 items-center gap-1 text-lg font-semibold">
          {CURRENCY_SYMBOL}
          <div className="h-full w-10 rounded-sm bg-darkest/10"></div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <span>{t('sections.studies.recruit.duration')}</span>
        <div className="h-7 w-14 rounded-sm bg-darkest/10"></div>
      </div>
    </div>
  );
};

export const OpportunityListingPreview = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();

  // Using useWatch for better performance than watch
  const [title, description] = useWatch({ control, name: ['listing_title', 'listing_description'] });

  // Display current time
  const now = new Date();
  const hours = now.getHours() % 12 || 12;
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return (
    <div
      className="m-auto flex aspect-[9/18] w-full max-w-80 select-none flex-col overflow-hidden rounded-[30px] border-[6px]
        border-darkest bg-brand-accent-2 shadow-md"
      aria-label={t('global.preview')}
    >
      <div className="grid grid-cols-3 items-center px-4 py-2 text-sm font-semibold leading-none text-darkest">
        <div>{`${hours}:${minutes}`}</div>
        <div>
          <div className="h-6 w-full rounded-full bg-darkest"></div>
        </div>
        <div className="flex justify-end gap-1">
          <div className="h-[9px] w-4 rounded-sm bg-darkest/30">
            <div className="h-full w-3 rounded-l-sm bg-darkest"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-between gap-4 p-4 text-darkest">
        {title ? (
          <div className="line-clamp-5 text-balance font-bricolage-grotesque text-3xl font-semibold text-darkest md:text-4xl">
            {title}
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <div className="h-10 w-full rounded-sm bg-darkest/10"></div>
            <div className="h-10 w-full rounded-sm bg-darkest/10"></div>
            <div className="h-10 w-2/3 rounded-sm bg-darkest/10"></div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2 text-sm font-semibold">
            <Survey size={24} />
            <span>{t('sections.studies.recruit.onlineTask')}</span>
          </div>

          <RewardDurationSection />
        </div>
      </div>

      <div className="flex flex-col gap-1 overflow-hidden rounded-t-md bg-background p-4">
        <h2 className="text-lg font-semibold text-foreground">{t('sections.studies.recruit.aboutThisOpportunity')}</h2>
        <div className="min-h-20 overflow-auto whitespace-pre-wrap text-sm">
          {description ? description : <span className="text-muted-foreground">...</span>}
        </div>
      </div>

      <footer className="flex items-center justify-between gap-4 border-t border-border bg-background p-4">
        <div className="flex h-7 items-center gap-1 text-lg font-semibold">
          {CURRENCY_SYMBOL}
          <div className="h-full w-8 rounded-sm bg-foreground/10"></div>
        </div>

        <Button disabled variant="primary" className="flex-1 rounded-xl">
          {t('sections.studies.recruit.apply')}
        </Button>
      </footer>
    </div>
  );
};
