import { toast } from '@askable/ui/components/ui/sonner';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { match } from 'ts-pattern';
import { CombinedError, useMutation } from 'urql';

import { UpdateTaskBlockFigmaPrototype } from 'containers/Studies/BuildStudy/data/UpdateTaskBlockFigmaPrototype.mutation';
import { UpdateUnmoderatedBookingConfig } from 'containers/Studies/BuildStudy/data/UpdateUnmoderatedBookingConfig.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { blockSchema } from 'containers/Studies/data/schemas/blockSchema';
import { useFormAutoSave } from 'containers/Studies/utils/useFormAutoSave';
import { SystemBlockType, TaskBlockType } from 'generated/graphql';

import type { ActiveBlock } from '../hooks/useActiveBlockId';
import type { ReactNode } from 'react';
import type { z } from 'zod';

export type BlockFormFields = z.infer<typeof blockSchema>;

interface Props {
  children: ReactNode;
  activeBlock: NonNullable<ActiveBlock>;
  activeBlockId: string;
}

export const BlockForm = ({ children, activeBlock, activeBlockId }: Props) => {
  const [, updateUnmoderatedBookingConfig] = useMutation(UpdateUnmoderatedBookingConfig);
  const [, updateTaskBlockFigmaPrototype] = useMutation(UpdateTaskBlockFigmaPrototype);

  const { newBlockId, setIsSaving, studyId } = useStudyContext();
  const form = useForm<BlockFormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(blockSchema),
    defaultValues: {
      type: activeBlock.type,
      instructions: activeBlock.instructions,
      is_recording_enabled: 'is_recording_enabled' in activeBlock ? activeBlock.is_recording_enabled : false,
      title: activeBlock.title,
      figma_prototype:
        'figma_prototype' in activeBlock
          ? {
              file_id: activeBlock.figma_prototype?.file_id ?? '',
              start_screen_id: activeBlock.figma_prototype?.start_screen_id ?? '',
              goal_screen_id: activeBlock.figma_prototype?.goal_screen_id ?? '',
            }
          : undefined,
    },
  });

  // Validate form immediately if user is not currently creating a new one
  useEffect(() => {
    if ('_id' in activeBlock && activeBlock._id !== newBlockId) {
      form.trigger();
    }
  }, [activeBlock, form, newBlockId]);

  const handleSubmit = useCallback(
    async ({ values }: { values: BlockFormFields }) => {
      try {
        setIsSaving(true);

        // System blocks (welcome | thankyou) are stored in the study config, so we handle
        // them differently here
        const res = await match(values.type)
          .returnType<Promise<any> | void>()
          .with(SystemBlockType.Welcome, () =>
            updateUnmoderatedBookingConfig({
              input: {
                _id: studyId,
                welcome_block: {
                  title: values.title,
                  instructions: values.instructions || '',
                },
              },
            }),
          )
          .with(SystemBlockType.ThankYou, () =>
            updateUnmoderatedBookingConfig({
              input: {
                _id: studyId,
                thank_you_block: {
                  title: values.title,
                  instructions: values.instructions || '',
                },
              },
            }),
          )
          .with(TaskBlockType.FigmaPrototype, () =>
            updateTaskBlockFigmaPrototype({
              input: {
                _id: studyId,
                task_block: {
                  _id: activeBlockId,
                  title: values.title,
                  instructions: values.instructions,
                  is_recording_enabled: 'is_recording_enabled' in values ? values.is_recording_enabled : false,
                  figma_prototype:
                    'figma_prototype' in values && values.figma_prototype?.file_id
                      ? {
                          file_id: values.figma_prototype.file_id,
                          start_screen_id: values.figma_prototype.start_screen_id,
                          goal_screen_id: values.figma_prototype.goal_screen_id || null,
                        }
                      : // TODO: check if we can/want to fix the gql schema to make the `figma_prototype` field nullable
                        // right now things break if we set it to null and then attempt to update it to an object :|
                        {},
                },
              },
            }),
          )
          .otherwise(() => {
            // Unsupported type
          });

        if (!res || res?.error) {
          throw new Error(res?.error.message || 'Update failed');
        }
      } catch (err) {
        toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
      } finally {
        setTimeout(() => setIsSaving(false), 200);
      }
    },
    [setIsSaving, updateUnmoderatedBookingConfig, studyId, updateTaskBlockFigmaPrototype, activeBlockId],
  );

  // Auto submit the form on every change
  useFormAutoSave({ form, handleSubmit, preventTrigger: activeBlockId === newBlockId });

  return <FormProvider {...form}>{children}</FormProvider>;
};
