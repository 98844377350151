import { AnchorButton } from '@askable/ui/components/ui/button';
import { Link } from 'react-router-dom';

import {
  bookingParticipantSelectors,
  useBookingParticipantState,
} from 'containers/Booking/containers/BookingParticipantsContainer/state/booking-participant-state';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

export const Segments = () => {
  const { study } = useStudyContext();
  const segments = useBookingParticipantState(bookingParticipantSelectors.allSegments);
  const activeSegment = useBookingParticipantState(bookingParticipantSelectors.segment);
  const changeSegment = useBookingParticipantState(state => state.changeSegment);

  return (
    <div className="flex h-full flex-col gap-3 border-r-0.5 border-border p-3">
      <ul className="flex flex-col gap-1">
        {segments?.map(segment => {
          return (
            <li key={segment?._id}>
              <AnchorButton
                variant={segment?._id === activeSegment?._id ? 'secondary' : 'ghost'}
                className="w-full justify-start"
                asChild
              >
                <Link
                  to={{ pathname: `/studies/${study._id}/recruit/participants/${segment._id}`, hash: 'segments' }}
                  onClick={() => changeSegment(segment?._id)}
                >
                  {segment?.name}
                </Link>
              </AnchorButton>
            </li>
          );
        })}
      </ul>
      {segments.length === 1 ? (
        <div className="flex flex-col items-center justify-center gap-2 py-6 text-center">
          <img src="/segments-empty-state.svg" className="h-20" aria-hidden="true" />
          <div className="text-sm text-foreground-subtle">Apply filters to create custom segments of participants</div>
        </div>
      ) : null}
    </div>
  );
};
