import { graphql } from 'src/graphql';

import type { ResultOf } from 'gql.tada';

export const BookingSubmissionsWithMessages = graphql(`
  query bookingSubmissionsWithMessages(
    $bookingId: ID!
    $userId: ID
    $displayParticipantsWithoutAvailability: Boolean
    $hideBatchMessages: Boolean
    $status: [String]
    $limit: Int
    $page: Int
  ) {
    bookingSubmissionsWithMessages(
      bookingId: $bookingId
      userId: $userId
      displayParticipantsWithoutAvailability: $displayParticipantsWithoutAvailability
      hideBatchMessages: $hideBatchMessages
      status: $status
      limit: $limit
      page: $page
    ) {
      nodes {
        _id
        status
        user {
          _id
          displayName
          contact {
            phone {
              country_code
              mobile
            }
          }
          email
        }
        session {
          session {
            _id
            end
            start
          }
        }
        status_updated
        Messages {
          _id
          _from_user_id
          _to_user_id
          body
          created
          direction
          seen
          type
          UserFrom {
            meta {
              identity {
                firstname
              }
            }
          }
        }
      }
      currentPage
      totalCount
      totalPages
    }
  }
`);

type TBookingSubmissionsWithMessages = NonNullable<ResultOf<typeof BookingSubmissionsWithMessages>>;

export type Submissions = TBookingSubmissionsWithMessages['bookingSubmissionsWithMessages'];

export type Submission = NonNullable<
  NonNullable<TBookingSubmissionsWithMessages['bookingSubmissionsWithMessages']>['nodes'][number]
>;

export type SubmissionStatus = NonNullable<Submission['status']>;

export type Message = NonNullable<
  NonNullable<
    NonNullable<TBookingSubmissionsWithMessages['bookingSubmissionsWithMessages']>['nodes'][number]
  >['Messages']
>[number];

export type User = NonNullable<
  NonNullable<TBookingSubmissionsWithMessages['bookingSubmissionsWithMessages']>['nodes'][number]
>['user'];
